import React, { FunctionComponent, useState } from 'react';
import { Sender } from 'xstate';
import Container, { Subgrid } from '../../../design-system/components/Container';
import DepartureContainer from '../../../design-system/components/DepartureContainer';
import { Sticky } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import { CatalogContext, CatalogEvent } from '../../../fsm/catalog/catalogMachine';
import * as API from '../../../fsm/types';
import { getSelected, getSelectedSailings, indexToLeg } from '../../../fsm/utils/sailingUtils';
import Summary from '../../common/Summary';
import CatalogFooter, { CatalogFooterPassedProps } from '../CatalogFooter';
import CelebrationPackages from './CelebrationPackages';
import MealSelection, { MealSelectionType } from './MealSelection';
import NightOnboard from './NightOnboard';
import OnboardServices from './OnboardServices';
import WellnessServices from './WellnessServices';
import WifiSelection from './WifiSelection';
import { SailingMeta, TripType } from '../../../fsm/types';
import LoungeSelection from './LoungeSelection';
import { isMealForced } from '../../../fsm/utils/productUtils';

type DetailProps = {
  context: CatalogContext;
  send: Sender<CatalogEvent>;
};

const catalogId = (index: number) => `catalog-${index}`;

const DetailView: FunctionComponent<DetailProps & CatalogFooterPassedProps> = ({ context, send, ...footerProps }) => {
  const addOnboard =
    (...indices: number[]) =>
    (code: string, amount?: number) => {
      send({ type: 'ADD_ONBOARD', legs: indices.map((i) => indexToLeg(i)), code, amount });
    };

  const removeOnboard =
    (...indices: number[]) =>
    (code: string, amount?: number) => {
      send({ type: 'REMOVE_ONBOARD', legs: indices.map((i) => indexToLeg(i)), code, amount });
    };

  const { agreement, catalogs, passengers, searchParams } = context;

  const [lastSectionSeen, setLastSectionSeen] = useState(0);
  const catalogsWithSailings = catalogs.filter((c) => c.sailings.length > 0);
  const allSectionsSeen = catalogsWithSailings.length <= lastSectionSeen + 1;
  const sbTicket = hooks.useStoryblokComponents('products/tickets');
  const [sbLegSummary] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.leg_summary',
  });

  const requiredProductsList = [
    API.ProductType.NIGHT,
    API.ProductType.FOOD,
    API.ProductType.WIFI,
    API.ProductType.PACKAGE,
  ];
  const anyOnboardsPresent = catalogsWithSailings.some((catalog) => {
    const catalogWithMatchingProducts = getSelected(catalog).filter(({ products }) => {
      const productKeys = Object.keys(products);
      return requiredProductsList.some((listedProduct) => productKeys.some((key) => key === listedProduct));
    });
    return catalogWithMatchingProducts.isJust();
  });
  const footerToggle = !anyOnboardsPresent || allSectionsSeen; // Change the label of the button at bottom

  return (
    <>
      <Sticky.Scroll>
        {catalogs.map((catalog, index) => {
          const selected = getSelected(catalog);
          const products = selected
            .chainNullable(({ products }) => (API.isError(products) ? null : products))
            .extract();

          const [sbLegsWithFreeMeals] = hooks.useStoryblokComponent<HTMLDivElement>({
            path: 'onboard.legs_with_free_meals',
          });
          const [sbShipsWithFreeMeals] = hooks.useStoryblokComponent<HTMLDivElement>({
            path: 'onboard.ships_with_free_meals',
          });
          const legsWithFreeMeals: string[] = sbLegsWithFreeMeals?.content.legs || [];
          const shipsWithFreeMeals: string[] = sbShipsWithFreeMeals?.content.ships || [];
          const selectedLegName = selected.chainNullable((_) => _.legCode);
          const selectedShipName = selected.chainNullable((_) => _.shipName);

          const freeMealsOnLeg = selectedLegName
            .map((name) => legsWithFreeMeals.some((legName) => legName === name))
            .orDefault(false);
          const freeMealsOnShip = selectedShipName
            .map((name) => shipsWithFreeMeals.some((shipName) => shipName === name))
            .orDefault(false);
          const freeMeals = freeMealsOnLeg && freeMealsOnShip;

          const [sbHeroImage, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
            fullSlug: `components/details-hero-image/${selected.mapOrDefault((_) => _.legCode, '')}`,
          });

          const mealForced = isMealForced(
            catalogs,
            context.searchParams.type,
            selected.mapOrDefault((_) => _.meta, { loading: false } as SailingMeta),
            selected.mapOrDefault((_) => _.offerCode, undefined)
          );

          const handleScrolledToView = (isVisible: boolean) => {
            if (isVisible && catalog.index > lastSectionSeen) {
              setLastSectionSeen(catalog.index);
            }
          };

          return selected
            .map((selected) => (
              <Sticky.Container key={catalog.index} {...{ handleScrolledToView }} id={catalogId(catalog.index)}>
                <DepartureContainer.Detail
                  ref={ref}
                  origin={API.Port[selected.departurePort as API.PortString]}
                  destination={API.Port[selected.arrivalPort as API.PortString]}
                  image={sbHeroImage?.content?.image?.image}
                />
                <Container>
                  <Subgrid variant="spacious" cols={2}>
                    {products?.NIGHT && (
                      <NightOnboard
                        sailing={{ ...selected }}
                        passengers={passengers}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode, passengerTypes) =>
                          send({ type: 'ON_NIGHT_OPEN', data: { sailingCode, passengerTypes } })
                        }
                      />
                    )}

                    {(products?.FOOD || freeMeals) &&
                      (context.searchParams.type !== TripType.OVERNIGHT_CRUISE || index === 0) && (
                        <MealSelection
                          passengerInfo={passengers}
                          passengers={context.searchParams.passengers}
                          sailing={selected}
                          add={
                            context.searchParams.type === TripType.OVERNIGHT_CRUISE
                              ? addOnboard(0, 1)
                              : addOnboard(catalog.index)
                          }
                          remove={
                            context.searchParams.type === TripType.OVERNIGHT_CRUISE
                              ? removeOnboard(0, 1)
                              : removeOnboard(catalog.index)
                          }
                          onOpen={(sailingCode, passengerTypes) =>
                            send({ type: 'ON_MEAL_OPEN', data: { sailingCode, passengerTypes } })
                          }
                          {...{ freeMeals }}
                          mealSelectionType={MealSelectionType.PACKAGE}
                          disableModify={mealForced}
                        />
                      )}

                    {products?.FOOD && (
                      <MealSelection
                        passengerInfo={passengers}
                        passengers={context.searchParams.passengers}
                        sailing={selected}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode, passengerTypes) =>
                          send({ type: 'ON_MEAL_OPEN', data: { sailingCode, passengerTypes } })
                        }
                        {...{ freeMeals }}
                        mealSelectionType={MealSelectionType.INDIVIDUAL_MEAL}
                        disableModify={false}
                      />
                    )}

                    {products?.FOOD && (
                      <MealSelection
                        passengerInfo={passengers}
                        passengers={context.searchParams.passengers}
                        sailing={selected}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode, passengerTypes) =>
                          send({ type: 'ON_MEAL_OPEN', data: { sailingCode, passengerTypes } })
                        }
                        {...{ freeMeals }}
                        mealSelectionType={MealSelectionType.ALA_CARTE}
                        disableModify={false}
                      />
                    )}

                    {products?.WIFI && (
                      <WifiSelection
                        sailing={selected}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode) => send({ type: 'ON_WIFI_OPEN', data: { sailingCode } })}
                      />
                    )}

                    <OnboardServices sailing={selected} leg={catalog.index} />

                    {products?.PACKAGE && (
                      <CelebrationPackages
                        sailing={{ ...selected }}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode) => send({ type: 'ON_CELEBRATION_PACKAGES_OPEN', data: { sailingCode } })}
                      />
                    )}

                    {products?.LOUNGE && (
                      <LoungeSelection
                        passengers={context.searchParams.passengers}
                        passengerInfo={passengers}
                        sailing={selected}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode, passengerTypes) =>
                          send({
                            type: 'ON_LOUNGE_PACKAGES_OPEN',
                            data: { sailingCode, passengerTypes },
                          })
                        }
                      />
                    )}

                    {/* {products?.COUPON && (
                      <OnboardCoupons
                        sailing={{ ...selected }}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode) => send({ type: 'ON_ONBOARD_COUPONS_OPEN', data: { sailingCode } })}
                      />
                    )} */}

                    {products?.WELLNESS && (
                      <WellnessServices
                        passengers={context.searchParams.passengers}
                        passengerInfo={passengers}
                        sailing={{ ...selected }}
                        add={addOnboard(catalog.index)}
                        remove={removeOnboard(catalog.index)}
                        onOpen={(sailingCode) => send({ type: 'ON_WELLNESS_SERVICES_OPEN', data: { sailingCode } })}
                      />
                    )}
                  </Subgrid>
                  <Subgrid variant="spacious" hideMobile>
                    {selected && (
                      <Summary
                        small
                        agreement={agreement}
                        index={catalog.index}
                        sailing={selected}
                        sbLegSummary={sbLegSummary}
                        sbTicket={sbTicket}
                        offerCode={selected.offerCode}
                      />
                    )}
                  </Subgrid>
                </Container>
              </Sticky.Container>
            ))
            .extract();
        })}
      </Sticky.Scroll>
      <CatalogFooter
        {...footerProps}
        summary={{
          agreement,
          sailings: getSelectedSailings(catalogs),
          offerCode: searchParams.offerCode,
        }}
        toggle={footerToggle}
        moreId={catalogId(lastSectionSeen + 1)}
      />
    </>
  );
};

export default DetailView;
