import { Either } from 'purify-ts/Either';
import { List } from 'purify-ts/List';
import { listTimeTableAvailability } from '../../graphql/queries';
import { CatalogContext } from '../catalog/catalogMachine';
import {
  AccommodationError,
  Catalog,
  ExtendedAccommodation,
  ExtendedPassenger,
  Form,
  isPassengerInput,
  isPetInput,
  ListTimeTableAvailabilityQuery,
  PassengerInput,
  PetInput,
  Port,
  Tariff,
  Timetable,
  TimetableQuery,
  VehicleInput,
} from '../types';
import {
  findEveningSailing,
  getNumberOfDeparturesPerDayBetweenPorts,
  getOfferCode,
  getSelected,
  indexToLeg,
} from '../utils/sailingUtils';
import { fetchSailingData, GENERIC_STARCLUB_ID } from './api';
import * as GraphQL from './graphql';
import { settings, isDaySailing } from '../../settings';
import logger from '../../utils/logging';

const MAX_DEPARTURES = 21;
const MAX_DAYS = 20;

/**
 * Used to create initial timetables with some missing information from catalog data.
 * WARNING! use this only as an escape hatch when the Timetable data can't be retrieved from Grimaldi.
 *
 * @param catalogs
 */
export const createInitialTimetables = (catalogs: Catalog[]): Timetable[] => {
  const initialFields = {
    availabilityInfo: null,
    chargeTotal: null,
    crossingTime: null,
    moreInfo: null,
    voyageCode: null,
    isAvailable: false,
    availabilityReason: 'GraphQL Error',
  };

  return catalogs.reduce((timetables, catalog, index) => {
    const sailings = catalog.sailings.reduce((sailings, sailing) => {
      const timetable: Timetable = {
        ...sailing,
        ...initialFields,
        __typename: 'Timetable',
        legCode: indexToLeg(index).toString(10),
      };

      return sailings.concat(timetable);
    }, [] as Timetable[]);

    return timetables.concat(sailings);
  }, [] as Timetable[]);
};

/**
 * Builds a valid TimetableQuote query from the catalog context.
 * Additionally, resolves the default accommodations. Hence, the return value is a Promise.
 *
 * @param context
 */
export async function buildQuery(
  context: CatalogContext,
  catalog: Catalog,
  requireAccommodation: boolean = false,
  removeOfferCode: boolean = false
): Promise<TimetableQuery> {
  const { searchParams, passengers, vehicles, agreement } = context;
  const { forms, currency, language, starclub, offerCode, type } = searchParams;

  const query: TimetableQuery = {
    currency,
    language,
    starclub: starclub ? { code: agreement?.code || GENERIC_STARCLUB_ID } : undefined,
    tariff: [],
    passengers: [],
    pets: [],
    vehicles: [],
    sailings: [],
    accommodations: [],
    // Onboard products are not needed for initial prices at this point.
    onboards: [],
  };

  const people: ExtendedPassenger[] = passengers.filter(isPassengerInput);
  const pets: PetInput[] = passengers.filter(isPetInput);

  const { index } = catalog;
  const legCode = 1;

  query.tariff?.push(formatTariff(legCode));

  people
    .filter((person) => person.legs.includes(indexToLeg(index)))
    .map((person) => query.passengers?.push(formatPassenger(legCode, person)));

  pets.filter((pet) => pet.legs.includes(indexToLeg(index))).map((pet) => query.pets?.push(formatPet(legCode, pet)));

  vehicles
    .filter((vehicle) => vehicle.legs.includes(indexToLeg(index)))
    .map((vehicle) => query.vehicles?.push(formatVehicle(legCode, vehicle)));

  const actualOfferCode = getOfferCode(type, offerCode, !removeOfferCode);

  query.sailings?.push(formatSailing(legCode, catalog, forms, actualOfferCode));

  if (sailingRequiresAccommodation(context, index) || requireAccommodation) {
    await getSelected(catalog).caseOf({
      Nothing: async () => {
        logger.warn('No selected sailing found for catalog item:', catalog);
        return Promise.reject('Missing selected sailing');
      },
      Just: async (selected) => {
        let defaultAccommodations = [] as ExtendedAccommodation[] | AccommodationError;
        let sailingsToCheck = [...catalog.sailings];
        while (defaultAccommodations.length === 0 && sailingsToCheck.length > 0) {
          const sailing =
            isDaySailing({ departureTime: selected.departureTime }) || !sailingsToCheck.includes(selected)
              ? findEveningSailing(sailingsToCheck) || sailingsToCheck.shift()
              : selected;

          if (!sailing) {
            break;
          }

          const sailingData = await fetchSailingData({
            ...searchParams,
            passengers,
            index: catalog.index,
            sailing: sailing || selected,
          });

          if (
            typeof sailingData.defaultAccommodations !== 'string' &&
            sailingData.defaultAccommodations !== undefined &&
            sailingData.defaultAccommodations.length > 0
          ) {
            defaultAccommodations = sailingData.defaultAccommodations;
          } else {
            sailingsToCheck = [...sailingsToCheck.filter((s) => s.sailingCode !== sailing?.sailingCode)];
          }
        }

        if (typeof defaultAccommodations !== 'string' && defaultAccommodations.length > 0) {
          const formattedAccommodations = defaultAccommodations.map((acc) => formatAccommodation(legCode, acc, people));
          query.accommodations?.push(...formattedAccommodations);
        }
      },
    });
  }

  return query;
}

export async function buildQueryWithOptions(
  context: CatalogContext,
  catalog: Catalog,
  queryOptions: { forceAccommodation: boolean; removeOfferCode: boolean }
): Promise<TimetableQuery> {
  return buildQuery(context, catalog, queryOptions.forceAccommodation, queryOptions.removeOfferCode);
}

/**
 * Fetches the timetables (sailings) using the provided query object.
 * @param query
 */
export async function fetchSailings(query: TimetableQuery): Promise<Either<Error, Timetable[]>> {
  return await GraphQL.query<ListTimeTableAvailabilityQuery>(listTimeTableAvailability, query)
    .map((_) => _.listTimeTableAvailability as Timetable[])
    .run();
}

const formatTariff = (legCode: number) => ({
  legCode,
  type: Tariff.SPECIAL,
});

const formatPassenger = (legCode: number, person: ExtendedPassenger) => ({
  legCode,
  id: person.id,
  type: person.type,
});

const formatPet = (legCode: number, pet: PetInput) => ({
  legCode,
  number: pet.id,
  type: pet.type,
});

const formatVehicle = (legCode: number, vehicle: VehicleInput) => ({
  legCode,
  type: vehicle.type,
  height: vehicle.heightCm,
  length: vehicle.lengthCm,
});

const formatAccommodation = (legCode: number, accommodation: ExtendedAccommodation, people: PassengerInput[]) => ({
  legCode,
  code: accommodation.code,
  type: accommodation.type,
  passengers: (accommodation.passengers || [])
    .map((id) => {
      const person = people.find((p) => p.id === id);

      return person ? { id: person.id, type: person.type } : null;
    })
    .filter(Boolean),
});

const formatSailing = (legCode: number, catalog: Catalog, forms: Form[], offerCode?: string) => {
  const params = List.at(catalog.index, forms).chainNullable((form) => form.params);
  const arrivalPort = params.chainNullable((_) => _.arrivalPort).orDefault('' as Port);
  const departurePort = params.chainNullable((_) => _.departurePort).orDefault('' as Port);
  const startDate = params.chainNullable((_) => _.startDate).orDefault('');
  const endDate = params.chainNullable((_) => _.endDate).orDefault('');
  const numberOfDays = params
    .chainNullable((_) => _.numberOfDays)
    .map((n) => Number.parseInt(n, 10))
    .orDefault(1);
  // Ensure enough departures are returned by multiplying max departures / day with 2.
  const numberOfDepartures = 2 * numberOfDays * getNumberOfDeparturesPerDayBetweenPorts(arrivalPort, departurePort);

  return {
    legCode,
    offerCode,
    arrivalPort,
    departurePort,
    startDate,
    endDate,
    // Limits the number of departures/days to predefined maximum value
    numberOfDays: numberOfDays > MAX_DAYS ? MAX_DAYS : numberOfDays,
    numberOfDepartures: numberOfDepartures > MAX_DEPARTURES ? MAX_DEPARTURES : numberOfDepartures,
  };
};

const sailingRequiresAccommodation = (context: CatalogContext, index = 0): boolean => {
  const {
    searchParams: { forms, petCount },
  } = context;

  // Travelling with a pet always requires a cabin.
  if (petCount > 0) {
    return true;
  }

  const params = List.at(index, forms).chainNullable((form) => form.params);
  const arrivalPort = params.chainNullable((_) => _.arrivalPort).orDefault('' as Port);
  const departurePort = params.chainNullable((_) => _.departurePort).orDefault('' as Port);

  return (
    !settings.accommodationNotRequired.includes(departurePort) &&
    !settings.accommodationNotRequired.includes(arrivalPort)
  );
};
