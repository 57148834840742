import React, { FunctionComponent } from 'react';
import Container from '../../../design-system/components/Container';
import Footer from '../../../design-system/components/Footer';
import Link from '../../../design-system/components/Link';
import Spinner from '../../../design-system/components/Spinner';
import { H1, H2, Lead } from '../../../design-system/components/Text';
import { hooks } from '../../../design-system/helpers/mixins';
import { PaymentInterpreter } from '../../../fsm/payment/paymentMachine';
import { Port } from '../../../fsm/types';
import { formatString } from '../../../utils/formats';
import { useService } from '../../../xstate-react';
import Notification from '../../common/Notification';

interface SuccessViewProps {
  readonly service: PaymentInterpreter;
  readonly moveToSearch: () => void;
}

function onClickHandler(func: () => void) {
  return (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    func();
  };
}
const SuccessView: FunctionComponent<SuccessViewProps> = ({ moveToSearch, service }) => {
  const [current, send] = useService(service);

  const [sbSuccess, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment-success.payment_success',
  });

  return (
    <>
      <Container variant="text" ref={ref}>
        <H1>{sbSuccess?.content.title}</H1>
        <H2>
          {formatString(sbSuccess?.content.reference_number, current.context.reservation?.bookingDetail.reference)}
        </H2>
        {current.context.trip && (
          <Notification
            legs={current.context.trip.sailings.map(({ sailing }) => [
              sailing.departurePort as Port,
              sailing.arrivalPort as Port,
            ])}
            storyblokPath="payment-success.notification-list"
          />
        )}
        <Lead>
          {formatString(
            sbSuccess?.content.info_text,
            <strong key="email">{current.context.reservation?.contactInfo.email}</strong>
          )}
        </Lead>
        <Lead>
          {current.matches('success.idle') ? (
            <Link onClick={onClickHandler(() => send({ type: 'DOWNLOAD_PDF' }))}>
              {sbSuccess?.content.save_link_label}
            </Link>
          ) : (
            <Spinner />
          )}
          <br />
          {/*
          <Link href="#">{sbSuccess?.content.print_link_label}</Link>
        */}
          <br />
          <Link onClick={onClickHandler(moveToSearch)}>{sbSuccess?.content.start_new_reservation}</Link>
        </Lead>
      </Container>
      <Footer.Bar fixed>
        <Footer.FinishLink href={sbSuccess?.content.finnlines_url.url}>
          {sbSuccess?.content.finnlines_link_label}
        </Footer.FinishLink>
      </Footer.Bar>
    </>
  );
};

export default SuccessView;
