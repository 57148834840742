import React from 'react';
import styled from 'styled-components';

import { typography } from '../../helpers/mixins';

import { Lead } from '../Text';
import Link from '../Link';

interface TabWrapperProps {
  readonly active?: boolean;
}

interface TabProps extends TabWrapperProps {
  readonly asa?: any;
  readonly location?: string;
}

export const TabWrapper = styled(Lead)<TabWrapperProps & { as: 'li' }>`
  ${({ active }) => (active ? typography.h3 : typography.lead)}
`;

const Tab: React.FunctionComponent<TabProps & React.HTMLProps<HTMLAnchorElement>> = ({
  location,
  asa,
  active,
  ...props
}) => {
  return (
    <TabWrapper active={(location && props.href === location) || active} as={'li'}>
      {props.href !== location ? <Link to={props.href} as={asa} {...props} /> : <span {...props} />}
    </TabWrapper>
  );
};

export default Tab;
