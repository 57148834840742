import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { PaymentContext } from '../payment/paymentMachine';
import {
  PaymentProvider,
  StartPaymentMutation,
  isApiError,
  VerifyPaymentMutation,
  isPaymentError,
  ErrorCode,
  ApiError,
} from '../types';

export const startPayment = async ({ currency, language, redirectUrl, reservation }: PaymentContext): Promise<any> => {
  try {
    // If user has already started payment process once,
    // let's reuse the same redirect URL and not register new one.
    if (redirectUrl) {
      return { redirectUrl };
    } else {
      const result = await API.graphql(
        graphqlOperation(mutations.startPayment, {
          input: {
            currency,
            language: language.toUpperCase(),
            provider: PaymentProvider.NETS_NETAXEPT,
            reference: reservation?.bookingDetail.reference,
          },
        })
      );

      const { data, errors } = result as GraphQLResult<StartPaymentMutation>;

      if (errors || !data || !data.startPayment || isApiError(data.startPayment) || isPaymentError(data.startPayment)) {
        console.error('Start of payment process failed, data:', data, 'errors:', errors);
        return Promise.reject(errors || data?.startPayment);
      } else {
        return data.startPayment;
      }
    }
  } catch (e) {
    console.error('startPayment', e);
    return Promise.reject(e);
  }
};

export const verifyPayment = async ({
  currency,
  language,
  reservation,
  transactionId,
}: PaymentContext): Promise<any> => {
  try {
    const result = await API.graphql(
      graphqlOperation(mutations.verifyPayment, {
        input: {
          currency,
          transactionId,
          language: language.toUpperCase(),
          provider: PaymentProvider.NETS_NETAXEPT,
          reference: reservation?.bookingDetail.reference,
        },
      })
    );

    const { data, errors } = result as GraphQLResult<VerifyPaymentMutation>;

    if (
      errors ||
      !data ||
      !data.verifyPayment ||
      isApiError(data.verifyPayment) ||
      isPaymentError(data.verifyPayment)
    ) {
      console.error('Verifying payment failed', errors || data);
      return Promise.reject(errors || data?.verifyPayment);
    } else {
      return data.verifyPayment;
    }
  } catch (error: any) {
    console.error('verifyPayment:', error);
    const { errorType, message } = error.errors[0];
    return Promise.reject({
      __typename: 'ApiError',
      errorCode: ErrorCode.SERVER_ERROR,
      errorMessage: `Verifying payment failed, error type: ${errorType}, message: ${message}`,
    } as ApiError);
  }
};
