import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import DayPicker, { DayPickerProps } from 'react-day-picker';
import { size, math } from 'polished';
import { useDialogState } from 'reakit/Dialog';

import { LanguageContext } from '../../../Language.context';

import { color, font, measurement } from '../../helpers/vars';
import { animation, responsive, style, typography } from '../../helpers/mixins';

import { H3 } from '../Text';
import InputDisclosure, { InputDisclosurePassedProps } from '../InputDisclosure';
import Modal from '../Modal';
import Button from '../Button';

export interface WrapperParentProps extends DayPickerProps {
  readonly title: string;
  readonly submit: string;
  readonly label?: string | string[];
}

export interface WrapperProps extends WrapperParentProps, InputDisclosurePassedProps {}

interface NavBarProps {
  readonly locale?: string;
  readonly month?: Date;
  readonly previousMonth?: Date;
  readonly nextMonth?: Date;
  readonly onPreviousClick?: () => void;
  readonly onNextClick?: () => void;
}

const Styled = styled(DayPicker)<{ ref?: any }>`
  position: relative;

  .DayPicker-wrapper {
    margin: -${measurement.inset.input.datePicker};
    padding: ${measurement.inset.input.datePicker};
    border-radius: ${measurement.size.radius.default};
    border: ${style.border.placeholder};
    transition: ${animation.transition('box-shadow', 'border', 'background')};

    &:focus,
    &:focus-within {
      border-color: ${color.border.default};
      ${style.focus.subtle}
    }
  }

  .DayPicker {
    &-Month {
      display: table;
      margin: 0;
      width: 100%;
      background: ${color.bg.default};
      border-radius: ${measurement.size.radius.default};
    }

    &-Weekdays {
      display: table-header-group;
    }

    &-WeekdaysRow {
      display: table-row;
    }

    &-Weekday {
      ${typography.small}
      display: table-cell;
      text-align: center;

      abbr {
        text-decoration: none;
      }
    }

    &-Body {
      display: table-row-group;
    }

    &-Week {
      display: table-row;
    }

    &-Day,
    &-WeekNumber {
      padding: 0.25rem;
      height: ${measurement.size.input.datePicker.day.s};
      display: table-cell;
      vertical-align: middle;
      position: relative;

      ${responsive.fontSize(css`
        height: ${measurement.size.input.datePicker.day.l};
      `)}
    }

    &-WeekNumber {
      ${typography.small}
      width: ${math(`${measurement.size.input.datePicker.day.s} / 2`)};

      ${responsive.fontSize(css`
        width: ${math(`${measurement.size.input.datePicker.day.l} / 2`)};
      `)}
    }

    &-Day {
      ${typography.lead}
      width: ${measurement.size.input.datePicker.day.s};
      text-align: center;
      font-weight: ${font.weight.strong.lead};
      color: ${color.link.default};
      cursor: pointer;
      transition: ${animation.transition('background', 'color', 'border-radius', 'box-shadow')};
      border-radius: ${measurement.size.radius.default};

      &--outside {
        color: ${color.text.copy};
        font-weight: ${font.weight.lead};
      }

      &:hover {
        background: ${color.bg.datepicker.hover};
      }

      ${responsive.fontSize(css`
        width: ${measurement.size.input.datePicker.day.l};
      `)}

      &--selected {
        background: ${color.bg.datepicker.selected};
        border-radius: 0;

        &:hover {
          background: ${color.bg.datepicker.selectedHover};
        }
      }

      &--from,
      &--to,
      &--selectedDay {
        color: ${color.text.invert};
        background: ${color.bg.datepicker.active};

        &:hover {
          background: ${color.bg.datepicker.activeHover};
        }
      }

      &--from {
        border-top-left-radius: ${measurement.size.radius.rounded};
        border-bottom-left-radius: ${measurement.size.radius.rounded};
      }

      &--to {
        border-top-right-radius: ${measurement.size.radius.rounded};
        border-bottom-right-radius: ${measurement.size.radius.rounded};
      }

      &--selectedDay {
        border-radius: ${measurement.size.radius.rounded};
      }

      &--today {
        &::before {
          ${size(measurement.size.input.datePicker.today)}
          content: '';
          position: absolute;
          background: ${color.bg.datepicker.active};
          bottom: ${measurement.size.input.datePicker.today};
          left: 50%;
          transform: translateX(-50%);
          border-radius: ${measurement.size.radius.circle};
        }

        &.DayPicker-Day--outside {
          &::before {
            background: ${color.icon.default};
          }
        }

        &.DayPicker-Day--from {
          &::before {
            background: ${color.bg.default};
          }
        }
      }

      &--disabled {
        ${typography.p}
        color: ${color.text.disabled};
        cursor: default;

        &:hover {
          background: none;
        }
      }

      &:focus {
        ${style.focus.default}
      }
    }
  }
`;

const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const NavBarTitle = styled(H3)`
  flex: 1;
`;

const NavBar: React.FunctionComponent<NavBarProps> = ({ locale, month, onPreviousClick, onNextClick }) => (
  <NavBarWrapper>
    <Button onClick={() => onPreviousClick && onPreviousClick()} icon="left" label="Previous month" />
    <NavBarTitle align="center">{month?.toLocaleString(locale, { month: 'long', year: 'numeric' })}</NavBarTitle>
    <Button onClick={() => onNextClick && onNextClick()} icon="right" label="Next month" />
  </NavBarWrapper>
);

const Wrapper: React.FunctionComponent<WrapperProps> = ({ value, label, title, submit, onClick, ...props }) => {
  const modalState = useDialogState({ animated: false });
  const focusRef = React.useRef<HTMLElement>();

  const { localeData } = useContext(LanguageContext);

  React.useEffect(() => {
    const focusElement = focusRef.current;

    if (focusElement && modalState.visible) {
      focusElement.focus();
    }
  }, [modalState.visible]);

  return (
    <>
      <InputDisclosure {...{ value, label, modalState, onClick }} />
      <Modal state={modalState} title={title} {...{ submit }}>
        {(modalState.animating || modalState.visible) && (
          <Styled
            showWeekNumbers
            fixedWeeks
            showOutsideDays
            firstDayOfWeek={1}
            ref={focusRef}
            {...localeData}
            {...props}
            navbarElement={<NavBar locale={localeData.locale} />}
            captionElement={<></>}
          />
        )}
      </Modal>
    </>
  );
};

export default Wrapper;
