import { differenceInYears, parse } from 'date-fns/esm';
import { ExtendedPassenger, ExtendedPassengerAndPet, isPetInput, PassengerType, PetType } from '../types';

export const getPassengersAndPetsForLeg = (passengersAndPets: ExtendedPassengerAndPet[], leg: number) => [
  ...passengersAndPets.filter((_) => _.legs?.includes(leg)),
];

export const passengersAsValues = {
  [PassengerType.ADULT]: 4,
  [PassengerType.JUNIOR]: 3,
  [PassengerType.CHILD]: 2,
  [PassengerType.INFANT]: 1,
  [PetType.PET]: 0,
};

export const sortAdultToInfant = (sortable: { type: PassengerType | PetType }[]) => {
  return [...sortable].sort((p1, p2) =>
    passengersAsValues[p1.type] > passengersAsValues[p2.type]
      ? -1
      : passengersAsValues[p1.type] < passengersAsValues[p2.type]
      ? 1
      : 0
  );
};

export const getAdults = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter(({ type }) => type === PassengerType.ADULT);

export const getJuniors = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter(({ type }) => type === PassengerType.JUNIOR);

export const getChildren = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter(({ type }) => type === PassengerType.CHILD);

export const getInfants = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter(({ type }) => type === PassengerType.INFANT);

export const getPets = (passengers: ExtendedPassengerAndPet[]) => passengers.filter(isPetInput);

export const hasPets = (passengers: ExtendedPassengerAndPet[]) => passengers.some(isPetInput);
export const hasChildOrJunior = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.some((p) => p.type === PassengerType.CHILD || p.type === PassengerType.INFANT);

export const arrangeByType = (passengers: number[], allPassengers: ExtendedPassengerAndPet[]) =>
  passengers.sort((a, b) => {
    const passengerA = allPassengers.find((p) => p.id === a);
    const passengerB = allPassengers.find((p) => p.id === b);
    if (!passengerA) return 1;
    if (!passengerB) return -1;
    return compareWith(passengerA.type) - compareWith(passengerB.type);
  });

const compareWith = (type: PassengerType | PetType) => {
  switch (type) {
    case PassengerType.ADULT:
      return 2;
    case PassengerType.JUNIOR:
      return 3;
    case PassengerType.CHILD:
      return 4;
    case PassengerType.INFANT:
      return 5;
    case PetType.PET:
      return 6;
    default:
      return Infinity;
  }
};

export const canBeAlone = ({ type }: { type: PassengerType | PetType }) => {
  switch (type) {
    case PassengerType.ADULT:
    case PassengerType.JUNIOR:
      return true;
    case PassengerType.CHILD:
    case PassengerType.INFANT:
    default:
      return false;
  }
};

/**
 * AGE VALIDATION
 */

export const validateAge = (type: PassengerType, firstDepartureDateTime: Date, age: string) => {
  const ageAsDate = parse(age, 'dd/MM/yyyy', new Date());
  switch (type) {
    case PassengerType.INFANT:
      return ageIsInfant(firstDepartureDateTime, ageAsDate);
    case PassengerType.CHILD:
      return ageIsChild(firstDepartureDateTime, ageAsDate);
    case PassengerType.JUNIOR:
      return ageIsJunior(firstDepartureDateTime, ageAsDate);
    case PassengerType.ADULT:
    default:
      return ageIsAdult(firstDepartureDateTime, ageAsDate);
  }
};

export const passengerAge = {
  [PassengerType.INFANT]: {
    min: null,
    max: 5,
  },
  [PassengerType.CHILD]: {
    min: 6,
    max: 12,
  },
  [PassengerType.JUNIOR]: {
    min: 13,
    max: 17,
  },
  [PassengerType.ADULT]: {
    min: 18,
    max: null,
  },
};

export const ageIsInfant = (atDate: Date, age: Date) => differenceInYears(atDate, age) <= passengerAge.INFANT.max;

export const ageIsChild = (atDate: Date, age: Date) =>
  differenceInYears(atDate, age) >= passengerAge.CHILD.min && differenceInYears(atDate, age) <= passengerAge.CHILD.max;

export const ageIsJunior = (atDate: Date, age: Date) =>
  differenceInYears(atDate, age) >= passengerAge.JUNIOR.min &&
  differenceInYears(atDate, age) <= passengerAge.JUNIOR.max;

export const ageIsAdult = (atDate: Date, age: Date) => differenceInYears(atDate, age) >= passengerAge.ADULT.min;

//Newsletter, privacy and terms properties should not be checked here
export const compareInfo = (p1: ExtendedPassenger, p2: ExtendedPassenger) => {
  return (
    p1.info?.birth === p2.info?.birth &&
    p1.info?.firstname === p2.info?.firstname &&
    p1.info?.lastname === p2.info?.lastname &&
    p1.info?.gender === p2.info?.gender &&
    p1.info?.nationality === p2.info?.nationality &&
    p1.info?.specialNeeds === p2.info?.specialNeeds &&
    p1.info?.starclub === p2.info?.starclub &&
    (p1.info?.reserverInfo && p2.info?.reserverInfo
      ? p1.info?.reserverInfo.address === p2.info?.reserverInfo.address &&
        p1.info?.reserverInfo.city === p2.info?.reserverInfo.city &&
        p1.info?.reserverInfo.country === p2.info?.reserverInfo.country &&
        p1.info?.reserverInfo.countryCallCode === p2.info?.reserverInfo.countryCallCode &&
        p1.info?.reserverInfo.email === p2.info?.reserverInfo.email &&
        p1.info?.reserverInfo.phone === p2.info?.reserverInfo.phone &&
        p1.info?.reserverInfo.postalCode === p2.info?.reserverInfo.postalCode
      : p1.info?.reserverInfo && !p2.info?.reserverInfo
      ? false
      : !p1.info?.reserverInfo && p2.info?.reserverInfo
      ? false
      : true)
  );
};
