export type GridKey = 'mobile' | 'default' | 'wide' | 'form' | 'text';
export type SubgridKey = 'dense' | 'default' | 'spacious';

interface GridValues {
  readonly count: number;
  readonly gap: number;
  readonly width: number;
}

interface GridUnits {
  readonly count: number;
  readonly gap: string;
  readonly width: string;
}

interface Values {
  readonly hairline: number;
  readonly tiny: number;

  readonly xxs: number;
  readonly xs: number;
  readonly s: number;
  readonly m: number;
  readonly l: number;
  readonly xl: number;

  readonly grid: {
    readonly [key in GridKey]: GridValues;
  };

  readonly subgrid: {
    readonly [key in SubgridKey]: GridValues;
  };
}

const isNumber = (v: string | number): boolean => typeof v === 'number';

const value: Values = {
  hairline: 0.0625,
  tiny: 0.125,

  xxs: 0.25,
  xs: 0.5,
  s: 0.75,
  m: 1,
  l: 1.5,
  xl: 2,

  grid: {
    mobile: {
      count: 1,
      gap: 0.5,
      width: 32,
    },
    default: {
      count: 3,
      gap: 2,
      width: 64,
    },
    wide: {
      count: 3,
      gap: 1,
      width: 77,
    },
    form: {
      count: 1,
      gap: 1,
      width: 40,
    },
    text: {
      count: 1,
      gap: 0,
      width: 40,
    },
  },

  subgrid: {
    dense: {
      count: 2,
      gap: 0.5,
      width: 0,
    },
    default: {
      count: 1,
      gap: 1,
      width: 0,
    },
    spacious: {
      count: 1,
      gap: 2,
      width: 0,
    },
  },
};

const addUnit = (vals: (number | string)[], unit: string) =>
  vals.map((v) => (isNumber(v) && v !== 0 ? `${v}${unit}` : v)).join(' ');

const rem = (...vals: (number | string)[]) => addUnit(vals, 'rem');
const em = (...vals: (number | string)[]) => addUnit(vals, 'em');

const remToGrid = (grids: { [key: string]: GridValues }) =>
  Object.keys(grids).reduce((obj, key) => {
    const { count, gap, width } = grids[key as GridKey];

    return {
      ...obj,
      [key]: {
        count,
        gap: rem(gap),
        width: rem(width),
      },
    };
  }, {});

export default {
  grid: remToGrid(value.grid) as { [key in GridKey]: GridUnits },
  subgrid: remToGrid(value.subgrid) as { [key in SubgridKey]: GridUnits },

  size: {
    border: {
      default: rem(value.hairline),
      strong: rem(value.tiny),
    },
    radius: {
      default: rem(value.xs),
      input: rem(value.xxs),
      rounded: '100vh',
      circle: '50%',

      modal: {
        s: rem(value.l, value.l, 0, 0),
        l: rem(value.xl),
      },
    },
    modal: {
      default: rem(64),
      input: rem(28),
    },
    input: {
      field: {
        s: '3.875rem',
        l: '4.25rem',
      },
      stepper: '2rem',
      select: '24rem',
      datePicker: {
        wrapper: '24rem',
        day: {
          s: '2rem',
          l: '3rem',
        },
        today: rem(value.s / 2),
      },
      checkbox: '2.25rem',
      radio: '2rem',
    },
    icon: {
      default: '1.5rem',
      small: '1rem',
      large: '2.5rem',
    },
    logo: '2.5rem',
    tabbar: {
      s: '3.25rem',
      l: '4rem',
    },
    footerbar: {
      s: '3.75rem',
      l: '2.5rem',
      xl: '4.25rem',
    },
    departure: {
      height: {
        s: '6rem',
        l: '7rem',
      },
      width: {
        s: '3rem',
        l: '4rem',
      },
    },
    scrollbar: rem(value.xs),
    map: {
      container: '18rem',
      actual: {
        h: '54em',
        w: '108rem',
      },
    },
  },

  space: {
    header: {
      s: rem(2.5),
      l: rem(4),
    },
    footer: {
      s: rem(1.5),
      l: rem(2),
    },
    text: {
      heading: rem(value.m),
      copy: em(value.m),
    },
    subgrid: em(value.s),
    input: {
      stepper: {
        control: rem(value.s),
        label: rem(value.m),
        field: rem(0, -2.5),
        group: rem(value.xl),
      },
      segmented: {
        label: rem(0, 0, value.s),
        indicator: {
          default: rem(-value.tiny),
          focus: rem(-value.xxs),
        },
      },
      select: {
        label: rem(value.s, 0),
        indicator: rem(value.tiny),
      },
      datePicker: {
        caption: rem(0, 1.5 + (value.xs - value.hairline) * 2, value.m),
      },
      checkbox: {
        wrapper: rem(value.xxs),
        input: rem(0, value.m, 0, 0),
      },
      icon: rem(value.m),
    },
    icon: {
      inline: rem(-value.s, 0, -value.s),
      button: rem(value.xs),
      alert: rem(value.s + value.hairline * 2, value.xs),
    },
    button: {
      target: rem(-value.xs),
      finish: {
        xs: rem(value.xs, -value.xl, -value.hairline, 'auto'),
        s: rem(-value.xs, -value.xl, -value.hairline, 'auto'),
        xl: rem(value.s, value.s, value.s, 'auto'),
      },
      back: {
        xs: rem(value.xs, 0, -value.hairline, -value.xl),
        s: rem(-value.xs, 0, -value.hairline, -value.xl),
        xl: rem(value.s, value.s, value.s),
      },
      alert: rem(-value.s, -value.xxs, value.s, 'auto'),
    },
    modal: {
      s: rem(value.m, 0, 0),
      l: rem(value.xl),
      button: {
        s: rem(value.xxs),
        l: rem(value.m),
      },
    },
    departure: {
      gap: rem(value.xs),
      container: {
        s: rem(value.m, 0),
        l: rem(value.xl, 0),
      },
      area: {
        s: rem(value.m),
        l: rem(value.xl),
      },
    },
    flexible: {
      s: rem(value.xs),
      l: rem(value.m),
    },
    menu: {
      item: rem(value.xs),
    },
  },

  inset: {
    button: {
      regular: rem(value.s, value.l),
      icon: rem(value.xs - value.hairline),
      small: rem(value.xs, value.m),
      finish: {
        s: rem(value.xs, value.xl * 1.5, value.xs, value.xl),
        l: rem(value.xs, value.xl * 3, value.xs, value.xl * 2),
        xl: rem(value.s, value.xl * 2),
      },
    },
    input: {
      label: {
        default: rem(value.l, value.m, 0),
        active: rem(value.s, value.m, 0),
      },
      field: rem(value.xl, value.m, value.xxs),
      innerSelect: rem(value.xl, value.m, value.xxs, value.xl * 2),
      noLabel: rem(value.xxs, value.m),
      stepper: {
        control: rem(value.s),
        field: rem(value.xxs, 2.5),
      },
      segmented: rem(value.xs, value.m),
      datePicker: rem(value.s),
    },
    container: {
      s: rem(value.s, value.m),
      l: rem(value.m, value.xl),
    },
    modal: {
      s: rem(value.l),
      l: rem(value.xl),
      title: {
        s: rem(value.m, value.xl, value.m - value.tiny),
        l: rem(value.l, value.xl, value.l - value.tiny),
      },
      footer: {
        bottom: rem(value.m),
        s: rem(0, value.m, value.m),
        l: rem(0, value.l, value.l),
      },
      fade: rem(value.xl),
    },
    menu: {
      container: rem(value.xs),
    },
    group: {
      s: rem(value.xs),
      l: rem(value.s),
    },
    alert: {
      s: rem(0, value.s, 0, value.xs * 2 + 1.5 + value.s),
      l: rem(0, value.m, 0, value.xs * 2 + 1.5 + value.m),
    },
  },
};
