import * as API from '../../types';

export const createPassengerInputs = (passengers: API.ExtendedPassenger[]): API.PassengerInput[] => {
  return passengers.map((o) => createPassengerInput(o));
};
const createPassengerInput = ({ info, price, reserver, ...rest }: API.ExtendedPassenger): API.PassengerInput => {
  return { ...rest };
};

export const createPetInputs = (pets: API.ExtendedPet[]): API.PetInput[] => {
  return pets.map((o) => createPetInput(o));
};
const createPetInput = ({ price, ...rest }: API.ExtendedPet): API.PetInput => {
  return { ...rest };
};

export const createOnboardInputs = (onboards: API.ExtendedOnboard[]) => {
  return onboards.map((o) => createOnboardInput(o));
};
const createOnboardInput = ({ price, ...rest }: API.ExtendedOnboard): API.OnboardInput => {
  return { ...rest };
};

export const createVehicleInputs = (vehicles: API.ExtendedVehicle[]): API.VehicleInput[] => {
  return vehicles.map((v) => createVehicleInput(v));
};
const createVehicleInput = ({ price, assignedDriver, ...rest }: API.ExtendedVehicle): API.VehicleInput => {
  return { ...rest };
};

export const createAccommodationInputs = (
  accommodations: API.ExtendedAccommodation[],
  passengers: API.ExtendedPassengerAndPet[]
): API.AccommodationInput[] => {
  return accommodations.map((o) => createAccommodationInput(o, passengers));
};

const createAccommodationInput = (
  { id, passengers, price, ...rest }: API.ExtendedAccommodation,
  passengersAndPets: API.ExtendedPassengerAndPet[]
): API.AccommodationInput => ({
  ...rest,
  passengers: passengers?.filter((id) => {
    const p = passengersAndPets.find((p) => p.id === id);
    return p ? API.isPassengerInput(p) : false;
  }),
  pets: passengers?.filter((id) => {
    const p = passengersAndPets.find((p) => p.id === id);
    return p ? API.isPetInput(p) : false;
  }),
  accommodationId: id,
});
