import React, { FunctionComponent } from 'react';

import { StyledInput } from '../../helpers/components';

import { InputDobStateReturn } from './useInputGroupState';
import Wrapper, { validation } from './Wrapper';

const InputDob: FunctionComponent<InputDobStateReturn> = ({
  htmlFor,
  label,
  error,
  hint,
  gridSpan,
  day,
  month,
  year,
}) => {
  const { className, ...validationProps } = validation(error, htmlFor) || {};

  return (
    <Wrapper {...{ htmlFor, label, error, hint, gridSpan }}>
      <StyledInput.Input as="div" id={htmlFor} wrapper {...{ className }}>
        <StyledInput.InnerSelect as="div" className="days">
          <select aria-label={day.label} {...{ onChange: day.onChange, value: day.value }} {...validationProps}>
            <option value="" disabled>
              {day.label}
            </option>
            {day.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </StyledInput.InnerSelect>

        <StyledInput.InnerSelect as="div" className="months">
          <select aria-label={month.label} {...{ onChange: month.onChange, value: month.value }} {...validationProps}>
            <option value="" disabled>
              {month.label}
            </option>
            {month.options.map(({ monthNumber, monthName }) => (
              <option key={monthNumber} value={monthNumber}>
                {monthName}
              </option>
            ))}
          </select>
        </StyledInput.InnerSelect>

        <StyledInput.InnerSelect as="div" className="years">
          <select aria-label={year.label} {...{ onChange: year.onChange, value: year.value }} {...validationProps}>
            <option value="" disabled>
              {year.label}
            </option>
            {year.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </StyledInput.InnerSelect>
      </StyledInput.Input>
    </Wrapper>
  );
};

export default InputDob;
