import { Port, Language } from '../types';

export const defaultPortsByLang = {
  [Language.EN]: { from: Port.DETRV, to: Port.FIHEL },
  [Language.FI]: { from: Port.FIHEL, to: Port.DETRV },
  [Language.DE]: { from: Port.DETRV, to: Port.FIHEL },
  [Language.SV]: { from: Port.SEMMA, to: Port.DETRV },
  [Language.PL]: { from: Port.PLSWI, to: Port.SEMMA },
};

export const defaultDaycruisePortsByLang = {
  [Language.EN]: { from: Port.FINLI, to: Port.FILAN },
  [Language.FI]: { from: Port.FINLI, to: Port.FILAN },
  [Language.DE]: { from: Port.FINLI, to: Port.FILAN },
  [Language.SV]: { from: Port.SEKPS, to: Port.FILAN },
  [Language.PL]: { from: Port.FINLI, to: Port.FILAN },
};

export const defaultPortsByPort = [
  { from: Port.SEKPS, to: Port.FINLI },
  { from: Port.FILAN, to: Port.FINLI },
];
