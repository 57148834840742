import React from 'react';
import styled, { css } from 'styled-components';

import { color, measurement, zindex } from '../../helpers/vars';

import Icon from '../Icon';
import { responsive, style } from '../../helpers/mixins';

import { TabWrapper } from './Tab';

interface CommonProps {
  readonly fixed?: boolean;
}

interface TabBarProps extends CommonProps {
  readonly children: React.ReactNode | React.ReactNode[];
  readonly innerRef?: React.Ref<HTMLDivElement>;
}

interface StyledBarProps extends CommonProps {
  readonly single?: boolean;
}

const size = css`
  height: ${measurement.size.tabbar.s};

  ${responsive.fontSize(css`
    height: ${measurement.size.tabbar.l};
  `)}
`;

const BarPadding = styled.div`
  ${size}
`;

const StyledBar = styled.nav<StyledBarProps>`
  ${size}
  background: ${color.bg.default};
  list-style: none;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: ${style.border.default};
  padding: 0 0.5rem;

  ${({ single }) =>
    single
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: space-between;

          ${TabWrapper} {
            flex: 1;
          }
        `}

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: ${zindex.nav};
    `}
`;

const PaddedIcon = styled(Icon)`
  padding: 0 0.5rem;
  flex: 0 0 auto;
`;

const TabBar: React.FunctionComponent<TabBarProps> = ({ fixed, children, innerRef }) => {
  const Spacer = <PaddedIcon name="right" small />;
  const Back = <PaddedIcon name="left" small />;

  return (
    <>
      {fixed && <BarPadding />}
      <StyledBar {...{ fixed }} single={!Array.isArray(children)} ref={innerRef}>
        {Array.isArray(children) ? (
          children.map((c, i) => (
            <React.Fragment key={i}>
              {i !== 0 && Spacer}
              {c}
            </React.Fragment>
          ))
        ) : (
          <>
            {Back}
            {children}
          </>
        )}
      </StyledBar>
    </>
  );
};

export default TabBar;
