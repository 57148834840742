import React from 'react';

import Selection from './Selection';
import { SuboptionType } from './Item';

export interface SelectedType {
  readonly id: number;
  readonly productcode: string;
  readonly uuid?: string;
}

export type SelectedListType = SelectedType[];

interface SelectedListProps {
  readonly selected: SelectedListType;
  readonly removeSelected: (id: number) => void;
  readonly optionInfo: SuboptionType[];
  readonly focusRef?: any;
}

const SelectedList: React.FunctionComponent<SelectedListProps> = ({
  selected,
  removeSelected,
  optionInfo,
  focusRef,
}) => {
  const handleRemoval = (id: number) => removeSelected(id);

  return (
    <>
      {selected.map(({ uuid, id }, i) => {
        const info = optionInfo.find((o) => o.uuid === uuid);
        return info ? (
          <Selection key={i} {...{ info, id, handleRemoval }} {...(focusRef && i === 0 && { focusRef })} />
        ) : null;
      })}
    </>
  );
};

export default SelectedList;
