import styled, { css } from 'styled-components';
import { cover } from 'polished';

import { color, font, measurement } from '../vars';
import { animation, responsive, style, typography } from '../mixins';

export const WrapperButton = styled.button<{ as?: any }>`
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: ${measurement.size.radius.input};
  background: none;
  text-align: left;

  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div<{ gridSpan?: number }>`
  position: relative;
  cursor: text;
  border-radius: ${measurement.size.radius.input};

  ${({ gridSpan }) =>
    gridSpan &&
    css`
      grid-column: span ${gridSpan};
    `}
`;

const CommonInnerSelect = styled.label`
  z-index: 3;
  display: flex;
  align-items: center;

  select {
    ${typography.lead}
    background: none;
    appearance: none;
    border: none;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer;
    color: inherit;
    z-index: 1;

    &:focus {
      outline: none;
    }

    /* autofill background would otherwise block flags */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
      border: 0;
      -webkit-text-fill-color: transparent;
      box-shadow: 0;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const InnerSelect = styled(CommonInnerSelect)`
  position: relative;
  border-radius: ${measurement.size.radius.input};
  flex: 1;
  margin: 0 -0.5rem;
  padding: 0 0.5rem;

  &:hover {
    background-color: ${color.bg.alt};
  }

  &:focus-within {
    ${style.focus.default}
  }
`;

export const MaskSelect = styled(CommonInnerSelect)`
  margin: ${measurement.inset.input.field};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2rem;

  img,
  span {
    position: absolute;
    height: auto !important;
    z-index: 1;
    border-radius: ${measurement.size.radius.input};
    display: block;
    width: 100%;
  }

  span {
    ${typography.small}
    text-align: center;
  }

  &:focus-within {
    img,
    span {
      ${style.focus.default}
    }
  }

  select {
    opacity: 0;
    z-index: 2;
  }
`;

export const Input = styled.input<{ noLabel?: boolean; wrapper?: boolean; as?: string }>`
  ${typography.lead}
  height: ${measurement.size.input.field.s};
  border-radius: inherit;
  padding: ${measurement.inset.input.field};
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  transition: ${animation.transition('color', 'border', 'box-shadow')};
  position: relative;
  z-index: 2;
  background: none;
  appearance: none;

  ${MaskSelect} ~ & {
    padding: ${measurement.inset.input.innerSelect};
  }

  ${responsive.fontSize(css`
    height: ${measurement.size.input.field.l};
  `)}

  ${({ wrapper }) =>
    wrapper &&
    css`
      display: flex;
    `}

  ${({ theme }) => css`
    border: ${style.borderStr(theme.border)};
    color: ${theme.text.input};

    &:focus,
    ${WrapperButton}:focus & {
      ${theme.focus}
    }

    &:focus,
    ${WrapperButton}:focus &,
    ${Wrapper}:hover & {
      ${theme.input.hover}
    }
  `}

  ${({ noLabel }) =>
    noLabel
      ? css`
          padding: ${measurement.inset.input.noLabel};
          display: flex;
          align-items: center;
        `
      : css`
          white-space: nowrap;
        `}

  &::placeholder {
    opacity: 0;
    color: transparent;
  }

  .invalid &,
  .invalid:focus &,
  .invalid:hover & {
    border: ${style.border.error};
  }

  ${WrapperButton}:focus .invalid & {
    ${({ theme }) => theme.error.focus}
  }

  &:invalid,
  &.invalid {
    &,
    &:hover,
    &:focus {
      border: ${style.border.error};
    }

    ${({ theme }) => css`
      &:focus {
        ${theme.error.focus}
      }
    `}
  }

  ${WrapperButton} + ${WrapperButton} & {
    justify-content: flex-end;
  }

  /* autofill background would otherwise block labels */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: ${({ theme }) => theme.text.input};
    box-shadow: 0;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const labelNoValue = css`
  padding: ${measurement.inset.input.label.default};
  font-size: ${font.size[1].mobile};
  font-weight: ${font.weight.default};

  ${responsive.fontSize(css`
    font-size: ${font.size[1].desktop};
  `)}
`;

export const Label = styled.label<{ noValue?: boolean }>`
  ${cover(measurement.size.border.default)}
  ${typography.small}
  padding: ${measurement.inset.input.label.active};
  margin: 0;
  transition: ${animation.transition('color', 'padding', 'font-size', 'background')};
  cursor: text;
  user-select: none;
  border-radius: inherit;

  ${(props) => css`
    color: ${props.theme.text.input};
    background: ${props.theme.input.background};
  `}

  ${WrapperButton} & {
    cursor: pointer;
  }

  ${Input}:required ~ &::after {
    content: ' *';
  }

  ${(props) => props.noValue && labelNoValue}

  ${Input}:placeholder-shown ~ & {
    ${labelNoValue}
  }

  ${Input}:placeholder-shown:focus ~ & {
    padding: ${measurement.inset.input.label.active};
    font-size: ${font.size[0].mobile};
    font-weight: ${font.weight.small};

    ${responsive.fontSize(css`
      font-size: ${font.size[0].desktop};
    `)}
  }

  ${WrapperButton}:focus &,
  ${Wrapper}:hover &,
  ${Input}:focus + & {
    color: ${color.text.copy};
    background: ${color.bg.input.default};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${color.bg.input.invert.inactive};
    border-radius: ${measurement.size.radius.input};
    mix-blend-mode: soft-light;
    transition: ${animation.transition('background')};
  }

  ${Input}:invalid ~ &&, ${Input}.invalid ~ &&, .invalid && {
    color: ${color.text.error};
    background: ${color.bg.error.default};
  }

  ${({ theme }) => css`
    .invalid:focus &,
    .invalid:hover &,
    ${Input}:invalid:hover ~ &,
    ${Input}:invalid:focus ~ &,
    ${Input}.invalid:hover ~ &,
    ${Input}.invalid:focus ~ & {
      background: ${theme.input.background};
    }
  `}
`;

const Alert = styled.div`
  ${typography.small};
  color: ${color.text.invert};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -${measurement.size.radius.input};
  z-index: 3;
  border-radius: 0 0 ${measurement.size.radius.input} ${measurement.size.radius.input};
  padding: 0 0.5rem;
  opacity: 0;
  transition: ${animation.transition('opacity')};
`;

export const Error = styled(Alert)`
  background: ${color.bg.error.invert};

  ${Input}:hover ~ &, ${Input}:focus ~ &, ${WrapperButton}:hover &, ${WrapperButton}:focus & {
    opacity: 1;
  }
`;

export const Hint = styled(Alert)`
  background: ${color.brand};

  ${Input}:focus ~ &, ${WrapperButton}:focus & {
    opacity: 1;
  }
`;
