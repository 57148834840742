import { parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker, { RangeDates } from '../../../design-system/components/DatePicker';
import { hooks } from '../../../design-system/helpers/mixins';
import { Form, Port, PortMap, TripType } from '../../../fsm/types';
import { createForm } from '../common';
import TripPicker from '../TripPicker';

interface RangeFormProps {
  form: Form;
  returnForm: Form;
  portMap: PortMap[];
  range: number;
  type: TripType;
  update: (form: Form) => void;
}

const RangeForm: FunctionComponent<RangeFormProps> = ({ form, returnForm, range, portMap, type, update }) => {
  const [dep, setDep] = useState<Port>(form.params.departurePort || Port.FIHEL);

  const [dest, setDest] = useState<Port>(form.params.arrivalPort);

  const [rangeDates, setRangeDates] = useState<RangeDates>({
    from: parseISO(form.selectedDate),
    to: parseISO(returnForm.selectedDate),
  });

  const [disabledDays] = useState<{ before: Date }>({
    before: parseISO(form.searchedDate),
  });

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  useEffect(() => {
    update(createForm(form, dep, dest, rangeDates.from, range));
    update(createForm(returnForm, dest, dep, rangeDates.to, range));
  }, [dep, dest, rangeDates.from, rangeDates.to, type]);

  return (
    <>
      <TripPicker portMap={portMap} setDep={setDep} setDest={setDest} dep={dep} dest={dest} />

      <DatePicker.Range
        {...{ dates: rangeDates, setDates: setRangeDates, disabledDays }}
        submit={sbSearchForm?.content['ready_button']}
        label={[sbSearchForm?.content['departure_date'], sbSearchForm?.content['return_date']]}
        title={sbSearchForm?.content['select_travel_dates']}
      />
    </>
  );
};

export default RangeForm;
