import styled from 'styled-components';

const ChangeTripButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  Button {
    min-width: 12.5rem;
  }
`;

export default ChangeTripButtonWrapper;
