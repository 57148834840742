import { css } from 'styled-components';
import { cover, transparentize } from 'polished';

import { color, font, measurement } from '../vars';
import animation from './animation';
import responsive from './responsive';
import typography from './typography';

const borderStr = (
  currentColor = color.border.default,
  currentWidth = measurement.size.border.default,
  currentStyle = 'solid'
) => [currentWidth, currentStyle, currentColor].join(' ');

const colorAsImage = (color: string) => `linear-gradient(${color}, ${color})`;

const focusShadow = (bgColor: string, color: string, isInset?: boolean) => {
  const border = measurement.size.border.default;
  const strong = measurement.size.border.strong;
  const inset = isInset ? 'inset ' : '';

  return `${inset}0 0 0 ${border} ${bgColor}, ${inset}0 0 ${border} ${strong} ${color}}`;
};

const style = {
  focus: {
    default: css`
      outline: none;
      box-shadow: ${focusShadow(color.bg.default, color.brand)};
    `,
    invert: css`
      outline: none;
      box-shadow: ${focusShadow(color.bg.invert.default, color.text.invert)};
    `,
    subtle: css`
      outline: none;
      box-shadow: ${focusShadow(color.bg.default, color.border.default)};
    `,
    error: {
      default: css`
        box-shadow: ${focusShadow(color.bg.default, color.alert.default.error)};
      `,
      invert: css`
        box-shadow: ${focusShadow(color.bg.invert.default, color.alert.default.error)};
      `,
    },
    inset: {
      default: css`
        outline: none;
        box-shadow: ${focusShadow(color.bg.default, color.brand, true)};
      `,
    },
  },

  border: {
    default: borderStr(),
    error: borderStr(color.alert.default.error),
    input: borderStr(color.border.transparent),
    button: borderStr(color.border.transparent),
    action: borderStr(color.border.action),
    active: borderStr(color.border.active),
    placeholder: borderStr(color.border.transparent),
    overlay: borderStr(color.border.overlay),
    focus: borderStr(color.border.focus, measurement.size.border.default, 'dotted'),
  },

  link: {
    common: css`
      background-position: center bottom;
      background-size: 100% ${measurement.size.border.default}, 100% 0;
      background-repeat: no-repeat;
      transition: ${animation.transition('background-size', 'color')};
    `,
    default: css`
      background-image: ${colorAsImage(color.bg.link.default)}, ${colorAsImage(color.link.default)};
      color: ${color.link.default};
    `,
    subtle: css`
      background-size: 100% 0, 100% 0;
    `,
    hover: css`
      background-size: 100% ${measurement.size.border.strong}, 100% 0;
    `,
    focus: css`
      background-size: 100% 0, 100% 100%;
      color: ${color.text.invert};
      outline: none;
    `,
    invert: css`
      color: ${color.link.invert};
      background-image: ${colorAsImage(color.bg.link.invert)}, ${colorAsImage(color.link.default)};
    `,
  },

  shadow: {
    modal: `0 0 2rem 0 ${color.shadow.modal}`,
    menu: `inset 0 -${measurement.size.border.default} 0 ${color.shadow.menu}`,
    drag: `0 0.25rem 1rem 0 ${color.shadow.drag}`,
  },

  background: {
    departure: {
      wrapper: `linear-gradient(${color.bg.departure.join()})`,
      area: `linear-gradient(${transparentize(1, color.bg.alt)}, ${color.bg.alt} 12rem)`,
    },
  },

  colorAsImage,
};

export default {
  ...style,

  borderStr,

  button: {
    common: css`
      cursor: pointer;
      transition: ${animation.transition('background', 'box-shadow', 'border', 'color')};
      vertical-align: middle;
      position: relative;
      border-radius: ${measurement.size.radius.default};
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &::before {
        border-radius: inherit;
      }
    `,
    size: {
      default: css`
        ${typography.h3}
        padding: ${measurement.inset.button.regular};
      `,
      small: css`
        ${typography.p}
        font-weight: ${font.weight.strong.default};
        padding: ${measurement.inset.button.small};

        &::before {
          ${cover(measurement.space.button.target)}
          content: '';
        }
      `,
    },
    variant: {
      default: css`
        background: ${color.bg.button.default};
        border: ${style.border.button};
        color: ${color.text.invert};

        &:hover,
        &:focus {
          background-image: ${colorAsImage(color.bg.button.hover)};
          background-blend-mode: overlay;
        }
      `,
      secondary: css`
        color: ${color.link.default};
        border: ${style.border.default};
        background: ${color.bg.button.secondary.default};

        &:hover,
        &:focus {
          background: ${color.bg.button.secondary.hover};
        }
      `,
      icon: css`
        background: ${color.bg.button.secondary.icon};

        &:hover,
        &:focus {
          background: ${color.bg.button.secondary.hover};
        }
      `,
    },
    disabled: {
      common: css`
        cursor: not-allowed;
        color: ${color.text.disabled};
      `,
      default: css`
        border-color: ${color.border.disabled};
        background: ${color.bg.button.disabled};
      `,
      secondary: css`
        background: none;
        border-color: ${color.border.transparent};
      `,
    },
  },

  card: {
    wrapper: css`
      position: relative;
      border-radius: ${measurement.size.radius.default};
      display: grid;
      background: ${color.bg.default};
      transition: ${animation.transition('box-shadow', 'border')};

      &:focus-within {
        ${style.focus.default}
      }
    `,
    content: css`
      border: ${style.border.default};
      border-radius: ${measurement.size.radius.default};
      padding: ${measurement.inset.container.s};

      ${responsive.fontSize(css`
        padding: ${measurement.inset.container.l};
      `)}
    `,
  },

  radio: {
    input: css`
      opacity: 0;
      position: absolute;
      z-index: 0;
    `,
    label: {
      common: css`
        position: relative;
        z-index: 1;
        cursor: pointer;
      `,
      checked: css`
        cursor: default;
      `,
      disabled: css`
        cursor: default;
      `,
    },
  },

  input: {
    hover: {
      default: css``,
      alternative: css`
        border-color: ${color.border.default};
      `,
      inverted: css`
        color: ${color.text.input};
      `,
    },
  },

  label: {
    shape: (bg: string, skewLeft?: boolean) => css`
      position: relative;
      z-index: 1;

      &::before {
        ${cover()}
        content: '';
        background: ${bg};
        transform: skew(${skewLeft ? '20deg' : '-20deg'});
        border-radius: ${skewLeft ? '0 0.3rem' : '0.3rem 0'};
        z-index: -1;
      }
    `,
    bg: (bg: string) => css`
      &::before {
        background: ${bg};
      }
    `,
    l: css`
      &::before {
        transform: none;
        border-radius: ${measurement.size.radius.default};
      }
    `,
  },
};
