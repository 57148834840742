import { GraphQLResult } from '@aws-amplify/api-graphql';
import { GraphQLError } from 'graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { List } from 'purify-ts/List';
import { quoteAccommodations } from '../../graphql/queries';
import { CatalogContext } from '../catalog/catalogMachine';
import { LoaderAction, LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import {
  AccommodationCodeInput,
  AccommodationError,
  AccommodationQuote,
  ApiError,
  ChargeInfo,
  isApiError,
  QuoteAccommodationsQuery,
  TripType,
} from '../types';
import { createSelectedPriceObject } from './api';
import { sum } from '../../utils/priceCalculation';
import { getCruisePairLeg } from './cruise';

export const fetchAccommodationPrices = async ({
  type,
  data,
}: LoaderAction<
  CatalogContext,
  LOADER_ACTION_EVENTS,
  {
    accommodations: { code: string; type: string }[];
    leg: number;
    sailingCode: string;
    accommodationId: number;
  }
>) => {
  const { accommodations, context, leg, sailingCode, accommodationId } = data;
  const { searchParams } = context;

  try {
    const base = createSelectedPriceObject(context);

    const queries = [
      {
        query: {
          base,
          alts: accommodations as AccommodationCodeInput[],
          leg,
          accommodationId,
        },
      },
    ];

    if (!base.sailings.some((s) => s.leg === leg)) {
      return AccommodationError.NOT_AVAIL_ACCOMMODATIONS;
    }

    if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
      // For cruise the accommodation prices are shown as total for both outbound and
      // inbound legs so here we need to fetch the accommodation price for the other leg
      // also
      queries.push({
        query: {
          base,
          alts: accommodations as AccommodationCodeInput[],
          leg: getCruisePairLeg(leg),
          accommodationId: accommodationId,
        },
      });
    }

    const results = await Promise.all(
      queries.map(async (query) => {
        return await API.graphql(graphqlOperation(quoteAccommodations, query));
      })
    );

    const { errors, data } = results.reduce(
      (quotesAndErrors: { errors: GraphQLError[]; data: (AccommodationQuote | ApiError)[] }, quoteResults) => {
        const { errors, data } = quoteResults as GraphQLResult<QuoteAccommodationsQuery>;
        if (errors !== undefined) {
          quotesAndErrors.errors.push(...errors);
        }
        if (data !== undefined) {
          quotesAndErrors.data.push(...(data.quoteAccommodations as (AccommodationQuote | ApiError)[]));
        }
        return quotesAndErrors;
      },
      {
        errors: [] as GraphQLError[],
        data: [] as (AccommodationQuote | ApiError)[],
      }
    );

    if (errors.length > 0) {
      return Promise.reject(List.head(errors).extract());
    } else if (data.length === 0 || data.some(isApiError)) {
      return Promise.reject(List.head(data?.filter(isApiError) || []).extract());
    } else {
      const finalQuotes = data.reduce((quotes, quote: AccommodationQuote | ApiError) => {
        if (!isApiError(quote)) {
          const existingQuoteIndex = quotes.findIndex((q) => q.code === quote.code);
          if (existingQuoteIndex !== -1) {
            const ci = quote.chargeInfo as ChargeInfo;
            const charge = sum([quote.chargeInfo as ChargeInfo, quotes[existingQuoteIndex].chargeInfo as ChargeInfo]);

            const newQuote: AccommodationQuote = {
              ...quote,
              chargeInfo: {
                ...ci,
                charge,
              },
            };
            quotes[existingQuoteIndex] = newQuote;
          } else {
            quotes.push(quote);
          }
        }
        return quotes;
      }, [] as AccommodationQuote[]);

      return {
        type,
        result: {
          sailingCode,
          leg,
          quotes: finalQuotes,
        },
      };
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
