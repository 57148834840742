import React, { useContext } from 'react';
import { Radio, RadioStateReturn } from 'reakit/Radio';
import styled, { css } from 'styled-components';
import { Errors, TripType, Tariff, Catalog, ExtendedSailing, TariffPrice } from '../../../fsm/types';
import { LanguageContext } from '../../../Language.context';
import { Transition } from '../../helpers/components';
import { animation, responsive, style, typography } from '../../helpers/mixins';
import { color, measurement } from '../../helpers/vars';
import { isCruiseType } from '../../../fsm/api/cruise';
import { getCheapestCruisePrice } from '../../../utils/priceCalculation';
import * as API from '../../../fsm/types';

interface DepartureProps {
  readonly date: Date;
  readonly error?: Errors | string;
  readonly fromLabel: string;
  readonly price?: string | null;
  readonly disabled?: boolean;
  readonly state: RadioStateReturn;
  readonly value: any;
  readonly noDepartures: string;
  readonly catalogs: Catalog[];
  readonly tripType: TripType;
  readonly tariff: API.Tariff;
  readonly sailingsOnDate: ExtendedSailing[];
}

const DepartureWrapper = styled.div`
  position: relative;
  padding: ${measurement.space.departure.gap};
  scroll-snap-align: center;
`;

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DepartureRadio = styled(Radio)`
  opacity: 0;
  position: absolute;
`;

const ContentText = styled.span`
  transition: ${animation.transition('color')};
`;

const Label = styled(ContentText)`
  ${typography.small};
  line-height: 1.25;
`;

const Day = styled(ContentText)`
  ${typography.h2};
  line-height: 0.875;
`;

const Price = styled(ContentText)`
  ${typography.h3};
  color: ${color.brand};
  line-height: 1;
`;

const DepartureCard = styled(ContentGroup)`
  background: ${color.bg.default};
  border: ${style.border.placeholder};
  border-radius: ${measurement.size.radius.default};
  text-align: center;
  justify-content: space-between;
  height: ${measurement.size.departure.height.s};
  width: ${measurement.size.departure.width.s};
  padding: 1rem;
  transition: ${animation.transition('border', 'background', 'box-shadow', 'transform')};
  cursor: pointer;
  position: relative;
  z-index: 1;

  ${responsive.fontSize(css`
    height: ${measurement.size.departure.height.l};
    width: ${measurement.size.departure.width.l};
  `)}

  &:hover {
    transform: scale(1.025);
    border-color: ${color.border.active};
  }

  ${DepartureRadio}:focus + & {
    transform: scale(1.05);
    ${style.focus.default}
  }

  ${DepartureRadio}:checked + & {
    border-color: ${color.border.active};
    background: ${color.bg.invert.default};

    ${ContentText} {
      color: ${color.text.invert};
    }
  }

  ${DepartureRadio}[aria-disabled] + & {
    background: ${color.bg.alt};
    cursor: not-allowed;
    transform: scale(1);
    border-color: ${color.border.transparent};

    ${ContentText} {
      color: ${color.text.disabled};
    }
  }

  ${DepartureRadio}[aria-disabled]:focus + & {
    ${style.focus.subtle}
  }
`;

const Skeleton = css`
  @keyframes loading-shine {
    0% {
      background-position: -150%;
    }
    100% {
      background-position: 0;
    }
  }

  @keyframes loading-pulse {
    0% {
      opacity: 0.25;
    }

    100% {
      opacity: 0.1;
    }
  }

  color: transparent;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 1px;
    content: '';
    border-radius: 0.25rem;
    opacity: 0.25;
    animation: loading-shine 2s linear infinite, loading-pulse 1s ease-in-out infinite alternate;
    background: linear-gradient(120deg, ${color.text.copy} 30%, currentColor 50%, ${color.text.copy} 70%);
    background-size: 300%;
    background-position: -150%;
  }

  ${DepartureRadio}:checked + ${DepartureCard} & {
    color: transparent;
    &::before {
      background: linear-gradient(120deg, ${color.text.invert} 30%, currentColor 50%, ${color.text.invert} 70%);
      background-size: 300%;
      background-position: -150%;
    }
  }
`;

const SkeletonPrice = styled(Price)`
  ${Skeleton}
`;

const SkeletonLabel = styled(Label)`
  ${Skeleton}
`;

interface Content {
  readonly items: React.ReactNode[];
  readonly keys: any[];
}

const DepartureDay: React.FunctionComponent<DepartureProps> = ({
  date,
  error,
  noDepartures,
  fromLabel,
  price,
  disabled,
  state,
  value,
  catalogs,
  tripType,
  tariff,
  sailingsOnDate,
}) => {
  const getContent: () => Content = () => {
    if (price === undefined) {
      return {
        items: [<SkeletonLabel>Loading</SkeletonLabel>, <SkeletonPrice>Loading</SkeletonPrice>],
        keys: ['skeletonLabel', 'skeletonPrice'],
      };
    } else if (price === null) {
      return { items: [<Label>{error || noDepartures}</Label>], keys: ['disabled'] };
    }

    let cost: string | null | undefined = price;
    if (isCruiseType(tripType)) {
      const cruisePriceOrError = getCheapestCruisePrice(tripType, sailingsOnDate, catalogs[1].sailings, tariff);
      if (API.isError(cruisePriceOrError)) {
        return { items: [<Label>{error || noDepartures}</Label>], keys: ['disabled'] };
      }
      cost = formats.currency(cruisePriceOrError);
    } else {
      cost = formats.currency(Number(price));
    }

    return {
      items: [<Label>{fromLabel}</Label>, <Price>{cost}</Price>],
      keys: ['pricelabel', 'price'],
    };
  };

  const { formats } = useContext(LanguageContext);
  const id = `${state.baseId}-${value}`;
  const content: Content = getContent();

  return (
    <DepartureWrapper>
      <DepartureRadio
        {...{
          id,
          value,
          ...state,
          ...{ disabled, focusable: true },
        }}
      />
      <DepartureCard as="label" htmlFor={id}>
        <ContentGroup>
          <Label>{formats.i18n.localizeShortDayOfWeek(date)}</Label>
          <Day>{date.getDate()}</Day>
          <Label>{formats.i18n.localizeShortMonth(date.getMonth() + 1)}</Label>
        </ContentGroup>
        <ContentGroup>
          <Transition.HeightGroup {...content} />
        </ContentGroup>
      </DepartureCard>
    </DepartureWrapper>
  );
};

export default DepartureDay;
