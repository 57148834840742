import React, { FunctionComponent, useContext, useEffect } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Button from '../../../design-system/components/Button';
import Card from '../../../design-system/components/Card';
import { Subgrid } from '../../../design-system/components/Container';
import Modal from '../../../design-system/components/Modal';
import RichText from '../../../design-system/components/RichText';
import Stepper, { InputProps } from '../../../design-system/components/Stepper';
import { H2, H3, Lead, Price } from '../../../design-system/components/Text';
import { Transition } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { getCount, setStepperValue } from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import { formatString } from '../../../utils/formats';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import OnboardDescription from './shared/OnboardDescription';

interface SelectionProps {
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
  readonly onOpen: (sailingCode: string) => void;
}

interface PackageCardProps extends InputProps {
  readonly product: API.ExtendedProduct;
  readonly tariff: API.Tariff;
  readonly unit?: string;
}

const SelectedItem: FunctionComponent<API.ExtendedOnboard> = ({ code, amount }) => {
  const [sbPackage, ref] = hooks.useStoryblokComponent<HTMLLIElement>({
    path: code || '',
  });

  return (
    <li ref={ref}>
      {amount} × {sbPackage?.content.title || code}
    </li>
  );
};

const PackageCard: FunctionComponent<PackageCardProps> = ({ product, tariff, unit, ...props }) => {
  const { formats } = useContext(LanguageContext);
  const [sbPackage, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: product.code || '',
  });

  const description = hooks.useStoryblokRichText(sbPackage?.content.description);
  const chargeInfo = API.maybeChargeInfo(tariff, product);

  return (
    <Card ref={ref} vertical image={sbPackage?.content.image}>
      <H3>{sbPackage?.content.title || product.desc}</H3>
      {description && <RichText>{description}</RichText>}
      <Stepper {...props}>
        <Price align="left" inline>
          {chargeInfo.mapOrDefault(({ charge }) => formats.currency(charge), '-')}{' '}
          {unit && <Lead as="span">{unit}</Lead>}
        </Price>
      </Stepper>
    </Card>
  );
};

const CelebrationPackages: FunctionComponent<SelectionProps> = ({ sailing, add, remove, onOpen }) => {
  const modalState = useDialogState({ animated: false });
  const { formats } = useContext(LanguageContext);
  const sbPorts = hooks.useStoryblokDatasource('ports');
  const [sbCelebrationPackages, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.celebration_packages',
  });
  const [sbCelebrationPackagesModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.celebration_packages_modal',
  });

  const celebrationPackagesDescription = hooks.useStoryblokRichText(
    sbCelebrationPackages?.content.celebration_packages_description
  );

  const products = (API.isError(sailing.products) ? null : (sailing.products as API.Products))?.PACKAGE || [];

  const onboardPackages: API.ExtendedOnboard[] =
    (API.isError(sailing.onboards) ? null : (sailing.onboards as API.ExtendedOnboard[]))?.filter(
      ({ type }) => type === 'PACKAGE'
    ) || [];

  const arrivalPort = (sbPorts && sbPorts[sailing.arrivalPort]) || sailing.arrivalPort;
  const departurePort = (sbPorts && sbPorts[sailing.departurePort]) || sailing.departurePort;

  const totalCharge = calculateProductsTotalPrice(onboardPackages, sailing.tariff);

  const handleStepperChange = (productCode: string, value: number) => {
    setStepperValue(productCode, value, onboardPackages, add, remove);
  };

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode);
    }
  }, [modalState.visible]);

  return !!products.length ? (
    <Subgrid ref={ref}>
      <H2>{sbCelebrationPackages?.content.title}</H2>
      <OnboardDescription>{celebrationPackagesDescription}</OnboardDescription>
      {onboardPackages.length ? (
        <div>
          <H3>{sbCelebrationPackages?.content.selected_celebration_packages}</H3>
          <ul>
            {onboardPackages.map((product) => (
              <SelectedItem key={product.code} {...product} />
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
      <DialogDisclosure disclosure as={Button} round {...modalState}>
        {
          sbCelebrationPackages?.content[
            onboardPackages.length > 0 ? 'edit_celebration_packages_button' : 'add_celebration_packages_button'
          ]
        }
      </DialogDisclosure>
      <Modal
        ref={modalRef}
        state={modalState}
        title={formatString(sbCelebrationPackagesModal?.content.title, departurePort, arrivalPort)}
        focusOnDialog
        submit={sbCelebrationPackagesModal?.content.ready_button}
        size="input"
        footer={
          <Transition.Height show={!!totalCharge} as={Price}>
            <Transition.Number value={totalCharge} formatter={formats.currency} />
          </Transition.Height>
        }
      >
        {(modalState.animating || modalState.visible) && (
          <Subgrid>
            {products.map((product) => (
              <PackageCard
                id={`${sailing.sailingCode}-${product.code}`}
                key={product.code}
                product={product}
                tariff={sailing.tariff}
                setValue={(value) => handleStepperChange(product.code, value)}
                value={getCount(product.code, onboardPackages)}
                min={0}
                max={product.capacity.available || 0}
                unit={sbCelebrationPackagesModal?.content.price_unit}
              />
            ))}
          </Subgrid>
        )}
      </Modal>
    </Subgrid>
  ) : (
    <></>
  );
};

export default CelebrationPackages;
