import { assign } from 'xstate';
import { SelectedType } from '../../design-system/components/TreeSelect/SelectedList';
import { ExtendedVehicle, Form, VehicleType } from '../types';
import { indexToLeg } from '../utils/sailingUtils';
import { CatalogContext } from './catalogMachine';

const VehicleActions = {
  createVehicles: assign<CatalogContext>({
    vehicles: (ctx: CatalogContext, evt: any): ExtendedVehicle[] => {
      return ctx.searchParams.vehicles.map(
        (v: SelectedType): ExtendedVehicle => ({
          id: v.id,
          type: v.productcode as VehicleType,
          legs: ctx.searchParams.forms.map((f: Form) => indexToLeg(f.index)),
          assignedDriver: -1,
          //lengthCm: vehicleLengths[v.productcode as VehicleType],
          //heightCm: vehicleHeights[v.productcode as VehicleType],
        })
      );
    },
  }),
  addLegToVehicle: assign<CatalogContext>({
    vehicles: (ctx: CatalogContext, evt: any): ExtendedVehicle[] => {
      const vehicle = ctx.vehicles.find((v) => v.id === evt.data.id);
      if (!vehicle) return ctx.vehicles;
      if (!vehicle.legs) vehicle.legs = [];
      if (!vehicle.legs.includes(evt.data.leg)) vehicle.legs.push(evt.data.leg);

      return ctx.vehicles;
    },
    errors: [],
  }),
  removeLegFromVehicle: assign<CatalogContext>({
    vehicles: (ctx: CatalogContext, evt: any): ExtendedVehicle[] => {
      const vehicle = ctx.vehicles.find((v) => v.id === evt.data.id);
      if (!vehicle) return ctx.vehicles;
      if (!vehicle.legs) vehicle.legs = [];
      if (vehicle.legs.includes(evt.data.leg)) vehicle.legs = vehicle.legs.filter((leg) => leg !== evt.data.leg);

      return ctx.vehicles;
    },
    errors: [],
  }),
  editVehicle: assign<CatalogContext>({
    vehicles: (ctx: CatalogContext, evt: any): ExtendedVehicle[] => {
      return ctx.vehicles.map((v) => (v.id === evt.data.id ? { ...v, ...evt.data.info } : v));
    },
    errors: [],
  }),
};

export default VehicleActions;
