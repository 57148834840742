import React from 'react';
import styled from 'styled-components';

import { Language } from '../../../Language.context';

import { StyledInput } from '../../helpers/components';
import { typography } from '../../helpers/mixins';

export interface LanguageProps {
  readonly label: string;
  readonly value: Language;
}

interface LanguageSelectProps {
  readonly value: string;
  readonly label: string;
  readonly languages: LanguageProps[];
  readonly onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select = styled(StyledInput.Input)`
  ${typography.h3}
  text-align: center;
  text-align-last: center;
  padding: 0.5rem 1rem;

  && {
    height: auto;
  }
`;

const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = ({ value, label, languages, onChange }) => (
  <StyledInput.Wrapper>
    <Select as="select" {...{ onChange, value: value && value.toLowerCase() }} noLabel aria-label={label}>
      {languages.map(({ label, value }) => (
        <option key={value} value={value.toLowerCase()}>
          {label}
        </option>
      ))}
    </Select>
    <StyledInput.Label as="div" />
  </StyledInput.Wrapper>
);

export default LanguageSelect;
