import React from 'react';
import styled, { css } from 'styled-components';
import { cover } from 'polished';
import { Dialog as BaseDialog, DialogBackdrop, DialogStateReturn } from 'reakit/Dialog';
import { animated, useSpring } from 'react-spring';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { color, measurement, zindex } from '../helpers/vars';
import { animation, responsive, style } from '../helpers/mixins';
import { Theme } from '../helpers/components';

import Button from './Button';
import Label from './Label';
import { H3 } from './Text';

interface ContentWrapperProps {
  readonly image?: string;
  readonly ref?: any;
}

interface ContentProps {
  readonly grid?: boolean;
}

interface DialogProps {
  readonly size?: 'default' | 'input';
}

interface ModalProps extends ContentWrapperProps, ContentProps, DialogProps {
  readonly title: React.ReactNode;
  readonly children: React.ReactNode;
  readonly state: DialogStateReturn;
  readonly id?: any;
  readonly focusOnDialog?: boolean;
  readonly submit?: string;
  readonly label?: React.ReactNode;
  readonly footer?: React.ReactNode;
  readonly contentRef?: React.RefObject<HTMLDivElement>;
}

const Backdrop = styled(DialogBackdrop)`
  ${cover()}
  position: fixed;
  background: ${color.bg.overlay};
  padding: ${measurement.space.modal.s};
  z-index: ${zindex.modal};
  display: flex;

  ${responsive.fontSize(css`
    padding: ${measurement.space.modal.l};
  `)}
`;

const Dialog = styled(BaseDialog)<DialogProps>`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto auto 0;
  background: ${color.bg.default};
  border-radius: ${measurement.size.radius.modal.s};
  transition: ${animation.transition('box-shadow')};

  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: ${style.shadow.modal};
  }

  ${responsive.fontSize(css`
    margin: auto;
    border-radius: ${measurement.size.radius.modal.l};
  `)}

  ${(props) =>
    responsive.fontSize(css`
      ${props.size
        ? css`
            max-width: ${measurement.size.modal[props.size || 'default']};
          `
        : css`
            width: auto;
          `}
    `)}
`;

const TitleContainer = styled.div`
  position: relative;
  border-bottom: ${style.border.default};
  padding: ${measurement.inset.modal.title.s};
  flex: 0 0 auto;

  ${responsive.fontSize(css`
    padding: ${measurement.inset.modal.title.l};
  `)}

  button {
    position: absolute;
    top: ${measurement.space.modal.button.s};
    right: ${measurement.space.modal.button.s};

    ${responsive.fontSize(css`
      top: ${measurement.space.modal.button.l};
      right: ${measurement.space.modal.button.l};
    `)}
  }
`;

const StyledTitle = styled(H3)`
  text-align: center;
  margin: 0;
  margin-right: 2rem;
`;

const Title: React.FunctionComponent<{ id: any; onClickClose: () => void }> = ({ id, children, onClickClose }) => (
  <TitleContainer>
    <StyledTitle {...{ id }} as="h1">
      {children}
    </StyledTitle>
    <Button secondary icon="times" onClick={onClickClose} label="Close dialog" />
  </TitleContainer>
);

const ContentWrapper = styled.div<ContentWrapperProps>`
  mask-image: linear-gradient(0deg, transparent 0, #000 ${measurement.inset.modal.fade});
  flex: 1 1 auto;
  overflow: hidden auto;
  position: relative;
  -webkit-overflow-scrolling: touch;

  ${Label} {
    position: absolute;
    right: -0.5rem;
  }

  ${(props) =>
    props.image &&
    css`
      ${Label} {
        ${style.label.bg(color.bg.label.overlay)}
        transform: translateY(-100%);
        padding-right: 1.5rem;
      }

      &::before {
        content: '';
        border: ${style.border.overlay};
        background-image: url(${props.image});
        background-size: cover;
        background-repeat: no-repeat;
        background-origin: border-box;
        width: 100%;
        padding: 25% 0 0;
        display: block;
        box-sizing: border-box;
      }
    `}
`;

const Content = styled.div<ContentProps>`
  padding: ${measurement.inset.modal.s};

  & > * {
    &:first-child {
      margin-top: 0;
    }
  }

  ${responsive.fontSize(css`
    padding: ${measurement.inset.modal.l};
  `)}

  ${(props) =>
    props.grid &&
    css`
      display: grid;
      max-width: ${measurement.grid.mobile.width};
      grid-gap: ${measurement.grid.mobile.gap};
      margin: auto;

      ${responsive.grid(css`
        display: grid;
        max-width: ${measurement.grid.default.width};
        grid-template: auto / ${Array(measurement.grid.default.count).fill('1fr').join(' ')};
        grid-gap: ${measurement.grid.default.gap};
      `)}
    `}
`;

const Footer = styled.div`
  padding: ${measurement.inset.modal.footer.s};
  flex: 0 0 auto;

  @supports (padding: max(0px)) {
    padding-bottom: max(${measurement.inset.modal.footer.bottom}, env(safe-area-inset-bottom));
  }

  ${responsive.fontSize(css`
    padding: ${measurement.inset.modal.footer.l};
  `)}

  button {
    width: 100%;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;

// TODO investigate how to use backdrop animation without flickering
// const AnimatedBackdrop = animated(Backdrop);
const AnimatedDialog = animated(Dialog);

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ state, children, title, id, focusOnDialog, submit, image, size, label, footer, grid, contentRef }, ref) => {
    const titleId = id ? id : `${state.baseId}-title`;

    const springs = {
      // backdrop: useSpring({ opacity: state.visible ? 1 : 0, onRest: state.stopAnimation }),
      dialog: useSpring({ transform: state.visible ? 'translateY(0)' : 'translateY(100%)' }),
    };

    const scrollRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
      const scrollContainer = scrollRef.current;

      scrollContainer && (state.visible ? disableBodyScroll(scrollContainer) : enableBodyScroll(scrollContainer));
    }, [state.visible]);

    return (
      <Theme.Default>
        <Backdrop {...state}>
          <AnimatedDialog
            ref={ref}
            preventBodyScroll={false}
            style={springs.dialog}
            aria-labelledby={titleId}
            {...{ size, ...state }}
            {...(focusOnDialog && { tabIndex: 0 })}
          >
            <Title onClickClose={state.hide} id={titleId}>
              {title}
            </Title>
            <ContentWrapper ref={scrollRef} {...{ image }}>
              {label && <Label>{label}</Label>}
              <Content {...{ grid }} ref={contentRef}>
                {children}
              </Content>
            </ContentWrapper>
            {submit && (
              <Footer>
                {footer}
                <Button round onClick={state.hide}>
                  {submit}
                </Button>
              </Footer>
            )}
          </AnimatedDialog>
        </Backdrop>
      </Theme.Default>
    );
  }
);

export default Modal;
