import React from 'react';
import styled from 'styled-components';
import { cover } from 'polished';

import { color } from '../../helpers/vars';
import { style } from '../../helpers/mixins';

// storyblok advanced-image component
export interface ImageAreaProps {
  readonly image: string;
  readonly alt_tag?: string;
  readonly caption?: string;
  readonly copyright_info?: string;
}

export const StyledImageArea = styled.div`
  background: ${color.bg.alt};

  &::before {
    ${cover()};
    content: '';
    border-radius: inherit;
    border: ${style.border.overlay};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: block;
`;

const ImageArea: React.FunctionComponent<ImageAreaProps> = ({ image, alt_tag }) => {
  return (
    <StyledImageArea>
      <Image src={image} alt={alt_tag} />
    </StyledImageArea>
  );
};

export default ImageArea;
