import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import { style } from '../../helpers/mixins';

import Item, { ItemProps, SuboptionType } from './Item';

interface SelectionProps {
  readonly id: number;
  readonly info: SuboptionType;
  readonly handleRemoval: (val: number) => void;
  readonly focusRef?: any;
}

const Wrapper = styled.div`
  ${style.card.content}
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem;
`;

const Selection: React.FunctionComponent<ItemProps & SelectionProps> = ({ id, info, handleRemoval, focusRef }) => (
  <Wrapper>
    <Item {...info} />
    <Button secondary icon="times" onClick={() => handleRemoval(id)} forwardRef={focusRef} />
  </Wrapper>
);

export default Selection;
