import React from 'react';
import BookingSummary from '../../design-system/components/BookingSummary';
import { hooks } from '../../design-system/helpers/mixins';
import * as API from '../../fsm/types';
import Summary, { SailingSummary } from './Summary';

export interface FullSummaryPassedProps {
  readonly agreement?: API.Agreement;
  readonly offerCode?: string;
  readonly sailings: {
    index: number;
    sailing: SailingSummary;
  }[];
}

interface FullSummaryProps extends FullSummaryPassedProps {
  readonly totalPrice: string;
}

const FullSummary: React.FunctionComponent<FullSummaryProps> = ({ agreement, sailings, totalPrice, offerCode }) => {
  const [sbLegSummary, sbLegSummaryRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.leg_summary',
  });
  const [sbTotal, totalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_total',
  });
  const sbTicket = hooks.useStoryblokComponents('products/tickets');

  return (
    <>
      {sailings.map(({ index, sailing }) => (
        <Summary
          {...{ agreement, index, sailing }}
          key={index}
          ref={sbLegSummaryRef}
          sbLegSummary={sbLegSummary}
          sbTicket={sbTicket}
          offerCode={offerCode || sailing.offerCode}
        />
      ))}
      <BookingSummary.Total
        innerRef={totalRef}
        label={sbTotal?.content.total_label}
        addition={sbTotal?.content.vat_info}
        price={totalPrice}
      />
    </>
  );
};

export default FullSummary;
