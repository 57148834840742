import { isAfter, isBefore, parseISO } from 'date-fns';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Alert from '../../design-system/components/Alert';
import RichText from '../../design-system/components/RichText';
import { H3 } from '../../design-system/components/Text';
import { hooks } from '../../design-system/helpers/mixins';
import { Port } from '../../fsm/types';
import logger from '../../utils/logging';

interface NotificationProps {
  legs: [Port, Port][];
  storyblokPath: string;
}

const Wrapper = styled.div`
  margin-bottom: 1rem;

  & > div {
    margin-bottom: 1rem;
  }
  & > div:first-child {
    display: block;
  }
`;

const isSomeLegActive = (legs: string[], activeLegs: string[]): boolean =>
  legs.some((leg: string) => activeLegs.some((activeLeg) => activeLeg === leg));
const isStartDateBeforeNow = (startDate: string, now: Date): boolean =>
  startDate.trim() === '' || isBefore(parseISO(startDate), now);
const isEndDateAfterNow = (endDate: string, now: Date): boolean =>
  endDate.trim() === '' || isAfter(parseISO(endDate), now);

const NotificationComponent: FunctionComponent<{
  activeLegs: string[];
  uuid: string;
}> = ({ activeLegs, uuid }) => {
  const [sbNotification, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    uuid,
  });

  if (sbNotification?.content) {
    const { content, end_date = '', legs = [], show = false, start_date = '', title, type } = sbNotification.content;
    const now = new Date(Date.now());
    const richText = hooks.useStoryblokRichText(content);

    if (
      show &&
      sbNotification.slug !== 'load-failure-notification' && // This notification is handled different way on search page
      (!legs.length || isSomeLegActive(legs, activeLegs)) && // Allow showing notifications if no legs selected, generic notifications
      isStartDateBeforeNow(start_date, now) &&
      isEndDateAfterNow(end_date, now)
    ) {
      if (type && (title || richText)) {
        // Check if richtext has content
        const hasRichText = !(content && content.length === 1 && !content[0].content);
        return (
          <Alert ref={ref} severity={type}>
            {!hasRichText ? (
              title
            ) : (
              <>
                {title && <H3>{title}</H3>}
                <RichText>{richText}</RichText>
              </>
            )}
          </Alert>
        );
      }
    }
  } else {
    logger.warn('Notification content with uuid not found', uuid, sbNotification);
  }

  return null;
};

const Notification: FunctionComponent<NotificationProps> = ({ storyblokPath, legs }) => {
  const [sbNotificationList, wrapperRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: storyblokPath,
  });

  const sbLegs = legs.map(([departurePort, arrivalPort]) => [departurePort + '-' + arrivalPort]).flat();

  const notifications = (sbNotificationList?.content.notifications || []).map((uuid: string) => (
    <NotificationComponent key={uuid} activeLegs={sbLegs} uuid={uuid} />
  ));

  return React.Children.count(notifications) ? <Wrapper ref={wrapperRef}>{notifications}</Wrapper> : <></>;
};

export default Notification;
