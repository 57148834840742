import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme, Theme } from '../mixins';

const Provider: FunctionComponent<{ variant: Theme }> = ({ variant, ...props }) => (
  <ThemeProvider theme={theme[variant]} {...props} />
);

const Default: FunctionComponent = (props) => <Provider variant={Theme.DEFAULT} {...props} />;
const Alternative: FunctionComponent = (props) => <Provider variant={Theme.ALTERNATIVE} {...props} />;
const Inverted: FunctionComponent = (props) => <Provider variant={Theme.INVERTED} {...props} />;

export default {
  Provider,
  Default,
  Alternative,
  Inverted,
  type: Theme,
};
