import styled, { css } from 'styled-components';

import { font, measurement } from '../helpers/vars';
import { typography } from '../helpers/mixins';

interface TextProps {
  inline?: boolean;
  align?: 'left' | 'right' | 'center';
  theme?: any;
  gridSpan?: number;
}

interface ParagraphProps extends TextProps {
  small?: boolean;
  strong?: boolean;
}

interface PriceProps extends TextProps {
  small?: boolean;
}

const common = (type: 'heading' | 'copy', props: ParagraphProps, isPrice?: boolean) => css`
  color: ${isPrice ? props.theme.text.price : props.theme.text[type]};

  ${props.align &&
  css`
    text-align: ${props.align};
  `}

  ${props.inline
    ? css`
        margin: 0;
        padding: 0;
        display: inline;
      `
    : css`
        margin: ${measurement.space.text[type]} 0;
        min-height: 1.125rem;
      `};

  ${props.gridSpan &&
  css`
    grid-column: span ${props.gridSpan};
  `}
`;

/** @component */
/** @visibleName Text */
export const H1 = styled.h1<TextProps>`
  ${typography.h1}
  ${(props) => common('heading', props)}
`;

/** @component */
export const H2 = styled.h2<TextProps>`
  ${typography.h2}
  ${(props) => common('heading', props)}
`;

/** @component */
export const H3 = styled.h3<TextProps>`
  ${typography.h3}
  ${(props) => common('heading', props)}
`;

/** @component */
export const Lead = styled.p<ParagraphProps>`
  ${typography.lead}
  ${(props) => common('copy', props)}

  ${(props) =>
    props.strong &&
    css`
      font-weight: ${font.weight.strong.lead};
    `}
`;

/** @component */
export const P = styled.p<ParagraphProps>`
  ${({ small }) => (small ? typography.small : typography.p)}
  ${(props) => common('copy', props)}

  ${(props) =>
    props.strong &&
    css`
      font-weight: ${font.weight.strong.lead};
    `}
`;

/** @component */
export const Small = styled.p<ParagraphProps>`
  ${typography.small}
  ${(props) => common('copy', props)}

  ${(props) =>
    props.strong &&
    css`
      font-weight: ${font.weight.strong.lead};
    `}
`;

/** @component */
export const Price = styled.p<PriceProps>`
  ${({ small }) => (small ? typography.h4 : typography.h3)}
  position: relative;
  text-align: right;

  & > * {
    right: 0;
  }

  ${(props) => common('copy', props, true)}
`;
