import React, { useContext, useEffect, useState } from 'react';
import { Modifier, Modifiers } from 'react-day-picker';
import { LanguageContext } from '../../../Language.context';
import Wrapper, { WrapperParentProps } from './Wrapper';

export interface SingleDates {
  readonly selectedDay?: Date;
}

interface DatePickerProps extends WrapperParentProps {
  readonly dates: SingleDates;
  readonly setDates: React.Dispatch<React.SetStateAction<SingleDates>>;
}

const Single: React.FunctionComponent<DatePickerProps> = ({
  dates,
  setDates,
  label,
  submit,
  children,
  title,
  ...props
}) => {
  const [selected, setSelected] = useState(dates);
  const [value, setValue] = useState('');

  const { localeData } = useContext(LanguageContext);

  useEffect(() => {
    setSelected(dates);
  }, [dates]);

  useEffect(() => {
    const localeOptions: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };

    setValue(`${dates.selectedDay && dates.selectedDay.toLocaleDateString(localeData.locale, localeOptions)}`);
  }, [dates, localeData.locale]);

  const handleResetClick = () => setDates({});

  const onDayClick = (day: Date, modifiers: any) => {
    if (modifiers.disabled) return;

    const { selectedDay } = dates;

    if (selectedDay === day) {
      handleResetClick();
      return;
    }

    setDates({ selectedDay: day });
  };

  return (
    <Wrapper
      selectedDays={selected as unknown as Modifier}
      modifiers={dates as Partial<Modifiers>}
      month={dates.selectedDay}
      {...{ value, label, title, submit, onDayClick }}
      {...props}
    />
  );
};

export default Single;
