import { List } from 'purify-ts/List';
import { ActionFunction, ActionObject, assign } from 'xstate';
import gtm, { Impression, Product } from '../../analytics/gtm';
import { asEffect } from '../../xstate-react/useMachine';
import * as API from '../types';
import {
  accommodationProduct,
  onboardProduct,
  passengerProduct,
  petProduct,
  ticketProduct,
  vehicleProduct,
  formAnalyticsProductName,
  impressionsToGA4Impressions,
  productsToGA4Products,
} from '../utils/analyticsUtils';
import { collectSelectedSailings } from '../utils/sailingUtils';
import { electricityVehicles } from '../utils/vehicleUtils';
import { CatalogContext, CatalogEvent } from './catalogMachine';

const actions: Record<
  string,
  ActionFunction<CatalogContext, CatalogEvent> | ActionObject<CatalogContext, CatalogEvent>
> = {
  gtmAddOnboardToCart: ({ catalogs, searchParams }: CatalogContext) => {
    // UA
    // Exclude electricity onboard products, since they have already been added to cart on the trip page
    const products = collectOnboardProductsFromCatalogs(catalogs).filter(
      (product) => product.variant !== 'electricity'
    );

    gtm.clearEcommerce();
    gtm.addToCart({
      products,
      currency: searchParams.currency,
    });

    gtm.send();

    // GA4
    gtm.clearEcommerce();
    const ga4products = productsToGA4Products(products, searchParams.currency);
    gtm.ga4AddToCart({
      products: ga4products,
    });

    gtm.send();

    return 'noop';
  },

  // Moving from info to onboard should trigger this
  gtmRemoveOnboardProductsFromCart: ({ catalogs, searchParams }: CatalogContext) => {
    // Exclude electricity onboard products, since they are added to cart on the trip page
    const products = collectOnboardProductsFromCatalogs(catalogs).filter(
      (product) => product.variant !== 'electricity'
    );

    // UA
    gtm.clearEcommerce();
    gtm.removeFromCart({
      products,
      currency: searchParams.currency,
    });

    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4RemoveFromCart({
      products: productsToGA4Products(products, searchParams.currency),
    });

    gtm.send();

    return 'noop';
  },

  // Moving back to search or trip from later stages should trigger this
  gtmClearCart: ({ catalogs, searchParams }: CatalogContext) => {
    const products = collectProductsFromCatalogs(catalogs);

    // UA
    gtm.clearEcommerce();
    gtm.removeFromCart({
      products,
      currency: searchParams.currency,
    });

    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4RemoveFromCart({
      products: productsToGA4Products(products, searchParams.currency),
    });

    gtm.send();

    return 'noop';
  },

  gtmAddTripToCart: ({ catalogs, searchParams }: CatalogContext) => {
    const products = collectSelectedSailings(catalogs, (index, sailing) => ticketProduct(sailing, index)).concat(
      collectAccommodationsFromCatalogs(catalogs),
      // Although most onboard products are on onboards page, trip page has electricity products
      collectOnboardProductsFromCatalogs(catalogs),
      collectSearchProductsFromCatalogs(catalogs)
    );

    // UA
    gtm.clearEcommerce();
    gtm.addToCart({
      products,
      currency: searchParams.currency,
    });

    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4AddToCart({
      products: productsToGA4Products(products, searchParams.currency),
    });

    gtm.send();

    return 'noop';
  },

  gtmCheckoutStart: ({ catalogs, searchParams }: CatalogContext) => {
    const products = collectProductsFromCatalogs(catalogs);
    // UA
    gtm.clearEcommerce();
    gtm.checkout({
      actionField: { step: 1 },
      products: collectProductsFromCatalogs(catalogs),
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.beginCheckout({
      products: productsToGA4Products(products, searchParams.currency),
    });
    gtm.send();
  },

  gtmSendListingImpressions: assign(({ catalogs, searchParams, gtmState }: CatalogContext) => {
    if (gtmState.listing) {
      const catalogImpressions = collectSelectedSailings(
        catalogs,
        (index, sailing) =>
          ({
            id: sailing.sailingCode,
            category: 'ticket',
            list: 'trip',
            name: `${sailing.departurePort} - ${sailing.arrivalPort}`,
            position: index + 1,
            price: API.maybeChargeInfo(sailing.tariff, sailing)
              .map((_) => (_.charge / 100).toFixed(2))
              .extract(),
            variant: sailing.tariff.toLowerCase(),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          } as Impression)
      );

      const electricityImpressions =
        electricityVehicles(searchParams?.vehicles).length > 0
          ? collectElectricityImpressions(catalogs, searchParams)
          : [];

      // UA
      gtm.clearEcommerce();
      const impressions = [
        ...(catalogImpressions as Impression[]),
        ...collectAccommodationsFromCatalogs(catalogs),
        ...collectTicketTypeImpressions(catalogs),
        ...(electricityImpressions as Impression[]),
      ];
      gtm.impressions({
        currency: searchParams.currency,
        impressions,
      });
      gtm.send();

      // GA4
      gtm.clearEcommerce();
      gtm.ga4Impressions(impressionsToGA4Impressions(impressions, searchParams.currency));
      gtm.send();
    }
    return {
      gtmState: {
        ...gtmState,
        listing: false,
      },
    };
  }),

  gtmSendMealImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const mealImpressions = collectLimitedToImpressions(catalogs, data, API.ProductType.FOOD);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...mealImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(mealImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendWifiImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const wifiImpressions = collectOnboardImpressions(catalogs, data, API.ProductType.WIFI);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...wifiImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(wifiImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendCelebrationPackagesImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const serviceImpressions = collectOnboardImpressions(catalogs, data, API.ProductType.PACKAGE);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...serviceImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(serviceImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendLoungePackagesImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const serviceImpressions = collectOnboardImpressions(catalogs, data, API.ProductType.LOUNGE);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...serviceImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(serviceImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendOnboardCouponsImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const serviceImpressions = collectOnboardImpressions(catalogs, data, API.ProductType.PACKAGE);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...serviceImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(serviceImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendWellnessServicesImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const serviceImpressions = collectOnboardImpressions(catalogs, data, API.ProductType.PACKAGE);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...serviceImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(serviceImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendNightImpressions: ({ catalogs, searchParams }: CatalogContext, { data }: any) => {
    const nightImpressions = collectLimitedToImpressions(catalogs, data, API.ProductType.NIGHT);

    // UA
    gtm.clearEcommerce();
    gtm.impressions({
      currency: searchParams.currency,
      impressions: [...nightImpressions],
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Impressions(impressionsToGA4Impressions(nightImpressions, searchParams.currency));
    gtm.send();
  },

  gtmSendQueue: asEffect((_: CatalogContext) => gtm.send()),
};

function collectTicketTypeImpressions(catalogs: API.Catalog[]): Impression[] {
  const impressions: Impression[] = [];
  collectSelectedSailings(catalogs, (index, sailing) => {
    if (sailing && sailing.price && typeof sailing.price !== 'string') {
      Object.keys(sailing.price).forEach((ticketType) => {
        const value = {
          id: ticketType,
          category: 'ticket',
          name: ticketType === API.Tariff.SPECIAL ? 'Basic ticket' : 'Flexible ticket',
          position: index + 1, // Legs are zero indexed
          variant: ticketType.toLowerCase(),
          brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
        } as Impression;
        impressions.push(value);
      });
    }
  });
  return impressions;
}

// Used with onboard products that are limited to a certain passenger type
// e.g. meal and night packages
function collectLimitedToImpressions(catalogs: API.Catalog[], data: any, productType: API.ProductType): Impression[] {
  const impressions: Impression[] = [];
  collectSelectedSailings(catalogs, (index, sailing) => {
    if (
      sailing.sailingCode === data.sailingCode &&
      typeof sailing.products !== 'string' &&
      sailing.products[productType]
    ) {
      sailing.products[productType]?.forEach((product) => {
        // Create impressions only for those packages that the passenger sees in the modal,
        // I.e. those that match the types of the selected passengers
        if (product.limitedTo?.some((type) => data.passengerTypes.includes(type))) {
          const value = {
            id: product.code,
            category: 'onboard',
            list: 'onboard',
            name: product.desc || '',
            position: index + 1,
            variant: product.subtype?.toLowerCase() || product.type.toLowerCase(),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          } as Impression;
          impressions.push(value);
        }
      });
    }
  });
  return impressions;
}

function collectOnboardImpressions(catalogs: API.Catalog[], data: any, productType: API.ProductType): Impression[] {
  const onboardImpressions = collectSelectedSailings(catalogs, (index, sailing) => {
    if (
      sailing.sailingCode === data.sailingCode &&
      typeof sailing.products !== 'string' &&
      sailing.products[productType]
    ) {
      return sailing.products[productType]?.map((product) => {
        const value = {
          id: product.code,
          category: 'onboard',
          list: 'onboard',
          name: product.desc || '',
          position: index + 1,
          variant: product.subtype?.toLowerCase() || undefined,
          brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
        } as Impression;
        return value;
      });
    }
    return [];
  });
  return onboardImpressions as Impression[];
}

function collectElectricityImpressions(catalogs: API.Catalog[], searchParams: API.SearchParams): Impression[] {
  const electricityImpressions = collectSelectedSailings(catalogs, (index, sailing) => {
    if (typeof sailing.products !== 'string' && sailing.products[API.ProductType.ELECTRICITY]) {
      return sailing.products[API.ProductType.ELECTRICITY]?.map((product) => {
        const value = {
          id: product.code,
          category: 'onboard',
          list: 'onboard',
          name: product.desc || '',
          position: index + 1,
          variant: product.subtype?.toLowerCase() || undefined,
          brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
        } as Impression;
        return value;
      });
    }
    return [];
  });
  return electricityImpressions as Impression[];
}

function collectAccommodationsFromCatalogs(catalogs: API.Catalog[]): Product[] {
  return collectSelectedSailings(catalogs, (index, sailing) => {
    const sailingProducts = [] as Product[];

    if (sailing.accommodations && Array.isArray(sailing.accommodations)) {
      sailing.accommodations.forEach((accommodation) => {
        const { code, type } = accommodation;

        sailingProducts.push(
          accommodationProduct({
            code,
            index,
            type,
            amount: 1,
            chargeInfo: API.maybeChargeInfo(sailing.tariff, accommodation),
            name: formAnalyticsProductName(sailing.products, API.ProductType.ACCOMMODATION, code),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          })
        );
      });
    }

    return sailingProducts;
  });
}

function collectOnboardProductsFromCatalogs(catalogs: API.Catalog[]): Product[] {
  return collectSelectedSailings(catalogs, (index, sailing) => {
    const sailingProducts = [] as Product[];

    if (sailing.onboards && Array.isArray(sailing.onboards)) {
      sailing.onboards.forEach((onboard) => {
        const { code, type, amount } = onboard;

        sailingProducts.push(
          onboardProduct({
            code,
            index,
            type,
            amount,
            chargeInfo: API.maybeChargeInfo(sailing.tariff, onboard),
            name: formAnalyticsProductName(sailing.products, type as API.ProductType, code),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          })
        );
      });
    }
    return sailingProducts;
  });
}

function collectSearchProductsFromCatalogs(catalogs: API.Catalog[]): Product[] {
  return collectSelectedSailings(catalogs, (index, sailing) => {
    const sailingProducts = [] as Product[];

    if (sailing.quote && sailing.quote[sailing.tariff]) {
      sailing.quote[sailing.tariff].passengers.forEach(({ legs, type }) =>
        sailingProducts.push(
          passengerProduct({
            index,
            type,
            amount: 1,
            chargeInfo: List.find(({ leg }) => leg === index + 1, legs).map((_) => _.chargeInfo),
            id: sailing.sailingCode,
            name: formAnalyticsProductName(sailing.products, API.ProductType.PASSENGER, type),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          })
        )
      );

      sailing.quote[sailing.tariff].pets.forEach(({ legs, type }) =>
        sailingProducts.push(
          petProduct({
            index,
            type,
            amount: 1,
            chargeInfo: List.find(({ leg }) => leg === index + 1, legs).map((_) => _.chargeInfo),
            id: sailing.sailingCode,
            name: formAnalyticsProductName(sailing.products, API.ProductType.PET, type),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          })
        )
      );

      sailing.quote[sailing.tariff].vehicles.forEach(({ legs, type }) =>
        sailingProducts.push(
          vehicleProduct({
            index,
            type,
            amount: 1,
            chargeInfo: List.find(({ leg }) => leg === index + 1, legs).map((_) => _.chargeInfo),
            id: sailing.sailingCode,
            name: formAnalyticsProductName(sailing.products, API.ProductType.VEHICLE, type),
            brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
          })
        )
      );
    }

    return sailingProducts;
  });
}

function collectProductsFromCatalogs(catalogs: API.Catalog[]): Product[] {
  const products = collectSelectedSailings(catalogs, (index, sailing) => ticketProduct(sailing, index)).concat(
    collectAccommodationsFromCatalogs(catalogs),
    collectOnboardProductsFromCatalogs(catalogs),
    collectSearchProductsFromCatalogs(catalogs)
  );

  return products;
}
export default actions;
