/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ReserveBooking = {
  accommodations: Array< AccommodationInput >,
  agreement?: AgreementInput | null,
  contactInfo: ContactInfoInput,
  currency: Currency,
  language: Language,
  onboards: Array< OnboardInput >,
  passengers: Array< PassengerInput >,
  passengerDetails: Array< PassengerDetailInput >,
  pets: Array< PetInput >,
  sailings: Array< SailingInput >,
  vehicles: Array< VehicleInput >,
};

export type AccommodationInput = {
  legs: Array< number >,
  type: string,
  code: string,
  passengers?: Array< number > | null,
  pets?: Array< number > | null,
  accommodationId?: number | null,
};

export type AgreementInput = {
  code?: string | null,
  validationCode?: string | null,
};

export type ContactInfoInput = {
  firstName: string,
  id: number,
  lastName: string,
  birthdate: string,
  address: string,
  city: string,
  postCode: string,
  country: string,
  nationality: string,
  email: string,
  countryCallCode: string,
  mobile: string,
  gender: PassengerGender,
  termsOfServiceOk: boolean,
  privacyPolicyOk: boolean,
  newsletterOk: boolean,
};

export enum PassengerGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNSPECIFIED = "UNSPECIFIED",
}


export enum Currency {
  EUR = "EUR",
  SEK = "SEK",
  PLN = "PLN",
}


export enum Language {
  IT = "IT",
  DE = "DE",
  EN = "EN",
  ES = "ES",
  FI = "FI",
  FR = "FR",
  SV = "SV",
  PL = "PL",
}


export type OnboardInput = {
  amount: number,
  code: string,
  legs: Array< number >,
  type: string,
  passengerIndex?: Array< number > | null,
};

export type PassengerInput = {
  id: number,
  legs: Array< number >,
  type: PassengerType,
};

export enum PassengerType {
  ADULT = "ADULT",
  CHILD = "CHILD",
  INFANT = "INFANT",
  JUNIOR = "JUNIOR",
}


export type PassengerDetailInput = {
  id: number,
  title?: string | null,
  firstName: string,
  lastName: string,
  starclub?: string | null,
  birthdate: string,
  gender: PassengerGender,
  nationality: string,
  type: PassengerType,
  specialNeeds?: string | null,
  document?: PassengerDocumentInput | null,
};

export type PassengerDocumentInput = {
  type?: string | null,
  number?: string | null,
  nationality?: string | null,
  expiryDate?: string | null,
};

export type PetInput = {
  id: number,
  legs: Array< number >,
  type: PetType,
};

export enum PetType {
  PET = "PET",
}


export type SailingInput = {
  leg: number,
  departureDate: string,
  departureTime: string,
  departurePortCode: string,
  arrivalPortCode: string,
  eventCode?: string | null,
  offerCode?: string | null,
  tariff: TariffInput,
};

export enum TariffInput {
  SPECIAL = "SPECIAL",
  STANDARD = "STANDARD",
  BOTH = "BOTH",
}


export type VehicleInput = {
  id: number,
  legs: Array< number >,
  type: VehicleType,
  lengthCm?: number | null,
  heightCm?: number | null,
  make?: string | null,
  model?: string | null,
  registrationId?: string | null,
};

export enum VehicleType {
  CAR = "CAR",
  VAN_LM = "VAN_LM",
  MBUS = "MBUS",
  BUS = "BUS",
  LCAR = "LCAR",
  CMP = "CMP",
  MCY = "MCY",
  BCY = "BCY",
}


export type BookingReservationOrError = BookingReservation | ApiError


export type BookingReservation = {
  __typename: "BookingReservation",
  accommodations:  Array<BookingAccommodation >,
  agreement?: Agreement | null,
  contactInfo: BookingContactInfo,
  bookingDetail: BookingDetail,
  onboards:  Array<BookingOnboard >,
  passengers:  Array<BookingPassenger >,
  passengerDetails:  Array<BookingPassengerDetail >,
  pets:  Array<BookingPet >,
  sailings:  Array<BookingSailing >,
  total: BookingTotal,
  vehicles:  Array<BookingVehicle >,
};

export type BookingAccommodation = {
  __typename: "BookingAccommodation",
  code: string,
  type: string,
  legs:  Array<Leg >,
  passengers:  Array<BookingPassengerAmount >,
};

export type Leg = {
  __typename: "Leg",
  leg: number,
  chargeInfo: ChargeInfo,
};

export type ChargeInfo = {
  __typename: "ChargeInfo",
  charge: number,
  discount: number,
  discountDesc?: string | null,
  discountStarClub?: boolean | null,
  tax?: number | null,
  taxes?:  Array<TaxesInfo > | null,
};

export type TaxesInfo = {
  __typename: "TaxesInfo",
  taxcode?: string | null,
  taxtype?: string | null,
  taxdescription?: string | null,
  taxamount: number,
};

export type BookingPassengerAmount = {
  __typename: "BookingPassengerAmount",
  amount: number,
  type: PassengerType,
};

export type Agreement = {
  __typename: "Agreement",
  code?: string | null,
  status?: AgreementStatus | null,
  validationCode?: string | null,
};

export enum AgreementStatus {
  OK = "OK",
  NOTOK = "NOTOK",
}


export type BookingContactInfo = {
  __typename: "BookingContactInfo",
  firstName: string,
  lastName: string,
  birthdate?: string | null,
  address: string,
  city: string,
  postCode: string,
  country?: string | null,
  nationality: string,
  email: string,
  countryCallCode?: string | null,
  mobile: string,
  termsOfServiceOk: boolean,
  privacyPolicyOk: boolean,
  newsletterOk: boolean,
};

export type BookingDetail = {
  __typename: "BookingDetail",
  reference: string,
  referenceHash: string,
  status: BookingStatus,
  date: string,
  time: string,
  expiryDate?: string | null,
};

export enum BookingStatus {
  BOOKED = "BOOKED",
  CANCELLED = "CANCELLED",
  MODIFIED = "MODIFIED",
  OPTION = "OPTION",
}


export type BookingOnboard = {
  __typename: "BookingOnboard",
  amount: number,
  type: string,
  code: string,
  legs:  Array<Leg >,
};

export type BookingPassenger = {
  __typename: "BookingPassenger",
  id: number,
  legs:  Array<Leg >,
  type: PassengerType,
};

export type BookingPassengerDetail = {
  __typename: "BookingPassengerDetail",
  id: number,
  title?: string | null,
  firstName: string,
  lastName: string,
  birthdate: string,
  age: number,
  gender: PassengerGender,
  nationality: string,
  type: PassengerType,
  document?: BookingPassengerDocument | null,
};

export type BookingPassengerDocument = {
  __typename: "BookingPassengerDocument",
  type?: string | null,
  number?: string | null,
  nationality?: string | null,
  expiryDate?: string | null,
};

export type BookingPet = {
  __typename: "BookingPet",
  id: number,
  legs:  Array<Leg >,
  type: PetType,
};

export type BookingSailing = {
  __typename: "BookingSailing",
  leg: Leg,
  departureDate: string,
  departureTime: string,
  departurePortCode: string,
  departurePortName?: string | null,
  departurePortDesc?: string | null,
  arrivalDate?: string | null,
  arrivalTime?: string | null,
  arrivalPortCode: string,
  arrivalPortName?: string | null,
  arrivalPortDesc?: string | null,
  eventCode?: string | null,
  offerCode?: string | null,
  shipCode?: string | null,
  shipName?: string | null,
  shipDesc?: string | null,
  sailingCode: string,
  tariff: Tariff,
};

export enum Tariff {
  SPECIAL = "SPECIAL",
  STANDARD = "STANDARD",
}


export type BookingTotal = {
  __typename: "BookingTotal",
  total?: number | null,
  commission?: number | null,
  tax?: number | null,
};

export type BookingVehicle = {
  __typename: "BookingVehicle",
  id: number,
  type: VehicleType,
  lengthCm?: number | null,
  heightCm?: number | null,
  legs:  Array<Leg >,
  mark?: string | null,
  model?: string | null,
  registrationId?: string | null,
};

export type ApiError = {
  __typename: "ApiError",
  errorCode: ErrorCode,
  errorMessage: string,
  productCode?: string | null,
};

export type Error = {
  __typename: "Error",
  errorCode: ErrorCode,
  errorMessage: string,
};

export enum ErrorCode {
  ACCOMMODATION_INCORRECT = "ACCOMMODATION_INCORRECT",
  ACCOMMODATION_NOT_AVAILABLE = "ACCOMMODATION_NOT_AVAILABLE",
  ACCOMMODATION_PRICE_NOT_AVAILABLE = "ACCOMMODATION_PRICE_NOT_AVAILABLE",
  BOOKING_ALREADY_PAID = "BOOKING_ALREADY_PAID",
  BOOKING_CANCELLED = "BOOKING_CANCELLED",
  BOOKING_EXPIRED = "BOOKING_EXPIRED",
  BOOKING_NOT_FOUND = "BOOKING_NOT_FOUND",
  PAYMENT_VERIFY_FAILED = "PAYMENT_VERIFY_FAILED",
  BOOKING_STATUS_NOT_OPTION = "BOOKING_STATUS_NOT_OPTION",
  CABIN_REQUIRED_PET = "CABIN_REQUIRED_PET",
  NO_AVAIL_PET_CABINS = "NO_AVAIL_PET_CABINS",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  NO_PASSENGER_CAPACITY = "NO_PASSENGER_CAPACITY",
  SAILING_NOT_FOUND = "SAILING_NOT_FOUND",
  SAILING_ONBOARDING = "SAILING_ONBOARDING",
  SAILING_OVERLAPS = "SAILING_OVERLAPS",
  SAILING_LOCKED = "SAILING_LOCKED",
  SERVER_ERROR = "SERVER_ERROR",
  TARIFF_ERROR = "TARIFF_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  VEHICLE_NOT_FOUND = "VEHICLE_NOT_FOUND",
  VEHICLE_REQUIRED = "VEHICLE_REQUIRED",
  OFFERCODE_NO_TARIFF = "OFFERCODE_NO_TARIFF",
  OFFERCODE_NOT_VALID = "OFFERCODE_NOT_VALID",
  NO_PRICE_AVAILABLE = "NO_PRICE_AVAILABLE",
  NO_VEHICLE_SPACE = "NO_VEHICLE_SPACE",
  BOOKING_FLOW_STOPPED = "BOOKING_FLOW_STOPPED",
  DATABASE_ERROR = "DATABASE_ERROR",
  NO_CRUISE_AVAILABLE = "NO_CRUISE_AVAILABLE",
  JS3_CONFIGURATION_ERROR = "JS3_CONFIGURATION_ERROR",
  SALES_CLOSED = "SALES_CLOSED",
  UPDATED_BY_OTHER_USER = "UPDATED_BY_OTHER_USER",
}


export type ReservationVehicleChangeInput = {
  currency: Currency,
  language: Language,
  reservationUrl: string,
  vehicles: Array< VehicleRegistrationInput >,
};

export type VehicleRegistrationInput = {
  id: number,
  legs: Array< VehicleRegistrationLegInput >,
  registrationId: string,
  type: VehicleType,
};

export type VehicleRegistrationLegInput = {
  leg: number,
  newRegistrationId: string,
};

export type ReservationVehicleChangeOrError = ReservationVehicleChange | ApiError


export type ReservationVehicleChange = {
  __typename: "ReservationVehicleChange",
  bookingDetail: BookingDetail,
  vehicles:  Array<BookingVehicle >,
};

export type ReservationEmailInput = {
  currency: Currency,
  email: string,
  language: Language,
  reservationUrl: string,
};

export type ReservationEmailOrError = ReservationEmail | ApiError


export type ReservationEmail = {
  __typename: "ReservationEmail",
  result: string,
};

export type PaymentStartInput = {
  currency: Currency,
  language: Language,
  provider: PaymentProvider,
  reference: string,
};

export enum PaymentProvider {
  NETS_NETAXEPT = "NETS_NETAXEPT",
}


export type PaymentStartOrError = PaymentStart | ApiError | PaymentError


export type PaymentStart = {
  __typename: "PaymentStart",
  redirectUrl: string,
  transactionId: string,
};

export type PaymentError = {
  __typename: "PaymentError",
  paymentErrorCode: PaymentErrorCode,
  paymentErrorMessage: string,
};

export enum PaymentErrorCode {
  CANCELLED_BY_CUSTOMER = "CANCELLED_BY_CUSTOMER",
  REFUSED_BY_ISSUER = "REFUSED_BY_ISSUER",
  REFUSED_BY_ISSUER_ADVICE_CONSUMER = "REFUSED_BY_ISSUER_ADVICE_CONSUMER",
  REFUSED_BY_ISSUER_SUBMIT_AGAIN = "REFUSED_BY_ISSUER_SUBMIT_AGAIN",
  INVALID_TRANSACTION = "INVALID_TRANSACTION",
  INVALID_AMOUNT = "INVALID_AMOUNT",
  EXPIRED_CARD = "EXPIRED_CARD",
  TRANSACTION_ALREADY_PROCESSED = "TRANSACTION_ALREADY_PROCESSED",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
}


export type PaymentVerifyInput = {
  currency: Currency,
  language: Language,
  provider: PaymentProvider,
  reference: string,
  transactionId: string,
};

export type PaymentVerifyOrError = PaymentVerify | ApiError | PaymentError


export type PaymentVerify = {
  __typename: "PaymentVerify",
  bookingDetail: BookingDetail,
  transactionId: string,
};

export type LinesQuery = {
  currency: Currency,
};

export type LineOrError = Line | ApiError


export type Line = {
  __typename: "Line",
  departurePortCode?: string | null,
  departurePortName?: string | null,
  departurePortDesc?: string | null,
  arrivalPortCode?: string | null,
  arrivalPortName?: string | null,
  arrivalPortDesc?: string | null,
  legCode?: string | null,
  legDesc?: string | null,
  legOrder?: number | null,
  sectionOrder?: number | null,
  sectionDesc?: string | null,
  documentYn?: boolean | null,
  hobYn?: boolean | null,
  hobCode?: string | null,
  eventYn?: boolean | null,
  eventCode?: string | null,
  eventDate?: string | null,
  eventTime?: string | null,
  connections?:  Array<LineConnection | null > | null,
};

export type LineConnection = {
  __typename: "LineConnection",
  departurePortCode?: string | null,
  departurePortName?: string | null,
  departurePortDesc?: string | null,
  arrivalPortCode?: string | null,
  arrivalPortName?: string | null,
  arrivalPortDesc?: string | null,
  legCode?: string | null,
  legDesc?: string | null,
  documentYn?: boolean | null,
};

export type ProductsQuery = {
  currency: Currency,
  departurePort: string,
  departureDate: string,
  departureTime: string,
  arrivalPort: string,
  language: Language,
  productType?: ProductType | null,
};

export enum ProductType {
  ACCOMMODATION = "ACCOMMODATION",
  BIKE = "BIKE",
  ELECTRICITY = "ELECTRICITY",
  FOOD = "FOOD",
  PACKAGE = "PACKAGE",
  PASSENGER = "PASSENGER",
  PET = "PET",
  VEHICLE = "VEHICLE",
  WIFI = "WIFI",
  NIGHT = "NIGHT",
  WELLNESS = "WELLNESS",
  COUPON = "COUPON",
  LOUNGE = "LOUNGE",
}


export type ProductOrError = Product | ApiError


export type Product = {
  __typename: "Product",
  code: string,
  type: ProductType,
  subtype?: ProductSubType | null,
  name?: string | null,
  desc?: string | null,
  maxPeople?: number | null,
  maxPets?: number | null,
  available?: ProductAvailability | null,
  exclusive?: boolean | null,
  gender?: PassengerGender | null,
  capacity: Capacity,
  chargePerUnit?: number | null,
  measureUnit: ProductMeasureUnit,
  limitedTo?: Array< PassengerType | null > | null,
};

export enum ProductSubType {
  BED = "BED",
  CABIN = "CABIN",
  CHAIR = "CHAIR",
  BREAKFAST = "BREAKFAST",
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  NIGHTSNACK = "NIGHTSNACK",
  MEAL = "MEAL",
  BRUNCH = "BRUNCH",
  PREMIUM_BREAKFAST = "PREMIUM_BREAKFAST",
  LOUNGE_BREAKFAST = "LOUNGE_BREAKFAST",
  SAUNA = "SAUNA",
  GYM = "GYM",
  SAUNA_AND_GYM = "SAUNA_AND_GYM",
}


export enum ProductAvailability {
  NO = "NO",
  YES = "YES",
  LIMITED = "LIMITED",
}


export type Capacity = {
  __typename: "Capacity",
  available?: number | null,
  real?: number | null,
  sellable?: number | null,
  sold?: number | null,
};

export enum ProductMeasureUnit {
  LENGTH = "LENGTH",
  QUANTITY = "QUANTITY",
}


export type SailingsQuery = {
  currency: Currency,
  departurePort: string,
  arrivalPort: string,
  startDate: string,
  endDate?: string | null,
  numberOfDays?: number | null,
  numberOfDepartures?: number | null,
};

export type SailingOrError = Sailing | ApiError


export type Sailing = {
  __typename: "Sailing",
  sailingCode: string,
  legCode?: string | null,
  shipCode?: string | null,
  shipName?: string | null,
  departureDate: string,
  departureTime: string,
  departurePort: string,
  arrivalDate: string,
  arrivalTime: string,
  arrivalPort: string,
};

export type TimetableQuery = {
  currency: Currency,
  language: Language,
  starclub?: StarclubInput | null,
  tariff: Array< TimetableTariff >,
  sailings: Array< SailingQuote >,
  passengers: Array< SearchPassenger >,
  accommodations: Array< Accommodation >,
  vehicles?: Array< Vehicle > | null,
  pets?: Array< Pet > | null,
  onboards?: Array< Onboard > | null,
};

export type StarclubInput = {
  code: string,
};

export type TimetableTariff = {
  legCode: number,
  type?: Tariff | null,
};

export type SailingQuote = {
  legCode: number,
  departurePort: string,
  arrivalPort: string,
  startDate: string,
  endDate?: string | null,
  numberOfDays?: number | null,
  numberOfDepartures?: number | null,
  offerCode?: string | null,
};

export type SearchPassenger = {
  legCode: number,
  id: number,
  type: PassengerType,
};

export type Accommodation = {
  legCode: number,
  type: string,
  code: string,
  passengers: Array< AccommodationPassenger | null >,
};

export type AccommodationPassenger = {
  id: number,
  type: PassengerType,
};

export type Vehicle = {
  legCode: number,
  type: VehicleType,
  height?: number | null,
  length?: number | null,
};

export type Pet = {
  legCode: number,
  number: number,
  type: PetType,
};

export type Onboard = {
  legCode: number,
  code: string,
  type: string,
  passengerIndex?: Array< number > | null,
};

export type TimetableOrError = Timetable | ApiError


export type Timetable = {
  __typename: "Timetable",
  legCode: string,
  sailingCode: string,
  departureDate: string,
  departureTime: string,
  departurePort: string,
  arrivalDate: string,
  arrivalTime: string,
  arrivalPort: string,
  isAvailable: boolean,
  availabilityReason: string,
  shipCode?: string | null,
  shipName?: string | null,
  moreInfo?: string | null,
  availabilityInfo?: string | null,
  voyageCode?: string | null,
  crossingTime?: string | null,
  chargeTotal?: number | null,
};

export type AccommodationQuoteQuery = {
  alts: Array< AccommodationCodeInput >,
  base: BookingQuoteQuery,
  leg: number,
  accommodationId: number,
};

export type AccommodationCodeInput = {
  type: string,
  code: string,
};

export type BookingQuoteQuery = {
  accommodations: Array< AccommodationInput >,
  agreementActive?: boolean | null,
  currency: Currency,
  language: Language,
  onboards: Array< OnboardInput >,
  passengers: Array< PassengerInput >,
  pets: Array< PetInput >,
  sailings: Array< SailingInput >,
  vehicles: Array< VehicleInput >,
};

export type AccommodationQuoteOrError = AccommodationQuote | ApiError


export type AccommodationQuote = {
  __typename: "AccommodationQuote",
  chargeInfo?: ChargeInfoOrError | null,
  code: string,
  type: string,
};

export type ChargeInfoOrError = ChargeInfo | ApiError


export type BookingQuoteOrError = BookingQuote | ApiError


export type BookingQuote = {
  __typename: "BookingQuote",
  accommodations:  Array<BookingAccommodation >,
  agreement?: Agreement | null,
  onboards:  Array<BookingOnboard >,
  passengers:  Array<BookingPassenger >,
  pets:  Array<BookingPet >,
  sailings:  Array<BookingSailing >,
  total: BookingTotal,
  vehicles:  Array<BookingVehicle >,
};

export type ReservationUrlInput = {
  currency: Currency,
  language: Language,
  reservationUrl: string,
};

export type ReservationOrError = BookingReservation | ApiError


export type ReservationInput = {
  currency: Currency,
  language: Language,
  provider: PaymentProvider,
  reference: string,
  transactionId: string,
};

export type ReservationPdfOrError = ReservationPdf | ApiError


export type ReservationPdf = {
  __typename: "ReservationPdf",
  fileName: string,
  base64Data: string,
};

export type ReserveBookingMutationVariables = {
  input: ReserveBooking,
};

export type ReserveBookingMutation = {
  reserveBooking: ( {
      __typename: "BookingReservation",
      accommodations:  Array< {
        __typename: string,
        code: string,
        type: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        passengers:  Array< {
          __typename: string,
          amount: number,
          type: PassengerType,
        } >,
      } >,
      agreement?:  {
        __typename: string,
        code?: string | null,
        status?: AgreementStatus | null,
        validationCode?: string | null,
      } | null,
      contactInfo:  {
        __typename: string,
        firstName: string,
        lastName: string,
        birthdate?: string | null,
        address: string,
        city: string,
        postCode: string,
        country?: string | null,
        nationality: string,
        email: string,
        countryCallCode?: string | null,
        mobile: string,
        termsOfServiceOk: boolean,
        privacyPolicyOk: boolean,
        newsletterOk: boolean,
      },
      bookingDetail:  {
        __typename: string,
        reference: string,
        referenceHash: string,
        status: BookingStatus,
        date: string,
        time: string,
        expiryDate?: string | null,
      },
      onboards:  Array< {
        __typename: string,
        amount: number,
        type: string,
        code: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
      } >,
      passengers:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PassengerType,
      } >,
      passengerDetails:  Array< {
        __typename: string,
        id: number,
        title?: string | null,
        firstName: string,
        lastName: string,
        birthdate: string,
        age: number,
        gender: PassengerGender,
        nationality: string,
        type: PassengerType,
        document?:  {
          __typename: string,
          type?: string | null,
          number?: string | null,
          nationality?: string | null,
          expiryDate?: string | null,
        } | null,
      } >,
      pets:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PetType,
      } >,
      sailings:  Array< {
        __typename: string,
        leg:  {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        },
        departureDate: string,
        departureTime: string,
        departurePortCode: string,
        departurePortName?: string | null,
        departurePortDesc?: string | null,
        arrivalDate?: string | null,
        arrivalTime?: string | null,
        arrivalPortCode: string,
        arrivalPortName?: string | null,
        arrivalPortDesc?: string | null,
        eventCode?: string | null,
        offerCode?: string | null,
        shipCode?: string | null,
        shipName?: string | null,
        shipDesc?: string | null,
        sailingCode: string,
        tariff: Tariff,
      } >,
      total:  {
        __typename: string,
        total?: number | null,
        commission?: number | null,
        tax?: number | null,
      },
      vehicles:  Array< {
        __typename: string,
        id: number,
        type: VehicleType,
        lengthCm?: number | null,
        heightCm?: number | null,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        mark?: string | null,
        model?: string | null,
        registrationId?: string | null,
      } >,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};

export type ChangeReservationVehiclesMutationVariables = {
  input: ReservationVehicleChangeInput,
};

export type ChangeReservationVehiclesMutation = {
  changeReservationVehicles: ( {
      __typename: "ReservationVehicleChange",
      bookingDetail:  {
        __typename: string,
        reference: string,
        referenceHash: string,
        status: BookingStatus,
        date: string,
        time: string,
        expiryDate?: string | null,
      },
      vehicles:  Array< {
        __typename: string,
        id: number,
        type: VehicleType,
        lengthCm?: number | null,
        heightCm?: number | null,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        mark?: string | null,
        model?: string | null,
        registrationId?: string | null,
      } >,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};

export type SendReservationEmailMutationVariables = {
  input: ReservationEmailInput,
};

export type SendReservationEmailMutation = {
  sendReservationEmail: ( {
      __typename: "ReservationEmail",
      result: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};

export type StartPaymentMutationVariables = {
  input: PaymentStartInput,
};

export type StartPaymentMutation = {
  startPayment: ( {
      __typename: "PaymentStart",
      redirectUrl: string,
      transactionId: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    } | {
      __typename: "PaymentError",
      paymentErrorCode: PaymentErrorCode,
      paymentErrorMessage: string,
    }
  ),
};

export type VerifyPaymentMutationVariables = {
  input: PaymentVerifyInput,
};

export type VerifyPaymentMutation = {
  verifyPayment: ( {
      __typename: "PaymentVerify",
      bookingDetail:  {
        __typename: string,
        reference: string,
        referenceHash: string,
        status: BookingStatus,
        date: string,
        time: string,
        expiryDate?: string | null,
      },
      transactionId: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    } | {
      __typename: "PaymentError",
      paymentErrorCode: PaymentErrorCode,
      paymentErrorMessage: string,
    }
  ),
};

export type ListLinesAvailabilityQueryVariables = {
  query: LinesQuery,
};

export type ListLinesAvailabilityQuery = {
  listLinesAvailability:  Array<( {
      __typename: "Line",
      departurePortCode?: string | null,
      departurePortName?: string | null,
      departurePortDesc?: string | null,
      arrivalPortCode?: string | null,
      arrivalPortName?: string | null,
      arrivalPortDesc?: string | null,
      legCode?: string | null,
      legDesc?: string | null,
      legOrder?: number | null,
      sectionOrder?: number | null,
      sectionDesc?: string | null,
      documentYn?: boolean | null,
      hobYn?: boolean | null,
      hobCode?: string | null,
      eventYn?: boolean | null,
      eventCode?: string | null,
      eventDate?: string | null,
      eventTime?: string | null,
      connections?:  Array< {
        __typename: string,
        departurePortCode?: string | null,
        departurePortName?: string | null,
        departurePortDesc?: string | null,
        arrivalPortCode?: string | null,
        arrivalPortName?: string | null,
        arrivalPortDesc?: string | null,
        legCode?: string | null,
        legDesc?: string | null,
        documentYn?: boolean | null,
      } | null > | null,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type ListProductsAvailabilityQueryVariables = {
  query: ProductsQuery,
};

export type ListProductsAvailabilityQuery = {
  listProductsAvailability:  Array<( {
      __typename: "Product",
      code: string,
      type: ProductType,
      subtype?: ProductSubType | null,
      name?: string | null,
      desc?: string | null,
      maxPeople?: number | null,
      maxPets?: number | null,
      available?: ProductAvailability | null,
      exclusive?: boolean | null,
      gender?: PassengerGender | null,
      capacity:  {
        __typename: string,
        available?: number | null,
        real?: number | null,
        sellable?: number | null,
        sold?: number | null,
      },
      chargePerUnit?: number | null,
      measureUnit: ProductMeasureUnit,
      limitedTo?: Array< PassengerType | null > | null,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type ListSailingsAvailabilityQueryVariables = {
  query: SailingsQuery,
};

export type ListSailingsAvailabilityQuery = {
  listSailingsAvailability:  Array<( {
      __typename: "Sailing",
      sailingCode: string,
      legCode?: string | null,
      shipCode?: string | null,
      shipName?: string | null,
      departureDate: string,
      departureTime: string,
      departurePort: string,
      arrivalDate: string,
      arrivalTime: string,
      arrivalPort: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type ListTimeTableAvailabilityQueryVariables = {
  query: TimetableQuery,
};

export type ListTimeTableAvailabilityQuery = {
  listTimeTableAvailability:  Array<( {
      __typename: "Timetable",
      legCode: string,
      sailingCode: string,
      departureDate: string,
      departureTime: string,
      departurePort: string,
      arrivalDate: string,
      arrivalTime: string,
      arrivalPort: string,
      isAvailable: boolean,
      availabilityReason: string,
      shipCode?: string | null,
      shipName?: string | null,
      moreInfo?: string | null,
      availabilityInfo?: string | null,
      voyageCode?: string | null,
      crossingTime?: string | null,
      chargeTotal?: number | null,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type QuoteAccommodationsQueryVariables = {
  query: AccommodationQuoteQuery,
};

export type QuoteAccommodationsQuery = {
  quoteAccommodations:  Array<( {
      __typename: "AccommodationQuote",
      chargeInfo: ( {
          __typename: "ChargeInfo",
          charge: number,
          discount: number,
          discountDesc?: string | null,
          discountStarClub?: boolean | null,
          tax?: number | null,
          taxes?:  Array< {
            __typename: string,
            taxcode?: string | null,
            taxtype?: string | null,
            taxdescription?: string | null,
            taxamount: number,
          } > | null,
        } | {
          __typename: "ApiError",
          errorCode: ErrorCode,
          errorMessage: string,
          productCode?: string | null,
        }
      ) | null,
      code: string,
      type: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type QuoteBookingQueryVariables = {
  query: BookingQuoteQuery,
};

export type QuoteBookingQuery = {
  quoteBooking:  Array<( {
      __typename: "BookingQuote",
      accommodations:  Array< {
        __typename: string,
        code: string,
        type: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        passengers:  Array< {
          __typename: string,
          amount: number,
          type: PassengerType,
        } >,
      } >,
      agreement?:  {
        __typename: string,
        code?: string | null,
        status?: AgreementStatus | null,
        validationCode?: string | null,
      } | null,
      onboards:  Array< {
        __typename: string,
        amount: number,
        type: string,
        code: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
      } >,
      passengers:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PassengerType,
      } >,
      pets:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PetType,
      } >,
      sailings:  Array< {
        __typename: string,
        leg:  {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        },
        departureDate: string,
        departureTime: string,
        departurePortCode: string,
        departurePortName?: string | null,
        departurePortDesc?: string | null,
        arrivalDate?: string | null,
        arrivalTime?: string | null,
        arrivalPortCode: string,
        arrivalPortName?: string | null,
        arrivalPortDesc?: string | null,
        eventCode?: string | null,
        offerCode?: string | null,
        shipCode?: string | null,
        shipName?: string | null,
        shipDesc?: string | null,
        sailingCode: string,
        tariff: Tariff,
      } >,
      total:  {
        __typename: string,
        total?: number | null,
        commission?: number | null,
        tax?: number | null,
      },
      vehicles:  Array< {
        __typename: string,
        id: number,
        type: VehicleType,
        lengthCm?: number | null,
        heightCm?: number | null,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        mark?: string | null,
        model?: string | null,
        registrationId?: string | null,
      } >,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ) >,
};

export type GetReservationQueryVariables = {
  query: ReservationUrlInput,
};

export type GetReservationQuery = {
  getReservation: ( {
      __typename: "BookingReservation",
      accommodations:  Array< {
        __typename: string,
        code: string,
        type: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        passengers:  Array< {
          __typename: string,
          amount: number,
          type: PassengerType,
        } >,
      } >,
      agreement?:  {
        __typename: string,
        code?: string | null,
        status?: AgreementStatus | null,
        validationCode?: string | null,
      } | null,
      contactInfo:  {
        __typename: string,
        firstName: string,
        lastName: string,
        birthdate?: string | null,
        address: string,
        city: string,
        postCode: string,
        country?: string | null,
        nationality: string,
        email: string,
        countryCallCode?: string | null,
        mobile: string,
        termsOfServiceOk: boolean,
        privacyPolicyOk: boolean,
        newsletterOk: boolean,
      },
      bookingDetail:  {
        __typename: string,
        reference: string,
        referenceHash: string,
        status: BookingStatus,
        date: string,
        time: string,
        expiryDate?: string | null,
      },
      onboards:  Array< {
        __typename: string,
        amount: number,
        type: string,
        code: string,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
      } >,
      passengers:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PassengerType,
      } >,
      passengerDetails:  Array< {
        __typename: string,
        id: number,
        title?: string | null,
        firstName: string,
        lastName: string,
        birthdate: string,
        age: number,
        gender: PassengerGender,
        nationality: string,
        type: PassengerType,
        document?:  {
          __typename: string,
          type?: string | null,
          number?: string | null,
          nationality?: string | null,
          expiryDate?: string | null,
        } | null,
      } >,
      pets:  Array< {
        __typename: string,
        id: number,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        type: PetType,
      } >,
      sailings:  Array< {
        __typename: string,
        leg:  {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        },
        departureDate: string,
        departureTime: string,
        departurePortCode: string,
        departurePortName?: string | null,
        departurePortDesc?: string | null,
        arrivalDate?: string | null,
        arrivalTime?: string | null,
        arrivalPortCode: string,
        arrivalPortName?: string | null,
        arrivalPortDesc?: string | null,
        eventCode?: string | null,
        offerCode?: string | null,
        shipCode?: string | null,
        shipName?: string | null,
        shipDesc?: string | null,
        sailingCode: string,
        tariff: Tariff,
      } >,
      total:  {
        __typename: string,
        total?: number | null,
        commission?: number | null,
        tax?: number | null,
      },
      vehicles:  Array< {
        __typename: string,
        id: number,
        type: VehicleType,
        lengthCm?: number | null,
        heightCm?: number | null,
        legs:  Array< {
          __typename: string,
          leg: number,
          chargeInfo:  {
            __typename: string,
            charge: number,
            discount: number,
            discountDesc?: string | null,
            discountStarClub?: boolean | null,
            tax?: number | null,
            taxes?:  Array< {
              __typename: string,
              taxcode?: string | null,
              taxtype?: string | null,
              taxdescription?: string | null,
              taxamount: number,
            } > | null,
          },
        } >,
        mark?: string | null,
        model?: string | null,
        registrationId?: string | null,
      } >,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};

export type GetReservationPdfQueryVariables = {
  query: ReservationInput,
};

export type GetReservationPdfQuery = {
  getReservationPdf: ( {
      __typename: "ReservationPdf",
      fileName: string,
      base64Data: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};

export type GetReservationPdfForUrlQueryVariables = {
  query: ReservationUrlInput,
};

export type GetReservationPdfForUrlQuery = {
  getReservationPdfForUrl: ( {
      __typename: "ReservationPdf",
      fileName: string,
      base64Data: string,
    } | {
      __typename: "ApiError",
      errorCode: ErrorCode,
      errorMessage: string,
      productCode?: string | null,
    }
  ),
};
