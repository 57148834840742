import React from 'react';
import styled from 'styled-components';

import { color } from '../../helpers/vars';

import { P } from '../Text';

const List = styled.div`
  margin: 0;
  gap: 0 1rem;
`;

const StyledListTitle = styled(P)`
  margin-bottom: 0;
  color: ${color.text.heading};

  ${List} ~ & {
    margin-top: 2rem;
  }
`;

export const ListTitle: React.FunctionComponent = (props) => <StyledListTitle as="h3" strong {...props} />;

export default List;
