import RichText from '../../../../design-system/components/RichText';
import styled from 'styled-components';
import { typography } from '../../../../design-system/helpers/mixins';

const OnboardDescription = styled(RichText)`
  p {
    ${typography.lead}
  }
`;

export default OnboardDescription;
