import React from 'react';
import styled from 'styled-components';

import { P } from '../Text';
import Icon from '../Icon';

import Item, { ItemProps } from './Item';

export interface EventItemProps extends ItemProps {
  readonly variant: 'meal';
}

const PaddedIcon = styled(Icon)`
  margin: 0 0.5rem 0 0;
`;

const EventItem = React.forwardRef<HTMLParagraphElement, EventItemProps>(({ time, children, variant }, ref) => (
  <Item ref={ref} {...{ time }}>
    <PaddedIcon name={variant} />
    <P inline strong as="span">
      {children}
    </P>
  </Item>
));

export default EventItem;
