import StoryblokClient, { StoryData } from 'storyblok-js-client';
import * as API from '../fsm/types';
import { Settings } from '../settings';
import parseSettings from '../settings/parse';
import { DatasourceEntry, Datasources, Tree, Version } from './types';

export * from './types';

export const storyblokAccessToken = process.env.REACT_APP_STORYBLOK_API_KEY;
const defaultVersion = (process.env.REACT_APP_STORYBLOK_VERSION || 'published') as Version;

export const client = new StoryblokClient({
  accessToken: storyblokAccessToken,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

function omit<T extends object, K extends [...(keyof T | string)[]]>(obj: T, ...omitted: K) {
  return Object.keys(obj)
    .filter((key) => !omitted.includes(key as keyof T))
    .reduce(
      (result, key) => ({ ...result, [key]: obj[key as keyof T] }),
      {} as { [E in Exclude<keyof T, K[number]>]: T[E] }
    );
}

export function parseTree(stories: StoryData[]): Tree {
  return stories.reduce((tree, story) => {
    const minimalStory = omit(
      story,
      'alternates',
      'created_at',
      'default_full_slug',
      'first_published_at',
      'group_id',
      'id',
      'is_startpage',
      'meta_data',
      'parent_id',
      'published_at',
      'release_id',
      'sort_by_date',
      'tag_list',
      'translated_slugs'
    );

    if (Array.isArray(story.content.body)) {
      const { _uid, body, ...other } = story.content;

      const children = (body as any[]).reduce(
        (all, { component, ...content }) => ({ ...all, [component]: { content } }),
        {}
      );

      return {
        ...tree,
        [story.full_slug]: { ...minimalStory, content: { ...other, ...children } },
      };
    } else {
      const { _uid, ...content } = story.content;

      return { ...tree, [story.full_slug]: { ...minimalStory, content } };
    }
  }, {} as Tree);
}

export async function fetchDatasourceEntries({
  language,
  version,
}: {
  language: string;
  version?: Version;
}): Promise<Datasources> {
  const versionOfDefault = version || defaultVersion;

  console.log(`Storyblok: fetching ${versionOfDefault} datasources for ${language}.`);

  const datasources = await client.getAll('cdn/datasources', { version: versionOfDefault });

  // Find the dimension that matches selected language and fetch values for it.
  const datasourcesWithAllValues = await Promise.all(
    datasources.map(async (ds: any) => {
      const dimension = ds.dimensions.find((_: any) => _.entry_value === language);
      return client
        .getAll('cdn/datasource_entries', {
          datasource: ds.slug,
          dimension: dimension ? dimension.entry_value : null, // Defaults to English.
          per_page: 1000,
        })
        .then((entries: DatasourceEntry[]) => {
          const valuesByName = entries.reduce(
            (all, entry) => ({ ...all, [entry.name]: entry.dimension_value || entry.value }),
            {}
          );

          return { [ds.slug]: valuesByName };
        });
    })
  );

  return datasourcesWithAllValues.reduce((obj, source) => ({ ...obj, ...source }));
}

export async function fetchStoryblokData({
  language,
  version,
}: {
  language: API.Language;
  version?: Version;
}): Promise<[Tree, Datasources]> {
  const versionOfDefault = version || defaultVersion;
  const storyblokLanguage = language.toLowerCase();

  console.log(`Storyblok: fetching ${versionOfDefault} stories for ${storyblokLanguage}.`);

  const storiesPromise = client
    .getAll('cdn/stories', {
      language: storyblokLanguage,
      per_page: 100,
      starts_with: storyblokLanguage === 'en' ? undefined : `${storyblokLanguage}/*`,
      version: versionOfDefault,
    })
    .then(parseTree);

  const datasourceEntriesPromise = fetchDatasourceEntries({
    version,
    language: storyblokLanguage,
  });

  return Promise.all([storiesPromise, datasourceEntriesPromise]);
}

export async function fetchStoryblokSettings({
  env,
  version,
}: {
  env: 'prod' | 'test' | 'dev';
  version?: Version;
}): Promise<Settings> {
  const versionOfDefault = version || defaultVersion;

  console.log(`Storyblok: fetching ${versionOfDefault} settings.`);

  const datasourceEntriesPromise = fetchDatasourceEntries({
    version,
    language: env,
  });

  const { settings } = await datasourceEntriesPromise;

  return parseSettings({ settings });
}

export function storyblokChangeHandler({ version }: { version?: Version }, onNewStory: (tree: Tree) => void) {
  return (event?: StoryblokEventPayload) => {
    if (event && event.slug) {
      return client
        .get(`cdn/stories/${event.slug}`, { version: version || defaultVersion })
        .then(({ data }: { data: { story: StoryData } }) => {
          if (data.story) {
            onNewStory(parseTree([data.story]));
          }
        })
        .catch(console.error);
    } else {
      return Promise.resolve({});
    }
  };
}
