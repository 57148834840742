import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem, MenuButton, useMenuState } from 'reakit/Menu';
import Measure, { ContentRect } from 'react-measure';

import { Small } from '../Text';
import Icon from '../Icon';

import Option, { Title } from './Option';
import Item, { SuboptionType } from './Item';
import Illustration from './Illustration';

interface BranchProps {
  readonly example?: string;
  readonly name: string;
  readonly suboptions: SuboptionType[];
  readonly handleSelection: (productcode: string, uuid: string) => void;
  readonly setBranchHeight: (arg: number) => void;
  readonly isSelectable?: (productcode: string) => boolean;
}

const StyledMenu = styled(Menu)`
  z-index: 1;
  transform: none !important;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* Force menu placement inside modal overriding Reakit placement */
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  bottom: auto !important;

  &:focus {
    outline: none;
  }
`;

const Branch: React.FunctionComponent<BranchProps> = React.forwardRef(
  ({ example, name, suboptions, handleSelection, setBranchHeight, isSelectable, ...props }, ref: any) => {
    const [height, setHeight] = useState(0);
    const menu = useMenuState();

    useEffect(() => setBranchHeight(menu.visible ? height : 0), [setBranchHeight, height, menu.visible]);

    const measure: (arg: ContentRect) => void = ({ offset }) => setHeight(offset?.height || 0);

    const onSelect = (selection: string, uuid: string) => {
      menu.hide();
      handleSelection(selection, uuid);
    };

    const labelId = `${menu.baseId}-label`;

    const focusRef = useRef<HTMLElement>();

    useEffect(() => {
      if (menu.visible) {
        focusRef?.current?.focus();
      }
    }, [menu.visible]);

    return (
      <>
        <MenuButton ref={ref} as={Option} {...menu} {...props} disabled={menu.visible}>
          <Item {...{ example, name }} />
          <Icon name="right" />
        </MenuButton>
        <Measure offset onResize={measure}>
          {({ measureRef }) => (
            <StyledMenu {...menu} aria-labelledby={labelId} ref={measureRef} hideOnClickOutside={false}>
              <Title onClick={menu.hide}>
                <Icon name="left" label="back" />
                <Small as="h2" id={labelId}>
                  {name}
                </Small>
              </Title>
              {suboptions.map((suboption, i) => (
                <MenuItem
                  as={Option}
                  {...menu}
                  key={i}
                  {...(i === 0 && { ref: focusRef })}
                  onClick={() =>
                    isSelectable &&
                    isSelectable(suboption.productcode) &&
                    onSelect(suboption.productcode, suboption.uuid)
                  }
                  {...(isSelectable && !isSelectable(suboption.productcode) && { 'aria-disabled': true })}
                >
                  <Item {...suboption} />
                  {'illustration' in suboption && suboption.illustration && (
                    <Illustration name={suboption.illustration} />
                  )}
                </MenuItem>
              ))}
            </StyledMenu>
          )}
        </Measure>
      </>
    );
  }
);

export default Branch;
