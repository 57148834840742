import React, { FunctionComponent } from 'react';

import { StyledInput } from '../../helpers/components';

import { InputSelectStateReturn } from './useInputGroupState';
import Wrapper, { validation } from './Wrapper';

const InputSelect: FunctionComponent<InputSelectStateReturn & { defaultValue?: string }> = ({
  htmlFor,
  label,
  error,
  hint,
  gridSpan,
  ...props
}) => (
  <Wrapper {...{ htmlFor, label, error, hint, gridSpan }} select>
    <StyledInput.Input as="select" id={htmlFor} {...props} {...validation(error, htmlFor)} />
  </Wrapper>
);

export default InputSelect;
