import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../design-system/components/Checkbox';
import Container, { Subgrid } from '../../../design-system/components/Container';
import RichText from '../../../design-system/components/RichText';
import { H2 } from '../../../design-system/components/Text';
import { Theme } from '../../../design-system/helpers/components';
import { hooks, style } from '../../../design-system/helpers/mixins';
import { ExtendedPassenger, isPassengerInput, Trip, TripType } from '../../../fsm/types';

interface TermsProps {
  trip: Trip;
  send: any;
  error?: string;
}

const Line = styled('div')`
  border-bottom: ${style.border.default};
`;

const DenserGrid = styled(Subgrid)`
  grid-gap: 0;
`;

const CenteredGrid = styled(DenserGrid)`
  text-align: center;
`;

const Terms: FunctionComponent<TermsProps> = ({ trip, send, error }) => {
  const [dataConsent, setDataConsent] = useState(
    () =>
      (trip.passengers.find((p) => isPassengerInput(p) && p.info?.reserverInfo) as ExtendedPassenger)?.info
        ?.reserverInfo?.termsOfServiceOk || false
  );

  useEffect(() => {
    send({
      type: 'SET_CONSENT',
      dataConsent,
    });
  }, [dataConsent, send]);

  const [sbTerms, termsRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_terms',
  });
  const [sbTermsNight] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_terms_night',
  });

  //Transfer these
  enum NIGHT_PRODUCTS {
    NIGHT_A = 'NIGHT_A',
    NIGHT_J = 'NIGHT_J',
    NIGHT_C = 'NIGHT_C',
    NIGHT_I = 'NIGHT_I',
  }

  //Use sbTermsNight if triptype is OVERNIGHT_CRUISE or if trip contains NIGHT_ products
  const useTerms =
    trip.type === TripType.OVERNIGHT_CRUISE ||
    trip.sailings.some(
      (sailing) =>
        typeof sailing.sailing.onboards !== 'string' &&
        sailing.sailing.onboards &&
        sailing.sailing.onboards.some((onboard) => onboard.code in NIGHT_PRODUCTS)
    )
      ? sbTermsNight
      : sbTerms;

  const richtextSubtitle = hooks.useStoryblokRichText(useTerms?.content.subtitle);
  const richtextParagraph = hooks.useStoryblokRichText(useTerms?.content.terms_text);
  const richtextAdditional = hooks.useStoryblokRichText(useTerms?.content.additional_information);

  return (
    <Theme.Default>
      <Container variant="text" ref={termsRef}>
        <DenserGrid variant="default">
          <H2>{useTerms?.content.title}</H2>
        </DenserGrid>
        <DenserGrid variant="default">
          <RichText>{richtextSubtitle}</RichText>
          <RichText>{richtextParagraph}</RichText>
          <Line />
          {richtextAdditional && <RichText>{richtextAdditional}</RichText>}
          {richtextAdditional && <Line />}
        </DenserGrid>
        <CenteredGrid>
          <Checkbox
            gridSpan={2}
            value="dataConsent"
            id="dataconsent"
            checked={dataConsent}
            onChange={setDataConsent}
            required={error ? true : false}
            emphasize
            inline
          >
            {useTerms?.content.checkbox}
          </Checkbox>
        </CenteredGrid>
      </Container>
    </Theme.Default>
  );
};

export default Terms;
