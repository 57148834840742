import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDialogState } from 'reakit/Dialog';

import { measurement } from '../helpers/vars';

import Stepper, { InputProps } from './Stepper';
import Modal from './Modal';
import InputDisclosure from './InputDisclosure';
import { P } from './Text';
import { hooks } from '../../design-system/helpers/mixins';

interface Control extends InputProps {
  readonly label: string;
  readonly description?: string;
}

interface GroupParentProps {
  readonly labelSingle: string;
  readonly labelMultiple: string;
  readonly controls: Control[];
}

interface GroupProps extends GroupParentProps {
  readonly focusRef?: any;
}

interface SelectProps {
  readonly groups: GroupParentProps[];
  readonly submit: string;
  readonly placeholder: string;
}

const GroupWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: ${measurement.size.input.select};
  max-width: 100%;

  & + & {
    margin-top: ${measurement.space.input.stepper.group};
  }
`;

const Group: React.FunctionComponent<GroupProps> = ({ controls, focusRef }) => (
  <GroupWrapper>
    {controls.map((c, i) => {
      const { label, description, ...props } = c;
      return (
        <Stepper key={i} {...(focusRef && i === 0 && { focusRef })} {...props}>
          {label}
          {description && (
            <>
              {' '}
              <P as="span" inline>
                {description}
              </P>
            </>
          )}
        </Stepper>
      );
    })}
  </GroupWrapper>
);

const SelectCount: React.FunctionComponent<SelectProps> = ({ groups, children, submit, placeholder }) => {
  const modalState = useDialogState({ animated: false });
  const [value, setValue] = useState('');
  const focusRef = useRef<HTMLElement>();

  useEffect(() => {
    const getValue = () => {
      const values = groups
        .map((g) => {
          const sum = g.controls.reduce((acc, c) => acc + c.value, 0);

          return sum > 0 ? `${sum} ${sum === 1 ? g.labelSingle : g.labelMultiple}` : null;
        })
        .filter((v) => v !== null);

      return values.length ? values.join(', ') : placeholder;
    };

    setValue(getValue());
  }, [groups, placeholder]);

  useEffect(() => {
    const focusElement = focusRef.current;

    if (focusElement && modalState.visible) {
      focusElement.focus();
    }
  }, [modalState.visible]);

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  const label = sbSearchForm?.content['passengers_and_pets'];

  return (
    <>
      <InputDisclosure {...{ value, modalState, label }} />
      <Modal state={modalState} title={children} {...{ submit }}>
        {(modalState.animating || modalState.visible) &&
          groups.map((g, i) => <Group key={i} {...g} {...(i === 0 && { focusRef })} />)}
      </Modal>
    </>
  );
};

export default SelectCount;
