import { assign, createMachine, Interpreter, State } from 'xstate';
import guards from './guards';
import { SearchParams, Trip } from './types';

export interface AppContext {
  errorMsg: string;
  searchParams?: SearchParams;
  trip?: Trip;
}

export type AppEvent =
  | { type: 'INITIALDONE' }
  | { type: '/search' }
  | { type: '/catalog'; searchParams: SearchParams }
  | { type: '/payment'; trip: Trip };

export interface AppStateSchema {
  states: {
    search: {};
    catalog: {};
    payment: {};
  };
}

export type AppTypestates =
  | {
      value: 'search';
      context: AppContext;
    }
  | { value: 'catalog'; context: AppContext & { searchParams: SearchParams } }
  | { value: 'payment'; context: AppContext & { searchParams: SearchParams; trip: Trip } };

export type AppState = State<AppContext, AppEvent, AppStateSchema>;
export type AppInterpreter = Interpreter<AppContext, AppStateSchema, AppEvent>;

export const stateMachine = createMachine<AppContext, AppEvent, AppTypestates>(
  {
    id: 'finnlines',
    initial: 'search',
    context: {
      errorMsg: '',
    },
    states: {
      search: {},
      catalog: {},
      payment: {},
    },
    on: {
      '/search': {
        target: 'search',
      },
      '/catalog': {
        target: 'catalog',
        actions: assign<AppContext, Extract<AppEvent, { type: '/catalog' }>>((ctx, event) => ({
          searchParams: event.searchParams || ctx.searchParams,
        })),
      },
      '/payment': {
        target: 'payment',
        actions: assign<AppContext, Extract<AppEvent, { type: '/payment' }>>((ctx, event) => {
          return {
            trip: event.trip || ctx.trip,
          };
        }),
      },
    },
  },
  {
    guards,
  }
);
