import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useMenuState, Menu, MenuItem, MenuButton, MenuArrow } from 'reakit/Menu';

import { color, measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';
import { Transition } from '../../helpers/components';

import Button, { ButtonProps, StyledButton } from '../Button';
import Icon, { SVG } from '../Icon';

interface WrapperProps {
  readonly dragging?: boolean;
}

export interface MenuWrapperProps {
  readonly unwrapped?: boolean;
}

interface ItemProps extends ButtonProps, MenuWrapperProps {
  readonly id: any;
  readonly actions: ButtonProps[];
  readonly innerRef?: any;
  readonly setMenuState?: (arg: boolean) => void;
}

const Wrapper = styled.li<WrapperProps>`
  margin: 0.25rem;
  transition: ${animation.transition('margin')};

  ${StyledButton} span {
    display: flex;
    justify-content: space-between;

    ${SVG} {
      margin-right: -${measurement.space.icon.button};
    }
  }

  ${(props) =>
    props.dragging &&
    css`
      margin: 0;

      ${StyledButton} {
        box-shadow: ${style.shadow.drag};
      }
    `}
`;

const StyledMenuArrow = styled(MenuArrow)`
  fill: ${color.bg.alt};
  stroke: ${color.border.transparent};
  margin: -${measurement.size.border.default};
  z-index: 0;
  position: absolute;
  transform: translateX(-50%) !important;
  left: 50% !important;
`;

const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
  background: ${color.bg.alt};
  padding: ${measurement.inset.menu.container};
  border: ${style.border.placeholder};
  transition: ${animation.transition('border')};
  margin: 1rem -${measurement.inset.group.s} 0.5rem;

  ${StyledButton} {
    margin: ${measurement.space.menu.item};
  }

  ${responsive.fontSize(css`
    margin: 1rem -${measurement.inset.group.l} 0.5rem;
  `)}

  ${(props) =>
    props.unwrapped
      ? css`
          border-radius: ${measurement.size.radius.default};
        `
      : css`
          border-width: ${measurement.size.border.default} 0;
        `}
`;

const StyledMenu = styled(Menu)`
  position: relative !important;
  z-index: 1;
  transform: none !important;

  ${MenuWrapper} {
    border-color: ${color.border.default};
  }

  ${StyledMenuArrow} .stroke {
    fill: ${color.border.default};
  }

  &:focus,
  &:focus-within {
    outline: none;
  }
`;

const WithActions: React.FunctionComponent<ItemProps> = ({
  actions,
  id,
  unwrapped,
  setMenuState,
  icon,
  children,
  ...props
}) => {
  const state = useMenuState({ animated: true });
  useEffect(() => setMenuState && setMenuState(state.visible), [state.visible, setMenuState]);

  const disclosureId = `${state.baseId}-disclosure`;

  return (
    <>
      <MenuButton
        id={disclosureId}
        align="left"
        small
        disclosure
        fullWidth
        as={Button}
        {...{ ...state, ...props, icon }}
      >
        {children}
        <Icon name="dnd" />
      </MenuButton>
      <StyledMenu {...state} aria-labelledby={disclosureId}>
        <Transition.Height
          show={state.visible}
          as={MenuWrapper}
          onRest={state.stopAnimation}
          margin={['1rem', '0.5rem']}
          padding="0.5rem"
          {...{ unwrapped }}
        >
          <StyledMenuArrow {...state} size="2rem" />
          {actions.map(({ onClick, ...rest }, i) => (
            <MenuItem
              align="left"
              key={`${i}`}
              small
              secondary
              as={Button}
              {...{ ...rest, ...state }}
              onClick={() => {
                state.hide();
                onClick && onClick(id);
              }}
            />
          ))}
        </Transition.Height>
      </StyledMenu>
    </>
  );
};

const Item: React.FunctionComponent<ItemProps & WrapperProps> = ({ actions, innerRef, dragging, ...rest }) => (
  <Wrapper ref={innerRef} {...{ dragging }}>
    {actions && actions.length ? (
      <WithActions {...{ actions, ...rest }} />
    ) : (
      <Button align="left" fullWidth small {...rest} />
    )}
  </Wrapper>
);

export default Item;
