import styled, { css } from 'styled-components';
import { math } from 'polished';

import { color, measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';

import Label from '../Label';

import { StyledTagArea } from './TagArea';
import { StyledButtonArea } from './ButtonArea';
import { StyledImageArea } from './ImageArea';
import { StyledPriceArea } from './PriceArea';
import Content from './Content';

interface WrapperProps {
  vertical?: boolean;

  hasImage?: boolean;
  hasButton?: boolean;
  hasTags?: boolean;

  collapsedHeight?: number;
}

const Wrapper = styled.div<WrapperProps>`
  ${style.card.wrapper}

  grid:
    'tags' auto
    'content' auto
    'price' auto
    'button' auto / auto;

  ${StyledButtonArea}, ${StyledTagArea} {
    border-radius: ${measurement.size.radius.default};
  }

  ${StyledButtonArea} {
    grid-area: button;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  ${StyledTagArea} {
    padding: 0.5rem;
    grid-area: tags;
    border: ${style.border.placeholder};
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${Label} {
    position: absolute;
    right: -0.5rem;
    top: -0.25rem;
  }

  ${StyledImageArea} {
    transition: ${animation.transition('border-radius')};
    grid-area: image;
    border-radius: ${measurement.size.radius.default};
    position: relative;

    & ~ ${Content} {
      border-top-left-radius: 0;
    }
  }

  ${StyledPriceArea} {
    grid-area: price;
    border: ${style.border.default};
    border-top: 0;
    border-bottom: 0;
  }

  ${({ hasButton }) =>
    hasButton &&
    css`
      ${Content} {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 0;
      }
    `}

  ${({ hasTags }) =>
    hasTags &&
    css`
      ${Content} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `}

  ${(props) =>
    props.hasImage &&
    (props.vertical
      ? css`
          overflow: hidden;
          grid:
            'tags' auto
            'image' 8rem
            'content' 1fr
            'price' auto
            'button' auto / auto;

          ${StyledImageArea} {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          ${Content} {
            border-top: 0;
            border-top-right-radius: 0;
          }

          ${Label} {
            ${style.label.bg(color.bg.label.overlay)}
            top: 8rem;
            transform: translateY(-100%);
            padding-right: 1.5rem;
          }
        `
      : css`
          grid:
            'tags tags' auto
            'image content' 1fr
            'image price' auto
            'button button' auto / 1fr 2fr;

          ${Content} {
            border-left: 0;
            border-bottom-left-radius: 0;
          }

          ${StyledImageArea} {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;

            ${props.hasTags &&
            css`
              border-top-left-radius: 0;
            `}

            ${props.hasButton &&
            css`
              border-bottom-left-radius: 0;
            `}
          }

          &.open ${StyledImageArea} {
            border-bottom-right-radius: ${measurement.size.radius.default};
          }

          ${props.collapsedHeight &&
          css`
            grid:
              'tags tags' auto
              'image content' calc(
                ${props.collapsedHeight}px + 0.75rem + ${math(`${measurement.size.border.default} * 2`)}
              )
              'space content' auto
              'button button' auto / 1fr 2fr;

            ${responsive.fontSize(css`
              grid-template-rows:
                auto
                calc(${props.collapsedHeight}px + 1rem + ${math(`${measurement.size.border.default} * 2`)})
                auto;
            `)}

            &::after {
              content: '';
              grid-area: space;
              border-left: ${style.border.default};
            }
          `}
        `)}
`;

export default Wrapper;
