import React, { FunctionComponent } from 'react';

import SailingSelect, { SailingSelectProps } from './SailingSelect';
import TripOptions, { TripOptionsProps } from './TripOptions';

interface TripCatalogProps extends SailingSelectProps, TripOptionsProps {}

const TripCatalog: FunctionComponent<TripCatalogProps> = ({
  accommodationProducts,
  accommodations,
  agreement,
  catalog,
  catalogs,
  disableBefore,
  electricityProducts,
  form,
  index,
  loadMore,
  loadChangeTripSailings,
  offerCode,
  passengers,
  sailings,
  select,
  send,
  setTicketType,
  tripType,
  vehicles,
  tariff,
  context,
}) => {
  return (
    <>
      <SailingSelect
        {...{
          catalog,
          catalogs,
          disableBefore,
          form,
          index,
          loadMore,
          loadChangeTripSailings,
          sailings,
          select,
          send,
          tripType,
          tariff,
          context,
        }}
      />
      <TripOptions
        {...{
          accommodationProducts,
          accommodations,
          agreement,
          catalog,
          catalogs,
          electricityProducts,
          index,
          offerCode,
          passengers,
          send,
          setTicketType,
          tripType,
          vehicles,
        }}
      />
    </>
  );
};

export default TripCatalog;
