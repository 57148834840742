import React, { FunctionComponent, useContext, useEffect } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Button from '../../../design-system/components/Button';
import { Subgrid } from '../../../design-system/components/Container';
import Modal from '../../../design-system/components/Modal';
import Stepper from '../../../design-system/components/Stepper';
import { H2, H3, Lead, P, Price, Small } from '../../../design-system/components/Text';
import { Transition } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { getCount, setStepperValue } from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import { TreeData } from '../../../storyblok';
import { formatString } from '../../../utils/formats';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import OnboardDescription from './shared/OnboardDescription';

interface StateSetProps {
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
}

interface WifiSelectionProps extends StateSetProps {
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly onOpen: (sailingCode: string) => void;
}

interface WifiRowProps extends StateSetProps {
  readonly uuid: string;
  readonly products: API.ExtendedProduct[];
  readonly onboards: API.ExtendedOnboard[];
  readonly tariff: API.Tariff;
  readonly strs: TreeData | undefined;
  readonly id: string;
}

interface WifiItemProps {
  readonly amount: number;
  readonly code: string;
}

const getWiFiPrice = (sbWifi: TreeData | undefined, currency: API.Currency): number | undefined => {
  const prices = new Map<API.Currency, any>([
    [API.Currency.EUR, sbWifi?.content.onboard_price_eur],
    [API.Currency.SEK, sbWifi?.content.onboard_price_sek],
    [API.Currency.PLN, sbWifi?.content.onboard_price_pln],
  ]);

  return prices.get(currency);
};

const WifiItem: FunctionComponent<WifiItemProps> = ({ amount, code }) => {
  const [sbInfo, ref] = hooks.useStoryblokComponent<HTMLLIElement>({
    fullSlug: `products/onboards/wifi-packages/${code}`,
  });

  return (
    <li key={code} ref={ref}>
      {amount} × {sbInfo?.content.title}
    </li>
  );
};

const WifiRow: FunctionComponent<WifiRowProps> = ({ uuid, products, onboards, tariff, strs, id, add, remove }) => {
  const { formats, currency } = useContext(LanguageContext);

  const [sbWifi, ref] = hooks.useStoryblokComponent<HTMLDivElement>({ uuid });
  const productCode = sbWifi?.slug;
  const wiFiPrice = getWiFiPrice(sbWifi, currency);

  const asProduct = products.find(({ code }) => code === productCode);
  const charge = API.maybeChargeInfo(tariff, asProduct)
    .map((_) => _.charge)
    .extract();

  const stepper =
    charge && productCode
      ? {
          setValue: (value: number) => setStepperValue(productCode, value, onboards, add, remove),
          value: getCount(productCode, onboards),
          min: 0,
          max: (asProduct?.capacity.available || 10) < 10 ? asProduct?.capacity.available || 10 : 10,
        }
      : {
          placeholder: strs?.content.only_onboard,
        };

  return (
    <Stepper id={id} {...stepper} innerRef={ref}>
      <Lead as="span">{sbWifi?.content.title}</Lead>
      <br />
      <P as="span">{sbWifi?.content.description}</P>
      <br />
      <Small as="span">
        {charge && wiFiPrice
          ? formatString(
              strs?.content.price,
              formats.currency(wiFiPrice),
              <Price inline small as="span" key="online-price">
                {formats.currency(charge)}
              </Price>
            )
          : null}
      </Small>
    </Stepper>
  );
};

const WifiSelection: FunctionComponent<WifiSelectionProps> = ({ sailing, remove, add, onOpen }) => {
  const { formats } = useContext(LanguageContext);

  const sbPorts = hooks.useStoryblokDatasource('ports');

  const [sbWifiPackages, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.wifi_packages',
  });
  const [sbWifiPackagesModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.wifi_packages_modal',
  });

  const wifiPackagesDescription = hooks.useStoryblokRichText(sbWifiPackages?.content.wifi_packages_description);

  const arrivalPort = (sbPorts && sbPorts[sailing.arrivalPort]) || sailing.arrivalPort;
  const departurePort = (sbPorts && sbPorts[sailing.departurePort]) || sailing.departurePort;

  const products = API.isError(sailing.products) ? [] : sailing.products.WIFI || [];

  const onboards = (API.isError(sailing.onboards) ? [] : sailing.onboards || []).filter(({ type }) => type === 'WIFI');

  const [sbPackagesOnRoute, packagesRef] = hooks.useStoryblokComponent<HTMLDivElement | HTMLParagraphElement>({
    fullSlug: `products/onboards/wifis-per-route/${sailing.departurePort}-${sailing.arrivalPort}`,
  });
  const sbWifiInfo = hooks.useStoryblokComponents('products/onboards/wifi-packages');

  const modalState = useDialogState({ animated: false });

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode);
    }
  }, [modalState.visible]);

  const totalCharge = calculateProductsTotalPrice(onboards, sailing.tariff);
  const totalSaving =
    onboards.reduce(
      (total, { code, amount }) => total + (parseInt(sbWifiInfo[code]?.content.onboard_price_eur) || 0) * amount,
      0
    ) - totalCharge;

  return !!products.length ? (
    <Subgrid ref={ref}>
      <H2>{sbWifiPackages?.content.title}</H2>
      <OnboardDescription>{wifiPackagesDescription}</OnboardDescription>
      {!!onboards.length && (
        <div>
          <H3>{sbWifiPackages?.content.selected_wifis}</H3>
          <ul>
            {onboards.map(({ amount, code }) => (
              <WifiItem key={code} {...{ amount, code }} />
            ))}
          </ul>
        </div>
      )}
      <DialogDisclosure disclosure as={Button} round {...modalState}>
        {!onboards.length
          ? sbWifiPackages?.content.add_wifi_packages_button
          : sbWifiPackages?.content.edit_wifi_packages_button}
      </DialogDisclosure>
      <Modal
        ref={modalRef}
        contentRef={packagesRef}
        state={modalState}
        title={formatString(sbWifiPackagesModal?.content.title, departurePort, arrivalPort)}
        focusOnDialog
        submit={sbWifiPackagesModal?.content.ready_button}
        size="input"
        footer={
          <Transition.Height show={!!totalCharge} as={Price}>
            <Small as="span">
              {formatString(
                sbWifiPackagesModal?.content.footer,
                <Transition.Number key="footer-savings" value={totalSaving} formatter={formats.currency} />
              )}
            </Small>
            <br />
            <Transition.Number value={totalCharge} formatter={formats.currency} />
          </Transition.Height>
        }
      >
        {(modalState.animating || modalState.visible) &&
          sbPackagesOnRoute?.content.wifis_available.map((uuid: string) => (
            <WifiRow
              key={uuid}
              strs={sbWifiPackagesModal}
              id={`${sailing.sailingCode}-${uuid}`}
              {...{ uuid, products, onboards, remove, add, tariff: sailing.tariff }}
            />
          ))}
      </Modal>
    </Subgrid>
  ) : (
    <></>
  );
};

export default WifiSelection;
