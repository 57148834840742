import React from 'react';
import styled from 'styled-components';
import { Radio, RadioStateReturn } from 'reakit/Radio';
import { size } from 'polished';

import { color, measurement } from '../helpers/vars';
import { animation, style } from '../helpers/mixins';

import { IconDataUri } from './Icon';
import Spinner from './Spinner';

interface RadioCardProps {
  readonly children?: React.ReactNode;
  readonly state: RadioStateReturn;
  readonly value: any;
  readonly disabled?: boolean;
  readonly loading?: boolean;
}

const Wrapper = styled.label`
  position: relative;
  text-align: left;
`;

const Input = styled(Radio)`
  ${style.radio.input}
  position: absolute;
`;

const LabelContent = styled.div`
  ${style.card.content}
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  transition: ${animation.transition('border')};
`;

const LabelWrapper = styled.div`
  ${style.card.wrapper}
  ${style.radio.label.common}
  display: grid;
  grid: 'input label' auto / 4rem 1fr;
  transition: ${animation.transition('box-shadow')};

  &::before,
  &::after {
    transition: ${animation.transition('background')};
  }

  &::before {
    content: '';
    background: ${color.bg.overlay};
    grid-area: input;
    border-radius: ${measurement.size.radius.default};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &::after {
    ${size(measurement.size.input.radio)}
    content: '';
    background: ${color.bg.default} no-repeat;
    background-image: ${style.colorAsImage(color.bg.default)}, url(${IconDataUri('check', color.bg.input.radio.active)});
    background-size: 100% 100%, ${measurement.size.icon.default};
    background-position: right center, center;
    position: absolute;
    border-radius: ${measurement.size.radius.circle};
    margin: 1.5rem 1rem;
  }

  ${Input}:disabled + & {
    ${style.radio.label.disabled}
  }

  ${Input}:checked + & {
    ${style.radio.label.checked}
    border-color: ${color.bg.input.radio.active};

    &::before {
      background: ${color.bg.input.radio.active};
    }

    &::after {
      background-size: 0 100%, ${measurement.size.icon.default};
    }

    ${LabelContent} {
      border-color: ${color.bg.input.radio.active};
    }
  }

  ${Input}:focus + & {
    ${style.focus.default}
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 30px;
  left: 22px;
  z-index: 2;
`;

const RadioCard = React.forwardRef<HTMLLabelElement, RadioCardProps>(
  ({ state, value, children, disabled, loading }, ref) => {
    const isChecked = state?.state === value;

    return (
      <Wrapper ref={ref}>
        {loading && !isChecked && <StyledSpinner small />}
        <Input {...{ value, ...state, disabled }} />
        <LabelWrapper>
          <LabelContent>{children}</LabelContent>
        </LabelWrapper>
      </Wrapper>
    );
  }
);

export default RadioCard;
