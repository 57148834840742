import React, { useRef } from 'react';
import { StyledInput } from '../helpers/components';
import { DialogDisclosure, DialogStateReturn } from 'reakit/Dialog';

import { Subgrid } from './Container';
import Icon from './Icon';

interface InputButtonProps {
  readonly value?: string;
  readonly label?: string;
  readonly alertId: string;
  readonly error?: string;
}

export interface InputDisclosurePassedProps {
  readonly value?: string | string[];
  readonly label?: string | string[];
  readonly onClick?: (() => void) | (() => void)[];
  readonly error?: string;
}

interface InputDisclosureProps extends InputDisclosurePassedProps {
  readonly modalState: DialogStateReturn;
}

const InputButton: React.FunctionComponent<InputButtonProps> = ({ value, label, error, alertId }) => {
  const validation = error && {
    className: 'invalid',
    'aria-describedby': alertId,
    'aria-invalid': true,
  };

  // Show label only when there is enough room on input box for labels (with different languages)
  // Multiline texts are without labels

  const inputValueRef = useRef(null);
  const inputValueElem = inputValueRef?.current as HTMLDivElement | null;
  const inputValueMaxLength = inputValueElem && inputValueElem.clientWidth / 9; // Estimating room for text
  // console.log('value, len:', value?.length, '-', value, inputValueElem?.offsetHeight, 'x', inputValueElem?.offsetWidth, 'max:', inputValueMaxLength, 'label', label)

  const multiLineText = (inputValueMaxLength && value && value.length > inputValueMaxLength) || !label;

  return (
    <StyledInput.Wrapper as="div" {...validation}>
      <StyledInput.Input as="p" {...(multiLineText && { noLabel: true })} ref={inputValueRef}>
        {value}
      </StyledInput.Input>
      {!multiLineText ? (
        <StyledInput.Label {...(!value && { noValue: true })} as="p">
          {label}
        </StyledInput.Label>
      ) : (
        <StyledInput.Label as="div" />
      )}
      {error && (
        <StyledInput.Error role="alert" id={alertId}>
          {error}
        </StyledInput.Error>
      )}
      {error && <Icon name="error" />}
    </StyledInput.Wrapper>
  );
};

const InputDisclosure: React.FunctionComponent<InputDisclosureProps> = ({
  value,
  label,
  modalState,
  onClick,
  error,
}) => {
  const alertId = `${modalState.baseId}-button-alert`;

  return Array.isArray(value) ? (
    <Subgrid variant="dense">
      {value.map((v, i) => (
        <StyledInput.WrapperButton
          key={`${i}-${v}`}
          as={DialogDisclosure}
          {...modalState}
          {...(onClick && { onClick: Array.isArray(onClick) ? onClick[i] : onClick })}
        >
          <InputButton
            value={v}
            alertId={`${alertId}-${i}`}
            {...(label && { label: Array.isArray(label) ? label[i] : label })}
            {...{ error }}
          />
        </StyledInput.WrapperButton>
      ))}
    </Subgrid>
  ) : (
    <StyledInput.WrapperButton as={DialogDisclosure} {...modalState}>
      <InputButton {...{ value, error }} label={Array.isArray(label) ? label[0] : label} alertId={alertId} />
    </StyledInput.WrapperButton>
  );
};

export default InputDisclosure;
