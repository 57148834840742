import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { cover } from 'polished';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { useDialogState } from 'reakit/Dialog';

import { color, Key, measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';

import Modal from '../Modal';
import InputDisclosure from '../InputDisclosure';

import { SelectStateReturn } from './useSelectState';
import Choice, { ChoiceEventHandlers } from './Choice';

interface Choice {
  readonly value: any;
  readonly label: string;
}

interface SelectProps {
  readonly choices: Choice[];
  readonly submit?: string;
  readonly label: string;
  readonly onChange: () => void;
}

const Indicator = styled.div<{ count: number; state: number }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${(props) => (100 / props.count) * props.state}%;
  height: ${(props) => 100 / props.count}%;
  transition: ${animation.transition('top')};

  &::after {
    ${cover(measurement.space.input.select.indicator)}
    content: '';
    background: ${color.bg.active};
    border-radius: ${measurement.size.radius.default};
  }
`;

const ChoiceGroup = styled(RadioGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${style.border.placeholder};
  border-radius: ${measurement.size.radius.default};
  transition: ${animation.transition('border', 'box-shadow')};

  &:focus,
  &:focus-within {
    ${style.focus.subtle}
  }

  ${responsive.fontSize(css`
    width: ${measurement.size.input.select};
    max-width: 100%;
  `)}
`;

const Select: React.FunctionComponent<SelectProps & SelectStateReturn> = ({
  choices,
  state,
  setState,
  children,
  label,
  onChange,
  error,
}) => {
  const modalState = useDialogState({ animated: false });
  const radioState = useRadioState({ state });
  const setRadioState = radioState.setState;

  const indicator = {
    state: choices.findIndex((c) => c.value === radioState.state),
    count: choices.length,
  };

  const labelId = `${modalState.baseId}-label`;
  const value = choices.find((c) => c.value === state)?.label;
  const focusRef = React.useRef<HTMLInputElement>();

  // focus on selected when modal is opened
  useEffect(() => {
    if (modalState.visible) {
      const focusElement = focusRef.current;

      if (focusElement) {
        focusElement.focus();
        setRadioState(focusElement.value);
      }
    }
    if (!modalState.visible) onChange();
  }, [modalState.visible, setRadioState, setState]);

  const onClick = (value: any) => {
    setRadioState(value);
    setState(value);
    modalState.hide();
  };

  const eventHandlers: (value: any) => ChoiceEventHandlers = (value) => ({
    onMouseOver: () => setRadioState(value),
    onClick: () => onClick(value),
    onKeyPress: (e) => (e.which === Key.ENTER || e.which === Key.SPACE) && onClick(value),
  });

  return (
    <>
      <InputDisclosure {...{ value, label, modalState, error }} />
      <Modal id={labelId} state={modalState} title={children}>
        {(modalState.animating || modalState.visible) && (
          <ChoiceGroup {...radioState} aria-labelledby={labelId}>
            <Indicator {...indicator} />
            {choices.map((c, i) => (
              <Choice
                key={c.value}
                value={c.value}
                state={radioState}
                {...eventHandlers(c.value)}
                {...(((state && c.value === state) || (!state && i === 0)) && {
                  passedRef: focusRef,
                })}
              >
                {c.label}
              </Choice>
            ))}
          </ChoiceGroup>
        )}
      </Modal>
    </>
  );
};

export default Select;
