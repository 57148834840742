import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { measurement, color } from '../../design-system/helpers/vars';
import { hooks } from '../../design-system/helpers/mixins';

import { Cell } from '../../design-system/components/Container';

const Container = styled(Cell)`
  overflow: hidden;
  margin: 2rem -0.25rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 6rem;
  border-radius: ${measurement.size.radius.input};
  background: ${color.bg.alt};
  margin: 0.25rem;
`;

const Method: FunctionComponent<{ uuid?: string }> = ({ uuid }) => {
  const [sbMethod, ref] = hooks.useStoryblokComponent<HTMLImageElement>({ uuid });
  const content = sbMethod?.content;

  return <Logo ref={ref} src={content?.logo.image} alt={content?.name} title={content?.name} />;
};

const PaymentMethods: FunctionComponent = () => {
  const [sbPaymentMethods, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.payment_methods',
  });

  return (
    <Container ref={ref} cols={3} as="section" aria-label={sbPaymentMethods?.content.title || ''}>
      {sbPaymentMethods?.content.methods.map((uuid: string) => (
        <Method key={uuid} {...{ uuid }} />
      ))}
    </Container>
  );
};

export default PaymentMethods;
