import { createGlobalStyle } from 'styled-components';

import { typography } from '../mixins/';

export default createGlobalStyle`
  ${typography.globalFontImport}

  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;
