import React, { FunctionComponent, useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../design-system/components/Alert';
import Container, { Subgrid } from '../../../design-system/components/Container';
import Footer from '../../../design-system/components/Footer';
import TabBar, { Tab } from '../../../design-system/components/TabBar';
import { H1, H2 } from '../../../design-system/components/Text';
import { Sticky, Theme } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import { measurement } from '../../../design-system/helpers/vars';
import { PaymentInterpreter } from '../../../fsm/payment/paymentMachine';
import * as API from '../../../fsm/types';
import { Port } from '../../../fsm/types';
import { LanguageContext } from '../../../Language.context';
import { calculateTripTotalPrice } from '../../../utils/priceCalculation';
import { useService } from '../../../xstate-react';
import FullSummary from '../../common/FullSummary';
import Notification from '../../common/Notification';
import PassengerInfo from './PassengerInfo';
import PaymentSelect from './PaymentSelect';
import Terms from './Terms';

interface ConfirmViewProps extends RouteComponentProps<any> {
  readonly service: PaymentInterpreter;
  readonly moveToCatalog: () => void;
}

const onClickHandler = (func: () => void) => {
  return (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    func();
  };
};

const ConfirmView: FunctionComponent<ConfirmViewProps> = ({ service, moveToCatalog, ...props }) => {
  const { location, match } = props;

  const { formats } = useContext(LanguageContext);
  const [current, send] = useService(service);

  const sbErrorCodes = hooks.useStoryblokDatasource('error-codes');
  const sbPaymentErrorCodes = hooks.useStoryblokDatasource('payment-error-codes');
  const [sbPaymentErrorDefault] = hooks.useStoryblokComponent({
    path: 'payment.payment_error_default',
  });
  const [sbPayment] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment',
  });
  const [sbHeaders] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_headers',
  });
  const [sbNav, navRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_nav',
  });

  const [sbCatalogFooter, footerRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    fullSlug: 'components/catalog-footer',
  });

  const sbInfoErrorCodes = hooks.useStoryblokDatasource('info-error-codes');

  const trip = current.context.trip;

  if (trip) {
    const { error } = current.context;
    if (error) {
      const firstError = document.getElementById(`${error.field}`);

      if (firstError) {
        if (firstError.tagName === 'DIV') {
          const firstErrorChild = firstError.querySelector('input, select') as HTMLElement;
          firstErrorChild && firstErrorChild.focus();
          firstErrorChild && firstErrorChild.scrollIntoView();
        } else {
          firstError.focus();
          firstError.scrollIntoView();
        }
      }
    }

    const totalPrice = formats.currency(calculateTripTotalPrice(trip.sailings.map(({ sailing }) => sailing)));

    return (
      <>
        <TabBar fixed innerRef={navRef}>
          <Tab
            asa={Link}
            href={`${match.url}/catalog`}
            location={location.pathname}
            onClick={onClickHandler(moveToCatalog)}
          >
            {sbNav?.content.back_link_label}
          </Tab>
        </TabBar>
        <Sticky.Scroll>
          <Theme.Alternative>
            <Container variant="form">
              <H1>{sbPayment?.content.title}</H1>
              {current.context.error && (
                <Subgrid variant="default">
                  <Alert severity="error">
                    {API.isPaymentError(current.context.error) &&
                    sbPaymentErrorCodes[current.context.error.paymentErrorCode]
                      ? sbPaymentErrorCodes[current.context.error.paymentErrorCode]
                      : API.isApiError(current.context.error) && sbErrorCodes[current.context.error.errorCode]
                      ? sbErrorCodes[current.context.error.errorCode]
                      : sbInfoErrorCodes[current.context.error.msg] || sbPaymentErrorDefault?.content.message}
                  </Alert>
                </Subgrid>
              )}
              <Notification
                legs={trip.sailings.map(({ sailing }) => [sailing.departurePort as Port, sailing.arrivalPort as Port])}
                storyblokPath="payment.notification-list"
              />
              <H2>{sbHeaders?.content.passenger_header}</H2>
              <Subgrid variant="default">
                <PassengerInfo trip={current.context.trip} />
              </Subgrid>
              <H2>{sbHeaders?.content.trip_summary_header}</H2>
              <DenserGrid variant="default">
                <FullSummary {...{ ...trip, totalPrice }} />
              </DenserGrid>
            </Container>
            <Terms trip={trip} send={send} error={error?.msg} />
            <PaymentSelect send={send} />
          </Theme.Alternative>
        </Sticky.Scroll>

        <Footer.Bar innerRef={footerRef} fixed>
          <Footer.Total label={sbCatalogFooter?.content.total_price} price={totalPrice} />
          {(!current.context.error || current.context.error.paymentErrorCode === 'CANCELLED_BY_CUSTOMER') && (
            <Footer.FinishButton onClick={() => send('PAY')}>{sbCatalogFooter?.content.pay_button}</Footer.FinishButton>
          )}
          {current.context.error && current.context.transactionId && (
            <Footer.FinishButton onClick={() => send('RE_VERIFY')}>
              {sbCatalogFooter?.content.confirm_button}
            </Footer.FinishButton>
          )}
        </Footer.Bar>
      </>
    );
  }
  return <></>;
};

const DenserGrid = styled(Subgrid)`
  grid-gap: 0;
  section + section {
    margin-top: ${measurement.space.flexible.l};
  }
`;

export default ConfirmView;
