import styled from 'styled-components';
import Container from '../Container';

const ChangeTripDropdownContainer = styled(Container)<{ isChangeTripDropdownOpen: boolean }>`
  padding: 0 0.15rem 0 0.15rem;
  margin: 1.5rem 0.85rem 0 0.85rem;
  max-height: ${(props) => (props.isChangeTripDropdownOpen ? '600px' : '0')};
  transition: max-height 0.4s linear;
  overflow: hidden;
`;

export default ChangeTripDropdownContainer;
