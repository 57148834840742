import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { measurement, color } from '../../design-system/helpers/vars';
import { hooks } from '../../design-system/helpers/mixins';

import { Lead, Small } from '../../design-system/components/Text';
import Link from '../../design-system/components/Link';
import Measure, { ContentRect } from 'react-measure';

interface FooterLinkProps {
  readonly url?: string;
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Container = styled.footer`
  background: ${color.bg.invert.overlay};
  padding: ${measurement.inset.container.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterLink: FunctionComponent<FooterLinkProps> = ({ url, children }) => (
  <Lead as="span" inline>
    <Link href={url} rel="noopener noreferrer" target="_blank">
      {children}
    </Link>
  </Lead>
);

const CookieLink: FunctionComponent<FooterLinkProps> = ({ children }) => (
  <Lead as="span" inline>
    <Link rel="noopener noreferrer" id="ot-sdk-btn" className="ot-sdk-show-settings">
      {children}
    </Link>
  </Lead>
);

const SearchFooter: FunctionComponent = () => {
  const [height, setHeight] = React.useState(0);

  const [sbFooter, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.search_footer',
  });

  const onResize: (arg: ContentRect) => void = ({ offset }) => setHeight(offset?.height || 0);

  return (
    <>
      <div style={{ height }}></div>
      <Measure offset {...{ onResize }}>
        {({ measureRef }) => (
          <Wrapper ref={measureRef}>
            <Container ref={ref}>
              <FooterLink url={sbFooter?.content.company_link}>{sbFooter?.content.company_name}</FooterLink>
              <Small as="span" inline>
                {sbFooter?.content.business_id_label}
                <Lead as="span" inline>
                  {' '}
                  {sbFooter?.content.business_id_code}
                </Lead>
              </Small>
              <FooterLink url={sbFooter?.content.link_destination}>{sbFooter?.content.link_label}</FooterLink>
              <CookieLink>CookieSettings</CookieLink>
            </Container>
          </Wrapper>
        )}
      </Measure>
    </>
  );
};

export default SearchFooter;
