import React from 'react';
import styled, { css } from 'styled-components';

import { P } from '../Text';
import { responsive } from '../../helpers/mixins';

interface TotalProps {
  readonly label: string;
  readonly price: string;
}

const Wrapper = styled(P)`
  margin: 0 1rem;

  ${responsive.grid(css`
    margin: 0.5rem 1rem;
  `)}
`;

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.grid(css`
    flex-direction: row;
  `)}
`;

const Total: React.FunctionComponent<TotalProps> = ({ label, price }) => (
  <Wrapper>
    {label}{' '}
    <P as="span" inline strong>
      {price}
    </P>
  </Wrapper>
);

export default Total;
