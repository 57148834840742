import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  ActionFunction,
  ActionMeta,
  ActionObject,
  EventObject,
  interpret,
  Interpreter,
  InterpreterOptions,
  MachineOptions,
  State,
  StateConfig,
  StateMachine,
  Typestate,
  StateNode,
  TypegenDisabled,
} from 'xstate';
import useConstant from './useConstant';
import { partition } from './utils';
import actors from '../fsm/actors';

enum ReactEffectType {
  Effect = 1,
  LayoutEffect = 2,
}

export interface ReactActionFunction<TContext, TEvent extends EventObject> {
  (context: TContext, event: TEvent, meta: ActionMeta<TContext, TEvent>): () => void;
  __effect: ReactEffectType;
}

export interface ReactActionObject<TContext, TEvent extends EventObject> extends ActionObject<TContext, TEvent> {
  exec: ReactActionFunction<TContext, TEvent>;
}

function createReactActionFunction<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>,
  tag: ReactEffectType
): ReactActionFunction<TContext, TEvent> {
  const effectExec: unknown = (...args: Parameters<typeof exec>) => {
    // don't execute; just return
    return () => {
      return exec(...args);
    };
  };

  Object.defineProperties(effectExec, {
    name: { value: `effect:${exec.name}` },
    __effect: { value: tag },
  });

  return effectExec as ReactActionFunction<TContext, TEvent>;
}

export function asEffect<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>
): ReactActionFunction<TContext, TEvent> {
  return createReactActionFunction(exec, ReactEffectType.Effect);
}

export function asLayoutEffect<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>
): ReactActionFunction<TContext, TEvent> {
  return createReactActionFunction(exec, ReactEffectType.LayoutEffect);
}

export type ActionStateTuple<TContext, TEvent extends EventObject> = [
  ReactActionObject<TContext, TEvent>,
  State<TContext, TEvent>
];

function executeEffect<TContext, TEvent extends EventObject>(
  action: ReactActionObject<TContext, TEvent>,
  state: State<TContext, TEvent>
): void {
  const { exec } = action;
  const originalExec = exec!(state.context, state._event.data, {
    action,
    state: state,
    _event: state._event,
  });

  originalExec();
}

interface UseMachineOptions<TContext, TEvent extends EventObject> {
  /**
   * If provided, will be merged with machine's `context`.
   */
  context?: Partial<TContext>;
  /**
   * The state to rehydrate the machine to. The machine will
   * start at this state instead of its `initialState`.
   */
  state?: StateConfig<TContext, TEvent>;

  childIds?: string[];
}

const enableDevTools = () => process.env.NODE_ENV !== 'production' && process.env.REACT_APP_XSTATE_DEV_TOOLS === 'true';

export function useMachine<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext }
>(
  machine: StateMachine<TContext, any, TEvent, TTypestate>,
  options: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>> = {}
): [
  State<TContext, TEvent, any, TTypestate>,
  Interpreter<TContext, any, TEvent, TTypestate>['send'],
  Interpreter<TContext, any, TEvent, TTypestate>
] {
  const [initialMachine] = useState(machine);

  if (process.env.NODE_ENV !== 'production' && machine !== initialMachine) {
    console.warn(
      'Machine given to `useMachine` has changed between renders. This is not supported and might lead to unexpected results.\n' +
        'Please make sure that you pass the same Machine as argument each time.'
    );
  }

  const {
    context,
    guards,
    actions,
    activities,
    services,
    delays,
    state: rehydratedState,
    childIds,
    ...interpreterOptions
  } = options;

  const [resolvedMachine, service, childServices] = useConstant<
    [
      StateNode<TContext, any, TEvent, TTypestate>,
      Interpreter<TContext, any, TEvent, TTypestate>,
      Interpreter<any, any, any, any>[]
    ]
    // @ts-ignore
  >(() => {
    const machineConfig = {
      context,
      guards,
      actions,
      activities,
      services,
      delays,
    };

    const resolvedMachine = machine.withConfig(machineConfig, {
      ...machine.context,
      ...context,
    } as TContext);

    const parent = interpret(resolvedMachine, {
      deferEvents: true,
      ...interpreterOptions,
      devTools: enableDevTools(),
    });

    const childServices = (childIds || []).map((id) => {
      const childMachine = actors[id];
      const childService = new Interpreter(childMachine, {
        ...parent.options, // inherit options from parent interpreter
        id,
        parent,
      });

      childService.onDone((doneEvent) => {
        parent.send(doneEvent);
      });

      parent.children.set(childService.id, childService);

      return childService;
    });

    return [resolvedMachine, parent, childServices];
  });

  const [state, setState] = useState(() => {
    // Always read the initial state to properly initialize the machine
    // https://github.com/davidkpiano/xstate/issues/1334
    const { initialState } = resolvedMachine;
    return rehydratedState ? State.create(rehydratedState) : initialState;
  });

  const effectActionsRef = useRef<
    Array<[ReactActionObject<TContext, TEvent>, State<TContext, TEvent, any, TTypestate>]>
  >([]);
  const layoutEffectActionsRef = useRef<
    Array<[ReactActionObject<TContext, TEvent>, State<TContext, TEvent, any, TTypestate>]>
  >([]);

  useLayoutEffect(() => {
    childServices.forEach((_) => _.start());

    service
      .onTransition((currentState) => {
        // Only change the current state if:
        // - the incoming state is the "live" initial state (since it might have new actors)
        // - OR the incoming state actually changed.
        //
        // The "live" initial state will have .changed === undefined.
        const initialStateChanged = currentState.changed === undefined && Object.keys(currentState.children).length;

        if (currentState.changed || initialStateChanged) {
          setState(currentState);
        }

        if (currentState.actions.length) {
          const reactEffectActions = currentState.actions.filter(
            (action): action is ReactActionObject<TContext, TEvent> => {
              return (
                typeof action.exec === 'function' && '__effect' in (action as ReactActionObject<TContext, TEvent>).exec
              );
            }
          );

          const [effectActions, layoutEffectActions] = partition(
            reactEffectActions,
            (action): action is ReactActionObject<TContext, TEvent> => {
              return action.exec.__effect === ReactEffectType.Effect;
            }
          );

          effectActionsRef.current.push(
            ...effectActions.map<
              [ReactActionObject<TContext, TEvent>, State<TContext, TEvent, any, TTypestate, TypegenDisabled>]
            >((effectAction) => [effectAction, currentState])
          );

          layoutEffectActionsRef.current.push(
            ...layoutEffectActions.map<
              [ReactActionObject<TContext, TEvent>, State<TContext, TEvent, any, TTypestate, TypegenDisabled>]
            >((layoutEffectAction: ReactActionObject<TContext, TEvent>) => [layoutEffectAction, currentState])
          );
        }
      })
      .start(rehydratedState ? State.create(rehydratedState) : undefined);

    return () => {
      service.stop();
    };
  }, []);

  // Make sure actions and services are kept updated when they change.
  // This mutation assignment is safe because the service instance is only used
  // in one place -- this hook's caller.
  useEffect(() => {
    Object.assign(service.machine.options.actions, actions);
  }, [actions]);

  useEffect(() => {
    Object.assign(service.machine.options.services, services);
  }, [services]);

  useLayoutEffect(() => {
    while (layoutEffectActionsRef.current.length) {
      const [layoutEffectAction, effectState] = layoutEffectActionsRef.current.shift()!;
      // @ts-ignore
      executeEffect(layoutEffectAction, effectState);
    }
  }, [state]); // https://github.com/davidkpiano/xstate/pull/1202#discussion_r429677773

  useEffect(() => {
    while (effectActionsRef.current.length) {
      const [effectAction, effectState] = effectActionsRef.current.shift()!;
      // @ts-ignore
      executeEffect(effectAction, effectState);
    }
  }, [state]);

  return [state, service.send, service];
}
