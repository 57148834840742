import React from 'react';
import styled from 'styled-components';

import { color } from '../../helpers/vars';

export type IllustrationName = 'passengercar' | 'van' | 'trailer' | 'camper' | 'longtrailer' | 'longvan';

interface IllustrationInfo {
  readonly viewBox: string;
  readonly children: React.ReactChild;
}

interface IllustrationProps {
  readonly name: IllustrationName;
}

const info: { [key in IllustrationName]: IllustrationInfo } = {
  passengercar: {
    viewBox: '0 0 102 24',
    children: (
      <>
        <path d="M12.846 20.692a3.385 3.385 0 11-6.77 0 3.385 3.385 0 016.77 0zm-1.27 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0zM39.923 20.692a3.385 3.385 0 11-6.77 0 3.385 3.385 0 016.77 0zm-1.27 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0z" />
        <path d="M46.861 17.392l.254.085c.339.17.423.677.339 1.438 0 1.693-.762 2.962-1.523 3.047l-5.416.338c.931-3.385-.846-5.923-4.061-5.923s-4.992 2.538-4.062 5.923H13.438c.931-3.385-.846-5.923-4.061-5.923S4.385 18.915 5.315 22.3H2.354C1.508 22.3 1 20.608 1 18.915c0-1.1.338-1.523 1.015-1.692.254-1.608 1.185-2.877 2.454-3.13 2.539-.508 5.246-.847 8.377-1.185 2.285-1.523 4.23-2.708 6.854-4.062.846-.423 1.523-.592 2.454-.677C23.93 8 26.384 8 30.615 8c4.485 0 7.108 0 10.323.254.762 0 1.185.17 1.693.677a18.536 18.536 0 013.554 4.23c.015.031.033.064.053.1.09.167.216.4.285.747.193.836.24 1.673.288 2.547.015.274.03.554.05.837zm-31.476-3.469h9.392l.761-4.992c-1.776 0-2.792.084-3.384.169a6.556 6.556 0 00-1.946.508c-1.523.761-3.131 1.777-4.823 3.046v1.27zm11.084 0l.762-5.077h6.854l1.269 5.077h-8.885zm10.239 0h5.754l.761-.846c-.592-1.1-1.354-2.37-1.946-3.046-.423-.593-.846-.846-1.608-.931-.761-.17-2.03-.254-4.23-.254l1.269 5.077z" />
        <path d="M64.409 1.486A.94.94 0 0165.243 1H67L55.591 22.514a.94.94 0 01-.834.486H53L64.409 1.486z" />
        <path d="M100.454 17.44l-.254-.085c0-1.191-.085-2.297-.338-3.402-.085-.425-.17-.68-.254-.85-.593-1.446-1.862-3.232-3.216-4.253-.824-.62-1.828-.655-2.39-.674l-.148-.006c-1.862-.085-4.4-.17-7.362-.17-2.388 0-2.388 0-3.043.076-.203.023-.47.054-.891.094-.931.085-1.608.255-2.454.68-2.623 1.361-4.451 2.466-6.941 4.54-2.224.26-3.082.409-4.694.733-1.269.255-2.2 1.446-2.454 3.146-.677.17-1.015.596-1.015 1.701 0 1.701.508 3.402 1.354 3.402h2.115c-.93-3.402.846-5.953 4.062-5.953 3.215 0 4.992 2.551 4.061 5.953h11.762c-.93-3.402.846-5.953 4.061-5.953 3.216 0 4.993 2.551 4.062 5.953l2.877-.34c.761-.085 1.523-1.361 1.523-3.062 0-.85-.085-1.36-.423-1.53zm-12.989-3.997l-11.761.34v-1.19c1.692-1.276 3.3-2.297 4.823-3.062a6.53 6.53 0 011.946-.51c.93-.17 2.792-.17 5.67-.17l-.678 4.592zm1.523-4.567l-.677 4.482 7.404-.332c-.084-1.162-.677-2.656-1.184-3.154-.874-.857-2.06-.996-5.373-.996h-.17z" />
        <path d="M75.915 20.671a3.393 3.393 0 01-3.384 3.402 3.393 3.393 0 01-3.385-3.402 3.393 3.393 0 013.385-3.402 3.393 3.393 0 013.384 3.402zm-1.269 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.116-2.126 2.12 2.12 0 012.116-2.126 2.12 2.12 0 012.115 2.126zM95.8 20.671a3.393 3.393 0 01-3.385 3.402 3.393 3.393 0 01-3.384-3.402 3.393 3.393 0 013.384-3.402 3.393 3.393 0 013.385 3.402zm-1.27 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.115-2.126 2.12 2.12 0 012.115-2.126 2.12 2.12 0 012.116 2.126z" />
      </>
    ),
  },
  van: {
    viewBox: '0 0 113 24',
    children: (
      <>
        <path d="M47.831 8.077h-1.77c-.53 0-.884.354-.884.885v1.769h-.884v2.654h.884v1.769c0 .53.354.885.885.885h1.77c.53 0 .884-.354.884-.885V8.962c0-.443-.443-.885-.885-.885z" />
        <path d="M13.331 20.02a3.98 3.98 0 11-7.962-.001 3.98 3.98 0 017.962 0zm-1.77 0a2.212 2.212 0 11-4.422 0 2.212 2.212 0 014.423 0z" />
        <path d="M40.754 3.654H21.293L24.212.796C25.185.176 26.423 0 27.485 0h11.5c.442 0 .973.354 1.15.796l.62 2.858z" />
        <path d="M45.62 16.215l-.266-.088c0-1.592-.177-3.273-.354-4.423s-1.504-5.22-2.3-6.193c-.354-.442-.884-.619-1.68-.707-1.239-.177-4.07-.177-10.97-.177-3.98 0-6.988 0-8.846.177-.973.088-1.77.177-2.565.707-2.212 1.504-4.335 3.185-6.281 5.131-3.273.354-6.104.796-8.758 1.239-1.275.255-1.488 2.306-1.66 3.957l-.02.2c-.708.177-1.062.62-1.062 1.77 0 1.769.53 3.538 1.415 3.538h2.3c0-.221-.022-.442-.044-.663-.022-.222-.044-.443-.044-.664 0-2.654 2.211-4.865 4.865-4.865 2.654 0 4.866 2.211 4.866 4.865 0 .442-.089.885-.177 1.327H31.2c-.088-.442-.177-.885-.177-1.327 0-2.654 2.212-4.865 4.866-4.865 2.654 0 4.865 2.211 4.865 4.865 0 .442-.088.885-.177 1.327l3.893-.354c.796-.088 1.592-1.415 1.592-3.184 0-.885-.089-1.416-.442-1.593zM15.1 9.935c1.504-1.504 3.008-2.831 4.335-3.804.53-.354 1.327-.531 2.123-.62.62-.088 1.946-.088 4.158-.088l-.797 6.192H15.1v-1.68zm12.385-4.512l-.796 6.192h8.669l-.442-6.192h-7.431zm8.934 6.192l-.442-6.192c.479.016.909.028 1.296.04 1.829.054 2.69.08 3.127.225.708.177 1.062.531 1.239 1.239.442 1.504.707 3.096.973 4.688h-6.193zM15.1 19.577H30.14v.885H15.1v-.885z" />
        <path d="M39.87 20.02a3.98 3.98 0 11-7.962 0 3.98 3.98 0 017.962 0zm-1.77 0a2.211 2.211 0 11-4.423 0 2.211 2.211 0 014.423 0zM78.254 19.891a4.109 4.109 0 11-8.218 0 4.109 4.109 0 018.218 0zm-1.827 0a2.283 2.283 0 11-4.565 0 2.283 2.283 0 014.565 0zM104.58 19.891a4.109 4.109 0 11-8.218 0 4.109 4.109 0 018.218 0zm-1.826 0a2.283 2.283 0 11-4.565 0 2.283 2.283 0 014.565 0z" />
        <path d="M110.514 4.004c.274.457.548 1.918.822 3.653.457 3.013.548 5.934.457 9.039 0 2.739-.366 4.108-1.644 4.2l-4.93.365c.091-.457.182-.913.182-1.37 0-2.739-1.826-5.021-5.021-5.021-3.287 0-5.022 2.282-5.022 5.021 0 .457.091.913.182 1.37H78.893c.09-.457.182-.913.182-1.37 0-2.739-1.917-5.021-5.022-5.021-3.195 0-5.021 2.282-5.021 5.021 0 .457.091.913.182 1.37h-2.373c-.914 0-1.461-.913-1.461-2.74 0-1.186.273-1.643.821-1.825.024-.272.045-.554.065-.84.14-1.93.295-4.047 1.487-4.365a80.74 80.74 0 015.844-1.278c1.644-2.1 3.56-4.383 5.478-6.117.73-.64 1.278-.913 2.648-1.005C82.453 3 83.275 3 83.914 3h24.409c.822 0 1.735.091 2.191 1.004zm-35 6.3v.913h8.31v-6.3h-2.375c-.913 0-1.46.183-2.1.822-1.278 1.278-2.465 2.74-3.835 4.565z" />
        <path d="M63.409 1.486A.94.94 0 0164.243 1H66L54.591 22.514a.94.94 0 01-.834.486H52L63.409 1.486z" />
      </>
    ),
  },
  trailer: {
    viewBox: '0 0 71 24',
    children: (
      <>
        <path d="M41.8 17.367l-.254-.085c0-1.19-.084-2.296-.338-3.402-.085-.425-.17-.68-.254-.85-.593-1.446-1.862-3.232-3.215-4.252h1.1l.846-1.701-4.231.68-.254.34c-1.861-.085-4.4-.17-7.362-.17-3.13 0-4.907 0-6.684.17-.93.085-1.608.256-2.454.68-2.623 1.361-4.57 2.552-6.854 4.083-2.708.34-4.992.68-7.53 1.19-1.27.255-2.2 1.446-2.454 3.147-.677.17-1.016.595-1.016 1.7 0 1.701.508 3.402 1.354 3.402h2.115c-.93-3.401.847-5.953 4.062-5.953 3.215 0 4.992 2.552 4.061 5.953H29.7c-.93-3.401.846-5.953 4.061-5.953 3.216 0 4.993 2.552 4.062 5.953l2.877-.34c.761-.085 1.523-1.36 1.523-3.061 0-.85-.084-1.361-.423-1.531zM26.062 13.37l-11.762.34v-1.19c1.692-1.276 3.3-2.297 4.823-3.062a6.53 6.53 0 011.946-.51c.931-.17 2.793-.17 5.67-.17l-.678 4.592zm1.523-4.677l-.677 4.592 10.154-.34c-.085-1.19-.677-2.722-1.185-3.232-.423-.425-.93-.595-1.608-.68-1.354-.255-3.215-.34-6.515-.34h-.17z" />
        <path d="M11.761 20.598A3.393 3.393 0 018.377 24a3.393 3.393 0 01-3.385-3.402 3.393 3.393 0 013.385-3.401 3.393 3.393 0 013.384 3.401zm-1.269 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.115-2.126 2.12 2.12 0 012.115-2.126 2.12 2.12 0 012.115 2.126zM37.146 20.598A3.393 3.393 0 0133.761 24a3.393 3.393 0 01-3.384-3.402 3.393 3.393 0 013.384-3.401 3.393 3.393 0 013.385 3.401zm-1.27 0a2.12 2.12 0 01-2.114 2.126 2.12 2.12 0 01-2.116-2.126 2.12 2.12 0 012.115-2.126 2.12 2.12 0 012.116 2.126zM62.192 20.598A3.393 3.393 0 0158.808 24a3.393 3.393 0 01-3.385-3.402 3.393 3.393 0 013.385-3.401 3.393 3.393 0 013.384 3.401zm-1.269 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.116-2.126 2.12 2.12 0 012.116-2.126 2.12 2.12 0 012.115 2.126z" />
        <path d="M69.723 14.646h-22v5.017h-6.261v.935h6.176v.85c0 .511.339.851.847.851h6.176c-.93-3.401.847-5.953 4.062-5.953 3.215 0 4.992 2.552 4.062 5.953h6.007c.508 0 .846-.34.846-.85v-6.804h.085z" />
      </>
    ),
  },
  camper: {
    viewBox: '0 0 50 24',
    children: (
      <>
        <path d="M11.732 20.186a3.814 3.814 0 11-7.627 0 3.814 3.814 0 017.627 0zm-1.694 0a2.119 2.119 0 11-4.238 0 2.119 2.119 0 014.237 0zM38.765 20.186a3.814 3.814 0 11-7.627 0 3.814 3.814 0 017.627 0zm-1.695 0a2.119 2.119 0 11-4.237 0 2.119 2.119 0 014.237 0z" />
        <path d="M48.98 3A2.025 2.025 0 0046.965.958H22.26c-.564 0-.97-.148-1.463-.328C20.038.352 19.072 0 17.002 0c-3.413 0-5.045 0-5.045 2.087 0 .584.268 1.105.513 1.581.358.695.667 1.295.025 1.857-1.78 1.61-3.56 3.729-5.085 5.678a74.916 74.916 0 00-5.424 1.186c-1.106.296-1.25 2.26-1.38 4.05-.02.267-.038.529-.06.781-.509.17-.763.593-.763 1.695 0 1.695.508 2.543 1.356 2.543h2.203c-.084-.424-.17-.848-.17-1.272 0-2.542 1.696-4.66 4.662-4.66 2.882 0 4.661 2.118 4.661 4.66 0 .424-.084.848-.17 1.271h18.13c-.084-.423-.169-.847-.169-1.27 0-2.543 1.61-4.662 4.662-4.662 2.966 0 4.66 2.119 4.66 4.661 0 .424-.084.848-.169 1.271l7.623-.26a1.994 1.994 0 001.932-1.998c-.01-3.776-.031-12.122-.013-16.198zM9.19 12.136v-.847c1.271-1.695 2.373-3.051 3.56-4.238.593-.593 1.101-.763 1.949-.763h2.203v5.848H9.19zM21.788 9.24a1 1 0 001 1h5.689a1 1 0 001-1.024l-.048-2.04a1 1 0 00-1-.978h-5.64a1 1 0 00-1 1V9.24z" />
      </>
    ),
  },
  longtrailer: {
    viewBox: '0 0 84 24',
    children: (
      <>
        <path d="M12.692 20.615a3.385 3.385 0 11-6.769 0 3.385 3.385 0 016.77 0zm-1.269 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0zM42.308 20.615a3.385 3.385 0 11-6.77 0 3.385 3.385 0 016.77 0zm-1.27 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0z" />
        <path d="M50.346 17.4l-.254-.085c-.084-1.184-.169-2.284-.338-3.384-.085-.592-.339-.931-.677-.931H34.692l-.846-4.23c-.084-.424-.338-.593-1.1-.678-1.015-.084-2.877-.169-5.67-.169-1.691 0-3.3 0-5.076.17-.93.084-1.608.253-2.454.676-2.623 1.354-4.57 2.539-6.854 4.062-3.13.338-5.838.677-8.377 1.184-1.269.254-2.2 1.439-2.453 3.131-.677.17-1.016.592-1.016 1.692 0 1.693.508 3.385 1.354 3.385h2.962C4.23 18.84 6.008 16.3 9.223 16.3s4.992 2.538 4.062 5.923h21.492c-.93-3.384.846-5.923 4.062-5.923 3.215 0 4.992 2.538 4.061 5.923l6.261-.338c.762-.085 1.524-1.354 1.524-3.047.084-.761 0-1.269-.339-1.438zm-35.115-3.638v-1.27c1.692-1.269 3.3-2.284 4.823-3.046A6.558 6.558 0 0122 8.938c.677-.084 1.439-.169 2.538-.169l-.677 4.57-8.63.423zm10.323-.424l6.6-.338V9.53c0-.422-.254-.592-.846-.676-1.1-.085-2.539-.085-4.231-.085h-.846l-.677 4.57zM75.815 20.615a3.385 3.385 0 11-6.769 0 3.385 3.385 0 016.77 0zm-1.269 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0z" />
        <path d="M83.346 14.692H56.27v4.993h-6.261v.93h6.177v.847c0 .507.338.846.846.846h11.254c-.931-3.385.846-5.923 4.061-5.923s4.992 2.538 4.062 5.923h6.007c.508 0 .846-.339.846-.846v-6.77h.085z" />
      </>
    ),
  },
  longvan: {
    viewBox: '0 0 86 24',
    children: (
      <>
        <path d="M68.71 20.615a3.385 3.385 0 11-6.769 0 3.385 3.385 0 016.77 0zm-1.269 0a2.115 2.115 0 11-4.23 0 2.115 2.115 0 014.23 0z" />
        <path d="M83.417 5a4 4 0 00-4-4h-25.59a4 4 0 00-3.877 3.017l-2.962 11.68c-.116.455-.15.926-.102 1.393l.267 2.595H41.14v.93h6.123l.126.84a1 1 0 00.989.853H61.29c-.93-3.385.846-5.923 4.062-5.923 3.215 0 4.992 2.538 4.061 5.923H79.81a2 2 0 001.45-.622l1.607-1.69a2 2 0 00.551-1.379V5zm-30.006-.242a1 1 0 01.97-.758h5.786a1 1 0 011 1v4.417a1 1 0 01-1 1H53.28a1 1 0 01-.97-1.242l1.101-4.417zM75.001 4a1 1 0 00-1 1v2.583a1 1 0 001 1h4.416a1 1 0 001-1V5a1 1 0 00-1-1H75z" />
        <path d="M41.327 17.367l-.254-.085c0-1.19-.085-2.296-.338-3.402-.085-.425-.17-.68-.254-.85-.593-1.446-1.862-3.232-3.216-4.252h1.1l.846-1.7-4.23.68-.254.34c-1.862-.085-4.4-.17-7.362-.17-3.13 0-4.907 0-6.684.17-.931.085-1.608.255-2.454.68-2.623 1.36-4.57 2.551-6.854 4.082-2.708.34-4.992.68-7.53 1.19-1.27.256-2.2 1.446-2.455 3.147-.676.17-1.015.595-1.015 1.7 0 1.702.508 3.402 1.354 3.402h2.115c-.93-3.401.846-5.952 4.062-5.952 3.215 0 4.992 2.55 4.061 5.952h17.262c-.93-3.401.846-5.952 4.061-5.952 3.216 0 4.993 2.55 4.062 5.952l2.877-.34c.761-.085 1.523-1.36 1.523-3.061 0-.85-.085-1.36-.423-1.531zM25.588 13.37l-11.761.34v-1.19c1.692-1.276 3.3-2.296 4.823-3.062a6.53 6.53 0 011.946-.51c.93-.17 2.792-.17 5.67-.17l-.678 4.592zm1.523-4.677l-.676 4.592 10.153-.34c-.084-1.19-.677-2.721-1.184-3.232-.423-.425-.931-.595-1.608-.68-1.354-.255-3.215-.34-6.515-.34h-.17z" />
        <path d="M11.289 20.599A3.393 3.393 0 017.904 24 3.393 3.393 0 014.52 20.6a3.393 3.393 0 013.384-3.402A3.393 3.393 0 0111.29 20.6zm-1.27 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.115-2.126 2.12 2.12 0 012.115-2.126 2.12 2.12 0 012.116 2.126zM36.673 20.599A3.393 3.393 0 0133.29 24a3.393 3.393 0 01-3.385-3.401 3.393 3.393 0 013.385-3.402 3.393 3.393 0 013.384 3.402zm-1.269 0a2.12 2.12 0 01-2.115 2.126 2.12 2.12 0 01-2.116-2.126 2.12 2.12 0 012.116-2.126 2.12 2.12 0 012.115 2.126z" />
      </>
    ),
  },
};

const SVG = styled.svg`
  fill: ${color.brand};
  fill-rule: evenodd;
  height: 1.5rem;
  flex: 0 1 auto;
`;

const Illustration: React.FunctionComponent<IllustrationProps> = ({ name }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" {...info[name]} />
);

export default Illustration;
