import { css, FlattenSimpleInterpolation } from 'styled-components';

import { breakpoint, Breakpoint } from '../vars';

type MediaQueries = {
  [key: string]: (args: FlattenSimpleInterpolation) => FlattenSimpleInterpolation;
};

const responsive: MediaQueries = Object.keys(breakpoint).reduce(
  (acc: MediaQueries, key) => ({
    ...acc,
    [key]: (args) => css`
      @media (min-width: ${breakpoint[key as Breakpoint]}) {
        ${args}
      }
    `,
  }),
  {}
);

export default responsive;
