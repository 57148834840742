import React from 'react';
import styled from 'styled-components';

import { measurement } from '../../helpers/vars';
import { animation, style, typography } from '../../helpers/mixins';

import Button from '../Button';
import { Small } from '../Text';

interface PlaceholderProps {
  readonly placeholder: string;
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly id: string;
  readonly focusRef?: any;
  readonly value: number;
  readonly setValue: (arg: number) => void;
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly placeholder?: never;
}

export type ExtendedInputProps = InputProps | PlaceholderProps;

const InputField = styled.input`
  ${typography.lead}
  margin: ${measurement.space.input.stepper.field};
  padding: ${measurement.inset.input.stepper.field};
  width: ${measurement.size.input.stepper};
  text-align: center;
  border: ${style.border.default};
  border-radius: ${measurement.size.radius.rounded};
  appearance: textfield;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: fit-content;
  align-items: center;
  border-radius: ${measurement.size.radius.rounded};
  transition: ${animation.transition('box-shadow')};

  button {
    z-index: 1;
  }

  &:focus-within {
    ${style.focus.default}
  }
`;

const Placeholder = styled(Small)`
  flex: 0 1 ${(1 / 3) * 100}%;
`;

const toFloat = (val: string | number): number => (typeof val === 'string' ? parseFloat(val) : val);

const InputControls: React.FunctionComponent<InputProps> = ({
  setValue,
  value,
  focusRef,
  id,
  min = 0,
  max = 9,
  step = 1,
}) => {
  const [inputValue, setInputValue] = React.useState(value.toFixed());

  const adjustToLimits = (tmpValue: number) => (tmpValue < min ? min : tmpValue > max ? max : tmpValue);

  React.useEffect(() => setInputValue(value.toFixed()), [value]);

  const onChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>> = (e) => setInputValue(e.currentTarget.value);

  const onBlur: React.EventHandler<React.FocusEvent<HTMLInputElement>> = () => {
    const fixedValue =
      typeof inputValue === 'number' ? inputValue : /^\d+([.,]{1}\d*)?$/.test(inputValue) ? inputValue : value;

    const newValue = adjustToLimits(toFloat(fixedValue));

    setInputValue(newValue.toFixed());
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Button
        type="button"
        aria-hidden="true"
        icon="minus"
        onClick={() => setValue(value - step)}
        disabled={value <= min}
      />
      <InputField type="number" pattern="[0-9]*" value={inputValue} {...{ id, min, max, step, onChange, onBlur }} />
      <Button
        type="button"
        aria-hidden="true"
        icon="plus"
        onClick={() => setValue(value + step)}
        disabled={value >= max}
        {...(focusRef && { forwardRef: focusRef })}
      />
    </Wrapper>
  );
};

const Input: React.FunctionComponent<ExtendedInputProps> = (props) =>
  'placeholder' in props ? <Placeholder align="right">{props.placeholder}</Placeholder> : <InputControls {...props} />;

export default Input;
