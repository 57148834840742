import React from 'react';
import styled, { css } from 'styled-components';

import { color, measurement, zindex } from '../../helpers/vars';
import { responsive, style } from '../../helpers/mixins';

interface WrapperProps {
  readonly fixed?: boolean;
  readonly innerRef?: React.RefObject<HTMLDivElement>;
}

const size = css`
  height: ${measurement.size.footerbar.s};
  padding-bottom: env(safe-area-inset-bottom);

  ${responsive.grid(css`
    height: ${measurement.size.footerbar.l};
  `)}

  ${responsive.wide(css`
    height: ${measurement.size.footerbar.xl};
  `)}
`;

const BarPadding = styled.div`
  ${size}
`;

const Wrapper = styled.div<WrapperProps>`
  ${size}
  border: ${style.border.default};
  border-width: ${measurement.size.border.default} 0 0;
  background: ${color.bg.default};

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: ${zindex.nav};
    `}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  max-width: ${measurement.grid.wide.width};
  margin: auto;
  ${responsive.wide(css`
    align-items: center;
  `)}
`;

const Bar: React.FunctionComponent<WrapperProps> = ({ fixed, children, innerRef }) => (
  <>
    {fixed && <BarPadding />}
    <Wrapper {...{ fixed }} ref={innerRef}>
      <Container>{children}</Container>
    </Wrapper>
  </>
);

export default Bar;
