import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { color } from '../../helpers/vars';
import { typography, style } from '../../helpers/mixins';

import { Small } from '../Text';

interface RowProps {
  readonly showHeader?: boolean;
  readonly title?: string;
  readonly price: string;
}

const Content = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const Price = styled.p`
  ${typography.h3}
  color: ${color.text.heading};
  margin: 0;
  padding: 0;
  justify-self: end;
  flex: 0;
  align-self: flex-end;
`;

const StyledTitle = styled(Small)`
  margin-bottom: 0;
  color: ${color.text.heading};
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${style.border.default};
`;

export const Title: FunctionComponent = (props) => <StyledTitle as="h4" {...props} />;

const Row: FunctionComponent<RowProps> = ({ showHeader = true, title, price, children }) => {
  return (
    <>
      <Content>
        {showHeader && (
          <Header>
            {title && <Title>{title}</Title>}
            {price && <Price>{price}</Price>}
          </Header>
        )}
        {children}
      </Content>
    </>
  );
};
export default Row;
