import { css } from 'styled-components';

import { color, font } from '../vars';
import { responsive } from '../mixins';

import RobotoSlab700Woff2 from '../../fonts/RobotoSlab-Bold.woff2';
import SourceSans400Woff2 from '../../fonts/SourceSansPro-Regular.woff2';
import SourceSans600Woff2 from '../../fonts/SourceSansPro-SemiBold.woff2';
import SourceSans700Woff2 from '../../fonts/SourceSansPro-Bold.woff2';

const fontFace = [
  {
    family: font.family.robotoSlab,
    woff2: RobotoSlab700Woff2,
    weight: font.weight.heading,
  },
  {
    family: font.family.sourceSans,
    woff2: SourceSans400Woff2,
    weight: font.weight.default,
  },
  {
    family: font.family.sourceSans,
    woff2: SourceSans600Woff2,
    weight: font.weight.strong.lead,
  },
  {
    family: font.family.sourceSans,
    woff2: SourceSans700Woff2,
    weight: font.weight.strong.default,
  },
];

const common = {
  heading: css`
    font-family: ${font.family.heading};
    font-weight: ${font.weight.heading};
  `,
  copy: css`
    font-family: ${font.family.copy};
    line-height: ${font.lineHeight.copy};
  `,
};

export default {
  globalFontImport: css`
    ${fontFace.map(
      (f) => css`
        @font-face {
          font-family: ${f.family};
          src: local(${f.family}), url(${f.woff2}) format('woff2');
          font-weight: ${f.weight};
          font-style: normal;
        }
      `
    )}

    body {
      ${common.copy}
      color: ${color.text.copy};
    }
  `,
  h1: css`
    ${common.heading}
    line-height: ${font.lineHeight.heading};
    font-size: ${font.size[4].mobile};

    ${responsive.fontSize(css`
      font-size: ${font.size[4].desktop};
    `)}
  `,
  h2: css`
    ${common.heading}
    line-height: ${font.lineHeight.heading};
    font-size: ${font.size[3].mobile};

    ${responsive.fontSize(css`
      font-size: ${font.size[3].desktop};
    `)}
  `,
  h3: css`
    ${common.heading}
    line-height: ${font.lineHeight.smallHeading};
    font-size: ${font.size[2].mobile};

    ${responsive.fontSize(css`
      font-size: ${font.size[2].desktop};
    `)}
  `,
  h4: css`
    ${common.heading}
    line-height: ${font.lineHeight.smallHeading};
    font-size: ${font.size[0].mobile};

    ${responsive.fontSize(css`
      font-size: ${font.size[0].desktop};
    `)}
  `,
  lead: css`
    ${common.copy}
    font-size: ${font.size[2].mobile};
    font-weight: ${font.weight.lead};

    ${responsive.fontSize(css`
      font-size: ${font.size[2].desktop};
    `)}
  `,
  p: css`
    ${common.copy}
    font-size: ${font.size[1].mobile};
    font-weight: ${font.weight.default};

    ${responsive.fontSize(css`
      font-size: ${font.size[1].desktop};
    `)}
  `,
  small: css`
    ${common.copy}
    font-size: ${font.size[0].mobile};
    font-weight: ${font.weight.small};

    ${responsive.fontSize(css`
      font-size: ${font.size[0].desktop};
    `)}
  `,
};
