import { isAfter, parseISO, subMinutes } from 'date-fns';
import { Maybe } from 'purify-ts/Maybe';
import * as API from '../../FinnlinesB2CBookingAPI';
import { ExtendedPassenger, ExtendedPassengerAndPet, SailingMeta, TariffPrice } from './catalog';

export const maybeTariffPrice = <T extends { price?: TariffPrice | any }>(input?: T): Maybe<TariffPrice> => {
  return Maybe.fromNullable(input)
    .chainNullable((_) => _.price)
    .filter((_) => typeof _ !== 'string' && API.Tariff.SPECIAL in _ && API.Tariff.STANDARD in _);
};

export const maybeTariffInitialPrice = <T extends { meta?: SailingMeta | any }>(input?: T): Maybe<TariffPrice> => {
  return Maybe.fromNullable(input?.meta)
    .chainNullable((_) => _.initialCheapestPrice)
    .filter((_) => _ && (API.Tariff.SPECIAL in _ || API.Tariff.STANDARD in _));
};

export const maybeChargeInfo = <T extends { price?: TariffPrice | any }>(
  tariff: API.Tariff,
  input?: T
): Maybe<API.ChargeInfo> => {
  return Maybe.fromNullable(input)
    .chainNullable((_) => _.price)
    .chainNullable((_) => _[tariff])
    .filter(isChargeInfo);
};

export const isApiError = <T extends { __typename: string }>(input: T | API.ApiError): input is API.ApiError => {
  return input.__typename === 'ApiError' || ('errorCode' in input && 'errorMessage' in input);
};

export const isPaymentError = <T extends { __typename: string }>(
  input: T | API.PaymentError
): input is API.PaymentError => {
  return input.__typename === 'PaymentError' || ('paymentErrorCode' in input && 'paymentErrorMessage' in input);
};

export const isChargeInfo = (input: API.ChargeInfo | any): input is API.ChargeInfo => {
  return input && typeof input === 'object' ? input.__typename === 'ChargeInfo' || 'charge' in input : false;
};

export const isTariffPrice = (input: TariffPrice): input is TariffPrice => {
  if (input !== undefined) return input.SPECIAL !== undefined && input.STANDARD !== undefined ? true : false;
  return false;
};

export const isPassengerInput = (input: ExtendedPassengerAndPet): input is ExtendedPassenger => {
  return input.type !== API.PetType.PET;
};

export const isPetInput = (input: ExtendedPassengerAndPet): input is API.PetInput => {
  return input.type === API.PetType.PET;
};

export const isReservationActive = (reservation: API.BookingReservation, bufferInMinutes: number): boolean => {
  return reservation && reservation.bookingDetail && reservation.bookingDetail.expiryDate
    ? isAfter(subMinutes(parseISO(reservation.bookingDetail.expiryDate), bufferInMinutes), new Date())
    : false;
};
