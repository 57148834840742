import { List } from 'purify-ts/List';
import React, { FunctionComponent, useContext } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../design-system/components/Spinner';
import { Center } from '../../design-system/helpers/components';
import { hooks } from '../../design-system/helpers/mixins';
import paymentMachine from '../../fsm/payment/paymentMachine';
import { updateHistoryAction, useChildServiceWithHistory, usePersistedMachine } from '../../fsm/react';
import {
  isReservationActive,
  PaymentError,
  PaymentErrorCode,
  Trip,
  PassengerType,
  ExtendedPassenger,
  ErrorCode,
} from '../../fsm/types';
import { LanguageContext } from '../../Language.context';
import ConfirmView from './confirm/ConfirmView';
import SuccessView from './success/SuccessView';
import { compareInfo } from '../../fsm/utils/passengerUtils';

interface PaymentViewProps extends RouteComponentProps<any> {
  readonly trip: Trip;
  readonly moveToCatalog: () => void;
  readonly moveToSearch: () => void;
}

const LoadingView = styled(Center)`
  height: 100%;
`;

const isTripEqual = (t1: Trip, t2: Trip): boolean => {
  // This function expects array elements to be unique.
  const arrayEquals = (a1: any[] | null | undefined, a2: any[] | null | undefined) =>
    a1 === a2 || (!!a1 && !!a2 && a1.length === a2.length && a1.every((a) => a2.includes(a)));

  const passengersMatch = t1.passengers.every((p1) =>
    List.find(({ id }) => id === p1.id, t2.passengers)
      .map(
        (p2) =>
          arrayEquals(p1.legs, p2.legs) &&
          p1.type === p2.type &&
          (p1.type in PassengerType && p2.type in PassengerType
            ? compareInfo(p1 as ExtendedPassenger, p2 as ExtendedPassenger)
            : true)
      )
      .orDefault(false)
  );

  const sailingsMatch = t1.sailings.every(({ index: idx1, sailing: s1 }) =>
    List.find(({ index }) => index === idx1, t2.sailings)
      .map(({ sailing: s2 }) => {
        const basePropsMatch =
          s1.offerCode === s2.offerCode &&
          // Sailing code contains departure and arrival props.
          s1.sailingCode === s2.sailingCode &&
          s1.tariff === s2.tariff;

        const accommodationsMatch =
          Array.isArray(s1.accommodations) &&
          s1.accommodations.every(
            (a1) =>
              Array.isArray(s2.accommodations) &&
              List.find(({ id }) => id === a1.id, s2.accommodations || [])
                .map(
                  (a2) =>
                    a1.code === a2.code &&
                    arrayEquals(a1.legs, a2.legs) &&
                    arrayEquals(a1.passengers, a2.passengers) &&
                    arrayEquals(a1.pets, a2.pets) &&
                    a1.type === a2.type
                )
                .orDefault(false)
          );

        const onboardsMatch =
          s1.onboards === s2.onboards ||
          (Array.isArray(s1.onboards) &&
            s1.onboards.reduce(
              (onboards, o1) => {
                const index = onboards.findIndex(
                  (o2) =>
                    o1.amount === o2.amount &&
                    o1.code === o2.code &&
                    arrayEquals(o1.legs, o2.legs) &&
                    o1.type === o2.type
                );

                if (index !== -1) {
                  onboards.splice(index, 1);
                }

                return onboards;
              },
              Array.isArray(s2.onboards) ? [...s2.onboards] : []
            ).length === 0);

        return basePropsMatch && accommodationsMatch && onboardsMatch;
      })
      .orDefault(false)
  );

  const vehiclesMatch = t1.vehicles.every((v1) =>
    List.find(({ id }) => id === v1.id, t2.vehicles)
      .map(
        (v2) =>
          v1.assignedDriver === v2.assignedDriver &&
          v1.heightCm === v2.heightCm &&
          arrayEquals(v1.legs, v2.legs) &&
          v1.lengthCm === v2.lengthCm &&
          v1.make === v2.make &&
          v1.model === v2.model &&
          v1.registrationId === v2.registrationId &&
          v1.type === v2.type
      )
      .orDefault(false)
  );

  return t1.type === t2.type && passengersMatch && sailingsMatch && vehiclesMatch;
};

const isTimeoutError = (error: any): boolean => {
  if (!error || !error.errorCode || !error.errorMessage || typeof error.errorMessage !== 'string') {
    return false;
  }
  return error.errorCode === ErrorCode.SERVER_ERROR && error.errorMessage.includes('ExecutionTimeout');
};

const getMaximumVerificationRetries = () => {
  if (process.env.REACT_APP_MAXIMUM_PAYMENT_VERIFICATION_RETRIES) {
    try {
      return parseInt(process.env.REACT_APP_MAXIMUM_PAYMENT_VERIFICATION_RETRIES, 10);
    } catch (err) {
      // ignore
    }
  }
  return 5;
};

const PaymentView: FunctionComponent<PaymentViewProps> = ({ trip, ...props }) => {
  const { history, location, match } = props;
  const { currency, language } = useContext(LanguageContext);
  const [current, send, service] = usePersistedMachine(paymentMachine, {
    actions: {
      updateHistory: updateHistoryAction(props),
    },
    context: {
      currency,
      language,
      trip,
    },
    expiresInMinutes: (state) => (state.value === 'success' ? 10 : undefined),
    onLoad: (state) =>
      // Keep state if
      //  user has successfully booked the trip
      //  or
      //    trip exists and it matches one given as prop
      //    and
      //    reservation does not exist or if does it is still active
      //
      // Note: the order of these matters, so do not change without complete tests.
      state.value === 'success' ||
      (state.context.trip &&
        isTripEqual(state.context.trip, trip) &&
        (!state.context.reservation || isReservationActive(state.context.reservation, 0)))
        ? state
        : undefined,
  });

  useChildServiceWithHistory(service, history);

  const [sbReserveBooking, reserveBookingRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'reserve-booking.making_reservation',
  });
  const [sbContactingPaymentProvider, contactingPaymentProviderRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'start-payment.contacting_payment_provider',
  });
  const [sbMovingToPaymentProvider, movingToPaymentProviderRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'start-payment.moving_to_payment_provider',
  });
  const [sbVerifyPayment, verifyPaymentRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'verify-payment.verifying_payment',
  });

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/payment/callback`}
        render={() => {
          // This block is executed when coming back from payment provider.
          // Backend is responsible for creating correct return URL to this route.
          if (current.matches('startPayment')) {
            // TODO Check for selected payment provider and use provider specific logic
            const params = new URLSearchParams(location.search);
            const reference = current.context.reservation.bookingDetail?.reference;
            const transactionId = params.get('transactionId');

            if (/Cancel/i.test(params.get('responseCode') || '')) {
              send({
                type: '/payment',
                error: {
                  paymentErrorCode: PaymentErrorCode.CANCELLED_BY_CUSTOMER,
                  paymentErrorMessage: '',
                } as PaymentError,
              });
            } else if (
              params.get('bookingReference') === reference &&
              /OK/i.test(params.get('responseCode') || '') &&
              transactionId
            ) {
              send({ type: 'VERIFY', transactionId });
            }
          }

          return <></>;
        }}
      />
      <Route
        render={() => {
          const { transactionId, error, verificationRetried } = current.context;
          switch (true) {
            case current.matches('confirmBooking'):
              if (error) {
                if (transactionId && error.errorCode === ErrorCode.BOOKING_ALREADY_PAID) {
                  send({ type: 'PAID' });
                }
                if (transactionId && isTimeoutError(error) && verificationRetried < getMaximumVerificationRetries()) {
                  send({
                    type: 'RE_VERIFY',
                    transactionId,
                    verificationRetried: verificationRetried + 1,
                  });
                }
              }
              return <ConfirmView service={service} {...props} />;

            case current.matches('reserveBooking'):
              return (
                <LoadingView ref={reserveBookingRef}>
                  <Spinner />
                  <p>{sbReserveBooking?.content.message}</p>
                </LoadingView>
              );

            case current.matches('startPayment'): {
              if (current.context.redirectUrl) {
                const { redirectUrl } = current.context;
                if (redirectUrl !== 'used') {
                  window.location.assign(redirectUrl);
                  // Prevent to get stuck on Nets terminal.
                  send({ type: 'REDIRECTED' });
                }

                return (
                  <LoadingView ref={movingToPaymentProviderRef}>
                    <Spinner />
                    <p>{sbMovingToPaymentProvider?.content.message}</p>
                  </LoadingView>
                );
              } else {
                return (
                  <LoadingView ref={contactingPaymentProviderRef}>
                    <Spinner />
                    <p>{sbContactingPaymentProvider?.content.message}</p>
                  </LoadingView>
                );
              }
            }

            case current.matches('verifyPayment'):
              return (
                <LoadingView ref={verifyPaymentRef}>
                  <Spinner />
                  <p>{sbVerifyPayment?.content.message}</p>
                </LoadingView>
              );

            case current.matches('success.idle'):
            case current.matches('success.load'):
              return <SuccessView service={service} {...props} />;

            default:
              console.error(`Invalid state: ${current.value}.`);
          }
        }}
      />
    </Switch>
  );
};

export default PaymentView;
