import { SelectedListType } from '../../design-system/components/TreeSelect';
import { Passengers, VehicleType, ExtendedPassenger } from '../types';
import { parse, differenceInYears } from 'date-fns';
import { vehicleAgeLimits } from '../constants';

export const hasEnoughDriversAsCodes = (
  newVehicleCode: VehicleType | 'BCY',
  passengers: Passengers,
  vehicles: SelectedListType
): boolean =>
  (vehicleTypeRequiresAdultOrJunior(newVehicleCode)
    ? adultsWithoutVehicle(passengers, vehicles)
    : passengersWithoutVehicle(passengers, vehicles)) > 0;

export const vehicleTypeRequiresAdultOrJunior = (productcode: string): boolean => {
  switch (productcode) {
    case VehicleType.BCY:
    case 'BIKE':
      return false;
    case VehicleType.BUS:
    case VehicleType.CAR:
    case VehicleType.CMP:
    case VehicleType.LCAR:
    case VehicleType.MBUS:
    case VehicleType.MCY:
    case VehicleType.VAN_LM:
    default:
      return true;
  }
};

export const validateVehicleByAge = (type: VehicleType, passenger: ExtendedPassenger) =>
  passenger.info?.birth
    ? differenceInYears(new Date(), parse(passenger.info.birth, 'dd/MM/yyyy', new Date())) >= vehicleAgeLimits[type]
    : false;

export const vehiclesRequireAdultOrJuniorAsCodes = (vehicles: SelectedListType): number => {
  return vehicles.reduce(
    (requiresAdult, { productcode }) =>
      vehicleTypeRequiresAdultOrJunior(productcode) ? requiresAdult + 1 : requiresAdult,
    0
  );
};

export const adultsWithoutVehicle = (passengers: Passengers, vehicles: SelectedListType) =>
  passengers.ADULT + passengers.JUNIOR - vehiclesRequireAdultOrJuniorAsCodes(vehicles);

export const passengersWithoutVehicle = (passengers: Passengers, vehicles: SelectedListType) =>
  (Object.keys(passengers) as (keyof Passengers)[]).map((key) => passengers[key]).reduce((acc, val) => acc + val, 0) -
  vehicles.length;

export const hasJuniorWithVehicle = (passengers: Passengers, vehicles: SelectedListType) =>
  vehiclesRequireAdultOrJuniorAsCodes(vehicles) > passengers.ADULT;

export const electricityVehicles = (vehicles: SelectedListType) => {
  return vehicles.filter(
    (vehicle) => vehicle.productcode !== VehicleType.MCY && vehicle.productcode !== VehicleType.BCY
  );
};
