import React, { useContext } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import { Subgrid } from '../../design-system/components/Container';
import Footer from '../../design-system/components/Footer';
import { Content, Link } from '../../design-system/components/LinkButton';
import Modal from '../../design-system/components/Modal';
import { Transition } from '../../design-system/helpers/components';
import { hooks } from '../../design-system/helpers/mixins';
import { LanguageContext } from '../../Language.context';
import FullSummary, { FullSummaryPassedProps } from '../common/FullSummary';
import Spinner from '../../design-system/components/Spinner';

export interface CatalogFooterPassedProps {
  readonly allowProceedingToNextPage: boolean;
  readonly tripTotalPrice: number;
  readonly next: () => void;
  readonly back: () => void;
}

interface CatalogFooterProps extends CatalogFooterPassedProps {
  readonly toggle?: boolean;
  readonly moreId?: string;
  readonly summary?: FullSummaryPassedProps;
  readonly disabled?: boolean;
}

const CatalogFooter: React.FunctionComponent<CatalogFooterProps> = ({
  allowProceedingToNextPage,
  tripTotalPrice,
  toggle,
  moreId,
  next,
  back,
  summary,
  disabled,
}) => {
  const { formats } = useContext(LanguageContext);
  const modalState = useDialogState({ animated: false });

  const [sbCatalogFooter, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    fullSlug: 'components/catalog-footer',
  });

  const onNextClick = () => {
    if (!disabled) {
      if (allowProceedingToNextPage) {
        next(); // Go forward
      }
    }
  };

  const onBackClick = () => {
    if (!disabled) {
      back();
    }
  };

  const totalPrice = formats.currency(tripTotalPrice);

  const nextButtonText = disabled ? (
    <Spinner small title="Loading" description="Prices for catalog products are loading." />
  ) : (
    sbCatalogFooter?.content.next_button
  );

  const backButtonText = sbCatalogFooter?.content.back_button;

  const nextButtonDisabled = disabled || !allowProceedingToNextPage;
  return (
    <Footer.Bar fixed innerRef={ref}>
      <Footer.FinishButton onClick={onBackClick} aria-disabled={disabled} icon={'left'} skewLeft={true}>
        {backButtonText}
      </Footer.FinishButton>
      <Footer.TotalWrapper>
        <Footer.Total label={sbCatalogFooter?.content.total_price || ''} price={totalPrice} />

        {summary && (
          <>
            <DialogDisclosure {...modalState} as={Link}>
              <Content small>{sbCatalogFooter?.content.summary_label}</Content>
            </DialogDisclosure>
            <Modal state={modalState} title={sbCatalogFooter?.content.summary_label}>
              {(modalState.animating || modalState.visible) && (
                <Subgrid variant="default">
                  <FullSummary {...{ ...summary, totalPrice }} />
                </Subgrid>
              )}
            </Modal>
          </>
        )}
      </Footer.TotalWrapper>

      <Transition.Toggle
        toggle={toggle === undefined || toggle}
        as={Footer.FinishWrapper}
        whenFalse={
          <Footer.FinishLink secondary icon="down" href={`#${moreId}`}>
            {sbCatalogFooter?.content.more_button}
          </Footer.FinishLink>
        }
        whenTrue={
          <Footer.FinishButton
            onClick={onNextClick}
            aria-disabled={nextButtonDisabled}
            icon={nextButtonDisabled ? undefined : allowProceedingToNextPage ? 'right' : 'left'}
          >
            {nextButtonText}
          </Footer.FinishButton>
        }
      />
    </Footer.Bar>
  );
};

export default CatalogFooter;
