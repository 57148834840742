import React, { useState } from 'react';

import Label from '../Label';

import ImageArea, { ImageAreaProps } from './ImageArea';
import TagArea, { TagAreaProps } from './TagArea';
import ButtonArea, { ButtonAreaSharedProps } from './ButtonArea';
import Collapsible from './Collapsible';
import Wrapper from './Wrapper';
import Content from './Content';
import PriceArea, { PriceAreaSharedProps } from './PriceArea';
import gtm from '../../../analytics/gtm';
import { ExtendedSailing } from '../../../fsm/types';
import { serviceProduct } from '../../../fsm/utils/analyticsUtils';

export type PriceAreaProps = PriceAreaSharedProps;

interface CardWrapperProps {
  readonly label?: React.ReactNode;
  readonly image?: ImageAreaProps;
  readonly vertical?: boolean;
  readonly tags?: TagAreaProps;
  readonly children?: React.ReactNode;
  readonly showMoreLabel?: string;
  readonly showLessLabel?: string;
}

export interface CardProps extends CardWrapperProps, ButtonAreaSharedProps {
  readonly button?: string;
  readonly collapse?: number;
  readonly price?: PriceAreaSharedProps;
  readonly title?: string;
  readonly sailing?: ExtendedSailing;
  readonly leg?: number;
}

const CardWrapper = React.forwardRef<HTMLDivElement, CardWrapperProps>(
  ({ label, children, tags, image, ...props }, ref) => (
    <Wrapper ref={ref} {...{ ...(tags && { hasTags: true }), ...(image?.image && { hasImage: true }), ...props }}>
      {image?.image && <ImageArea {...image} />}
      {label && <Label>{label}</Label>}
      {children}
      {tags && <TagArea {...tags} />}
    </Wrapper>
  )
);

const CollapsipleCard = React.forwardRef<HTMLDivElement, CardProps>(
  ({ collapse = 0, children, button, showLessLabel, showMoreLabel, ...props }, ref) => {
    const [collapsible, setCollapsible] = useState(false);
    const [collapsedHeight, setCollapsedHeight] = useState(0);
    const [toggle, setToggle] = useState(false);
    const { title, sailing, leg } = props;

    const onClick = () => {
      // When toggle is false, the card is clicked open.
      // Only send analytics events when the card is opened.
      if (title && sailing && leg !== undefined && !toggle) {
        const product = serviceProduct(sailing, leg, title);
        // UA
        gtm.clearEcommerce();
        gtm.productClick({
          products: [product],
        });

        gtm.detail({
          products: [product],
        });

        gtm.send();

        // GA4
        gtm.clearEcommerce();
        gtm.ga4ProductClick({
          products: [
            {
              item_id: product.id,
              item_name: product.name,
              index: product.position,
              item_brand: product.brand,
              item_category: product.category,
              item_variant: product.variant,
              quantity: product.quantity,
            },
          ],
        });

        gtm.ga4Detail({
          products: [
            {
              item_id: product.id,
              item_name: product.name,
              item_brand: product.brand,
              item_category: product.category,
              item_variant: product.variant,
            },
          ],
        });

        gtm.send();
      }
      setToggle(!toggle);
    };

    const contentProps = {
      collapse,
      collapsible,
      setCollapsible,
      toggle,
      collapsedHeight,
      setCollapsedHeight,
    };

    return (
      <CardWrapper
        ref={ref}
        {...{
          ...(collapsible && { hasButton: true }),
          ...(toggle && { className: 'open' }),
          ...{ collapsedHeight },
          ...props,
        }}
      >
        <Collapsible {...contentProps}>{children}</Collapsible>

        {collapsible && (
          <ButtonArea secondary {...{ onClick }}>
            {toggle ? showLessLabel : showMoreLabel}
          </ButtonArea>
        )}
      </CardWrapper>
    );
  }
);

const DefaultCard = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, button, price, onClick, disclosureState, removeButton, ...props }, ref) => {
    return (
      <CardWrapper
        ref={ref}
        {...{
          ...(button && { hasButton: true }),
          ...props,
        }}
      >
        <Content>{children}</Content>

        {price && <PriceArea {...price} />}
        {button && <ButtonArea {...{ onClick, disclosureState, removeButton }}>{button}</ButtonArea>}
      </CardWrapper>
    );
  }
);

const Card = React.forwardRef<HTMLDivElement, CardProps>((props, ref) =>
  props.collapse ? <CollapsipleCard ref={ref} {...props} /> : <DefaultCard ref={ref} {...props} />
);

export default Card;
