import { isAfter, isSameDay, parseISO } from 'date-fns';
import { SelectedListType } from '../../design-system/components/TreeSelect/SelectedList';
import { Tree } from '../../storyblok';
import { maxPassengers } from '../constants';
import { Port, TripType } from '../types';
import { SearchContext } from './searchMachine';
import { isCruiseType } from '../api/cruise';
import { settings } from '../../settings';

const guards = {
  loadSearchOptionsOnBoot: (ctx: SearchContext): boolean => {
    return ctx.portMap.length === 0;
  },
  searchParamGuard: (_: SearchContext, evt: any) => {
    const { searchParams, sbLegMeta } = evt;

    if (!searchParams) return false;

    const { forms, passengers, type, vehicles, offerCode } = searchParams;

    return (
      forms.every(
        (form: any) =>
          form.params.departurePort &&
          form.params.departurePort in Port &&
          form.params.arrivalPort &&
          form.params.arrivalPort in Port &&
          form.params.departurePort !== form.params.arrivalPort &&
          validateVehiclePort(
            type,
            form.params.departurePort,
            form.params.arrivalPort,
            form.selectedDate,
            vehicles,
            sbLegMeta
          )
      ) &&
      passengers.ADULT + passengers.JUNIOR + passengers.CHILD + passengers.INFANT <= maxPassengers &&
      passengers.ADULT + passengers.JUNIOR + passengers.CHILD + passengers.INFANT > 0 &&
      validateOfferCode(type, offerCode)
    );
  },
};

/**
 * Validates offerCode based on TripType
 * @param tripType
 * @param offerCode
 * @returns
 */
export const validateOfferCode = (tripType: TripType, offerCode: string) => {
  if (!tripType || !offerCode) return true;

  // matches "cr" (case-insensitive)
  const matcher = new RegExp(`${settings.cruiseOfferCodeAbbrv}`, 'i');
  const abbrFound = matcher.test(offerCode);

  return isCruiseType(tripType) ? abbrFound : !abbrFound;
};

export const validateVehiclePort = (
  tripType: TripType,
  departure: string,
  arrival: string,
  selectedDateStr: string,
  vehicles: SelectedListType,
  sbLegMeta: Tree
) => {
  if (vehicles.length || isCruiseType(tripType)) return true;

  const legMeta = Object.values(sbLegMeta).find((meta) => {
    const [departurePort, arrivalPort] = meta.slug.split('-');
    return departurePort === departure && arrivalPort === arrival;
  });

  if (!legMeta?.content) return true;

  const {
    content: { vehicle_required_ends_at, vehicle_required },
  } = legMeta;

  // Vehicle is not required on this leg
  if (!vehicle_required) return true;

  // Vehicle is always mandatory on this leg
  if (!vehicle_required_ends_at) return false;

  // .. vehicle is mandatory on the leg only before given date
  const endsAt = parseISO(vehicle_required_ends_at);
  const departureDate = parseISO(selectedDateStr);

  return isSameDay(departureDate, endsAt) || isAfter(departureDate, endsAt);
};
export default guards;
