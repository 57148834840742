import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RadioGroup, RadioStateReturn } from 'reakit/Radio';
import { size } from 'polished';

import { color, measurement } from '../../helpers/vars';
import { responsive } from '../../helpers/mixins';

interface DeparturePickerProps {
  readonly state: RadioStateReturn;
  readonly label: string;
  readonly handleFirstVisible?: () => void;
  readonly handleLastVisible?: () => void;
}

const Wrapper = styled.div<{ ref?: any }>`
  overflow-x: auto;
  scroll-snap-type: x proximity;
  padding: ${measurement.space.departure.container.s};
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    ${size(measurement.size.scrollbar)}
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${color.bg.overlay};
    border-radius: ${measurement.size.radius.rounded};
  }

  ${responsive.grid(css`
    padding: ${measurement.space.departure.container.l};
  `)}
`;

const StyledPicker = styled(RadioGroup)`
  display: flex;

  & > * {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }
  }
`;

const getCenteredScrollLeft = (element: HTMLElement, container: HTMLElement) => {
  const posRelativeToWrapper = element.offsetLeft - container.offsetLeft;
  const posOfCenter = posRelativeToWrapper + element.offsetWidth / 2;

  return posOfCenter - container.offsetWidth / 2;
};

const getCurrentHorizontalCenter = (state: RadioStateReturn, ref: React.MutableRefObject<HTMLElement | undefined>) => {
  const current = state.items.find((item) => item.id === `${state.baseId}-${state.state}`);
  const element = current?.ref?.current?.offsetParent as HTMLElement;
  const container = ref.current;

  return element && container && getCenteredScrollLeft(element, container);
};

const getElementHorizontalCenter = (element: Element | null) => {
  const bounding = element?.getBoundingClientRect();
  return bounding && bounding.x + bounding.width / 2;
};

const getChildrenElementsAndIds = (container: HTMLElement) => {
  const picker = container.firstElementChild;
  const first = picker?.firstElementChild;
  const last = picker?.lastElementChild;

  return {
    first: {
      element: first || null,
      id: first?.firstElementChild?.id || null,
    },
    last: {
      element: last || null,
      id: last?.firstElementChild?.id || null,
    },
  };
};

const DeparturePicker: React.FunctionComponent<DeparturePickerProps> = ({
  state,
  label,
  children,
  handleFirstVisible,
  handleLastVisible,
}) => {
  const [, setSelected] = useState<number | string | undefined>();
  const [initialScrollFinished, setInitialScrollFinished] = useState(false);
  const [first, setFirst] = useState<string | null>(null);
  const [last, setLast] = useState<string | null>(null);

  const ref = React.useRef<HTMLElement>();

  useEffect(() => {
    setSelected((previous) => {
      if (previous !== state.state) {
        const left = getCurrentHorizontalCenter(state, ref);

        if (left) {
          ref.current?.scrollTo({ left });
          return state.state;
        }
      }

      return previous;
    });
  }, [state, setSelected]);

  useEffect(() => (first && handleFirstVisible ? handleFirstVisible() : undefined), [first, handleFirstVisible]);

  useEffect(() => (last && handleLastVisible ? handleLastVisible() : undefined), [last, handleLastVisible]);

  useEffect(() => {
    const isScrollable = (container: HTMLElement) => {
      return container.scrollWidth > container.offsetWidth;
    };

    const handleVisible = () => {
      const container = ref.current;

      if (container && !isScrollable(container)) {
        const { first, last } = getChildrenElementsAndIds(container);

        first.id && setFirst((prev) => (first.id && first.id !== prev ? first.id : prev));
        last.id && setLast((prev) => (last.id && last.id !== prev ? last.id : prev));
      }
    };

    setTimeout(handleVisible);
  }, [setFirst, setLast]);

  const onScroll = () => {
    if (!initialScrollFinished) {
      // don't fire handlers before initial scroll is finished
      const initialScroll = getCurrentHorizontalCenter(state, ref);
      const currentScroll = ref.current?.scrollLeft;

      initialScroll &&
        currentScroll &&
        // some fuzziness because different screen sizes cause half pixels and other funny things
        Math.abs(currentScroll - initialScroll) < 10 &&
        setInitialScrollFinished(true);
    } else {
      const container = ref.current;

      if (container) {
        const containerWidth = container.getBoundingClientRect().width;
        const { first, last } = getChildrenElementsAndIds(container);

        first.element &&
          (getElementHorizontalCenter(first.element) || 0) > 0 &&
          setFirst((prev) => (first.id && first.id !== prev ? first.id : prev));

        last.element &&
          (getElementHorizontalCenter(last.element) || containerWidth) < containerWidth &&
          setLast((prev) => (last.id && last.id !== prev ? last.id : prev));
      }
    }
  };

  return (
    <Wrapper {...{ ref, onScroll }}>
      <StyledPicker {...state} aria-label={label}>
        {children}
      </StyledPicker>
    </Wrapper>
  );
};

export default DeparturePicker;
