declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

function toBlob(base64String: string, contentType: string): Blob {
  const chars = atob(base64String);
  const bytes = new Uint8Array(chars.length);
  for (let i = 0; i < chars.length; ++i) {
    bytes[i] = chars.charCodeAt(i);
  }
  return new Blob([bytes], { type: contentType });
}

export function downloadBase64(base64String: string, contentType: string, fileName: string) {
  if (navigator && navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(toBlob(base64String, contentType), fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(toBlob(base64String, contentType));
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.setAttribute('style', 'visibility:hidden');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL.
        window.URL.revokeObjectURL(url);
      }, 100);
    } else {
      // May open in new tab/window instead of downloading directly.
      window.open(`data:${contentType},${encodeURIComponent(base64String)}`);
    }
  }
}
