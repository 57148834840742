import styled from 'styled-components';

import { style } from '../../helpers/mixins';

const Container = styled.section`
  ${style.card.wrapper}
  align-self: flex-start;
  background: transparent;
`;

export default Container;
