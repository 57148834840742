import React, { FunctionComponent } from 'react';
import { Theme } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import styled from 'styled-components';
import { PaymentProvider } from '../../../fsm/types';
import Container, { Subgrid } from '../../../design-system/components/Container';
import { H2 } from '../../../design-system/components/Text';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import PaymentCard from './PaymentCard';

interface PaymentSelectProps {
  send: any;
}

const PaymentSelect: FunctionComponent<PaymentSelectProps> = ({ send }) => {
  const paymentState = useRadioState({ state: PaymentProvider.NETS_NETAXEPT });

  // useEffect(() => {
  //   console.log('State changing', paymentState.state);
  //    send({
  //      type: 'SET_PAYMENT_TYPE',
  //      paymentType: paymentState.state,
  //    });
  // }, [paymentState.state]);

  const [sbPaymentTypes, sbPaymentTypesRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.payment_types',
  });

  return (
    <Theme.Default>
      <Container variant="text">
        <DenserGrid variant="default" ref={sbPaymentTypesRef}>
          <H2>{sbPaymentTypes?.content.title}</H2>
          <Subgrid as={RadioGroup} aria-label="Payment Type" {...paymentState}>
            {(sbPaymentTypes?.content.types || []).map((uuid: any) => (
              <PaymentCard key={uuid} uuid={uuid} paymentType={paymentState} />
            ))}
          </Subgrid>
        </DenserGrid>
      </Container>
    </Theme.Default>
  );
};

const DenserGrid = styled(Subgrid)`
  grid-gap: 0;
`;

export default PaymentSelect;
