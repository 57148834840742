import React, { FunctionComponent, useContext, useEffect } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Button from '../../../design-system/components/Button';
import Card from '../../../design-system/components/Card';
import { Subgrid } from '../../../design-system/components/Container';
import Modal from '../../../design-system/components/Modal';
import Stepper, { InputProps } from '../../../design-system/components/Stepper';
import { H2, H3, Lead, P, Price } from '../../../design-system/components/Text';
import { Transition } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { isPassengerInput } from '../../../fsm/types';
import {
  getCount,
  setStepperValue,
  sortAdultToInfantOnboardInLimited,
  sortAdultToInfantProductInLimited,
} from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import { formatString } from '../../../utils/formats';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import OnboardDescription from './shared/OnboardDescription';

interface SelectionProps {
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly passengers: API.ExtendedPassengerAndPet[];
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
  readonly onOpen: (sailingCode: string, passengerTypes: API.PassengerType[]) => void;
}

interface NightOnboardCardProps extends InputProps {
  readonly product: API.ExtendedProduct;
  readonly tariff: API.Tariff;
  readonly unit?: string;
  readonly travelFree?: string;
}

const SelectedItem: FunctionComponent<API.ExtendedOnboard> = ({ code, amount }) => {
  const [sbPackage, ref] = hooks.useStoryblokComponent<HTMLLIElement>({
    path: code || '',
  });

  return (
    <li ref={ref}>
      {amount} × {sbPackage?.content.title || code}
    </li>
  );
};

const NightOnboardCard: FunctionComponent<NightOnboardCardProps> = ({
  product,
  tariff,
  unit,
  travelFree,
  ...props
}) => {
  const { formats } = useContext(LanguageContext);
  const [sbPackage] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: product.code || '',
  });

  const freeText = product.limitedTo?.map((limited) => {
    const [sbPassenger] = hooks.useStoryblokComponent<HTMLDivElement>({
      path: limited || '',
    });
    return `${sbPassenger?.content.plural} ${sbPassenger?.content.description}`;
  });

  const chargeInfo = API.maybeChargeInfo(tariff, product).mapOrDefault(({ charge }) => charge, -1);

  return (
    <Stepper {...props}>
      <Lead as="span">{sbPackage?.content.title || product.desc}</Lead>
      <br />
      {chargeInfo <= 0 ? (
        travelFree && freeText && <P>{formatString(travelFree, freeText.join(', ').toLowerCase())}</P>
      ) : (
        <Price align="left" small inline>
          {chargeInfo > 0 ? formats.currency(chargeInfo) : '-'}{' '}
        </Price>
      )}
    </Stepper>
  );
};

const NightOnboard: FunctionComponent<SelectionProps> = ({ sailing, passengers, add, remove, onOpen }) => {
  const modalState = useDialogState({ animated: false });
  const { formats } = useContext(LanguageContext);
  const sbPorts = hooks.useStoryblokDatasource('ports');
  const [sbNightProducts, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.night_products',
  });
  const [sbNightProductsModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.night_products_modal',
  });

  const nightProductsDescription = hooks.useStoryblokRichText(sbNightProducts?.content.night_products_description);

  const products = ((API.isError(sailing.products) ? null : (sailing.products as API.Products))?.NIGHT || []).filter(
    (product) => passengers.some((p) => product.limitedTo && isPassengerInput(p) && product.limitedTo.includes(p.type))
  );

  const onboardPackages: API.ExtendedOnboard[] =
    (API.isError(sailing.onboards) ? null : (sailing.onboards as API.ExtendedOnboard[]))?.filter(
      ({ type }) => type === API.ProductType.NIGHT
    ) || [];

  const arrivalPort = (sbPorts && sbPorts[sailing.arrivalPort]) || sailing.arrivalPort;
  const departurePort = (sbPorts && sbPorts[sailing.departurePort]) || sailing.departurePort;

  const totalCharge = calculateProductsTotalPrice(onboardPackages, sailing.tariff);

  const handleStepperChange = (productCode: string, value: number) => {
    setStepperValue(productCode, value, onboardPackages, add, remove);
  };

  const nightProducts = sortAdultToInfantProductInLimited<API.Product>(products);
  const passengerTypes = nightProducts.map((product) => product.limitedTo).flat();

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode, passengerTypes as API.PassengerType[]);
    }
  }, [modalState.visible]);

  return !!products.length ? (
    <Subgrid ref={ref}>
      <H2>{sbNightProducts?.content.title}</H2>
      {onboardPackages.length <= 0 && <OnboardDescription>{nightProductsDescription}</OnboardDescription>}
      {onboardPackages.length ? (
        <Card vertical={false} image={sbNightProductsModal?.content?.image}>
          <H3>{sbNightProducts?.content.selected_night_products}</H3>
          <ul>
            {sortAdultToInfantOnboardInLimited<API.ExtendedOnboard, API.Product>(onboardPackages, products).map(
              (product) => (
                <SelectedItem key={product.code} {...product} />
              )
            )}
          </ul>
        </Card>
      ) : (
        <></>
      )}
      <DialogDisclosure disclosure as={Button} round {...modalState}>
        {
          sbNightProducts?.content[
            onboardPackages.length > 0 ? 'edit_night_products_button' : 'add_night_products_button'
          ]
        }
      </DialogDisclosure>
      <Modal
        ref={modalRef}
        state={modalState}
        title={formatString(sbNightProductsModal?.content.title, departurePort, arrivalPort)}
        focusOnDialog
        submit={sbNightProductsModal?.content.ready_button}
        image={sbNightProductsModal?.content?.image.image}
        size="input"
        footer={
          <Transition.Height show={!!totalCharge} as={Price}>
            <Transition.Number value={totalCharge} formatter={formats.currency} />
          </Transition.Height>
        }
      >
        {(modalState.animating || modalState.visible) && (
          <Subgrid>
            <P ref={modalRef}>{sbNightProductsModal?.content.description}</P>
            {nightProducts.map((product) => {
              const maxP = passengers.reduce(
                (acc, passenger) =>
                  isPassengerInput(passenger) && product.limitedTo?.includes(passenger.type) ? acc + 1 : acc,
                0
              );
              const max =
                product.capacity.available && product.capacity.available > maxP
                  ? maxP
                  : product.capacity.available || 0;

              return (
                <NightOnboardCard
                  id={`${sailing.sailingCode}-${product.code}`}
                  key={product.code}
                  product={product}
                  tariff={sailing.tariff}
                  setValue={(value) => handleStepperChange(product.code, value)}
                  value={getCount(product.code, onboardPackages)}
                  min={0}
                  max={max}
                  unit={sbNightProductsModal?.content.price_unit}
                  travelFree={sbNightProductsModal?.content.travel_free}
                />
              );
            })}
          </Subgrid>
        )}
      </Modal>
    </Subgrid>
  ) : (
    <></>
  );
};

export default NightOnboard;
