import { ActionFunction, ActionObject, assign, send } from 'xstate';
import gtm from '../../analytics/gtm';
import { LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import { isApiError, isPaymentError, isPassengerInput, Trip, InfoError } from '../types';
import { collectProducts, productsToGA4Products } from '../utils/analyticsUtils';
import { PaymentContext, PaymentEvent } from './paymentMachine';
const loaderId = 'loader';

const actions: Record<
  string,
  ActionObject<PaymentContext, PaymentEvent> | ActionFunction<PaymentContext, PaymentEvent>
> = {
  extractError: assign<PaymentContext, PaymentEvent>({
    error: (_: PaymentContext, event: any) => {
      console.log('Error, event.data:', event.data);
      return isApiError(event.data) || isPaymentError(event.data) ? event.data : undefined;
    },
  }),

  gtmCheckoutOrderSummary: ({ trip, currency }: PaymentContext) => {
    const products = collectProducts(trip);
    // UA
    gtm.clearEcommerce();
    gtm.checkout({
      actionField: { step: 2 },
      products,
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.addShippingInformation({
      products: productsToGA4Products(products, currency),
    });
    gtm.send();
  },

  gtmCheckoutPayment: ({ trip, currency }: PaymentContext) => {
    const products = collectProducts(trip);
    // UA
    gtm.clearEcommerce();
    gtm.checkout({
      actionField: { step: 3, option: 'Nets' },
      products,
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.addPaymentInformation({
      products: productsToGA4Products(products, currency),
    });
    gtm.send();
  },

  gtmPurchase: ({ currency, trip, reservation }: PaymentContext) => {
    const products = collectProducts(trip);
    // UA
    gtm.clearEcommerce();
    gtm.purchase({
      currency,
      products,
      referenceHash: reservation?.bookingDetail.referenceHash,
      totalPrice: ((reservation?.total.total || 0) / 100).toFixed(2),
    });
    gtm.send();

    // GA4
    gtm.clearEcommerce();
    gtm.ga4Purchase({
      currency,
      products: productsToGA4Products(products, currency),
      referenceHash: reservation?.bookingDetail.referenceHash,
      totalPrice: ((reservation?.total.total || 0) / 100).toFixed(2),
    });
    gtm.send();
  },

  loadConfirmationPdf: send<PaymentContext, PaymentEvent>(
    (context, { data }: any) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_CONFIRMATION_PDF,
        data: { context, ...data },
      };
    },
    {
      to: loaderId,
    }
  ),

  setConsent: assign<PaymentContext, PaymentEvent>({
    trip: (ctx, { dataConsent }: any): Trip | undefined =>
      ctx.trip
        ? {
            ...ctx.trip,
            passengers: ctx.trip.passengers.map((p) =>
              isPassengerInput(p) && p.info?.reserverInfo
                ? {
                    ...p,
                    info: {
                      ...p.info,
                      reserverInfo: {
                        ...(p.info?.reserverInfo || {}),
                        termsOfServiceOk: dataConsent,
                        privacyPolicyOk: dataConsent,
                        newsletterOk: false,
                      },
                    },
                  }
                : p
            ),
          }
        : ctx.trip,
    // Do not remove other error codes than datacontent.
    error: ({ error }: PaymentContext) =>
      error && (error.paymentErrorCode === 'dataconsent' || error.field === 'dataconsent') ? undefined : error,
  }),

  onConsentError: assign<PaymentContext, PaymentEvent>({
    error: {
      field: 'dataconsent',
      id: 0,
      source: 'confirmation',
      msg: InfoError.DATA_CONSENT,
    },
  }),
};

export default actions;
