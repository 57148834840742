import React, { FunctionComponent, useEffect } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import Alert from '../../../../design-system/components/Alert';
import Container, { Subgrid } from '../../../../design-system/components/Container';
import Spinner from '../../../../design-system/components/Spinner';
import { H2 } from '../../../../design-system/components/Text';
import { Transition } from '../../../../design-system/helpers/components';
import { hooks } from '../../../../design-system/helpers/mixins';
import * as API from '../../../../fsm/types';
import { getSelected, indexToLeg } from '../../../../fsm/utils/sailingUtils';
import Summary from '../../../common/Summary';
import AccommodationSelect, { AccommodationSelectProps } from './AccommodationSelect';
import ElectricitySelection, { ElectricitySelectionProps } from './ElectricitySelection';
import TicketCard from './TicketCard';
import { settings } from '../../../../settings';
import { isCruiseType, getSecondSailing } from '../../../../fsm/api/cruise';

export interface TripOptionsProps
  extends Omit<AccommodationSelectProps, 'sailing' | 'tariff' | 'title'>,
    Omit<ElectricitySelectionProps, 'sailing' | 'tariff' | 'electricityVehicles'> {
  readonly catalog: API.Catalog;
  readonly setTicketType: (index: number, newTariff: API.Tariff, oldTariff: API.Tariff) => void;
  readonly vehicles?: API.ExtendedVehicle[];
  readonly agreement?: API.Agreement;
  readonly offerCode?: string;
  readonly catalogs: API.Catalog[];
}

const TripOptions: FunctionComponent<TripOptionsProps> = ({
  accommodations,
  accommodationProducts,
  electricityProducts,
  catalog,
  catalogs,
  index,
  passengers,
  agreement,
  send,
  setTicketType,
  offerCode,
  tripType,
  vehicles,
}) => {
  const maybeSelected = getSelected(catalog);
  const ticketType = useRadioState({
    state: maybeSelected.mapOrDefault((_) => _.tariff, API.DEFAULT_TARIFF),
  });

  useEffect(() => {
    switch (ticketType.state) {
      case API.Tariff.SPECIAL:
        setTicketType(indexToLeg(index), API.Tariff.SPECIAL, API.Tariff.STANDARD);
        break;
      case API.Tariff.STANDARD:
      default:
        setTicketType(indexToLeg(index), API.Tariff.STANDARD, API.Tariff.SPECIAL);
        break;
    }
  }, [ticketType.state, index, setTicketType]);

  const [sbTicketTypes, sbTicketTypesRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.ticket_types',
  });

  const sbTicket = hooks.useStoryblokComponents('products/tickets');

  const [sbAccommodations, sbAccommodationsRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.accommodations',
  });
  const [sbLegSummary, sbLegSummaryRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.leg_summary',
  });

  const electricityVehicles = vehicles?.filter(
    (vehicle) => vehicle.type !== API.VehicleType.MCY && vehicle.type !== API.VehicleType.BCY
  );

  const sbCatalogErrorCodes = hooks.useStoryblokDatasource('catalog-error-codes');
  const errorCode = catalog.error as API.SailingError;
  const errorMessage = sbCatalogErrorCodes[errorCode];

  const loadingInProgress = maybeSelected.mapOrDefault((_) => _.meta?.loading, false);

  const shipsWithVehicleRestriction = settings.shipsWithVehicleRestriction;
  const showVehicleRestriction =
    maybeSelected.mapOrDefault((_) => shipsWithVehicleRestriction.includes(_.shipName || ''), false) &&
    vehicles &&
    vehicles?.length > 0;

  const findCruiseParams = maybeSelected.caseOf({
    Nothing: () => ({} as any),
    Just: (sailing) => ({
      departurePort: sailing.departurePort,
      shipName: sailing.shipName,
      selectedTimes: {
        arrivalDate: sailing.arrivalDate,
        arrivalTime: sailing.arrivalTime,
        departureDate: sailing.departureDate,
        departureTime: sailing.departureTime,
      },
    }),
  });

  const returnSails = catalogs[1]?.sailings || [];
  const secondSailing =
    tripType === API.TripType.OVERNIGHT_CRUISE ? getSecondSailing(tripType, findCruiseParams, returnSails) : undefined;

  return (
    <>
      {maybeSelected.isNothing() && errorCode && (
        <Container>
          <Subgrid variant="spacious" cols={3}>
            <Subgrid variant="default">
              <Alert severity="error">{errorMessage}</Alert>
            </Subgrid>
          </Subgrid>
        </Container>
      )}

      {showVehicleRestriction && !loadingInProgress && (
        <Container>
          <Subgrid variant="spacious" cols={4}>
            <Alert severity="warning">{sbCatalogErrorCodes['VEHICLE_RESTRICTION_WARNING']}</Alert>
          </Subgrid>
        </Container>
      )}

      <Container>
        <Subgrid variant="spacious" cols={2}>
          {maybeSelected
            .map((selected) => (
              <Subgrid>
                <Transition.Toggle
                  as={Subgrid}
                  ref={sbAccommodationsRef}
                  toggle={selected.meta?.loading === true && !accommodations.length && !accommodationProducts.length}
                  whenTrue={<Spinner large />}
                  whenFalse={
                    <AccommodationSelect
                      sailing={selected}
                      tariff={selected.tariff}
                      strs={sbAccommodations}
                      {...{
                        index,
                        send,
                        accommodations,
                        passengers,
                        accommodationProducts,
                        disabled: index > 0 && tripType === API.TripType.OVERNIGHT_CRUISE,
                        tripType,
                      }}
                    />
                  }
                />
              </Subgrid>
            ))
            .extract()}
          {(!isCruiseType(tripType) || index === 0) && (
            <Subgrid ref={sbTicketTypesRef}>
              <H2>{sbTicketTypes?.content.title}</H2>
              <Subgrid as={RadioGroup} aria-label="Ticket type" {...ticketType}>
                {(sbTicketTypes?.content.types || []).map((uuid: any) => (
                  <TicketCard
                    key={uuid}
                    catalog={catalog}
                    catalogs={catalogs}
                    ticketType={ticketType}
                    tripType={tripType}
                    uuid={uuid}
                    labelLower={sbTicketTypes?.content.lower_price_label}
                    labelHigher={sbTicketTypes?.content.higher_price_label}
                  />
                ))}
              </Subgrid>
            </Subgrid>
          )}
          {!isCruiseType(tripType) &&
            electricityVehicles &&
            !!electricityVehicles.length &&
            !!electricityProducts.length &&
            maybeSelected
              .map((selected) => (
                <ElectricitySelection
                  sailing={selected}
                  {...{ index, send, electricityProducts, electricityVehicles }}
                />
              ))
              .extract()}
        </Subgrid>
        <Subgrid variant="spacious" hideMobile>
          {maybeSelected
            .map((selected) => (
              <Summary
                small
                agreement={agreement}
                index={catalog.index}
                ref={sbLegSummaryRef}
                sailing={selected}
                sbLegSummary={sbLegSummary}
                sbTicket={sbTicket}
                offerCode={offerCode}
                tripType={tripType}
                otherSailing={secondSailing}
              />
            ))
            .extract()}
        </Subgrid>
      </Container>
    </>
  );
};

export default TripOptions;
