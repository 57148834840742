import { VehicleType } from './types';

type VehicleAgeLimits = {
  [key in VehicleType]: number;
};

type VehicleLengths = {
  [key in VehicleType]: number;
};

export const maxPassengers = 9;

export const vehicleLengths: VehicleLengths = {
  [VehicleType.BCY]: 0,
  [VehicleType.CAR]: 500,
  [VehicleType.MCY]: 0,
  [VehicleType.LCAR]: 900,
  [VehicleType.MBUS]: 600,
  [VehicleType.VAN_LM]: 500,
  [VehicleType.BUS]: 700,
  [VehicleType.CMP]: 900,
};

export const vehicleHeights: VehicleLengths = {
  [VehicleType.BCY]: 0,
  [VehicleType.CAR]: 200,
  [VehicleType.MCY]: 0,
  [VehicleType.LCAR]: 200,
  [VehicleType.MBUS]: 200,
  [VehicleType.VAN_LM]: 200,
  [VehicleType.BUS]: 400,
  [VehicleType.CMP]: 200,
};

export const vehicleWeights: VehicleLengths = {
  [VehicleType.BCY]: 0,
  [VehicleType.CAR]: 500,
  [VehicleType.MCY]: 0,
  [VehicleType.LCAR]: 900,
  [VehicleType.MBUS]: 3000,
  [VehicleType.VAN_LM]: 500,
  [VehicleType.BUS]: 3000,
  [VehicleType.CMP]: 900,
};

export const vehicleAgeLimits: VehicleAgeLimits = {
  [VehicleType.BCY]: 0,
  [VehicleType.CAR]: 18,
  [VehicleType.MCY]: 16,
  [VehicleType.LCAR]: 18,
  [VehicleType.MBUS]: 18,
  [VehicleType.VAN_LM]: 18,
  [VehicleType.BUS]: 18,
  [VehicleType.CMP]: 18,
};
