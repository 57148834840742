import { addDays, startOfISOWeek } from 'date-fns/esm';
import { de, enGB, fi, sv, pl } from 'date-fns/esm/locale';
import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as API from './fsm/types';
import { formatMoney } from './utils/formats';
import I18N from './utils/i18n';

// Omit currently unsupported languages.
export type Language = Exclude<API.Language, API.Language.ES | API.Language.FR | API.Language.IT>;

type LocaleType = {
  locale: string;
  weekdaysShort: string[];
};

const getLocaleData = (fromLocale: Locale) => {
  const weekdayArr = [...Array(7).fill(1)];

  return {
    locale: fromLocale.code,
    weekdaysShort: weekdayArr.map((_, i) =>
      addDays(startOfISOWeek(new Date()), i - 1).toLocaleDateString(fromLocale.code, {
        weekday: 'short',
      })
    ),
  } as LocaleType;
};

const localeData = {
  [API.Language.EN]: getLocaleData(enGB),
  [API.Language.FI]: getLocaleData(fi),
  [API.Language.SV]: getLocaleData(sv),
  [API.Language.DE]: getLocaleData(de),
  [API.Language.PL]: getLocaleData(pl),
} as { [key in Language]: LocaleType };

export type LanguageContextType = {
  currency: API.Currency;
  language: Language;
  localeData: LocaleType;
  formats: {
    currency: (moneyAsCents: number | undefined) => string;
    i18n: I18N;
  };

  setCurrency: (currency: API.Currency) => void;
};

const defaultCurrency = (language?: Language) => {
  switch (language) {
    case API.Language.SV:
      return API.Currency.SEK;
    case API.Language.PL:
      return API.Currency.PLN;
    default:
      return API.Currency.EUR;
  }
};
const defaultLanguage = API.Language.EN;

export const LanguageContext = createContext<LanguageContextType>({
  currency: defaultCurrency(),
  language: defaultLanguage,
  localeData: localeData[defaultLanguage],
  formats: {
    currency: formatMoney(defaultLanguage, defaultCurrency()),
    i18n: new I18N(defaultLanguage),
  },
  setCurrency: () => {},
});

export const setApplicationLanguage = (language: Language) => {
  document.documentElement.lang = language;

  if ('OneTrust' in window) {
    (window as any).OneTrust.changeLanguage(language);
  }
};

export const LanguageProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { language } = useParams<{ language: string }>();
  const enumLanguage = language.toUpperCase() as Language;
  const [currency, setCurrency] = useState(() => defaultCurrency(enumLanguage));

  useEffect(() => {
    setApplicationLanguage(enumLanguage);
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        currency,
        language: enumLanguage,
        localeData: localeData[enumLanguage],
        formats: { currency: formatMoney(enumLanguage, currency), i18n: new I18N(enumLanguage) },
        setCurrency,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
