import React from 'react';
import styled, { css } from 'styled-components';

import { measurement } from '../../helpers/vars';
import { responsive, style } from '../../helpers/mixins';
import { Theme } from '../../helpers/components';

import RouteMap, { RouteMapProps } from '../RouteMap';

import StickyTitle, { StickyTitleProps } from './StickyTitle';

const Wrapper = styled.div<{ hasMap?: boolean; hasImage?: boolean }>`
  overflow: hidden;
  width: 100vw;
  background: ${(props) => props.theme.bg};

  ${(props) =>
    props.hasImage
      ? css`
          min-height: 20vw;
        `
      : css`
          padding: ${measurement.space.departure.area.s} 0 0;

          ${responsive.fontSize(css`
            padding: ${measurement.space.departure.area.l} 0 0;
          `)}
        `}

  ${(props) =>
    props.hasMap &&
    css`
      background: ${style.background.departure.wrapper};
    `}
`;

const Image = styled.div<{ image: string }>`
  height: 30vw;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const DepartureArea = styled.div`
  background: ${style.background.departure.area};
  position: relative;
  z-index: 1;
  padding: ${measurement.space.departure.area.s} 0;

  ${responsive.fontSize(css`
    padding: ${measurement.space.departure.area.l} 0;
  `)}
`;

const List = React.forwardRef<HTMLDivElement, RouteMapProps & { readonly children: React.ReactNode }>(
  ({ children, ...props }, ref) => (
    <Theme.Alternative>
      <Wrapper ref={ref} hasMap>
        <StickyTitle {...props} hasMap />
        <RouteMap {...props} />
        <DepartureArea>{children}</DepartureArea>
      </Wrapper>
    </Theme.Alternative>
  )
);

interface DetailProps extends StickyTitleProps {
  readonly image?: string;
}

const Detail = React.forwardRef<HTMLDivElement, DetailProps>(({ image, ...props }, ref) => (
  <Theme.Alternative>
    <Wrapper ref={ref} hasImage={!!image}>
      {image && <Image {...{ image }} />}
      <StickyTitle {...props} />
    </Wrapper>
  </Theme.Alternative>
));

/** @component */
export default { List, Detail };
