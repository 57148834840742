import styled, { css } from 'styled-components';
import { Button } from 'reakit/Button';

import { color, measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';

import { Name } from './Item';

const Option = styled(Button)<{ as?: any }>`
  flex: 1;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 ${measurement.inset.modal.s};
  cursor: pointer;
  transition: ${animation.transition('background', 'box-shadow')};
  border-bottom: ${style.border.default};

  &:hover {
    background: ${color.bg.tree.active};

    ${Name} {
      ${style.link.hover}
    }
  }

  &:focus {
    box-shadow: none;
    outline: ${style.border.focus};
    outline-offset: -${measurement.size.border.strong};
  }

  ${responsive.fontSize(css`
    padding: 0 ${measurement.inset.modal.l};
  `)}

  &[aria-disabled] {
    cursor: not-allowed;
    color: ${color.text.disabled};

    &,
    &:hover {
      background: ${color.bg.alt};
    }

    ${Name} {
      background: none;
      color: inherit;
    }
  }
`;

export const Title = styled(Option)`
  border-bottom: ${style.border.default};
  justify-content: flex-start;
`;

export default Option;
