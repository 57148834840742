import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { color, measurement } from '../helpers/vars';
import { responsive, style, Theme } from '../helpers/mixins';

import { StyledButton } from './Button';
import { H3 } from './Text';
import Icon, { SVG } from './Icon';

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

interface AlertProps {
  readonly children: React.ReactNode;
  readonly severity: AlertSeverity;
}

const Container = styled.div<AlertProps>`
  padding: ${measurement.inset.alert.s};
  position: relative;
  border-radius: ${measurement.size.radius.default};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${measurement.size.icon.default};
    padding: ${measurement.space.icon.alert};
  }

  ${SVG} {
    position: absolute;
    top: 0;
    left: 0;
    margin: ${measurement.space.icon.alert};
  }

  ${StyledButton} {
    margin: ${measurement.space.button.alert};
    display: block;
  }

  ${responsive.fontSize(css`
    padding: ${measurement.inset.alert.l};
  `)}

  ${({ severity, theme }) => css`
    border: ${style.borderStr(theme.alert[severity])};
    background: ${theme.key === Theme.INVERTED ? transparentize(0.5, theme.alert[severity]) : color.bg.default};

    &::before {
      background: ${theme.key === Theme.INVERTED ? theme.alert[severity] : transparentize(0.9, theme.alert[severity])};
    }

    ${SVG} {
      color: ${theme.key === Theme.INVERTED ? theme.icon : theme.alert[severity]};
    }
  `}
`;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ children, ...props }, ref) => (
  <Container ref={ref} {...props}>
    <Icon name={props.severity} />
    {typeof children === 'string' ? <H3>{children}</H3> : children}
  </Container>
));

export default Alert;
