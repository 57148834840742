import { WrapperButton, Wrapper, Input, Label, Error, Hint, MaskSelect, InnerSelect } from './StyledInput';

export { default as Theme } from './Theme';
export { default as GlobalStyle } from './GlobalStyle';
export { default as Transition } from './Transition';
export { default as Center } from './Center';
export { default as Sticky } from './Sticky';

export const StyledInput = {
  WrapperButton,
  Wrapper,
  Input,
  Label,
  Error,
  Hint,
  MaskSelect,
  InnerSelect,
};
