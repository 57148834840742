import React from 'react';
import styled from 'styled-components';
import { DialogDisclosure, DialogStateReturn } from 'reakit/Dialog';

import { measurement } from '../../helpers/vars';
import Button, { StyledButton } from '../Button';

export interface ButtonAreaSharedProps {
  readonly onClick?: () => void;
  readonly disclosureState?: DialogStateReturn;
  readonly removeButton?: {
    readonly onClick: () => void;
    readonly label?: string;
  };
}

interface ButtonAreaProps extends ButtonAreaSharedProps {
  readonly secondary?: boolean;
}

export const StyledButtonArea = styled.div`
  display: flex;
  overflow: hidden;

  ${StyledButton} {
    flex: 1;
    border-radius: 0;
    height: 3rem;

    &:first-child {
      border-bottom-left-radius: ${measurement.size.radius.default};
    }

    &:last-child {
      border-bottom-right-radius: ${measurement.size.radius.default};
    }

    &.icon-only {
      flex: 0 0 3rem;
    }
  }
`;

const ButtonArea: React.FunctionComponent<ButtonAreaProps> = ({ disclosureState, removeButton, ...props }) => (
  <StyledButtonArea>
    {removeButton && <Button warning icon="remove" {...removeButton} />}
    {disclosureState ? (
      <DialogDisclosure small as={Button} {...{ ...disclosureState, ...props }} />
    ) : (
      <Button small {...props} />
    )}
  </StyledButtonArea>
);

export default ButtonArea;
