import React, { FunctionComponent } from 'react';

import { ExtendedSailing } from '../../../fsm/types';
import { formatString } from '../../../utils/formats';

import { hooks } from '../../../design-system/helpers/mixins';

import { Subgrid } from '../../../design-system/components/Container';
import { H2, H3, Lead } from '../../../design-system/components/Text';
import Card, { CardProps } from '../../../design-system/components/Card';
import RichText from '../../../design-system/components/RichText';

interface ServiceCardProps extends CardProps {
  readonly uuid: string;
  readonly sailing: ExtendedSailing;
  readonly leg: number;
}

interface OnboardServicesProps {
  readonly sailing: ExtendedSailing;
  readonly leg: number;
}

const ServiceCard: FunctionComponent<ServiceCardProps> = ({ uuid, ...props }) => {
  const [sbService, ref] = hooks.useStoryblokComponent<HTMLParagraphElement>({ uuid });
  const description = hooks.useStoryblokRichText(sbService?.content.description);
  const title = sbService?.content.title;

  return (
    <Card ref={ref} image={sbService?.content.image} collapse={1} title={title} {...props}>
      <H3>{title}</H3>
      {description && <RichText>{description}</RichText>}
    </Card>
  );
};

const OnboardServices: FunctionComponent<OnboardServicesProps> = ({ sailing, leg }) => {
  const sbShips = hooks.useStoryblokDatasource('ships');
  const [sbOnboardServices, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.onboard_services',
  });
  const [sbServicesPerShip] = hooks.useStoryblokComponent<HTMLDivElement | HTMLParagraphElement>({
    fullSlug: `products/onboards/services-per-ship/${sailing.shipName}`,
  });

  const availableServices = sbServicesPerShip?.content.services_available;
  const shipName = (sbShips && sailing.shipName && sbShips[sailing.shipName]) || sailing.shipName;

  return availableServices ? (
    <Subgrid ref={ref}>
      <H2>{sbOnboardServices?.content.title}</H2>
      <Lead>{formatString(sbOnboardServices?.content.onboard_services_description, shipName)}</Lead>
      {availableServices.map((uuid: string) => (
        <ServiceCard
          key={uuid}
          {...{ uuid }}
          showLessLabel={sbOnboardServices?.content.show_less_button}
          showMoreLabel={sbOnboardServices?.content.show_more_button}
          sailing={sailing}
          leg={leg}
        />
      ))}
    </Subgrid>
  ) : (
    <></>
  );
};

export default OnboardServices;
