import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { Maybe } from 'purify-ts';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { RadioGroup, useRadioState } from 'reakit/Radio';
import { Sender } from 'xstate';
import Container from '../../../../design-system/components/Container';
import DepartureContainer from '../../../../design-system/components/DepartureContainer';
import DeparturePicker, { DepartureDay } from '../../../../design-system/components/DeparturePicker';
import Icon from '../../../../design-system/components/Icon';
import RadioCard from '../../../../design-system/components/RadioCard';
import Spinner from '../../../../design-system/components/Spinner';
import { H3, P, Price } from '../../../../design-system/components/Text';
import { Transition } from '../../../../design-system/helpers/components';
import { hooks } from '../../../../design-system/helpers/mixins';
import { CatalogContext, CatalogEvent } from '../../../../fsm/catalog/catalogMachine';
import * as API from '../../../../fsm/types';
import { ErrorCode, PriceError, TariffPrice, TripType } from '../../../../fsm/types';
import {
  extractFirstErrorFromSailings,
  getCheapestChargeInfo,
  getCheapestSailing,
  getSailingErrors,
  getSelected,
  inNonblocking,
  pickCheaperPrice,
  sailingIsBefore,
} from '../../../../fsm/utils/sailingUtils';
import { LanguageContext } from '../../../../Language.context';
import { isDaySailing } from '../../../../settings';
import { formatString } from '../../../../utils/formats';
import I18N from '../../../../utils/i18n';
import { usePrevious } from '../../../common/usePrevious';
import { findCruisePairMaybe, isCruiseType } from '../../../../fsm/api/cruise';
import { getCruisePrice } from '../../../../utils/priceCalculation';
import { SailingChangeTripDropdown } from './SailingChangeTripDropdown';

interface SailingDate {
  date: Date;
  error?: string;
  // When price is
  //  - undefined it means loading
  //  - null it means error or no departures
  disabled?: boolean;

  price?: string | null;
  sailingsOnDate: API.ExtendedSailing[];
  value: string;
}

export interface SailingSelectProps {
  readonly select: any;
  readonly loadMore: any;
  readonly loadChangeTripSailings: any;
  readonly sailings: API.ExtendedSailing[];
  readonly form: API.Form;
  readonly index: number;
  readonly catalog: API.Catalog;
  readonly disableBefore?: API.ExtendedSailing;
  readonly tripType: TripType;
  readonly send: Sender<CatalogEvent>;
  readonly catalogs: API.Catalog[];
  readonly tariff: API.Tariff;
  readonly context: CatalogContext;
}

const SailingSelect: FunctionComponent<SailingSelectProps> = ({
  select,
  send,
  loadMore,
  loadChangeTripSailings,
  index,
  sailings,
  form,
  catalog,
  catalogs,
  disableBefore,
  tripType,
  tariff,
  context,
}) => {
  const { formats, language } = useContext(LanguageContext);
  const i18n = new I18N(language);
  const selectNewDeparture = (date: SailingDate) => {
    const possibleSailings = disableBefore
      ? date.sailingsOnDate.filter((sailing) => !sailingIsBefore(sailing, disableBefore))
      : date.sailingsOnDate;
    let pick = getCheapestSailing(possibleSailings);

    // If all departures does not have price, try to select first day time departure, then just first one from list.
    // We do not know prices before individual sailing (prices) has been reloaded.
    if (!pick) {
      pick = possibleSailings.find((sailing) => isDaySailing(sailing));
      if (!pick) {
        pick = possibleSailings[0];
      }
    }

    const sailingsWithoutPrices = date.sailingsOnDate
      .filter((s: API.ExtendedSailing) => API.isError(s.price) && s.sailingCode !== pick?.sailingCode) // Avoid requesting same departure twice
      .map((s: API.ExtendedSailing) => s.sailingCode);

    if (sailingsWithoutPrices.length) {
      // console.log('selectNewDeparture:', date.value, 'sailingsWithoutPrices:', sailingsWithoutPrices.length, sailingsWithoutPrices)
      const message = {
        type: 'RELOAD_MISSING_PRICES',
        data: { index, sailingCodes: sailingsWithoutPrices },
      } as CatalogEvent;
      // console.log('selectNewDeparture, send message:', message);
      send(message);
    }

    if (pick && pick.sailingCode !== sailingState.state) {
      // console.log('selectNewDeparture select:', pick);
      select(index, pick.sailingCode);
    }
  };

  const sbShips = hooks.useStoryblokDatasource('ships');
  const sbErrorCodes = hooks.useStoryblokDatasource('error-codes');

  const sbCatalogErrorCodes = hooks.useStoryblokDatasource('catalog-error-codes');
  const [sbSailingSelect, sbSailingSelectRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.sailing_select',
  });

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  const [dates, setDates] = useState<Date[]>([]);
  const [departureDates, setDepartureDates] = useState<SailingDate[]>([]);
  const [fetchingPricesDone, setfetchingPricesDone] = useState<boolean>(false);

  const initial = getSelected(catalog);
  const dateState = useRadioState({ state: initial.map((_) => _.departureDate).extract() });
  const sailingState = useRadioState({ state: initial.map((_) => _.sailingCode).extract() });

  const prevDateState = usePrevious(dateState.state);
  const prevSailingState = usePrevious(sailingState.state);

  // should set day only if there's sailings for that date, otherwise next one and then next one...
  const setSelectedDate = dateState.setState;
  const setSelectedSailing = sailingState.setState;
  const { params } = form;

  // return sailing if it is valid as a cruise departure, undefined otherwise
  const getValidCruiseDeparture = (
    departureSailing: API.ExtendedSailing,
    returnSails: API.ExtendedSailing[]
  ): API.ExtendedSailing | undefined => {
    const sailingsAfterDeparture = returnSails.filter((sailing) => {
      const comparisonStart = parseISO(sailing.departureDate + ' ' + sailing.departureTime).getTime();
      const sailingStart = parseISO(departureSailing.departureDate + ' ' + departureSailing.departureTime).getTime();
      return sailingStart < comparisonStart;
    });

    const maybePair = findCruisePairMaybe(
      departureSailing.departurePort,
      departureSailing.shipName,
      {
        arrivalDate: departureSailing.arrivalDate,
        arrivalTime: departureSailing.arrivalTime,
        departureDate: departureSailing.departureDate,
        departureTime: departureSailing.departureDate,
      },
      sailingsAfterDeparture,
      tripType
    );

    return maybePair.caseOf({
      Just: (pair) => {
        if (
          API.isError(pair.price) ||
          (API.isError((pair.price as TariffPrice).SPECIAL) && API.isError((pair.price as TariffPrice).STANDARD))
        ) {
          return undefined;
        }
        return departureSailing;
      },
      Nothing: () => undefined,
    });
  };

  // return first departure that is valid as a cruise departure, undefined otherwise
  const getFirstValidCruiseDeparture = (sailingsOnDate: API.ExtendedSailing[]): API.ExtendedSailing | undefined => {
    const returnSails = catalogs[1]?.sailings || [];
    return sailingsOnDate.find((onDateSailing) => getValidCruiseDeparture(onDateSailing, returnSails));
  };

  useEffect(() => {
    const currentDates = eachDayOfInterval({
      start: parseISO(params.startDate),
      end: parseISO(params.endDate),
    });

    setDates(currentDates);

    setDepartureDates(
      currentDates.map((date) => ({
        date,
        price: undefined, // = Loading
        sailingsOnDate: [],
        value: format(date, 'yyyy-MM-dd'),
      }))
    );
  }, [params.startDate, params.endDate]);

  useEffect(() => {
    setfetchingPricesDone(catalogs[1]?.fetchingPricesDone || false);
    if (sailings) {
      const sailingDates = dates.map((date) => {
        const value = format(date, 'yyyy-MM-dd');
        const sailingsOnDate = sailings.filter(({ departureDate }) => departureDate === value);
        const firstSailingError = sailingsOnDate.every((s) => getSailingErrors(s).length > 0)
          ? extractFirstErrorFromSailings(sailingsOnDate)
          : undefined;

        // When no sailings, price is null and departure day is disabled.
        // Overlapping may only happen, when there is at least one another sailing to compare with.

        if (sailingsOnDate.length <= 0 || firstSailingError) {
          const error = firstSailingError && getFirstError([firstSailingError]);
          return {
            date,
            sailingsOnDate,
            value,
            error,
            disabled: !inNonblocking(firstSailingError), // enable clicking if appears on list
            price: null, // = Error or no depatures
          } as SailingDate;
        } else {
          const sailingOverlaps =
            disableBefore && sailingsOnDate.length
              ? sailingsOnDate.every((s) => sailingIsBefore(s, disableBefore))
              : false;
          let cruisePairExists = true;
          if (tripType === TripType.OVERNIGHT_CRUISE && !sailingOverlaps && fetchingPricesDone) {
            const firstValidCruisePair = getFirstValidCruiseDeparture(sailingsOnDate);
            cruisePairExists = firstValidCruisePair != undefined;
          }
          const cheapest = getCheapestChargeInfo(sailingsOnDate);
          const price = sailingOverlaps || !cruisePairExists ? null : cheapest && `${cheapest.charge}`;
          const disabled = sailingOverlaps || !cruisePairExists;

          return {
            date,
            sailingsOnDate,
            value,
            disabled: disabled,
            error: sailingOverlaps ? sbCatalogErrorCodes[API.ErrorCode.SAILING_OVERLAPS] : undefined,
            price,
          } as SailingDate;
        }
      }) as SailingDate[];

      setDepartureDates(sailingDates);
    }
  }, [dates, disableBefore, formats, sailings, setDepartureDates]);

  useEffect(() => {
    // Fire change when previous state is known and it has changed.
    // This prevents unnecessary selection on first render.
    if (prevSailingState && sailingState.state !== prevSailingState) {
      select(index, sailingState.state);
    }
  }, [sailingState.state, prevSailingState, index, select]);

  useEffect(() => {
    if (fetchingPricesDone && isCruiseType(tripType)) {
      const searchDate = findDate(departureDates, dateState.state?.toString());
      if (searchDate && searchDate.sailingsOnDate) {
        getSelected(catalog).ifJust((selected) => {
          if (!selected.meta?.loading && !getValidCruiseDeparture(selected, catalogs[1]?.sailings)) {
            const validCruiseDeparture = getFirstValidCruiseDeparture(searchDate.sailingsOnDate);
            if (validCruiseDeparture) {
              select(index, validCruiseDeparture?.sailingCode, false);
            }
          }
        });
      }
    }
  }, [catalog, sailingState, fetchingPricesDone, dateState.state, departureDates]);

  useEffect(() => {
    getSelected(catalog).ifJust(({ departureDate, sailingCode }) => {
      if (sailingCode !== prevSailingState) {
        setSelectedSailing(sailingCode);
        setSelectedDate(departureDate);
      }
      return undefined;
    });
  }, [catalog, prevSailingState, setSelectedDate, setSelectedSailing]);

  const findDate = (departureDates: SailingDate[], date: string | undefined): SailingDate | undefined => {
    return departureDates.find((d) => {
      return format(d.date, 'yyyy-MM-dd') === date;
    });
  };

  useEffect(() => {
    if (departureDates.length && prevDateState && dateState.state !== prevDateState) {
      const searchDate = findDate(departureDates, dateState.state?.toString());

      if (searchDate && searchDate.sailingsOnDate) {
        setSelectedDate(searchDate.value);

        selectNewDeparture(searchDate);
      }
    }
  }, [
    dateState.state,
    departureDates,
    disableBefore,
    index,
    prevDateState,
    sailingState.state,
    select,
    setSelectedDate,
  ]);

  const handleVisible = {
    handleFirstVisible: useCallback(() => {
      loadMore(index, -1);
    }, [loadMore, index]),
    handleLastVisible: useCallback(() => {
      loadMore(index, 1);
    }, [loadMore, index]),
  };

  const getFirstError = (errors: any[]) => {
    const firstError = errors[0];
    // console.log('getFirstError, errors', errors, 'firstError', firstError);
    if (firstError === ErrorCode.NO_PRICE_AVAILABLE) {
      return sbCatalogErrorCodes[PriceError.NO_PRICE_RELOAD_PRICE];
    }
    if (firstError === PriceError.SAILING_ONBOARDING) {
      return sbErrorCodes[firstError];
    }
    return firstError ? sbCatalogErrorCodes[firstError] : undefined; // sbCatalogErrorCodes[PriceError.UNSPECIFIED];
  };

  return (
    <DepartureContainer.List
      ref={sbSailingSelectRef}
      origin={params.departurePort}
      destination={params.arrivalPort}
      isCruise={tripType === TripType.OVERNIGHT_CRUISE}
    >
      <SailingChangeTripDropdown
        isPriceLoading={!context?.productPricesLoaded || !context?.ticketPricesLoaded}
        forms={context?.searchParams.forms || []}
        portMap={context?.searchParams.portMap || []}
        range={context?.searchParams.range || 1}
        isVehicleSelected={context?.vehicles?.length > 0}
        tripType={tripType}
        loadChangeTripSailings={loadChangeTripSailings}
        showChangeTripText={sbSearchForm?.content['change_travel_days_button']}
        hideChangeTripText={sbSearchForm?.content['hide_change_travel_days_button']}
        searchButtonText={sbSearchForm?.content['search_button']}
      />
      {(index === 0 || !isCruiseType(tripType)) && (
        <DeparturePicker
          state={dateState}
          label={sbSailingSelect?.content.aria_departure_picker_label || 'Select departure date'}
          {...handleVisible}
        >
          {departureDates.map((date) => (
            <DepartureDay
              noDepartures={sbCatalogErrorCodes[API.SailingError.NO_DEPARTURES]}
              fromLabel={sbSailingSelect?.content.price_starts_from_label || 'From'}
              state={dateState}
              key={date.value}
              catalogs={catalogs}
              tripType={tripType}
              tariff={tariff}
              {...date}
              sailingsOnDate={date.sailingsOnDate}
            />
          ))}
        </DeparturePicker>
      )}
      {departureDates.map((date) => {
        const isSomeLoading = date.sailingsOnDate?.some((sailing) => sailing?.meta?.loading === true);
        return (
          date.value === dateState.state && (
            <RadioGroup
              as={Container}
              variant="departure"
              key={date.value}
              aria-label={formatString(sbSailingSelect?.content.aria_sailings_for_label, date.date)}
              {...sailingState}
            >
              {date.sailingsOnDate?.map((sailing, sailingIndex) => {
                // Show only the selected sailing when booking a CRUISE
                // and selecting a return (index === 1) sailing
                if (index === 1 && isCruiseType(tripType) && sailing?.sailingCode !== catalog?.selected?.sailingCode) {
                  return null;
                }
                const isBefore = disableBefore && sailingIsBefore(sailing, disableBefore);

                const errors = getSailingErrors(sailing);

                const charge = pickCheaperPrice(sailing)
                  .map((_) => _.charge)
                  .extract();

                const otherSailings = catalogs[1]?.sailings || [];

                const cruiseCharge = getCruisePrice(tripType, sailing, otherSailings, tariff);

                if (isCruiseType(tripType) && API.isError(cruiseCharge)) {
                  errors.push(cruiseCharge);
                }

                const formattedPrice =
                  tripType === TripType.OVERNIGHT_CRUISE
                    ? formats.currency(!API.isError(cruiseCharge) ? cruiseCharge : 0)
                    : formats.currency(charge);

                const showSpinner = sailing?.meta?.loading === true;
                const errorMsg = isBefore
                  ? sbCatalogErrorCodes[API.ErrorCode.SAILING_OVERLAPS]
                  : errors.length > 0
                  ? getFirstError(errors)
                  : undefined;

                const maybe = Maybe.fromNullable(sailing);
                const findCruiseParams = maybe.caseOf({
                  Nothing: () => ({} as any),
                  Just: (sailing) => ({
                    departurePort: sailing.departurePort,
                    shipName: sailing.shipName,
                    selectedTimes: {
                      arrivalDate: sailing.arrivalDate,
                      arrivalTime: sailing.arrivalTime,
                      departureDate: sailing.departureDate,
                      departureTime: sailing.departureTime,
                    },
                  }),
                });

                let selectedSailing: Maybe<API.ExtendedSailing> = Maybe.fromNullable();
                if (findCruiseParams.selectedTimes) {
                  selectedSailing = findCruisePairMaybe(
                    findCruiseParams.departurePort,
                    findCruiseParams.shipName,
                    findCruiseParams.selectedTimes,
                    otherSailings,
                    tripType
                  );
                }

                const secondSailing = selectedSailing.caseOf({
                  Nothing: () => ({} as any),
                  Just: (otherSailing) => otherSailing,
                });

                const arrivalDetails =
                  tripType === TripType.OVERNIGHT_CRUISE && secondSailing.arrivalDate && secondSailing.arrivalTime
                    ? `${i18n.localizeDate(parseISO(secondSailing.arrivalDate))} ${secondSailing.arrivalTime}`
                    : `${i18n.localizeDate(parseISO(sailing.arrivalDate))} ${sailing.arrivalTime}`;

                const departureDetails = `${i18n.localizeDate(parseISO(sailing.departureDate))} ${
                  sailing.departureTime
                }`;

                return (
                  <RadioCard
                    key={sailing.sailingCode}
                    state={sailingState}
                    loading={isSomeLoading}
                    disabled={isBefore || (errors.length > 0 && errors.some((e) => !inNonblocking(e))) || isSomeLoading}
                    value={sailing.sailingCode}
                  >
                    <H3>
                      {departureDetails} – {arrivalDetails}
                    </H3>
                    {sailing.shipName && (
                      <>
                        <Icon name="ship" label="ship" large inline inheritColor />
                        <div>{sbShips[sailing.shipName] || sailing.shipName}</div>
                      </>
                    )}

                    {!showSpinner && errorMsg ? (
                      <div key={sailingIndex}>
                        <P>{errorMsg}</P>
                      </div>
                    ) : (
                      <Price>
                        <Transition.Toggle
                          toggle={!showSpinner}
                          as="span"
                          whenFalse={<Spinner small inline />}
                          whenTrue={formattedPrice}
                        />
                      </Price>
                    )}
                  </RadioCard>
                );
              })}
            </RadioGroup>
          )
        );
      })}
    </DepartureContainer.List>
  );
};

export default SailingSelect;
