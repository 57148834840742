import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spring, Transition as SpringTransition } from 'react-spring/renderprops';

interface SingleTransitionProps {
  readonly as?: any;
  readonly onRest?: () => void;
}

type Whitespace = string | number | Whitespace[];

interface HeightProps extends SingleTransitionProps {
  readonly show: boolean;

  readonly margin?: Whitespace;
  readonly padding?: Whitespace;
  readonly marginFrom?: Whitespace;
  readonly paddingFrom?: Whitespace;
}

interface HeightGroupProps {
  readonly items: React.ReactNode[];
  readonly keys?: any[];
}

interface NumberProps {
  readonly value: number;
  readonly initial?: number;
  readonly formatter?: (arg: number) => string;
}

interface ToggleProps extends SingleTransitionProps {
  readonly toggle: boolean;
  readonly whenTrue: any;
  readonly whenFalse: any;
}

interface StyleProps {
  style: {
    opacity: number;
    overflow: string;
    height: number;
    margin: number;
    padding: number;
  };
}

const getWhitespaceStr = (prop: 'margin' | 'padding', value: Whitespace) =>
  Array.isArray(value)
    ? {
        [`${prop}Top`]: value[0],
        [`${prop}Bottom`]: value[1],
      }
    : {
        [`${prop}Top`]: value,
        [`${prop}Bottom`]: value,
      };

const getWhitespace = (margin: Whitespace, padding: Whitespace) => ({
  ...getWhitespaceStr('margin', margin),
  ...getWhitespaceStr('padding', padding),
});

const Height: FunctionComponent<HeightProps> = ({
  show,
  as,
  onRest,
  margin,
  padding,
  marginFrom,
  paddingFrom,
  ...props
}) => {
  const El = as || 'div';

  return (
    <SpringTransition
      {...{ onRest }}
      items={show}
      from={{ opacity: 0, overflow: 'visible', height: 0 }}
      enter={{
        opacity: 1,
        height: 'auto',
        ...getWhitespace(margin || 0, padding || 0),
      }}
      leave={{
        opacity: 0,
        height: 0,
        ...getWhitespace(marginFrom || 0, paddingFrom || 0),
      }}
    >
      {(show) => show && ((style) => <El {...{ style, ...props }} />)}
    </SpringTransition>
  );
};

const HeightGroup: FunctionComponent<HeightGroupProps> = ({ items, keys }) => {
  return (
    <SpringTransition
      {...{ items }}
      keys={keys || items.map((_, k) => `${k}`)}
      from={{ opacity: 0, overflow: 'visible', height: 0 }}
      enter={{ opacity: 1, height: 'auto' }}
      leave={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
    >
      {(item) => (style) =>
        React.isValidElement(item) && React.cloneElement(item as React.ReactElement<StyleProps>, { style })}
    </SpringTransition>
  );
};

const Number: FunctionComponent<NumberProps> = ({ value, initial, formatter }) => {
  const [previous, setPrevious] = useState(initial);
  useEffect(() => setPrevious(value), [setPrevious, value]);

  return (
    <Spring from={{ number: previous }} to={{ number: value }}>
      {({ number }) => <>{formatter ? formatter(number) : number}</>}
    </Spring>
  );
};

const Toggle = React.forwardRef<HTMLElement, ToggleProps>(({ toggle, as, whenTrue, onRest, whenFalse }, ref) => {
  const El = as || 'div';

  return (
    <SpringTransition
      {...{ onRest }}
      items={toggle}
      from={{ position: 'absolute', opacity: 0 }}
      enter={{ position: 'relative', opacity: 1 }}
      leave={{ position: 'absolute', opacity: 0 }}
    >
      {(toggle) =>
        toggle
          ? (style) => (
              <El {...{ style }} ref={ref}>
                {whenTrue}
              </El>
            )
          : (style) => (
              <El {...{ style }} ref={ref}>
                {whenFalse}
              </El>
            )
      }
    </SpringTransition>
  );
});

const Opacity: FunctionComponent<HeightProps> = ({ show, as, ...props }) => {
  const El = as || 'div';

  return (
    <SpringTransition items={show} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
      {(show) => show && ((style) => <El {...{ style, ...props }} />)}
    </SpringTransition>
  );
};

export default {
  Height,
  HeightGroup,
  Number,
  Toggle,
  Opacity,
};
