import { Interpreter, Machine, send, sendParent } from 'xstate';
import logger from '../../utils/logging';
import {
  fetchAccommodationPrices,
  fetchConfirmationPdf,
  fetchConfirmationPdfForUrl,
  fetchReservation,
  GetCatalogWithParams,
  GetInitialProducts,
  GetPricesForSailing,
  GetProductPrices,
  GetSelectedPricing,
  GetTimetablePrices,
} from '../api';
import { CatalogContext } from '../catalog/catalogMachine';
import { MyBookingContext } from '../my-booking/myBookingMachine';
import { PaymentContext } from '../payment/paymentMachine';
import * as API from '../types';
import actions from './actions';
import guards from './guards';

export enum LOADER_ACTION_EVENTS {
  LOAD_ACCOMMODATION_PRICES = 'LOAD_ACCOMMODATION_PRICES',
  LOAD_CATALOGS = 'LOAD_CATALOGS',
  LOAD_CATALOG_PRICES = 'LOAD_CATALOG_PRICES',
  LOAD_CATALOG_PRODUCTS = 'LOAD_CATALOG_PRODUCTS',
  LOAD_CONFIRMATION_PDF = 'LOAD_CONFIRMATION_PDF',
  LOAD_CONFIRMATION_PDF_FOR_URL = 'LOAD_CONFIRMATION_PDF_FOR_URL',
  LOAD_PRODUCT_PRICES = 'LOAD_PRODUCT_PRICES',
  LOAD_RESERVATION = 'LOAD_RESERVATION',
  RELOAD_SELECTED = 'RELOAD_SELECTED',
  RELOAD_MISSING = 'RELOAD_MISSING',
}

export enum LOADER_RESPONSE_EVENTS {
  LOADED_ACCOMMODATION_PRICES = 'LOADED_ACCOMMODATION_PRICES',
  LOADED_CATALOGS = 'LOADED_CATALOGS',
  LOADED_CATALOG_PRODUCTS = 'LOADED_CATALOG_PRODUCTS',
  DONE_CATALOG_PRODUCTS = 'DONE_CATALOG_PRODUCTS',
  LOADED_CATALOG_PRICES = 'LOADED_CATALOG_PRICES',
  DONE_CATALOG_PRICES = 'DONE_CATALOG_PRICES',
  LOADED_CONFIRMATION_PDF = 'LOADED_CONFIRMATION_PDF',
  LOADED_CONFIRMATION_PDF_FOR_URL = 'LOADED_CONFIRMATION_PDF_FOR_URL',
  LOADED_PRODUCT_PRICES = 'LOADED_PRODUCT_PRICES',
  DONE_PRODUCT_PRICES = 'DONE_PRODUCT_PRICES',
  LOADED_RESERVATION = 'LOADED_RESERVATION',
  RELOADED_SELECTED = 'RELOADED_SELECTED',
  ON_RELOAD_MISSING = 'ON_RELOAD_MISSING',
  DONE_RELOAD_MISSING = 'DONE_RELOAD_MISSING',
  LOADER_ERROR = 'LOADER_ERROR',
}

export type LoaderAction<TContext, EventType extends LOADER_ACTION_EVENTS, DataType = { [key: string]: any }> = {
  type: EventType;
  data: { context: TContext } & DataType;
};

const SPAWN_MAP = {
  [LOADER_ACTION_EVENTS.LOAD_ACCOMMODATION_PRICES]: {
    src: fetchAccommodationPrices,
    answer: LOADER_RESPONSE_EVENTS.LOADED_ACCOMMODATION_PRICES,
    done: null,
  },
  [LOADER_ACTION_EVENTS.LOAD_CATALOGS]: {
    src: GetCatalogWithParams,
    answer: LOADER_RESPONSE_EVENTS.LOADED_CATALOGS,
    done: null,
  },
  [LOADER_ACTION_EVENTS.LOAD_CATALOG_PRODUCTS]: {
    src: GetInitialProducts,
    answer: LOADER_RESPONSE_EVENTS.LOADED_CATALOG_PRODUCTS,
    done: LOADER_RESPONSE_EVENTS.DONE_CATALOG_PRODUCTS,
  },
  [LOADER_ACTION_EVENTS.LOAD_CATALOG_PRICES]: {
    src: GetTimetablePrices,
    answer: LOADER_RESPONSE_EVENTS.LOADED_CATALOG_PRICES,
    done: LOADER_RESPONSE_EVENTS.DONE_CATALOG_PRICES,
  },
  [LOADER_ACTION_EVENTS.LOAD_CONFIRMATION_PDF]: {
    src: fetchConfirmationPdf,
    answer: LOADER_RESPONSE_EVENTS.LOADED_CONFIRMATION_PDF,
    done: null,
  },
  [LOADER_ACTION_EVENTS.LOAD_CONFIRMATION_PDF_FOR_URL]: {
    src: fetchConfirmationPdfForUrl,
    answer: LOADER_RESPONSE_EVENTS.LOADED_CONFIRMATION_PDF_FOR_URL,
    done: null,
  },
  [LOADER_ACTION_EVENTS.LOAD_PRODUCT_PRICES]: {
    src: GetProductPrices,
    answer: LOADER_RESPONSE_EVENTS.LOADED_PRODUCT_PRICES,
    done: LOADER_RESPONSE_EVENTS.DONE_PRODUCT_PRICES,
  },
  [LOADER_ACTION_EVENTS.LOAD_RESERVATION]: {
    src: fetchReservation,
    answer: LOADER_RESPONSE_EVENTS.LOADED_RESERVATION,
    done: null,
  },
  [LOADER_ACTION_EVENTS.RELOAD_SELECTED]: {
    src: GetSelectedPricing,
    answer: LOADER_RESPONSE_EVENTS.RELOADED_SELECTED,
    done: LOADER_RESPONSE_EVENTS.RELOADED_SELECTED,
  },
  [LOADER_ACTION_EVENTS.RELOAD_MISSING]: {
    src: GetPricesForSailing,
    answer: LOADER_RESPONSE_EVENTS.ON_RELOAD_MISSING,
    done: LOADER_RESPONSE_EVENTS.DONE_RELOAD_MISSING,
  },
};

//error
export type LOADER_ERROR = {
  type: LOADER_RESPONSE_EVENTS.LOADER_ERROR;
  data: any;
};

//instruction
export type LOAD_CATALOGS = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.LOAD_CATALOGS>;
//response
export type LOADED_CATALOGS = {
  type: LOADER_RESPONSE_EVENTS.LOADED_CATALOGS;
  result: any;
  errors: any;
};

//instruction
export type LOAD_CATALOG_PRODUCTS = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.LOAD_CATALOG_PRODUCTS>;
//response
export type LOADED_CATALOG_PRODUCTS = {
  type: LOADER_RESPONSE_EVENTS.LOADED_CATALOG_PRODUCTS;
  data: any;
};
export type DONE_CATALOG_PRODUCTS = {
  type: LOADER_RESPONSE_EVENTS.DONE_CATALOG_PRODUCTS;
};

//instruction
export type LOAD_CATALOG_PRICES = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.LOAD_CATALOG_PRICES>;
//response
export type LOADED_CATALOG_PRICES = {
  type: LOADER_RESPONSE_EVENTS.LOADED_CATALOG_PRICES;
  result: any;
  errors: any;
};
export type DONE_CATALOG_PRICES = {
  type: LOADER_RESPONSE_EVENTS.DONE_CATALOG_PRICES;
};

//instruction
export type LOAD_CONFIRMATION_PDF = LoaderAction<PaymentContext, LOADER_ACTION_EVENTS.LOAD_CONFIRMATION_PDF>;
//response
export type LOADED_CONFIRMATION_PDF = {
  type: LOADER_RESPONSE_EVENTS.LOADED_CONFIRMATION_PDF;
  result: any;
  errors: any;
};

//instruction
export type LOAD_CONFIRMATION_PDF_FOR_URL = LoaderAction<
  MyBookingContext,
  LOADER_ACTION_EVENTS.LOAD_CONFIRMATION_PDF_FOR_URL
>;
//response
export type LOADED_CONFIRMATION_PDF_FOR_URL = {
  type: LOADER_RESPONSE_EVENTS.LOADED_CONFIRMATION_PDF_FOR_URL;
  result: any;
  errors: any;
};

//instruction
export type LOAD_PRODUCT_PRICES = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.LOAD_PRODUCT_PRICES>;
//response
export type LOADED_PRODUCT_PRICES = {
  type: LOADER_RESPONSE_EVENTS.LOADED_PRODUCT_PRICES;
  result: { index: number; products: API.Products | API.ProductError; sailingCode: string };
  errors: any;
};
export type DONE_PRODUCT_PRICES = {
  type: LOADER_RESPONSE_EVENTS.DONE_PRODUCT_PRICES;
};

//instruction
export type LOAD_RESERVATION = LoaderAction<MyBookingContext, LOADER_ACTION_EVENTS.LOAD_RESERVATION>;
//response
export type LOADED_RESERVATION = {
  type: LOADER_RESPONSE_EVENTS.LOADED_RESERVATION;
  result: { reservation: API.BookingReservation };
  errors: any;
};

//instruction
export type LOAD_ACCOMMODATION_PRICES = LoaderAction<
  CatalogContext,
  LOADER_ACTION_EVENTS.LOAD_ACCOMMODATION_PRICES,
  {
    accommodations: { code: string; type: string }[];
    index: number;
    sailingCode: string;
  }
>;
//response
export type LOADED_ACCOMMODATION_PRICES = {
  type: LOADER_RESPONSE_EVENTS.LOADED_ACCOMMODATION_PRICES;
  result: any;
  errors: any;
};

//instruction
export type RELOAD_SELECTED = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.RELOAD_SELECTED>;
//response
export type RELOADED_SELECTED = {
  type: LOADER_RESPONSE_EVENTS.RELOADED_SELECTED;
  data: any;
};

//instruction
export type RELOAD_MISSING = LoaderAction<CatalogContext, LOADER_ACTION_EVENTS.RELOAD_MISSING>;
//response
export type ON_RELOAD_MISSING = {
  type: LOADER_RESPONSE_EVENTS.ON_RELOAD_MISSING;
  data: any;
};

export type DONE_RELOAD_MISSING = {
  type: LOADER_RESPONSE_EVENTS.DONE_RELOAD_MISSING;
};

//internal
export type CONTEXT_CHANGED = {
  type: 'CONTEXT_CHANGED';
  data: any;
};

export type EVENT = {
  type: 'EVENT';
  data: any;
};

export type DONE = {
  type: 'DONE';
  data: any;
};

export type LoaderEventData = {
  evt: ActionEvents;
  timestamp: number;
  done: boolean;
};

export interface LoaderContext {
  evtQueue: LoaderEventData[];
}

export interface LoaderState {
  states: {
    listen: {};
    loading: {
      states: {
        idle: {};
        load: {};
      };
    };
  };
}

export type APIResult = {
  type: LOADER_ACTION_EVENTS;
  result: any;
};

export type ActionEvents =
  | LOAD_ACCOMMODATION_PRICES
  | LOAD_CATALOG_PRICES
  | LOAD_CATALOG_PRODUCTS
  | LOAD_CATALOGS
  | LOAD_CONFIRMATION_PDF
  | LOAD_CONFIRMATION_PDF_FOR_URL
  | LOAD_PRODUCT_PRICES
  | LOAD_RESERVATION
  | RELOAD_MISSING
  | RELOAD_SELECTED;

export type ResponseEvents =
  | LOADED_ACCOMMODATION_PRICES
  | LOADED_CATALOGS
  | LOADED_CATALOG_PRODUCTS
  | DONE_CATALOG_PRODUCTS
  | LOADED_CATALOG_PRICES
  | DONE_CATALOG_PRICES
  | LOADED_CONFIRMATION_PDF
  | LOADED_CONFIRMATION_PDF_FOR_URL
  | LOADED_PRODUCT_PRICES
  | DONE_PRODUCT_PRICES
  | LOADED_RESERVATION
  | RELOADED_SELECTED
  | ON_RELOAD_MISSING
  | DONE_RELOAD_MISSING;

export type LoaderEvent = ActionEvents | EVENT | DONE | CONTEXT_CHANGED;

export type LoaderInterpreter = Interpreter<LoaderContext, LoaderState, LoaderEvent>;

export default Machine<LoaderContext, LoaderState, LoaderEvent>(
  {
    id: 'loader',
    type: 'parallel',
    context: {
      evtQueue: [],
    },
    states: {
      listen: {
        on: {
          LOAD_ACCOMMODATION_PRICES: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_CATALOGS: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_CATALOG_PRODUCTS: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_CATALOG_PRICES: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_CONFIRMATION_PDF: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_CONFIRMATION_PDF_FOR_URL: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_PRODUCT_PRICES: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          LOAD_RESERVATION: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          RELOAD_SELECTED: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
          RELOAD_MISSING: {
            actions: ['queue', send({ type: 'CONTEXT_CHANGED' })],
          },
        },
      },
      loading: {
        initial: 'idle',
        states: {
          idle: {
            entry: 'cleanQueue',
            always: [
              {
                cond: (ctx) => ctx.evtQueue.length > 0,
                target: 'load',
              },
            ],
            on: {
              CONTEXT_CHANGED: {
                cond: (ctx) => ctx.evtQueue.length > 0,
                target: 'load',
              },
            },
          },
          load: {
            invoke: {
              src: (ctx) => {
                const spawnable = SPAWN_MAP[ctx.evtQueue[0].evt.type];
                logger.debug('LoaderMachine, invoke:', ctx.evtQueue[0].evt.type);
                return spawnable.src(ctx.evtQueue[0].evt as any);
              },
              onDone: [
                {
                  cond: (ctx, evt) => evt.data.type,
                  actions: [
                    sendParent((_, evt) => {
                      logger.debug('LoaderMachine, onDone:', evt.data.type, evt.data.result);
                      return (
                        evt.data.type && {
                          ...evt.data,
                          type: SPAWN_MAP[(evt.data as APIResult).type].answer,
                        }
                      );
                    }),
                    'setEventDone',
                  ],
                  target: 'idle',
                },
                {
                  actions: [
                    sendParent((_, evt) => ({
                      ...(evt || {}),
                      type: LOADER_RESPONSE_EVENTS.LOADER_ERROR,
                    })),
                    'setEventDone',
                  ],
                  target: 'idle',
                },
              ],
              onError: {
                actions: [
                  sendParent((_, evt) => ({
                    ...(evt || {}),
                    type: LOADER_RESPONSE_EVENTS.LOADER_ERROR,
                  })),
                  'setEventDone',
                ],
                target: 'idle',
              },
            },
            on: {
              EVENT: {
                actions: sendParent((_, evt: any) => {
                  return (
                    evt.data.type && {
                      ...evt.data,
                      type: SPAWN_MAP[(evt.data as APIResult).type].answer,
                    }
                  );
                }),
              },
              DONE: {
                actions: [
                  sendParent(
                    (_, evt) =>
                      evt.data.type && {
                        ...evt.data,
                        type: SPAWN_MAP[(evt.data as APIResult).type].done,
                      }
                  ),
                  'setEventDone',
                ],
                target: 'idle',
              },
            },
          },
        },
      },
    },
  },
  { actions, guards }
);
