import React from 'react';
import styled from 'styled-components';
import { Radio, RadioStateReturn } from 'reakit/Radio';

import { color, measurement } from '../../helpers/vars';
import { animation, style, typography } from '../../helpers/mixins';

export interface ChoiceEventHandlers {
  readonly onMouseOver: () => void;
  readonly onClick: () => void;
  readonly onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface ChoiceProps extends ChoiceEventHandlers {
  readonly value: any;
  readonly state: RadioStateReturn;
  readonly passedRef?: any;
}

const ChoiceWrapper = styled.label`
  cursor: pointer;
  flex: 1;
  position: relative;
  text-align: center;
  border-radius: ${measurement.size.radius.default};
  transition: ${animation.transition('box-shadow')};
`;

const ChoiceInput = styled(Radio)<{ ref?: any }>`
  ${style.radio.input}
`;

const ChoiceLabel = styled.span`
  ${style.radio.label.common}
  ${typography.lead}
  display: block;
  color: ${color.link.default};
  border-radius: ${measurement.size.radius.default};
  padding: ${measurement.inset.input.segmented};
  margin: ${measurement.space.input.select.indicator};
  transition: ${animation.transition('background', 'color')};

  ${ChoiceInput}:checked + & {
    color: ${color.text.invert};

    ${ChoiceWrapper}:hover & {
      background: none;
    }
  }
`;

const Choice: React.FunctionComponent<ChoiceProps> = ({ state, passedRef, children, onMouseOver, ...props }) => {
  return (
    <ChoiceWrapper {...{ onMouseOver }}>
      <ChoiceInput {...{ ...state, ...props }} {...(passedRef && { ref: passedRef })} />
      <ChoiceLabel>{children}</ChoiceLabel>
    </ChoiceWrapper>
  );
};

export default Choice;
