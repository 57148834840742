import { addMinutes } from 'date-fns';
import { History } from 'history';
import { identity } from 'purify-ts/Function';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  ActionFunction,
  EventObject,
  Interpreter,
  InterpreterOptions,
  MachineOptions,
  State,
  StateConfig,
  StateMachine,
  Typestate,
} from 'xstate';
import { toActionObject } from 'xstate/lib/actions';
import { useMachine } from '../xstate-react';
import { asEffect } from '../xstate-react/useMachine';

const stateVersion = process.env.REACT_APP_XSTATE_STATE_VERSION || 'dev';

const isLocaleStorageSupported: boolean = (() => {
  const item = '__finnlines_test__';
  try {
    localStorage.setItem(item, item);
    localStorage.removeItem(item);
    return true;
  } catch (e) {
    return false;
  }
})();

export function updateHistoryAction<TContext extends { path: string }, TEvent extends EventObject>({
  history,
  location,
  match,
}: RouteComponentProps<{}>): ActionFunction<TContext, TEvent> {
  return asEffect<TContext, TEvent>((ctx, { data }: any) => {
    const pathname = `${match.url}${ctx.path === '<root>' ? '' : ctx.path}`;
    if (!data?.popped && location.pathname !== pathname) {
      const search = location.search
        ? location.search
            .replace(/&?(bookingReference|responseCode|transactionId)=[^&]*/g, '')
            .replace(/&?(type|departurePort|arrivalPort|startDate|returnDate)=[^&]*/g, '')
            .replace(/&?(ADULT|JUNIOR|CHILD|INFANT|pets)=[^&]*/g, '')
            .replace(/&?(offerCode|starclub|currency|vehicles)=[^&]*/g, '')
            .replace(/^\?$/, '')
        : '';

      history.push({
        pathname,
        search,
      });
    }
  });
}
interface UseMachineOptions<TContext, TEvent extends EventObject> {
  /**
   * If provided, will be merged with machine's `context`.
   */
  context?: Partial<TContext>;
  /**
   * The state to rehydrate the machine to. The machine will
   * start at this state instead of its `initialState`.
   */
  state?: StateConfig<TContext, TEvent>;
}

export function usePersistedMachine<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext }
>(
  machine: StateMachine<TContext, any, TEvent, TTypestate>,
  options: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>> &
    Partial<{
      onLoad: (
        persistedState: State<TContext, TEvent, any, TTypestate>
      ) => State<TContext, TEvent, any, TTypestate> | undefined;
      onSave: (
        changedState: State<TContext, TEvent, any, TTypestate>
      ) => State<TContext, TEvent, any, TTypestate> | undefined;
      expiresInMinutes?: (changedState: State<TContext, TEvent, any, TTypestate>) => number | undefined;
    }> = {}
): [
  State<TContext, TEvent, any, TTypestate>,
  Interpreter<TContext, any, TEvent, TTypestate>['send'],
  Interpreter<TContext, any, TEvent, TTypestate>
] {
  const storageId = `app-state-${machine.id}`;

  const [{ childIds, state }] = useState(() => {
    const loadPersistedStateConditionally = () => {
      if (isLocaleStorageSupported) {
        const persistedState = localStorage.getItem(storageId);

        if (persistedState) {
          const { childIds, expiresAt, state, version } = JSON.parse(persistedState);

          // Load state only when version matches, it has not expired
          // or callback function returns a valid state.
          if (version === stateVersion && (expiresAt || 0) > new Date().getTime()) {
            const newState = (options?.onLoad || identity)(state);

            if (newState) {
              //@ts-ignore
              newState.actions = newState.actions.map((action: any) => toActionObject(action, options?.actions));
              return { childIds, state: newState };
            }
          } else {
            // Clear storage item on timeout.
            localStorage.removeItem(storageId);
          }
        }
      }

      return {};
    };

    return loadPersistedStateConditionally();
  });

  const [current, send, service] = useMachine(machine, {
    ...options,
    childIds,
    state,
  });

  service.onTransition((currentState) => {
    if (currentState.changed && isLocaleStorageSupported && (options?.onSave || identity)(currentState)) {
      try {
        const childIds = Array.from(service.children.keys());

        // TODO Refresh on error and discard invalid state?
        localStorage.setItem(
          storageId,
          JSON.stringify({
            childIds,
            expiresAt: addMinutes(
              new Date(),
              (options?.expiresInMinutes && options.expiresInMinutes(currentState)) || 60
            ).getTime(),
            // Do not persist history.
            state: { ...currentState, history: undefined, historyValue: undefined },
            version: stateVersion,
          })
        );
      } catch (e) {
        console.error('Failed to persist state', e);
      }
    }
  });

  return [current, send, service];
}

export function useServiceWithHistory<TContext, TEvent extends EventObject>(
  service: Interpreter<TContext, any, TEvent>,
  history: History,
  pathNameIndex: number = 2
): void {
  useEffect(() => {
    const unregister = history.listen((history, action) => {
      if (action === 'POP') {
        const pathNameParts = history.pathname.split('/');
        const path = pathNameParts[pathNameIndex];
        const event = service.machine.events.find((event) => event === `/${path}`);

        if (event) {
          service.send(event, { data: { popped: true } });
        } else {
          // try to fallback to root
          const rootEvent = service.machine.events.find((event) => event === `<root>`);
          if (rootEvent) {
            service.send(rootEvent, { data: { popped: true } });
          } else {
            // TODO: Proper error management
            console.log(`No event for ${history.pathname}`);
          }
        }
      }
    });

    return () => unregister();
  }, [history, service]);
}

export function useChildServiceWithHistory<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext }
>(service: Interpreter<TContext, any, TEvent, TTypestate>, history: History): void {
  useEffect(() => {
    const unregister = history.listen((history, action) => {
      if (action === 'POP') {
        const paths = history.pathname.split('/');
        const event = service.machine.events.find(
          (event) => event === (paths.length > 2 ? `/${paths.slice(2).join('/')}` : '<root>')
        );

        if (event) {
          service.send(event, { data: { popped: true } });
        } else {
          // TODO: Proper error management
          console.log(`No event for ${history.pathname}`);
        }
      }
    });

    return () => unregister();
  }, [history, service]);
}
