import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { downloadBase64 } from '../../utils/download';
import { LoaderAction, LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import { MyBookingContext } from '../my-booking/myBookingMachine';
import { PaymentContext } from '../payment/paymentMachine';
import {
  GetReservationPdfForUrlQuery,
  GetReservationPdfQuery,
  isApiError,
  PaymentProvider,
  ReservationPdf,
} from '../types';

export const fetchConfirmationPdf = async ({
  type,
  data,
}: LoaderAction<PaymentContext, LOADER_ACTION_EVENTS>): Promise<any> => {
  const { currency, language, reservation, transactionId } = data.context;

  try {
    const result = await API.graphql(
      graphqlOperation(queries.getReservationPdf, {
        query: {
          currency,
          transactionId,
          language: language.toUpperCase(),
          provider: PaymentProvider.NETS_NETAXEPT,
          reference: reservation?.bookingDetail.reference,
        },
      })
    );

    const { data, errors } = result as GraphQLResult<GetReservationPdfQuery>;

    if (errors || !data || !data.getReservationPdf || isApiError(data.getReservationPdf)) {
      console.error('Failed to download reservation PDF', errors || data);
      return Promise.reject(errors || data?.getReservationPdf);
    } else {
      const reservationPdf: ReservationPdf = data.getReservationPdf;
      downloadBase64(reservationPdf.base64Data, 'application/pdf', reservationPdf.fileName);

      return { type };
    }
  } catch (e) {
    console.error('fetchConfirmationPdf', e);
    return Promise.reject(e);
  }
};

export const fetchConfirmationPdfForUrl = async ({
  type,
  data,
}: LoaderAction<MyBookingContext, LOADER_ACTION_EVENTS>): Promise<any> => {
  const { currency, language, reservationUrl } = data.context;

  try {
    const result = await API.graphql(
      graphqlOperation(queries.getReservationPdfForUrl, {
        query: {
          currency,
          reservationUrl,
          language: language.toUpperCase(),
        },
      })
    );

    const { data, errors } = result as GraphQLResult<GetReservationPdfForUrlQuery>;

    if (errors || !data || !data.getReservationPdfForUrl || isApiError(data.getReservationPdfForUrl)) {
      console.error('Failed to download reservation PDF', errors || data);
      return Promise.reject(errors || data?.getReservationPdfForUrl);
    } else {
      const reservationPdfForUrl: ReservationPdf = data.getReservationPdfForUrl;
      downloadBase64(reservationPdfForUrl.base64Data, 'application/pdf', reservationPdfForUrl.fileName);

      return { type };
    }
  } catch (e) {
    console.error('fetchConfirmationPdfForUrl', e);
    return Promise.reject(e);
  }
};
