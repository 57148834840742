import React, { useState } from 'react';
import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { DialogStateReturn } from 'reakit/Dialog';
import { Portal } from 'reakit/Portal';
import styled from 'styled-components';
import { Subgrid } from '../../../../../design-system/components/Container';
import { Group, Item, MenuWrapperProps } from '../../../../../design-system/components/Grouping';
import { IconName } from '../../../../../design-system/components/Icon';
import Modal from '../../../../../design-system/components/Modal';
import { hooks } from '../../../../../design-system/helpers/mixins';
import { CatalogEvent } from '../../../../../fsm/catalog/catalogMachine';
import * as API from '../../../../../fsm/types';
import {
  AccommodationIdType,
  accommodationsAvailable,
  accommodationSubtype,
  canFitPassenger,
  NoAccommodationType,
  NO_ACCOMMODATION,
  passengerIcon,
  canBeRemovedFrom,
  moveToNew,
  inCabin,
} from '../../../../../fsm/utils/accommodationUtils';
import { canBeAlone, getChildren, getInfants, hasPets } from '../../../../../fsm/utils/passengerUtils';
import { sailingRequiresAccommodation } from '../../../../../settings';
import { formatString } from '../../../../../utils/formats';
import { Sender } from 'xstate';
import Alert from '../../../../../design-system/components/Alert';
import { H3 } from '../../../../../design-system/components/Text';
import { TripType } from '../../../../../fsm/types';

interface Action {
  readonly children: string;
  readonly onClick: () => void;
}

interface PassengerWithInfo {
  readonly id: number;
  readonly type: API.PassengerType;
  readonly props: {
    readonly icon: IconName;
    readonly children: string;
  };
}

interface PassengerGroupingProps {
  readonly state: DialogStateReturn;
  readonly accommodations: API.ExtendedAccommodation[];
  readonly accommodationProducts: API.Product[];
  readonly passengers: API.ExtendedPassengerAndPet[];
  readonly send: Sender<CatalogEvent>;
  readonly leg: number;
  readonly sailing: API.ExtendedSailing;

  readonly setGroupingPossible: (groupingPossible: boolean) => void;
  readonly tripType: TripType;
}

interface ItemCommonProps extends MenuWrapperProps {
  readonly actions: Action[];
}

interface PassengerItemProps extends ItemCommonProps {
  readonly index: number;
  readonly passengerId: number;
  readonly accommodationId: AccommodationIdType;
  readonly passengerInfo: PassengerWithInfo[];
}

interface PortalAwareItemProps extends ItemCommonProps {
  readonly provided: DraggableProvided;
  readonly snapshot: DraggableStateSnapshot;
  readonly id: number;
}

const Placeholder = styled.span`
  display: none;
  position: absolute;
`;

const getStyle = (style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

const PortalAwareItem: React.FunctionComponent<PortalAwareItemProps> = ({ provided, snapshot, ...props }) => {
  const usePortal = snapshot.isDragging;

  const child = (
    <Item
      dragging={snapshot.isDragging}
      innerRef={provided.innerRef}
      {...{ ...provided.draggableProps, ...provided.dragHandleProps, ...props }}
      style={getStyle(provided.draggableProps.style, snapshot)}
    />
  );

  if (!usePortal) {
    return child;
  }

  return <Portal>{child}</Portal>;
};

const PassengerItem: React.FunctionComponent<PassengerItemProps> = ({
  index,
  passengerId,
  passengerInfo,
  actions,
  ...props
}) => {
  const [, setMenuState] = useState(false);
  const current = passengerInfo.find((info) => passengerId === info.id);

  return current ? (
    <Draggable
      draggableId={`${current.id}`}
      {...{ index }}
      disableInteractiveElementBlocking
      key={current.id}
      isDragDisabled={actions === undefined || actions.length === 0}
    >
      {(provided, snapshot) => (
        <PortalAwareItem
          {...{ setMenuState, actions, provided, snapshot, ...current.props, ...props }}
          id={current.id}
        />
      )}
    </Draggable>
  ) : null;
};

interface PossibleMove {
  readonly id: number;
  readonly index: number;
}

export type PossibleNew = API.ProductSubType | NoAccommodationType;

interface PossibleState {
  readonly id: number;
  readonly move: PossibleMove[];
  readonly new: PossibleNew[];
}

const PassengerGrouping: React.FunctionComponent<PassengerGroupingProps> = ({
  state,
  leg,
  accommodations,
  accommodationProducts,
  passengers,
  send,
  sailing,
  setGroupingPossible,
  tripType,
}) => {
  const [possibleMove, setPossibleMove] = useState<PossibleMove[] | undefined>();
  const [possibleNew, setPossibleNew] = useState<PossibleNew[] | undefined>();
  const loading = sailing.meta?.loading;

  const sbAccommodationTypes = hooks.useStoryblokDatasource('accommodation-types');

  const sbAccommodations = hooks.useStoryblokComponents('products/accommodations');
  const sbPassengers = hooks.useStoryblokComponents('products/passengers');
  const [sbPassengerGroupingModal, sbPassengerGroupingModalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.passenger_grouping_modal',
  });

  const isAccommodationRequired = sailingRequiresAccommodation(sailing, tripType, hasPets(passengers));
  const passengersWithAccommodation = accommodations.map(({ passengers }) => passengers).flat() || [];
  const passengersWithoutAccommodation = passengers
    .map(({ id }) => id)
    .filter((passengerId) => !passengersWithAccommodation.includes(passengerId));

  const passengerInfo = passengers.map(({ id, type }) => {
    const { title, description } = sbPassengers[type]?.content || {};

    return {
      id,
      type,
      props: {
        icon: passengerIcon(type as API.PassengerType | API.PetType),
        children: `${title}${description ? ` ${description}` : ''}${
          id === 1 ? ` ${sbPassengerGroupingModal?.content.reserver_label || ''}` : ''
        }`,
      },
    };
  }) as PassengerWithInfo[];

  const available = {
    CABIN: accommodationsAvailable(accommodationProducts, ['CABIN']),
    // BED: accommodationsAvailable(accommodationProducts, ['BED']),
    CHAIR: accommodationsAvailable(accommodationProducts, ['CHAIR']),
  } as { [key in API.ProductSubType]: boolean };

  const possibleActions = passengerInfo.map((passenger) => {
    const currentAccommodation =
      accommodations.find((accommodation) => accommodation.passengers?.includes(passenger.id)) || NO_ACCOMMODATION;

    const asExtended = passengers.find((p) => p.id === passenger.id);
    const movePossible = !inCabin(passenger, accommodations)
      ? true
      : asExtended && typeof currentAccommodation !== 'string'
      ? canBeRemovedFrom(currentAccommodation, asExtended, passengers)
      : false;

    const alonePossible = canBeAlone(passenger);

    const possibleAccommodations = accommodations.reduce(
      (acc, a, index) =>
        (currentAccommodation === NO_ACCOMMODATION || a.id !== currentAccommodation?.id) &&
        accommodationSubtype(a, accommodationProducts) === 'CABIN' &&
        canFitPassenger(passenger.id, a, passengers, accommodationProducts)
          ? [...acc, { id: a.id, index }]
          : acc,
      [] as PossibleMove[]
    );

    const currentSubtype =
      currentAccommodation === NO_ACCOMMODATION
        ? NO_ACCOMMODATION
        : accommodationSubtype(currentAccommodation, accommodationProducts);

    const possibleNoAccommodation: PossibleNew[] =
      !isAccommodationRequired && currentAccommodation !== NO_ACCOMMODATION ? [NO_ACCOMMODATION] : [];

    const possibleNewCabin: PossibleNew[] =
      (currentAccommodation === NO_ACCOMMODATION ||
        currentSubtype !== API.ProductSubType.CABIN ||
        (currentAccommodation?.passengers?.length || 0) > 1) &&
      available.CABIN
        ? [API.ProductSubType.CABIN]
        : [];

    const possibleNewChair: PossibleNew[] =
      currentSubtype !== API.ProductSubType.CHAIR && available.CHAIR ? [API.ProductSubType.CHAIR] : [];

    const possibleNew = movePossible
      ? [
          ...possibleNoAccommodation,
          ...(alonePossible
            ? [
                ...possibleNewCabin,
                // ...(currentSubtype !== 'BED' && available.BED ? ['BED'] : []),
                ...possibleNewChair,
              ]
            : []),
        ]
      : [];

    return {
      id: passenger.id,
      move: movePossible ? possibleAccommodations : [],
      new: possibleNew,
    } as PossibleState;
  });

  const groupingPossible = possibleActions.some((possible) => !!possible.move.length || !!possible.new.length);

  React.useEffect(() => setGroupingPossible(groupingPossible), [setGroupingPossible, groupingPossible]);

  const moveTo = (passenger: number, source: AccommodationIdType, destination: number) => {
    send({
      type: 'ADD_PASSENGER_TO_ACCOMMODATION',
      data: {
        leg,
        accommodationId: destination,
        passengerId: passenger,
      },
    });
  };

  const moveToNewAcc = moveToNew(leg, accommodations, accommodationProducts, send);

  const dragDropResponders = {
    onBeforeCapture: ({ draggableId }: BeforeCapture) => {
      if (draggableId) {
        const currentlyPossible = possibleActions.find((p) => p.id === parseInt(draggableId));

        if (currentlyPossible) {
          setPossibleMove(currentlyPossible.move);
          setPossibleNew(currentlyPossible.new);
        }
      }
    },
    onDragEnd: ({ draggableId, source, destination }: DropResult) => {
      if (draggableId && source.droppableId && destination?.droppableId) {
        const sourceInt = parseInt(source.droppableId);

        const passengerId = parseInt(draggableId);
        const destinationId = parseInt(destination.droppableId);

        const sourceId = isNaN(sourceInt)
          ? accommodations.find((a) => a.passengers?.includes(passengerId))?.id
          : sourceInt;

        if (sourceId !== undefined || source.droppableId === NO_ACCOMMODATION) {
          const sourceIdOrNone = sourceId !== undefined ? sourceId : NO_ACCOMMODATION;

          if (!isNaN(destinationId)) {
            moveTo(passengerId, sourceIdOrNone, destinationId);
          } else {
            moveToNewAcc(passengerId, sourceIdOrNone, destination.droppableId as PossibleNew);
          }
        }
      }
      setPossibleMove(undefined);
      setPossibleNew(undefined);
    },
  };

  const getPassengerActions = (passengerId: number, accommodationId: AccommodationIdType) => {
    if (loading) {
      return [];
    }

    const possible = possibleActions.find((info) => passengerId === info.id);

    if (possible) {
      const cabinActions = possible.move.map(({ id, index }) => ({
        children: formatString(sbPassengerGroupingModal?.content.move_to_existing, index + 1),
        onClick: () => moveTo(passengerId, accommodationId, id),
      }));

      const newAccommodationActions = possible.new.map((type) => ({
        children:
          type === NO_ACCOMMODATION
            ? sbPassengerGroupingModal?.content.move_to_none
            : formatString(
                sbPassengerGroupingModal?.content.move_to_new,
                (sbAccommodationTypes[type] || '').toLowerCase()
              ),
        onClick: () => moveToNewAcc(passengerId, accommodationId, type),
      }));

      return [...cabinActions, ...newAccommodationActions];
    }

    return [];
  };

  const hasInfants = getInfants(passengers).length > 0;
  const hasChildren = getChildren(passengers).length > 0;

  return (
    <Modal
      {...{ state }}
      title={sbPassengerGroupingModal?.content.title}
      focusOnDialog
      size="input"
      submit={sbPassengerGroupingModal?.content.ready_button}
      ref={sbPassengerGroupingModalRef}
    >
      {(state.animating || state.visible) && (
        <Subgrid>
          {(hasInfants || hasChildren) && (
            <Alert severity="info">
              <H3>{sbPassengerGroupingModal?.content.children_info_title}</H3>
              <p>
                {
                  sbPassengerGroupingModal?.content[
                    !hasInfants
                      ? 'children_info_text_child'
                      : !hasChildren
                      ? 'children_info_text_infant'
                      : 'children_info_text_child_infant'
                  ]
                }
              </p>
            </Alert>
          )}
          <DragDropContext {...dragDropResponders}>
            {!isAccommodationRequired && (
              <Droppable droppableId="NO_ACCOMMODATION" isDropDisabled={false}>
                {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
                  <Group
                    unwrapped
                    isDropArea={false}
                    placeholder={<Placeholder>{placeholder}</Placeholder>}
                    title={sbPassengerGroupingModal?.content.not_in_accommodation}
                    {...{ isDraggingOver, innerRef, ...droppableProps }}
                  >
                    {passengersWithoutAccommodation.map((passengerId, index) => (
                      <PassengerItem
                        unwrapped
                        key={passengerId}
                        actions={getPassengerActions(passengerId, NO_ACCOMMODATION)}
                        accommodationId={NO_ACCOMMODATION}
                        {...{ index, passengerId, passengerInfo }}
                      />
                    ))}
                  </Group>
                )}
              </Droppable>
            )}
            {accommodations
              .filter((a) => accommodationSubtype(a, accommodationProducts) === 'CABIN')
              .map((accommodation, accommodationIndex) => {
                const dropPossible = typeof possibleMove?.find(({ id }) => id === accommodation.id)?.id === 'number';
                const type = accommodationSubtype(accommodation, accommodationProducts);

                return (
                  <Droppable droppableId={`${accommodation.id}`} key={accommodation.id} isDropDisabled={!dropPossible}>
                    {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
                      <Group
                        placeholder={<Placeholder>{placeholder}</Placeholder>}
                        title={`${(type && sbAccommodationTypes[type]) || type} ${accommodationIndex + 1}: ${
                          sbAccommodations[accommodation.code || '']?.content?.title || accommodation.code
                        }`}
                        innerRef={innerRef}
                        isDropArea={dropPossible}
                        {...{ isDraggingOver, ...droppableProps }}
                      >
                        {accommodation.passengers?.map((passengerId, index) => (
                          <PassengerItem
                            actions={getPassengerActions(passengerId, accommodation.id)}
                            key={index}
                            accommodationId={accommodation.id}
                            {...{ index, passengerId, passengerInfo }}
                          />
                        ))}
                      </Group>
                    )}
                  </Droppable>
                );
              })}
            {(
              [
                'CABIN',
                // 'BED',
                'CHAIR',
              ] as API.ProductSubType[]
            ).map((type) => {
              const isDropArea = possibleNew?.includes(type) || false;
              const passengers =
                type !== 'CABIN'
                  ? accommodations
                      .filter((a) => accommodationSubtype(a, accommodationProducts) === type)
                      .reduce(
                        (acc, a) => [
                          ...acc,
                          ...(a.passengers?.map((p) => ({
                            accommodationId: a.id,
                            passengerId: p,
                          })) || []),
                        ],
                        [] as { passengerId: number; accommodationId: number }[]
                      )
                  : ([] as { passengerId: number; accommodationId: number }[]);

              return (
                <Droppable key={type} droppableId={type} isDropDisabled={!isDropArea}>
                  {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
                    <Group
                      unwrapped
                      placeholder={<Placeholder>{placeholder}</Placeholder>}
                      title={
                        available[type]
                          ? formatString(
                              sbPassengerGroupingModal?.content.accommodation_available,
                              sbAccommodationTypes[type]
                            )
                          : formatString(
                              sbPassengerGroupingModal?.content.accommodation_not_available,
                              sbAccommodationTypes[type]
                            )
                      }
                      innerRef={innerRef}
                      {...{ isDraggingOver, isDropArea, ...droppableProps }}
                    >
                      {passengers.map(({ passengerId, accommodationId }, index) => (
                        <PassengerItem
                          unwrapped
                          key={passengerId}
                          actions={getPassengerActions(passengerId, accommodationId)}
                          {...{ index, accommodationId, passengerId, passengerInfo }}
                        />
                      ))}
                    </Group>
                  )}
                </Droppable>
              );
            })}
          </DragDropContext>
        </Subgrid>
      )}
    </Modal>
  );
};

export default PassengerGrouping;
