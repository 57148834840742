import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useDialogState } from 'reakit/Dialog';
import Card, { PriceAreaProps } from '../../../../../design-system/components/Card';
import Modal from '../../../../../design-system/components/Modal';
import Tag, { TagProps } from '../../../../../design-system/components/Tag';
import { H3, P } from '../../../../../design-system/components/Text';
import { hooks } from '../../../../../design-system/helpers/mixins';
import { CatalogEvent } from '../../../../../fsm/catalog/catalogMachine';
import {
  AccommodationCodeInput,
  ExtendedAccommodation,
  ExtendedPassengerAndPet,
  ExtendedProduct,
  maybeChargeInfo,
  PassengerType,
  PetType,
  Product,
  Tariff,
} from '../../../../../fsm/types';
import {
  canFitPassengers,
  containsPet,
  filterOutPetCabins,
  filterToPetCabins,
  passengerIcon,
} from '../../../../../fsm/utils/accommodationUtils';
import { LanguageContext } from '../../../../../Language.context';
import { TreeData } from '../../../../../storyblok';
import { findAccommodationFullSlug, findAccommodationServicesDescription } from '../../../../../storyblok/helpers';
import { usePrevious } from '../../../../common/usePrevious';
import { hasPets } from '../../../../../fsm/utils/passengerUtils';

type AccommodationCommonProps = {
  readonly accommodation: ExtendedAccommodation;
  readonly arrivalPort: string;
  readonly departurePort: string;
  readonly leg: number;
  readonly shipName: string;
  readonly send: (event: CatalogEvent) => void;
  readonly tariff: Tariff;
};

interface AccommodationModalContentProps extends AccommodationCommonProps {
  readonly choices: ExtendedProduct[];
  readonly passengers: ExtendedPassengerAndPet[];
  readonly sailingCode: string;
  readonly sbContent?: TreeData['content'];
  readonly movablePassengers: ExtendedPassengerAndPet[];
}

interface AccommodationCardProps extends AccommodationCommonProps {
  readonly choices: ExtendedProduct[];
  readonly groupingPossible: boolean;
  readonly passengers: ExtendedPassengerAndPet[];
  readonly removeAccommodation?: (id: number) => void;
  readonly sailingCode: string;
  readonly tagsDisclosure: React.ReactNode;
  readonly disabled?: boolean;
  readonly movablePassengers: ExtendedPassengerAndPet[];
}

interface AccommodationChoiceProps extends AccommodationCommonProps {
  readonly choice: ExtendedProduct;
  readonly currentCharge?: number;
  readonly labelSelect: string;
  readonly labelSelected: string;
  readonly sbContent?: TreeData['content'];
}

const ACCOMMODATION_ORDER = {
  CABIN: 1,
  CHAIR: 2,
};

const calcChargeDifference = (
  choice: ExtendedProduct,
  currentCharge: number | undefined,
  tariff: Tariff,
  sbContent: { [name: string]: string } | undefined,
  sbErrorCodes: { [name: string]: string },
  formatCurrency: (moneyAsCents: number | undefined) => string
): PriceAreaProps => {
  if (typeof choice.price === 'string') {
    return {
      label: sbErrorCodes[choice.price] ?? undefined,
      price: undefined,
      loading: false,
    } as PriceAreaProps;
  }

  if (currentCharge != undefined) {
    return maybeChargeInfo(tariff, choice)
      .map(({ charge }) => {
        const change = charge - currentCharge;
        const label =
          change === 0
            ? sbContent?.same_price_label
            : change > 0
            ? sbContent?.higher_price_label
            : sbContent?.lower_price_label;

        return {
          price: change !== 0 && formatCurrency(change),
          label,
        } as PriceAreaProps;
      })
      .orDefault({ loading: true } as PriceAreaProps);
  }

  return { loading: true } as PriceAreaProps;
};

const AccommodationChoice: FunctionComponent<AccommodationChoiceProps> = ({
  accommodation,
  arrivalPort,
  choice,
  currentCharge,
  departurePort,
  leg,
  send,
  labelSelect,
  labelSelected,
  sbContent,
  shipName,
  tariff,
}) => {
  const { formats } = useContext(LanguageContext);

  const sbAccommodationsWithMeals = Object.values(hooks.useStoryblokComponents('products/accommodation_services'))
    .filter(({ content }) => content.meals_included)
    .map(({ content }) => content.product_code);

  const selectCabinType = ({ code, subtype }: Product) => {
    send({
      type: 'REPLACE_ACCOMMODATION',
      data: {
        code,
        leg,
        accommodationId: accommodation.id,
        passengers: accommodation.passengers || undefined,
        type: subtype || '',
      },
    });

    // Check if all meals are included on sailing's leg(from storyblok). If so remove already selected onboard meals.
    if (sbAccommodationsWithMeals.includes(code)) {
      send({
        type: 'REMOVE_USER_SELECTED_MEALS',
        leg,
      });
    }
  };

  const sbAccommodations = hooks.useStoryblokComponents('products/accommodations');
  const accommodationFullSlug = findAccommodationFullSlug(Object.values(sbAccommodations), choice.code, shipName);
  const [sbAccommodation, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    fullSlug: accommodationFullSlug,
  });

  const sbAccommodationServices = hooks.useStoryblokComponents('products/accommodation_services');
  const sbAccommodationServicesDescription = findAccommodationServicesDescription(
    Object.values(sbAccommodationServices),
    choice.code,
    departurePort,
    arrivalPort
  );

  const image = sbAccommodation?.content.image || {};
  const title = sbAccommodation?.content.title || choice.code;
  const description = sbAccommodation?.content.description || '';

  const sbErrorCodes = hooks.useStoryblokDatasource('catalog-error-codes');

  const chargeDifference =
    accommodation.code !== choice.code
      ? calcChargeDifference(choice, currentCharge, tariff, sbContent, sbErrorCodes, formats.currency)
      : undefined;

  const buttonProps =
    accommodation.code === choice.code
      ? {
          button: labelSelected,
        }
      : {
          onClick:
            typeof choice.price === 'string' || chargeDifference === undefined || chargeDifference?.loading
              ? undefined
              : () => selectCabinType(choice),
          button: labelSelect,
        };

  return (
    <Card vertical image={image} ref={ref} {...(chargeDifference && { price: chargeDifference })} {...buttonProps}>
      <H3>{title || ''}</H3>
      {description && <P>{description}</P>}
      {sbAccommodationServicesDescription.mapOrDefault(
        (description) => (
          <P>{description}</P>
        ),
        <></>
      )}
    </Card>
  );
};

const AccommodationModalContent: FunctionComponent<AccommodationModalContentProps> = ({
  accommodation,
  arrivalPort,
  choices,
  departurePort,
  leg,
  passengers,
  sailingCode,
  sbContent,
  send,
  shipName,
  tariff,
  movablePassengers
}) => {
  const mostSuitableAccommodationChoices = [
    ...((containsPet(accommodation, passengers)|| hasPets(movablePassengers)) ? filterToPetCabins(choices) : filterOutPetCabins(choices)),
  ].filter((choice) => canFitPassengers(accommodation, choice, passengers));
  const previousAccommodationChoices = usePrevious(mostSuitableAccommodationChoices);
  const currentAccommodation = choices.find((c) => c.code === accommodation.code);
  // use updated price from choices if it exists for current accommodation
  // this is used for calculating correct price difference for cruise accommodation changes
  // accommodation contains the price of only outbound leg, but the prices in choices are
  // the sum of outbound and inbound leg
  const currentCharge = maybeChargeInfo(
    tariff,
    currentAccommodation && currentAccommodation.price ? currentAccommodation : accommodation
  )
    .map(({ charge }) => charge)
    .extract();

  useEffect(() => {
    // Only query prices when choices have been changed.
    if (
      !previousAccommodationChoices ||
      mostSuitableAccommodationChoices.length !== previousAccommodationChoices.length ||
      mostSuitableAccommodationChoices.some(({ code, subtype }) =>
        previousAccommodationChoices.find((prev) => prev.code !== code && prev.subtype !== subtype)
      ) == null
    )
      send({
        type: 'LOAD_ACCOMMODATION_PRICES',
        data: {
          sailingCode,
          accommodations: mostSuitableAccommodationChoices.reduce(
            (all, { code, subtype }) =>
              subtype
                ? all.concat({
                    code,
                    type: subtype,
                  })
                : all,
            [] as AccommodationCodeInput[]
          ),
          leg,
          accommodationId: accommodation.id,
        },
      });
  }, [leg, sailingCode, mostSuitableAccommodationChoices, previousAccommodationChoices]);

  const sortedMostSuitableAccommodationChoices = mostSuitableAccommodationChoices.sort((c1, c2) => {
    const price1 = maybeChargeInfo(tariff, c1)
      .map(({ charge }) => charge)
      .orDefault(0);
    const price2 = maybeChargeInfo(tariff, c2)
      .map(({ charge }) => charge)
      .orDefault(0);
    const compare1 = currentCharge ? price1 - currentCharge : price1;
    const compare2 = currentCharge ? price2 - currentCharge : price2;

    const subtype1 = ACCOMMODATION_ORDER[c1.subtype as 'CABIN' | 'CHAIR'];
    const subtype2 = ACCOMMODATION_ORDER[c2.subtype as 'CABIN' | 'CHAIR'];

    // Order primarily by product subtype and secondarily:
    //   * CABINs from most expensive to cheapest
    //   * CHAIRs (~others) from cheapest to most expensive
    return subtype1 - subtype2 === 0
      ? c1.subtype === 'CABIN' || c1.subtype === 'CHAIR'
        ? -(compare1 - compare2)
        : compare1 - compare2
      : subtype1 - subtype2;
  });

  return (
    <>
      {sortedMostSuitableAccommodationChoices.map((choice) => (
        <AccommodationChoice
          key={`${choice.type}-${choice.code}`}
          labelSelect={sbContent?.select_button}
          labelSelected={sbContent?.selected_button}
          {...{
            accommodation,
            arrivalPort,
            choice,
            currentCharge,
            departurePort,
            leg,
            sbContent,
            send,
            shipName,
            tariff,
          }}
        />
      ))}
    </>
  );
};

const AccommodationCard: FunctionComponent<AccommodationCardProps> = ({
  accommodation,
  arrivalPort,
  choices,
  departurePort,
  leg,
  passengers,
  send,
  sailingCode,
  shipName,
  tagsDisclosure,
  tariff,
  groupingPossible,
  removeAccommodation,
  disabled,
  movablePassengers
}) => {
  const sbAccommodationProducts = hooks.useStoryblokComponents('products/accommodations');
  const accommodationFullSlug = findAccommodationFullSlug(
    Object.values(sbAccommodationProducts),
    accommodation.code,
    shipName
  );
  const [sbAccommodation, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    fullSlug: accommodationFullSlug,
  });

  const sbAccommodationServices = hooks.useStoryblokComponents('products/accommodation_services');
  const sbAccommodationServicesDescription = findAccommodationServicesDescription(
    Object.values(sbAccommodationServices),
    accommodation.code,
    departurePort,
    arrivalPort
  );

  const [sbAccommodationModal, sbAccommodationModalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.accommodations_modal',
  });
  const [sbAccommodations] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.accommodations',
  });
  const sbPassengers = hooks.useStoryblokComponents('products/passengers');

  const state = useDialogState({ animated: false });

  const image = sbAccommodation?.content.image || {};
  const title = sbAccommodation?.content.title || accommodation.code;
  const description = sbAccommodation?.content.description || '';

  const passengerTags = passengers.map(({ type }) => ({
    icon: passengerIcon(type as PassengerType | PetType),
    label: sbPassengers[type]?.content['title'],
    addition: sbPassengers[type]?.content['description'] || undefined,
  })) as TagProps[];

  return (
    <Card
      ref={ref}
      tags={{
        title: sbAccommodations?.content.aria_label_passengers_in_this_cabin,
        tags: passengerTags?.map((props, index) => <Tag key={index} {...props} />) || [],
        ...(groupingPossible && !disabled && { disclosure: tagsDisclosure }),
      }}
      image={image}
      disclosureState={state}
      button={disabled ? undefined : sbAccommodationModal?.content.title}
      removeButton={
        !disabled && removeAccommodation
          ? {
              onClick: () => removeAccommodation(accommodation.id),
              label: sbAccommodations?.content.remove_accommodation,
            }
          : undefined
      }
    >
      <H3>{title || accommodation.code}</H3>
      {description && <P>{description}</P>}
      {sbAccommodationServicesDescription.mapOrDefault(
        (description) => (
          <P>{description}</P>
        ),
        <></>
      )}
      {!disabled && (
        <Modal
          {...{ state }}
          ref={sbAccommodationModalRef}
          title={sbAccommodationModal?.content.title}
          focusOnDialog
          grid
          submit={sbAccommodationModal?.content.ready_button}
        >
          {(state.animating || state.visible) && (
            <AccommodationModalContent
              {...{
                accommodation,
                arrivalPort,
                choices,
                departurePort,
                leg,
                passengers,
                sailingCode,
                send,
                shipName,
                tariff,
                movablePassengers
              }}
              sbContent={sbAccommodationModal?.content}
            />
          )}
        </Modal>
      )}
    </Card>
  );
};

export default AccommodationCard;
