import React from 'react';
import styled, { css } from 'styled-components';
import { cover } from 'polished';

import { font, measurement } from '../helpers/vars';
import { style, typography } from '../helpers/mixins';

import Icon, { IconName } from './Icon';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly position?: 'start' | 'end';
}

interface LinkButtonProps extends ButtonProps {
  readonly icon?: IconName;
  readonly small?: boolean;
}

const IconButton = styled(Icon)`
  ${style.button.common}
  ${style.button.size.small}
  ${style.button.variant.default}
  padding: ${measurement.inset.button.icon};
  border-radius: ${measurement.size.radius.circle};

  &::before {
    ${cover(measurement.space.button.target)}
    content: '';
  }
`;

export const Link = styled.button<ButtonProps>`
  font: inherit;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;

    ${IconButton} {
      ${({ theme }) => theme.focus};
    }
  }

  ${({ position }) =>
    position
      ? css`
          display: flex;
          align-items: center;
          place-self: center stretch;

          ${position === 'start' &&
          css`
            ${IconButton} {
              margin-right: 0.5rem;
            }
          `}

          ${position === 'end' &&
          css`
            flex-direction: row-reverse;

            ${IconButton} {
              margin-left: 0.5rem;
            }
          `}
        `
      : css`
          place-self: center;
        `}
`;

export const Content = styled.span<{ small?: boolean }>`
  font: inherit;
  ${style.link.common}
  font-weight: ${font.weight.strong.default};
  ${({ theme }) => theme.link}

  ${({ small }) =>
    small &&
    css`
      ${typography.small};
    `}

  ${Link}:hover & {
    ${style.link.hover}
  }

  ${Link}:focus & {
    ${style.link.focus}
  }
`;

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({ icon, children, small, ...props }) => (
  <Link {...props}>
    {icon && <IconButton name={icon} />}
    <Content {...{ small }}>{children}</Content>
  </Link>
);

/** @component */
export default LinkButton;
