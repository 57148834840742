import styled from 'styled-components';
import React, { FunctionComponent, useContext } from 'react';
import { RadioStateReturn } from 'reakit/Radio';
import RadioCard from '../../../../design-system/components/RadioCard';
import { H3 } from '../../../../design-system/components/Text';
import { Transition } from '../../../../design-system/helpers/components';
import { hooks } from '../../../../design-system/helpers/mixins';
import { measurement } from '../../../../design-system/helpers/vars';
import { Catalog, Tariff, TripType } from '../../../../fsm/types';
import { LanguageContext } from '../../../../Language.context';
import { calculateSailingTotalPrice } from '../../../../utils/priceCalculation';
import { PriceAreaProps } from '../../../../design-system/components/Card';
import PriceArea from '../../../../design-system/components/Card/PriceArea';
import { getSelected } from '../../../../fsm/utils/sailingUtils';
import RichText from '../../../../design-system/components/RichText';
import { isCruiseType } from '../../../../fsm/api/cruise';

export interface TicketCardProps {
  readonly catalog: Catalog;
  readonly catalogs: Catalog[];
  readonly tripType: TripType;
  readonly ticketType: RadioStateReturn;
  readonly uuid: string;
  readonly labelLower?: string;
  readonly labelHigher?: string;
}

const slugToType = (slug?: string): Tariff => {
  switch (slug) {
    case 'FLEXIBLE':
      return Tariff.STANDARD;
    case 'BASIC':
    default:
      return Tariff.SPECIAL;
  }
};

export const typeToSlug = (ticketType: string): string => {
  switch (ticketType) {
    case Tariff.SPECIAL:
      return 'BASIC';
    case Tariff.STANDARD:
    default:
      return 'FLEXIBLE';
  }
};

const PriceAreaWrapper = styled.div`
  margin-left: auto;
  width: fit-content;
`;

const PriceAreaFlex = styled.div`
  display: flex;
`;

const TicketCard: FunctionComponent<TicketCardProps> = ({
  catalog,
  catalogs,
  tripType,
  ticketType,
  uuid,
  labelLower,
  labelHigher,
}) => {
  const { formats } = useContext(LanguageContext);
  const [sbTicket, ref] = hooks.useStoryblokComponent<HTMLLabelElement>({ uuid });
  const slug = sbTicket?.slug;
  const priceDiff = !isCruiseType(tripType)
    ? priceDifference(catalog, formats, slug, labelLower, labelHigher)
    : priceDifferenceCruise(catalogs, formats, slug, labelLower, labelHigher);

  const ticketTitle = sbTicket?.content['title'];
  const ticketDesc = hooks.useStoryblokRichText(sbTicket?.content['description']);

  return (
    <>
      <RadioCard state={ticketType} value={slugToType(slug)} ref={ref}>
        <H3>{ticketTitle}</H3>
        <RichText>{ticketDesc}</RichText>
        <PriceAreaWrapper>
          <Transition.Height
            margin={[0, measurement.space.text.copy]}
            show={typeToSlug(ticketType.state + '') !== slug}
            as={PriceAreaFlex}
          >
            <PriceArea {...priceDiff} />
          </Transition.Height>
        </PriceAreaWrapper>
      </RadioCard>
    </>
  );
};

const priceDifference = (
  catalog: Catalog,
  formats: any,
  slug?: string,
  labelLower?: string,
  labelHigher?: string
): PriceAreaProps | undefined => {
  return getSelected(catalog)
    .map((selected) => {
      if (selected.meta?.loading === undefined || selected.meta?.loading) {
        return { loading: true };
      }
      if (!selected.price || typeof selected.price === 'string') {
        return undefined;
      }

      const specialPrice = calculateSailingTotalPrice(selected, Tariff.SPECIAL);
      const standardPrice = calculateSailingTotalPrice(selected, Tariff.STANDARD);

      if (!specialPrice || !standardPrice) {
        // Cannot compare, nought price(s)
        return {
          label: '',
          price: undefined,
          loading: false,
        } as PriceAreaProps;
      }

      const priceDiff: PriceAreaProps =
        slug === 'BASIC'
          ? {
              price: formats.currency(
                typeof selected.price.SPECIAL !== 'string' ? '' + (specialPrice - standardPrice) : ''
              ),
              label:
                typeof selected.price.SPECIAL !== 'string'
                  ? specialPrice - standardPrice > 0
                    ? labelHigher
                    : labelLower
                  : selected.price.SPECIAL,
            }
          : {
              price: formats.currency(
                typeof selected.price.STANDARD !== 'string' ? '' + (standardPrice - specialPrice) : ''
              ),
              label:
                typeof selected.price.STANDARD !== 'string'
                  ? standardPrice - specialPrice > 0
                    ? labelHigher
                    : labelLower
                  : selected.price.STANDARD,
            };

      return priceDiff;
    })
    .extract();
};

const priceDifferenceCruise = (
  catalogs: Catalog[],
  formats: any,
  slug?: string,
  labelLower?: string,
  labelHigher?: string
) => {
  const prices = catalogs
    .map((c) => getSelected(c))
    .map((maybSelected) =>
      maybSelected
        .map((selected) => {
          if (selected.meta?.loading === undefined || selected.meta?.loading) {
            return { loading: true };
          }
          if (!selected.price || typeof selected.price === 'string') {
            return undefined;
          }
          const specialPrice = calculateSailingTotalPrice(selected, Tariff.SPECIAL);
          const standardPrice = calculateSailingTotalPrice(selected, Tariff.STANDARD);
          return {
            specialPrice,
            standardPrice,
          };
        })
        .orDefault({
          specialPrice: 0,
          standardPrice: 0,
        })
    );

  // prices not loaded yet
  if (prices.some((p) => p === undefined)) {
    return undefined;
  }

  // prices are being loaded
  if (prices.some((p) => p?.loading)) {
    return {
      loading: true,
    };
  }

  // error received when fetching prices
  if (prices.some((p) => p?.specialPrice === undefined || p.standardPrice === undefined)) {
    return {
      label: '',
      price: undefined,
      loading: false,
    } as PriceAreaProps;
  }

  // calculate total price of cruise legs
  const specialPriceTotal = prices
    .map((p) => p?.specialPrice || 0)
    .reduce((specialSum, specialPrice) => specialSum + specialPrice, 0);

  const standardPriceTotal = prices
    .map((p) => p?.standardPrice || 0)
    .reduce((standardSum, standardPrice) => standardSum + standardPrice, 0);

  let price;
  let label;

  if (slug === 'BASIC') {
    if (prices.some((p) => p?.specialPrice === 0)) {
      price = formats.currency('');
      label = '';
    } else {
      price = formats.currency('' + (specialPriceTotal - standardPriceTotal));
      label = specialPriceTotal - standardPriceTotal > 0 ? labelHigher : labelLower;
    }
  } else {
    if (prices.some((p) => p?.standardPrice === 0)) {
      price = formats.currency('');
      label = '';
    } else {
      price = formats.currency('' + (standardPriceTotal - specialPriceTotal));
      label = standardPriceTotal - specialPriceTotal > 0 ? labelHigher : labelLower;
    }
  }

  return {
    price,
    label,
  };
};

export default TicketCard;
