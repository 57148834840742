import React from 'react';
import styled, { css } from 'styled-components';
import { Port } from '../../../fsm/types';
import { Sticky } from '../../helpers/components';
import { animation, hooks, responsive } from '../../helpers/mixins';
import { color, measurement, zindex } from '../../helpers/vars';
import Icon from '../Icon';
import { H2 } from '../Text';

interface PassedProps {
  readonly hasMap?: boolean;
}

interface TitleProps extends PassedProps {
  readonly isSticky?: boolean;
  readonly isBottom?: boolean;
  readonly height: number;
}

export interface StickyTitleProps extends PassedProps {
  readonly origin: Port;
  readonly destination: Port;
  readonly isCruise?: boolean;
}

const Title = styled(H2)<TitleProps>`
  text-align: center;
  position: relative;
  z-index: ${zindex.subnav};
  margin: 0;
  padding: ${measurement.space.departure.area.s};
  box-sizing: border-box;
  transition: ${animation.transition('background', 'border')};

  ${responsive.fontSize(css`
    padding: ${measurement.space.departure.area.l};
  `)}

  &::before {
    backdrop-filter: blur(0.125rem);
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    height: 0;
    ${(props) =>
      props.hasMap
        ? css`
            background: ${color.bg.sticky.default};
            transition: ${animation.transition('height')};
          `
        : css`
            background: ${color.bg.sticky.alt};
          `}
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    z-index: -1;
    top: ${measurement.size.tabbar.s};

    ${responsive.fontSize(css`
      top: ${measurement.size.tabbar.l};
    `)}
  }

  ${(props) =>
    (props.isSticky || props.isBottom) &&
    css`
      left: 0;
      right: 0;

      &::before {
        height: ${props.height}px;
      }
    `}

  ${(props) =>
    props.isSticky &&
    css`
      position: fixed;
      top: ${measurement.size.tabbar.s};

      ${responsive.fontSize(css`
        top: ${measurement.size.tabbar.l};
      `)}
    `};

  ${(props) =>
    props.isBottom &&
    css`
      &,
      &::before {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    `};
`;

const StickyTitle: React.FunctionComponent<StickyTitleProps> = ({ origin, destination, hasMap, isCruise }) => {
  const sbPorts = hooks.useStoryblokDatasource('ports');
  const originPort = (sbPorts && sbPorts[origin]) || origin;
  const destinationPort = (sbPorts && sbPorts[destination]) || destination;
  const oneWayTitleText = (
    <>
      {originPort} <Icon name="arrow" inline /> {destinationPort}
    </>
  );
  const cruiseTitleText = (
    <>
      {originPort} <Icon name="arrow" inline /> {destinationPort} <Icon name="arrow" inline /> {originPort}
    </>
  );
  const titleText = isCruise ? cruiseTitleText : oneWayTitleText;

  return <Sticky.Item>{(stickyProps) => <Title {...{ hasMap, ...stickyProps }}>{titleText}</Title>}</Sticky.Item>;
};

export default StickyTitle;
