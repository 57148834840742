import { useState } from 'react';
import Joi from '@hapi/joi';
import { PassengerType } from '../../../FinnlinesB2CBookingAPI';

export interface SelectStateReturn {
  readonly state: any;
  readonly setState: (state: any) => void;
  readonly onChange: () => void;
  readonly error?: string;
}

export interface SelectProps {
  readonly initial?: any;
  readonly onReady?: (key: string, val: string) => void;
  readonly validationSchema?: Joi.ObjectSchema<any>;
  readonly key?: string;
  readonly type?: PassengerType;
  readonly errorMsg?: string;
}

const useSelectState: (arg: SelectProps) => SelectStateReturn = ({
  initial,
  validationSchema,
  onReady,
  type,
  key,
  errorMsg,
}) => {
  const [state, setState] = useState(initial);
  const [error, setError] = useState<string | undefined>();

  const onChange = () => {
    if (!validationSchema || !onReady || !key) return;
    const validation = validationSchema.validate({
      [key]: state,
      type: type ? type : PassengerType.ADULT,
    });
    if (validation.error || validation.errors) {
      setError(errorMsg);
    } else {
      setError(undefined);
      onReady(key, state);
    }
  };

  return {
    state,
    setState,
    onChange,
    error,
  };
};

export default useSelectState;
