import format from 'date-fns/format';
import { fi, de, sv, enGB as en, it, es, fr, pl } from 'date-fns/locale';
import { Language } from '../fsm/types';
import { setMonth } from 'date-fns';

type DateFormat = {
  short: string;
  long: string;
};

const LOCALES: Record<Language, Locale> = {
  [Language.EN]: en,
  [Language.FI]: fi,
  [Language.DE]: de,
  [Language.SV]: sv,
  [Language.PL]: pl,
  [Language.IT]: it,
  [Language.ES]: es,
  [Language.FR]: fr,
};

const DATE_FORMATS: Record<Language, DateFormat> = {
  [Language.EN]: { short: 'dd MMM yyyy', long: 'iii dd MMM yyyy' },
  [Language.FI]: { short: 'dd.MM.yyyy', long: 'iiiiii dd.MM.yyyy' },
  [Language.DE]: { short: 'dd.MM.yyyy', long: 'iii dd.MM.yyyy' },
  [Language.SV]: { short: 'dd MMM yyyy', long: 'iii dd MMM yyyy' },
  [Language.PL]: { short: 'dd MMM yyyy', long: 'iii dd MMM yyyy' },
  [Language.IT]: { short: 'dd MMM yyyy', long: 'iii dd MMMM yyyy' },
  [Language.ES]: { short: 'dd MMM yyyy', long: 'iii dd MMMM yyyy' },
  [Language.FR]: { short: 'dd MMM yyyy', long: 'iii dd MMMM yyyy' },
};

const DOW_FORMATS: Record<Language, DateFormat> = {
  [Language.EN]: { short: 'ccc', long: 'cccc' },
  [Language.FI]: { short: 'cccccc', long: 'cccc' },
  [Language.DE]: { short: 'ccc', long: 'cccc' },
  [Language.SV]: { short: 'ccc', long: 'cccc' },
  [Language.PL]: { short: 'ccc', long: 'cccc' },
  [Language.IT]: { short: 'ccc', long: 'cccc' },
  [Language.ES]: { short: 'ccc', long: 'cccc' },
  [Language.FR]: { short: 'ccc', long: 'cccc' },
};

const MONTH_FORMATS: Record<Language, DateFormat> = {
  [Language.EN]: { short: 'MMM', long: 'MMMM' },
  [Language.FI]: { short: 'MMM', long: 'LLLL' },
  [Language.DE]: { short: 'MMM', long: 'MMMM' },
  [Language.SV]: { short: 'MMM', long: 'MMMM' },
  [Language.PL]: { short: 'MMM', long: 'MMMM' },
  [Language.IT]: { short: 'MMM', long: 'MMMM' },
  [Language.ES]: { short: 'MMM', long: 'MMMM' },
  [Language.FR]: { short: 'MMM', long: 'MMMM' },
};

export default class I18N {
  private language: Language = Language.EN;

  constructor(language: Language) {
    this.setLanguage(language);
  }

  setLanguage(language: Language) {
    this.language = language;
  }

  private getLocale(): Locale {
    return LOCALES[this.language] ?? en;
  }

  private monthToDate(month: Date | number): Date {
    return typeof month === 'number' ? setMonth(new Date(), month - 1) : month;
  }

  localizeMonth(month: number): string {
    const pattern = MONTH_FORMATS[this.language].long;

    return format(this.monthToDate(month), pattern, { locale: this.getLocale() });
  }

  localizeShortMonth(month: Date | number): string {
    const pattern = MONTH_FORMATS[this.language].short;

    return format(this.monthToDate(month), pattern, { locale: this.getLocale() });
  }

  localizeDate(date: Date): string {
    const pattern = DATE_FORMATS[this.language].long;

    return format(date, pattern, { locale: this.getLocale() });
  }

  localizeShortDate(date: Date): string {
    const pattern = DATE_FORMATS[this.language].short;

    return format(date, pattern, { locale: this.getLocale() });
  }

  localizeShortDayOfWeek(date: Date): string {
    const pattern = DOW_FORMATS[this.language].short;

    return format(date, pattern, { locale: this.getLocale() });
  }
}
