import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import { P } from '../Text';
import { typography } from '../../helpers/mixins';

interface ItemProps {
  readonly innerRef?: React.RefObject<HTMLLIElement>;
  readonly count?: number | string;
}

const Wrapper = styled(P)`
  margin: 0;

  & & {
    ${typography.small}
  }
`;

const Item: React.FunctionComponent<ItemProps> = ({ count, children, innerRef }) => (
  <Wrapper as="li" ref={innerRef}>
    {count && (
      <>
        {count}
        <Icon small inline name="times" />
      </>
    )}
    {children}
  </Wrapper>
);

export default Item;
