import React, { FunctionComponent, useContext } from 'react';
import { hooks, style } from '../../../design-system/helpers/mixins';
import { color, measurement } from '../../../design-system/helpers/vars';

import styled from 'styled-components';
import {
  Trip,
  PassengerType,
  ExtendedPassenger,
  TripType,
  TripTagTypes,
  ExtendedSailing,
  SelectedSailing,
} from '../../../fsm/types';
import Card from '../../../design-system/components/Card';
import { P, H3 } from '../../../design-system/components/Text';
import Tag, { Wrapper } from '../../../design-system/components/Tag';
import { parse } from 'date-fns/esm';
import I18N from '../../../utils/i18n';
import { LanguageContext } from '../../../Language.context';
import { TreeData } from '../../../storyblok/types';

interface PassengerInfoProps {
  readonly trip?: Trip;
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin: ${measurement.space.input.select.indicator} 0 ${measurement.space.input.select.indicator} 0;
`;

const Contact = styled(P)`
  color: ${color.brand};
`;

const SWrapper = styled(Wrapper)`
  flex-wrap: nowrap;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${style.border.default};
  margin-bottom: ${measurement.space.flexible.s};
  ${H3} {
    margin-bottom: 0;
  }
  ${Contact} {
    margin-bottom: 0;
  }
`;

const PassengerInfo: FunctionComponent<PassengerInfoProps> = ({ trip }) => {
  const [sbPassengers] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.summary_passenger',
  });

  const [sbGenderSelect] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.gender_select',
  });
  const [sbNeedForCareSelect] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.need_for_care_select',
  });

  const sbSummaryTags = hooks.useStoryblokDatasource('summary-tags');

  const sbCountryCodes = hooks.useStoryblokComponents('components/country-codes');

  const sbAccommodation = hooks.useStoryblokComponents('products/accommodations');

  const { language } = useContext(LanguageContext);
  const i18n = new I18N(language);

  return (
    <Card>
      {trip
        ? arrangePassengersReserverFirst(
            trip.passengers.filter((p) => p.type in PassengerType) as ExtendedPassenger[]
          ).map((p) => {
            const tags = createTags(trip.sailings, trip.type, p, sbAccommodation, sbSummaryTags[TripTagTypes.NOCABIN]);
            return (
              p.info && (
                <React.Fragment key={p.id}>
                  <Header>
                    <H3>{`${p.info.firstname} ${p.info.lastname}`}</H3>
                    {p.info?.reserverInfo?.address ? <Contact>{sbPassengers?.content.contact_person}</Contact> : null}
                  </Header>
                  <SWrapper>
                    {tags.map((tag, index) => {
                      // TODO: Add Multileg and cruise tags
                      const typeTag = tag.label;

                      return <Tag addition={`${sbSummaryTags[typeTag] || ''}: ${tag.cabin}`} key={index} />;
                    })}
                  </SWrapper>
                  <List>
                    <ListItem>{i18n.localizeShortDate(parse(p.info?.birth ?? '', 'dd/MM/yyyy', new Date()))}</ListItem>
                    <ListItem>
                      {sbGenderSelect?.content.choices.find((c: any) => c.value === p.info?.gender).label}
                    </ListItem>
                    <ListItem>
                      {p.info.nationality && sbCountryCodes && sbCountryCodes[p.info.nationality]
                        ? sbCountryCodes[p.info.nationality].content.label || 'No country'
                        : null}
                    </ListItem>
                    {p.info?.specialNeeds && (
                      <ListItem>
                        {sbNeedForCareSelect?.content.choices.find((c: any) => p.info?.specialNeeds === c.value)?.label}
                      </ListItem>
                    )}

                    {p.info.reserverInfo?.address ? (
                      <>
                        <ListItem>{p.info.reserverInfo.phone}</ListItem>
                        <ListItem>{p.info.reserverInfo.email}</ListItem>
                        <ListItem>{p.info.reserverInfo.address}</ListItem>
                        <ListItem>{`${p.info.reserverInfo.postalCode} ${p.info.reserverInfo.city}, ${
                          p.info.reserverInfo.country && sbCountryCodes && sbCountryCodes[p.info.reserverInfo.country]
                            ? sbCountryCodes[p.info.reserverInfo.country].content.label || 'No country'
                            : ''
                        }`}</ListItem>
                      </>
                    ) : null}
                  </List>
                </React.Fragment>
              )
            );
          })
        : 'Error, no trip to display'}
    </Card>
  );
};

export const createTags = (
  sailings: {
    index: number;
    sailing: ExtendedSailing & SelectedSailing;
  }[],
  type: TripType,
  passenger: ExtendedPassenger,
  sbAccommodation: {
    [slug: string]: TreeData;
  },
  nocabin: string = ''
) =>
  sailings.reduce(
    (tags, sailing) => {
      const cabinCode =
        typeof sailing.sailing.accommodations !== 'string' && sailing.sailing.accommodations
          ? sailing.sailing.accommodations.find((a) => a.passengers?.includes(passenger.id))?.code
          : undefined;
      const cabins = cabinCode
        ? Object.keys(sbAccommodation).reduce((result, key) => {
            if (sbAccommodation[key].content.product_code === cabinCode) result.push(sbAccommodation[key]);
            return result;
          }, [] as TreeData[])
        : undefined;
      const cabin =
        cabins?.find((c) =>
          (c.content.ship as string[]).find(
            (ship) => ship.toUpperCase().replace(/\s+/g, '_') === sailing.sailing.shipName
          )
        )?.content.title || nocabin;

      if (tags.find((t) => t.cabin === cabin)) {
        tags = tags.map((tag) =>
          tag.cabin === cabin
            ? {
                ...tag,
                label: type === TripType.RETURN ? TripTagTypes.TWOWAY : tag.label,
                legs: [...tag.legs, sailing.index],
              }
            : tag
        );
      } else {
        const label =
          type === TripType.RETURN || type === TripType.DAYCRUISE
            ? sailing.index === 0
              ? TripTagTypes.OUTGOING
              : TripTagTypes.RETURN
            : type === TripType.ONEWAY
            ? TripTagTypes.OUTGOING
            : type === TripType.OVERNIGHT_CRUISE
            ? TripTagTypes.CRUISE
            : TripTagTypes.LEG;

        tags.push({
          label,
          legs: [sailing.index],
          cabin,
          code: cabinCode,
        });
      }
      return tags;
    },
    [] as {
      label: TripTagTypes;
      legs: number[];
      cabin: string | undefined;
      code: string | undefined;
    }[]
  );

const arrangePassengersReserverFirst = (passengers: ExtendedPassenger[]) =>
  passengers.sort((a, b) => {
    return a.info?.reserverInfo?.address ? 1 : compareType(a.type, b.type);
  });

const typeValues = {
  [PassengerType.ADULT]: 3,
  [PassengerType.JUNIOR]: 2,
  [PassengerType.CHILD]: 1,
  [PassengerType.INFANT]: 0,
};
const compareType = (typeA: PassengerType, typeB: PassengerType) =>
  typeValues[typeA] > typeValues[typeB] ? -1 : typeValues[typeA] === typeValues[typeB] ? 0 : 1;

export default PassengerInfo;
