import { List } from 'purify-ts/List';
import { Maybe } from 'purify-ts/Maybe';
import { GA4Product, Impression, Product } from '../../analytics/gtm';
import * as API from '../types';
import { Currency } from '../types';

export interface ProductWithCodeProps<T extends string> {
  amount: number;
  chargeInfo?: Maybe<API.ChargeInfo>;
  code: string;
  index: number;
  type: T;
  name: string | undefined | null;
  brand: string;
}

export interface ProductWithIdProps<T extends string> {
  amount: number;
  chargeInfo?: Maybe<API.ChargeInfo>;
  id: string;
  index: number;
  type: T;
  name: string | undefined | null;
  brand: string;
}

const generalProduct = <T extends string>({
  amount,
  id,
  index,
  category,
  chargeInfo,
  type,
  name,
  brand,
}: ProductWithIdProps<T> & {
  category: string;
}) => ({
  category,
  id,
  name: name ?? id,
  position: index + 1, // Index is zero-based.
  price: chargeInfo ? chargeInfo.map(({ charge }) => (charge / 100).toFixed(2)).extract() : undefined,
  quantity: amount,
  variant: type.toLowerCase(),
  brand,
});

const generalProductWithCode = <T extends string>({
  amount,
  code,
  index,
  category,
  chargeInfo,
  type,
  name,
  brand,
}: ProductWithCodeProps<T> & {
  category: string;
}) => ({
  category,
  id: code,
  name: name ?? code,
  position: index + 1, // Index is zero-based.
  price: chargeInfo ? chargeInfo.map(({ charge }) => (charge / 100).toFixed(2)).extract() : undefined,
  quantity: amount,
  variant: type.toLowerCase(),
  brand,
});

export const accommodationProduct = (props: ProductWithCodeProps<string>) =>
  generalProductWithCode({
    ...props,
    category: 'accommodation',
  });

export const onboardProduct = (props: ProductWithCodeProps<string>) =>
  generalProductWithCode({
    ...props,
    category: 'onboard',
  });

export const passengerProduct = (props: ProductWithIdProps<API.PassengerType>) =>
  generalProduct({ ...props, category: 'passenger' });
export const petProduct = (props: ProductWithIdProps<API.PetType>) => generalProduct({ ...props, category: 'pet' });
export const vehicleProduct = (props: ProductWithIdProps<API.VehicleType>) =>
  generalProduct({ ...props, category: 'vehicle' });

export const ticketProduct = (sailing: API.ExtendedSailing & API.SelectedSailing, leg: number): Product => ({
  category: 'ticket',
  coupon: sailing.offerCode,
  id: sailing.sailingCode,
  name: sailing.tariff === API.Tariff.SPECIAL ? 'Basic ticket' : 'Flexible ticket',
  position: leg + 1, // Leg is zero-based.
  price: API.maybeChargeInfo(sailing.tariff, sailing)
    .chainNullable((_) => (_.charge / 100).toFixed(2))
    .extract(),
  variant: sailing.tariff.toLowerCase(),
  brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
});

export const serviceProduct = (sailing: API.ExtendedSailing, leg: number, name: string): Product => ({
  category: 'onboard',
  id: name,
  name: name,
  position: leg + 1, // Leg is zero-based.
  variant: name.toLowerCase(),
  brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
});

export const collectProducts = (trip?: API.Trip): Product[] => {
  const products = [] as Product[];

  if (trip) {
    trip.sailings.forEach(({ index, sailing }) => {
      products.push(ticketProduct(sailing, index));

      if (Array.isArray(sailing.accommodations)) {
        sailing.accommodations.forEach((accommodation) => {
          products.push(
            accommodationProduct({
              index,
              amount: 1,
              chargeInfo: API.maybeChargeInfo(sailing.tariff, accommodation),
              code: accommodation.code,
              type: accommodation.type,
              name: formAnalyticsProductName(sailing.products, API.ProductType.ACCOMMODATION, accommodation.code),
              brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
            })
          );

          // Added using passenger indexes from accommodation
          // to include sailing index to passengers/pets.
          if (Array.isArray(accommodation.passengers)) {
            accommodation.passengers.forEach((passengerId) => {
              List.find(({ id }) => id === passengerId, trip.passengers).map((passengerOrPet) => {
                return products.push(
                  API.isPassengerInput(passengerOrPet)
                    ? passengerProduct({
                        index,
                        amount: 1,
                        chargeInfo: Maybe.fromNullable(passengerOrPet.price)
                          .chainNullable((price) => price[index + 1])
                          .chainNullable((legs) => (typeof legs === 'string' ? undefined : legs[sailing.tariff]))
                          .chainNullable((charge) => (typeof charge === 'string' ? undefined : charge)),
                        id: passengerOrPet.type,
                        type: passengerOrPet.type,
                        name: formAnalyticsProductName(
                          sailing.products,
                          API.ProductType.PASSENGER,
                          passengerOrPet.type
                        ),
                        brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
                      })
                    : petProduct({
                        index,
                        amount: 1,
                        chargeInfo: Maybe.fromNullable(passengerOrPet.price)
                          .chainNullable((price) => price[index + 1])
                          .chainNullable((legs) => (typeof legs === 'string' ? undefined : legs[sailing.tariff]))
                          .chainNullable((charge) => (typeof charge === 'string' ? undefined : charge)),
                        id: passengerOrPet.type,
                        type: passengerOrPet.type,
                        name: formAnalyticsProductName(sailing.products, API.ProductType.PET, passengerOrPet.type),
                        brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
                      })
                );
              });
            });
          }
        });
      }

      if (Array.isArray(sailing.onboards)) {
        sailing.onboards.forEach((onboard) => {
          products.push(
            onboardProduct({
              index,
              amount: onboard.amount,
              chargeInfo: API.maybeChargeInfo(sailing.tariff, onboard),
              code: onboard.code,
              type: onboard.type,
              name: formAnalyticsProductName(sailing.products, onboard.type as API.ProductType, onboard.code),
              brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
            })
          );
        });
      }

      if (Array.isArray(trip.vehicles)) {
        trip.vehicles.forEach((vehicle) => {
          if (vehicle.legs.includes(index)) {
            products.push(
              vehicleProduct({
                index,
                amount: 1,
                id: vehicle.type,
                type: vehicle.type,
                chargeInfo: Maybe.fromNullable(vehicle.price)
                  .chainNullable((price) => price[index + 1])
                  .chainNullable((legs) => (typeof legs === 'string' ? undefined : legs[sailing.tariff]))
                  .chainNullable((charge) => (typeof charge === 'string' ? undefined : charge)),
                name: formAnalyticsProductName(sailing.products, API.ProductType.VEHICLE, vehicle.type),
                brand: `${sailing.departurePort} - ${sailing.arrivalPort}`,
              })
            );
          }
        });
      }
    });
  }

  return products;
};

export function formAnalyticsProductName(
  products: API.Products | API.ProductError,
  productType: API.ProductType,
  identifier: string
) {
  if (productType === API.ProductType.PASSENGER) {
    return typeof products !== 'string'
      ? products[productType]?.find((_) => _.limitedTo?.includes(identifier as API.PassengerType))?.desc
      : undefined;
  }
  return typeof products !== 'string' ? products[productType]?.find((_) => _.code === identifier)?.desc : undefined;
}

export function productsToGA4Products(products: Product[], currency: Currency): GA4Product[] {
  return products.map((p) => productToGa4Product(p, currency));
}

export function productToGa4Product(product: Product, currency: Currency): GA4Product {
  return {
    currency,
    item_id: product.id,
    item_name: product.name,
    index: product.position,
    item_brand: product.brand,
    item_variant: product.variant,
    item_category: product.category,
    price: product.price,
    quantity: product.quantity,
    coupon: product.coupon,
  };
}

export function impressionsToGA4Impressions(impressions: Impression[], currency: Currency): GA4Product[] {
  return impressions.map((i) => ({
    item_id: i.id,
    item_name: i.name,
    index: i.position,
    item_brand: i.brand,
    item_category: i.category,
    item_list_name: i.list,
    item_variant: i.variant,
    price: i.price,
    currency,
  }));
}
