import styled from 'styled-components';
import { cover } from 'polished';

import { color, measurement } from '../../helpers/vars';

const DropIndicator = styled.div`
  ${cover(`-${measurement.size.border.default}`)}
  content: '';
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${measurement.size.border.strong} dotted ${color.border.action};
`;

export default DropIndicator;
