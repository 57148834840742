import { IconName } from '../../design-system/components/Icon';
import { settings } from '../../settings';
import {
  ExtendedAccommodation,
  ExtendedPassengerAndPet,
  isPetInput,
  PassengerType,
  PetType,
  Product,
  AccommodationError,
  ExtendedProduct,
  ExtendedPassenger,
  PassengerGender,
  Catalog,
  SelectedSailing,
  ExtendedSailing,
} from '../types';
import { canBeAlone } from './passengerUtils';
import { CatalogEvent } from '../catalog/catalogMachine';
import { Sender } from 'xstate';
import { PossibleNew } from '../../components/catalog/listing/Trip/AccommodationSelect/PassengerGrouping';
import { getSelectedSailings } from './sailingUtils';

export const checkAccommodationAvailableInSailing = (
  products: Product[],
  accommodations: ExtendedAccommodation[],
  codeToCheck: string
): boolean => {
  const availCapacity = products.find((p) => p.code === codeToCheck)?.capacity.available;
  const usedCapacity = accommodations.reduce(
    (capacity, current) => (capacity += current.code === codeToCheck ? 1 : 0),
    0
  );
  return availCapacity ? usedCapacity < availCapacity : false;
};

export const cabinsAvailable = (products: Product[], accommodations: ExtendedAccommodation[]): boolean => {
  return settings.accommodationOrder.some((code) => {
    const pick = products.find((a) => a.code === code);
    return (
      pick &&
      pick.subtype === 'CABIN' &&
      pick.capacity &&
      pick.capacity.available &&
      accommodationHasCapacity(pick, accommodations)
    );
  });
};

export const accommodationsAvailable = (accommodations: Product[], requireSubtypes?: string[]): boolean => {
  return settings.accommodationOrder.some((code) => {
    const pick = accommodations.find((a) => a.code === code);

    return (
      pick &&
      (!requireSubtypes || requireSubtypes.some((_) => _ === pick.subtype)) &&
      pick.capacity &&
      pick.capacity.available &&
      pick.capacity.available > 0
    );
  });
};

export const canAddMoreAccommodations = (
  passengers: ExtendedPassengerAndPet[],
  accommodations: ExtendedAccommodation[]
): boolean => {
  const withoutCabin = getWithoutCabin(passengers, accommodations);

  // movables without cabin has passengers that can be alone in cabin
  if (getAdults(withoutCabin).length > 0 || getJuniors(withoutCabin).length > 0) {
    return true;
  }
  // there's an accomodation that has two that can be alone in cabin
  return (
    accommodations.find((accommodation) => {
      const adults = getAdultsInAccommodation(accommodation, passengers);
      const juniors = getJuniorsInAccommodation(accommodation, passengers);
      return adults.length + juniors.length > 1;
    }) !== undefined
  );
};

export const removeEmptyAccommodations = (accommodations: ExtendedAccommodation[]): ExtendedAccommodation[] => {
  return accommodations.filter((a) => a.passengers && a.passengers.length > 0);
};

export const accommodationSubtype = (accommodation: ExtendedAccommodation, accommodationsAsProducts: Product[]) => {
  const product = accommodationsAsProducts.find((a) => a.code === accommodation.code);
  return product?.subtype;
};

export const inCabin = (passenger: { id: number }, accommodations: ExtendedAccommodation[]) =>
  accommodations.some((a) => a.passengers?.includes(passenger.id));

export const getSelectedAccommodations = (catalogs: Catalog[]) => {
  const selectedSailings = getSelectedSailings(catalogs);
  return getAccommodations(selectedSailings);
};

export const getAccommodations = (selectedSailings: { index: number; sailing: SelectedSailing & ExtendedSailing }[]) =>
  selectedSailings.reduce((acc, curr) => {
    if (curr.sailing.accommodations && Array.isArray(curr.sailing.accommodations))
      acc.push(...curr.sailing.accommodations);
    return acc;
  }, [] as ExtendedAccommodation[]);

export const getSelectedAccAsProducts = (catalogs: Catalog[]) => {
  const selectedSailings = getSelectedSailings(catalogs);
  return getAccommodationsAsProducts(selectedSailings);
};
export const getAccommodationsAsProducts = (
  selectedSailings: { index: number; sailing: SelectedSailing & ExtendedSailing }[]
) =>
  selectedSailings.reduce((acc, curr) => {
    if (curr.sailing.products && typeof curr.sailing.products !== 'string' && curr.sailing.products.ACCOMMODATION) {
      acc.push(...curr.sailing.products.ACCOMMODATION);
    }
    return acc;
  }, [] as ExtendedProduct[]);

export const inGenderSpecific = (
  passenger: ExtendedPassenger,
  accommodations: ExtendedAccommodation[],
  accommodationProducts: ExtendedProduct[]
): PassengerGender[] => {
  const inAcc = accommodations.reduce((acc, curr) => {
    if (curr.passengers?.includes(passenger.id)) acc.push(curr);
    return acc;
  }, [] as ExtendedAccommodation[]);

  const inGenderSpecificAcc = inAcc.reduce((acc, curr) => {
    const asProduct = accommodationProducts.find((p) => p.code === curr.code);
    if (asProduct?.desc?.toUpperCase().includes(PassengerGender.FEMALE)) {
      acc.push(PassengerGender.FEMALE);
    } else if (asProduct?.desc?.toUpperCase().includes(PassengerGender.MALE)) {
      acc.push(PassengerGender.MALE);
    }
    // if (asProduct?.gender) {
    //   acc.push(asProduct?.gender);
    // }
    return acc;
  }, [] as PassengerGender[]);

  return inGenderSpecificAcc.length > 0 ? inGenderSpecificAcc : [PassengerGender.UNSPECIFIED];
};

export const accommodationHasCapacity = (accommodation: Product, currentChoices: ExtendedAccommodation[]) =>
  (accommodation.capacity?.available || 0) >
  currentChoices.reduce((a, c) => (a + c.code === accommodation.code ? 1 : 0), 0);

export const pickUntilFoundCheapest = (
  availableAccommodations: Product[],
  currentAccommodations: ExtendedAccommodation[],
  requireSize?: number,
  requireSubtypes?: string[],
  doNotExpandSubtypes?: boolean
): Product | undefined => {
  let pick: Product | undefined;
  let i = 0;
  const maxSize = Math.max.apply(
    Math,
    availableAccommodations.map((o) => o.maxPeople || 0)
  );
  let expandedAllDown = false;
  let usableRequireSize = requireSize;

  if (
    requireSize &&
    usableRequireSize &&
    requireSubtypes?.includes('CABIN') &&
    usableRequireSize < minCabinSize(availableAccommodations)
  ) {
    usableRequireSize = minCabinSize(availableAccommodations);
  }

  while (!pick && i < settings.accommodationOrder.length) {
    pick = availableAccommodations.find((a) => a.code === settings.accommodationOrder[i]);

    if (
      !pick ||
      (usableRequireSize && pick.maxPeople && pick.maxPeople < usableRequireSize) ||
      !pick.capacity.available ||
      pick.capacity.available <= 0 ||
      !checkAccommodationAvailableInSailing(availableAccommodations, currentAccommodations, pick.code) ||
      (requireSubtypes && pick.subtype && !requireSubtypes.includes(pick.subtype))
    ) {
      pick = undefined;
    }
    i++;

    //expand search if not found

    if (usableRequireSize && requireSize && !pick && i >= settings.accommodationOrder.length) {
      //first go down
      if (!expandedAllDown) {
        usableRequireSize--;
        i = 0;
      } else {
        //then go up
        usableRequireSize++;
        i = 0;
      }

      if (usableRequireSize < 1) {
        expandedAllDown = true;
        usableRequireSize = requireSize + 1;
      }

      if (expandedAllDown && usableRequireSize > maxSize) return undefined;
    }

    if (!doNotExpandSubtypes) {
      if (requireSubtypes && !pick && i >= settings.accommodationOrder.length) {
        requireSubtypes = undefined;
        i = 0;
      }
    }
  }

  return pick;
};

const minCabinSize = (accommodations: Product[]): number => {
  return Math.min.apply(
    Math,
    accommodations.map((_) => _.maxPeople || -1)
  );
};

export const filterOutPetCabins = (accommodations: ExtendedProduct[]) =>
  accommodations.filter((a) => !a.maxPets || a.maxPets <= 0);

export const filterToPetCabins = (accommodations: ExtendedProduct[]) =>
  accommodations.filter((a) => a.maxPets && a.maxPets > 0);

export const containsAdultOrJunior = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]) => {
  return (
    getAdultsInAccommodation(accommodation, passengers)?.length +
      getJuniorsInAccommodation(accommodation, passengers).length >
    0
  );
};
export const containsAdult = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]) => {
  return getAdultsInAccommodation(accommodation, passengers)?.length > 0;
};

export const containsChildren = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]) => {
  return getChildrenInAccommodation(accommodation, passengers)?.length > 0;
};

export const containsPet = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]) => {
  return getPetsInAccommodation(accommodation, passengers)?.length > 0;
};

export const getAdultsInAccommodation = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[]
): number[] => {
  const adults = accommodation.passengers?.filter((id) => {
    const maybeAdult = passengers.find((p) => p.id === id);
    return maybeAdult?.type === PassengerType.ADULT;
  });
  return adults ? adults : [];
};

export const getJuniorsInAccommodation = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[]
): number[] => {
  const juniors = accommodation.passengers?.filter((id) => {
    const maybeJunior = passengers.find((p) => p.id === id);
    return maybeJunior?.type === PassengerType.JUNIOR;
  });
  return juniors ? juniors : [];
};

export const getChildrenInAccommodation = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[]
): number[] => {
  var children = accommodation.passengers?.filter((id) => {
    const maybeChild = passengers.find((p) => p.id === id);
    return maybeChild?.type === PassengerType.CHILD || maybeChild?.type === PassengerType.INFANT;
  });
  return children ? children : [];
};

export const getPetsInAccommodation = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[]
): number[] => {
  var pets = accommodation.passengers?.filter((id) => {
    const maybePet = passengers.find((p) => p.id === id);
    return maybePet && isPetInput(maybePet);
  });
  return pets ? pets : [];
};

export const getAdults = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter((p) => p.type === PassengerType.ADULT);

export const getJuniors = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter((p) => p.type === PassengerType.JUNIOR);

export const getChildren = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter((p) => p.type === PassengerType.CHILD);

export const getInfants = (passengers: ExtendedPassengerAndPet[]) =>
  passengers.filter((p) => p.type === PassengerType.INFANT);

export const getPets = (passengers: ExtendedPassengerAndPet[]) => passengers.filter(isPetInput);

export const canFitPet = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: Product[]
): boolean => {
  const product = accommodationsAsProducts.find((a) => a.code === accommodation.code);
  if (!product || !product.maxPets || product.maxPets <= 0) {
    return false;
  }
  const pets = getPetsInAccommodation(accommodation, passengers);
  const adults = getAdultsInAccommodation(accommodation, passengers);
  const juniors = getJuniorsInAccommodation(accommodation, passengers);
  const result = (adults.length > 0 || juniors.length > 0) && pets.length < product.maxPets;
  // console.log(`canFitPet, adults: ${adults.length}, juniors: ${juniors.length}, pets: ${pets.length}, maxPets: ${product.maxPets} => ${result}`);
  return result;
};

export const NO_ACCOMMODATION = 'NO_ACCOMMODATION';
export type NoAccommodationType = typeof NO_ACCOMMODATION;
export type AccommodationIdType = number | NoAccommodationType;

export const canBeMovedTo = (
  moveTo: ExtendedAccommodation | NoAccommodationType,
  passenger: ExtendedPassengerAndPet,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: ExtendedProduct[],
  moveFrom?: ExtendedAccommodation
) => {
  const to =
    moveTo === 'NO_ACCOMMODATION'
      ? true
      : moveTo.passengers?.length && moveTo.passengers?.length <= 0
      ? canBeAlone(passenger) && canFitPassenger(passenger.id, moveTo, passengers, accommodationsAsProducts)
      : canFitPassenger(passenger.id, moveTo, passengers, accommodationsAsProducts);

  const from = moveFrom ? canBeRemovedFrom(moveFrom, passenger, passengers) : true;
  return to && from;
};

export const canBeRemovedFrom = (
  removeFrom: ExtendedAccommodation,
  passenger: ExtendedPassengerAndPet,
  passengers: ExtendedPassengerAndPet[]
): boolean => {
  if (passenger.type === PetType.PET) {
    return false;
  }
  const withoutRemovable = passengers.filter((p) => p.id !== passenger.id);
  return (
    (containsChildren(removeFrom, withoutRemovable) ? containsAdult(removeFrom, withoutRemovable) : true) &&
    (containsPet(removeFrom, withoutRemovable) ? containsAdultOrJunior(removeFrom, withoutRemovable) : true)
  );
};

export type Locations = { id: number; to: number };

export const allCanBeMoved = (
  moveFrom: ExtendedAccommodation,
  accommodations: ExtendedAccommodation[],
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: ExtendedProduct[]
): false | Locations[] => {
  const copy = accommodations.map((a) => clone(a)) as ExtendedAccommodation[];

  const allInFrom = moveFrom.passengers?.map((pid) => {
    const passenger = passengers.find((p) => p.id === pid);

    const fitsIn = copy.find((a) => {
      return (
        a.id !== moveFrom.id && passenger && canFitPassenger(passenger.id, a, passengers, accommodationsAsProducts)
      );
    });
    if (passenger) {
      if (fitsIn?.passengers) {
        fitsIn.passengers.push(passenger.id);
        return { id: passenger.id, to: fitsIn.id };
      } else if (fitsIn) {
        fitsIn.passengers = [passenger.id];
        return { id: passenger.id, to: fitsIn.id };
      }
    }

    return undefined;
  });
  return !allInFrom || allInFrom.some((a) => !a) ? false : (allInFrom as Locations[]);
};

export const getWithoutCabin = (passengers: ExtendedPassengerAndPet[], accommodations: ExtendedAccommodation[]) =>
  passengers.reduce((acc, passenger) => {
    if (accommodations.every((a) => !a.passengers?.includes(passenger.id))) {
      acc.push(passenger);
    }
    return acc;
  }, [] as ExtendedPassengerAndPet[]);

const canFitPassengersInSuite = (accommodationProductCode: string, passengers: ExtendedPassengerAndPet[]): boolean => {
  // RES-144, Suites can have max three adults regardless bigger amount of beds
  if (['JS4', 'OS4'].includes(accommodationProductCode) && getAdults(passengers).length > 3) {
    return false;
  }
  return true;
};

export const canFitPassengers = (
  currentAccommodation: ExtendedAccommodation,
  accommodationProduct: Product,
  passengers: ExtendedPassengerAndPet[]
) => {
  if (!accommodationProduct.maxPeople) return false;
  const beds = getPassengerAmounts(currentAccommodation, passengers);
  return (
    beds.takesBed <= accommodationProduct.maxPeople &&
    beds.takesBed + beds.doesntTakeBedChild + beds.doesntTakeBedInfant <= accommodationProduct.maxPeople + 2 &&
    beds.takesBed + beds.doesntTakeBedChild <= accommodationProduct.maxPeople + 1 &&
    canFitPassengersInSuite(accommodationProduct.code, passengers)
  );
};

const adultsInCabin = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]): number => {
  let count = 0;
  if (Array.isArray(accommodation.passengers)) {
    for (const pix of accommodation.passengers) {
      const found = passengers.find((p) => p.id === pix);
      if (PassengerType.ADULT === found?.type) count++;
    }
  }
  return count;
};

export const canFitAdultOrJunior = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: Product[],
  adultOnly = false
) => {
  const product = accommodationsAsProducts.find((a) => a.code === accommodation.code);
  if (!product || !product.maxPeople) return false;
  const beds = getPassengerAmounts(accommodation, passengers);

  // RES-144, Suites can have max three adults regardless bigger amount of beds
  if (adultOnly && ['JS4', 'OS4'].includes(accommodation.code) && adultsInCabin(accommodation, passengers) >= 3) {
    return false;
  }

  return beds.takesBed < product.maxPeople;
};

export const canFitInfant = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: Product[]
) => {
  const product = accommodationsAsProducts.find((a) => a.code === accommodation.code);
  if (!product || !product.maxPeople) return false;
  const beds = getPassengerAmounts(accommodation, passengers);
  return (
    getAdultsInAccommodation(accommodation, passengers).length > 0 &&
    (beds.takesBed < product.maxPeople ||
      (beds.takesBed >= product.maxPeople && beds.doesntTakeBedChild + beds.doesntTakeBedInfant < 2))
  );
};

export const canFitChild = (
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: Product[]
) => {
  const product = accommodationsAsProducts.find((a) => a.code === accommodation.code);
  if (!product || !product.maxPeople) return false;
  const beds = getPassengerAmounts(accommodation, passengers);
  return (
    getAdultsInAccommodation(accommodation, passengers).length > 0 &&
    (beds.takesBed < product.maxPeople ||
      (beds.takesBed >= product.maxPeople &&
        beds.doesntTakeBedChild + beds.doesntTakeBedInfant < 2 &&
        beds.doesntTakeBedChild < 1))
  );
};

export const getPassengerAmounts = (accommodation: ExtendedAccommodation, passengers: ExtendedPassengerAndPet[]) => {
  var takesBed = 0;
  var doesntTakeBedChild = 0;
  var doesntTakeBedInfant = 0;
  accommodation.passengers?.forEach((id) => {
    const passenger = passengers.find((p) => p.id === id);
    if (!passenger) return;

    switch (passenger.type) {
      case PassengerType.ADULT:
        takesBed++;
        break;
      case PassengerType.JUNIOR:
        takesBed++;
        break;
      case PassengerType.CHILD:
        if (doesntTakeBedChild >= 1 || doesntTakeBedChild + doesntTakeBedInfant >= 2) takesBed++;
        else doesntTakeBedChild++;
        break;
      case PassengerType.INFANT:
        if (doesntTakeBedInfant >= 2 || doesntTakeBedChild + doesntTakeBedInfant >= 2) takesBed++;
        else doesntTakeBedInfant++;
        break;
      default:
        break;
    }
  });
  return { takesBed, doesntTakeBedChild, doesntTakeBedInfant };
};

export const createAccommodationId = (accommodations?: ExtendedAccommodation[] | AccommodationError): number => {
  if (!accommodations || typeof accommodations === 'string' || accommodations.length <= 0) {
    return 0;
  } else {
    return (
      Math.max.apply(
        Math,
        accommodations.map((_) => _.id)
      ) + 1
    );
  }
};

export const canFitPassenger = (
  passengerId: number,
  accommodation: ExtendedAccommodation,
  passengers: ExtendedPassengerAndPet[],
  accommodationsAsProducts: Product[]
) => {
  const passengerType = passengers.find((p) => p.id === passengerId)?.type;
  switch (passengerType) {
    case PassengerType.ADULT:
      return canFitAdultOrJunior(accommodation, passengers, accommodationsAsProducts, true); // Adult only
    case PassengerType.JUNIOR:
      return canFitAdultOrJunior(accommodation, passengers, accommodationsAsProducts);
    case PassengerType.CHILD:
      return canFitChild(accommodation, passengers, accommodationsAsProducts);
    case PassengerType.INFANT:
      return canFitInfant(accommodation, passengers, accommodationsAsProducts);
    case PetType.PET:
      return canFitPet(accommodation, passengers, accommodationsAsProducts);
    default:
      return false;
  }
};

export const passengerIcon: (type: PassengerType | PetType) => IconName | undefined = (type) => {
  switch (type) {
    case PassengerType.ADULT:
      return 'adult';
    case PassengerType.JUNIOR:
      return 'junior';
    case PassengerType.CHILD:
      return 'child';
    case PassengerType.INFANT:
      return 'infant';
    case PetType.PET:
      return 'pet';
    default:
      return undefined;
  }
};

export const moveToNew =
  (
    leg: number,
    accommodations: ExtendedAccommodation[],
    accommodationProducts: ExtendedProduct[],
    send: Sender<CatalogEvent>
  ) =>
  (passenger: number, source: AccommodationIdType, type: PossibleNew) => {
    const accommodation =
      type === NO_ACCOMMODATION
        ? NO_ACCOMMODATION
        : pickUntilFoundCheapest(accommodationProducts, accommodations, 1, [type]);

    const currentAccommodation =
      accommodation && accommodation !== NO_ACCOMMODATION ? accommodations.find((acc) => acc.id === source) : undefined;
    if (
      accommodation &&
      accommodation !== NO_ACCOMMODATION &&
      currentAccommodation?.passengers?.length === 1 &&
      source !== NO_ACCOMMODATION
    ) {
      send({
        type: 'REPLACE_ACCOMMODATION',
        data: {
          leg,
          accommodationId: source,
          passengers: [passenger] || undefined,
          type: accommodation.subtype || '',
          code: accommodation.code,
        },
      });
    } else if (accommodation) {
      accommodation !== NO_ACCOMMODATION &&
        send({
          type: 'ADD_ACCOMMODATION',
          data: {
            leg,
            code: accommodation.code,
            type: accommodation.subtype || '',
            passengers: [passenger],
          },
        });
      source !== NO_ACCOMMODATION &&
        send({
          type: 'REMOVE_PASSENGER_FROM_ACCOMMODATION',
          data: {
            leg,
            accommodationId: source,
            passengerId: passenger,
          },
        });
    }
  };

export const allPassengersInCabins = (
  accommodations: ExtendedAccommodation[],
  passengers: ExtendedPassengerAndPet[]
): boolean => {
  return passengers.every((passenger) => {
    return accommodations.find((acc) => acc.passengers?.includes(passenger.id));
  });
};

function clone(src: any) {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      if (isObject(src[prop])) {
        (target as any)[prop] = clone(src[prop]);
      } else {
        (target as any)[prop] = Array.isArray(src[prop]) ? [...src[prop]] : src[prop];
      }
    }
  }
  return target;
}

function isObject(obj: any) {
  return typeof obj === 'object' && !Array.isArray(obj) && !!obj;
}
