import React, { createContext, useContext, useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { Language, LanguageContext } from './Language.context';
import {
  client,
  Datasources,
  fetchStoryblokData,
  parseTree,
  storyblokAccessToken,
  storyblokChangeHandler,
  Tree,
  Version,
} from './storyblok';

const inStoryblokEditor = window.location.search.indexOf('_storyblok') !== -1;
const version: Version | undefined = inStoryblokEditor ? 'draft' : (process.env.REACT_APP_STORYBLOK_VERSION as Version);

export type StoryblokContextType = {
  client?: StoryblokClient;
  datasources?: Datasources;
  editElement?: <T extends HTMLElement>(ref: React.RefObject<T>) => void;
  tree?: Tree;
};

function loadStoryblokData(language: Language): Promise<[Tree, Datasources]> {
  if (version === 'draft') {
    return fetchStoryblokData({
      language,
      version,
    });
  } else {
    return import(`./storyblok/data/${language}.json`).then((_) => _.default);
  }
}

export const StoryblokContext = createContext<StoryblokContextType>({});

export const StoryblokProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [datasources, setStoryblokDataSources] = useState<Datasources>();
  const [tree, setStoryblokTree] = useState<Tree>();
  const { language: languageFromContext } = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageFromContext);

  useEffect(() => {
    const getStories = async (): Promise<void> => {
      if (language === languageFromContext && !!tree && !!datasources) {
        return;
      }

      try {
        setLanguage(languageFromContext);

        const [stories, datasourceEntries] = await loadStoryblokData(languageFromContext);

        setStoryblokTree(stories);
        setStoryblokDataSources(datasourceEntries);

        // Enable Storyblok editor
        if (inStoryblokEditor) {
          const script: HTMLScriptElement & {
            onreadystatechange?: () => void;
            readyState?: 'loaded' | 'complete';
          } = document.createElement('script');

          script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${storyblokAccessToken || ''}`;
          script.id = 'storyblok';

          const onload = (): void => {
            window.storyblok.on(
              ['published', 'change'],
              storyblokChangeHandler({ version }, (tree) => {
                setStoryblokTree({
                  ...stories,
                  ...tree,
                });
              })
            );
            window.storyblok.on('input', (event) =>
              setStoryblokTree({
                ...stories,
                ...(event && event.story ? parseTree([event.story]) : {}),
              })
            );
          };

          if (script.readyState) {
            script.onreadystatechange = () => {
              if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = undefined;
                onload();
              }
            };
          } else {
            // Others
            script.onload = onload;
          }

          document.body.appendChild(script);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getStories().catch(() => {});
  }, [datasources, language, languageFromContext, tree]);

  //
  // Provide function to call Storyblok internal API to change currently edited component.
  //
  const editElement = <T extends HTMLElement>(ref: React.RefObject<T>): void => {
    if (window.storyblok && (window.storyblok as any).editElement && ref && ref.current) {
      (window.storyblok as any).editElement({
        target: ref.current,
        preventDefault: () => {},
        stopPropagation: () => {},
      });
    }
  };

  return (
    <StoryblokContext.Provider value={{ client, datasources, editElement, tree }}>{children}</StoryblokContext.Provider>
  );
};
