import { List } from 'purify-ts/List';
import { Maybe } from 'purify-ts/Maybe';
import React, { FunctionComponent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import TabBar, { Tab } from '../../design-system/components/TabBar';
import { hooks } from '../../design-system/helpers/mixins';
import catalogMachine, { CatalogContext, catalogInitialContext } from '../../fsm/catalog/catalogMachine';
import { updateHistoryAction, useChildServiceWithHistory, usePersistedMachine } from '../../fsm/react';
import {
  Catalog,
  ExtendedSailing,
  Form,
  isChargeInfo,
  isSearchEqual,
  isTariffPrice,
  SearchParams,
  SelectedSailing,
  TariffPrice,
  Trip,
} from '../../fsm/types';
import { getSelected } from '../../fsm/utils/sailingUtils';
import { calculateTripTotalPrice } from '../../utils/priceCalculation';
import DetailView from './details/DetailView';
import PassengerInfoView from './info/PassengerInfoView';
import ListView from './listing/ListView';

interface CatalogViewProps extends RouteComponentProps<any> {
  moveToPayment: (trip: Trip) => void;
  moveToSearch: () => void;
  submitChangeTripSearch: (newFormsValues: Form[]) => void;
  searchParams: SearchParams;
}

function extractTrip(ctx: CatalogContext): Trip {
  return {
    type: ctx.searchParams.type,
    agreement: ctx.agreement,
    passengers: ctx.passengers,
    sailings: ctx.catalogs.reduce(
      (newSailings, { index, selected, sailings }) =>
        Maybe.fromNullable(selected)
          .chain((selected) =>
            List.find((_) => _.sailingCode === selected.sailingCode, sailings).map((sailing) =>
              newSailings.concat({ index, sailing: { ...sailing, ...selected } })
            )
          )
          .orDefault(newSailings),
      [] as { index: number; sailing: ExtendedSailing & SelectedSailing }[]
    ),
    vehicles: ctx.vehicles,
  };
}

function onClickHandler(func: () => void) {
  return (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    func();
  };
}

const CatalogView: FunctionComponent<CatalogViewProps> = ({
  moveToPayment,
  moveToSearch,
  submitChangeTripSearch,
  searchParams,
  ...props
}) => {
  const { history, location, match } = props;
  // @ts-ignore
  const [current, send, service] = usePersistedMachine(catalogMachine, {
    actions: {
      payment: (ctx: CatalogContext) => moveToPayment(extractTrip(ctx)),
      updateHistory: updateHistoryAction(props),
    },
    context: {
      ...catalogInitialContext,
      searchParams,
    },
    onLoad: (state) => (isSearchEqual(state.context.searchParams, searchParams) ? state : undefined),
    // Do not store state when moving to payment.
    // Otherwise History API gets stuck on the transition.
    onSave: (state) => {
      if (state.matches('payment')) {
        return undefined;
      }
      if (state.event.type === 'LOADED_CATALOG_PRICES' || state.event.type === 'LOADED_CATALOG_PRODUCTS') {
        return undefined;
      }
      return state;
    },
  });

  useChildServiceWithHistory(service, history);

  const sbPages = hooks.useStoryblokComponents('pages/');

  const sailingsForTotalPrice = current.context.catalogs.reduce(
    (all: (ExtendedSailing & SelectedSailing)[], catalog: Catalog) =>
      getSelected(catalog).mapOrDefault((selected) => [...all, selected], all),
    [] as (ExtendedSailing & SelectedSailing)[]
  );

  const tripTotalPrice = calculateTripTotalPrice(sailingsForTotalPrice);
  const next = () => send('NEXT');
  const selectedSailingHasAllPrices: boolean = current.context.catalogs.every((c: Catalog) => {
    const price = c.selected?.price as TariffPrice;
    return isTariffPrice(price) && isChargeInfo(price?.SPECIAL) && isChargeInfo(price?.STANDARD)
      ? price !== undefined && price?.SPECIAL?.charge > 0 && price?.STANDARD?.charge > 0
      : false;
  });

  // Check if catalog has proper sailings and prices loaded before allowing to enter onboards (or info) page
  const anySailingPresentInEveryCatalog: boolean =
    current.context.catalogs.length > 0 && current.context.catalogs.every((c: Catalog) => c.sailings?.length > 0);
  const allowProceedingToNextPage: boolean =
    anySailingPresentInEveryCatalog &&
    current.context.productPricesLoaded &&
    current.context.ticketPricesLoaded &&
    tripTotalPrice > 0 &&
    selectedSailingHasAllPrices;

  const currentLocation = props.location.pathname;
  let backActions = () => {};
  if (current.matches('listing')) {
    backActions = moveToSearch;
  } else if (current.matches('onboard')) {
    backActions = () => {
      send({ type: 'CLEAR_CART' });
      send('/catalog');
    };
  } else if (current.matches('info')) {
    backActions = () => {
      send({ type: 'REMOVE_ONBOARD_PRODUCTS_FROM_CART' });
      send('/catalog/onboard');
    };
  }

  const back = () => backActions();
  const footerProps = { tripTotalPrice, next, back, allowProceedingToNextPage };

  return (
    <>
      <TabBar fixed>
        <Tab
          asa={Link}
          href={match.url}
          location={location.pathname}
          onClick={onClickHandler(() => {
            // Clear the cart, unless the user is leaving the trip page (catalog),
            // since no products are added to cart between search and trip views
            if (!currentLocation.endsWith('catalog')) {
              send({ type: 'CLEAR_CART' });
            }
            moveToSearch();
          })}
        >
          {sbPages.search?.content.title}
        </Tab>
        <Tab
          asa={Link}
          href={`${match.url}/catalog`}
          location={location.pathname}
          onClick={onClickHandler(() => {
            // Returning to trip view (catalog) equals abandoning the cart.
            send({ type: 'CLEAR_CART' });
            send('/catalog');
          })}
        >
          {sbPages.trip?.content.title}
        </Tab>
        <Tab
          {...(allowProceedingToNextPage && {
            asa: Link,
            href: `${match.url}/catalog/onboard`,
            location: location.pathname,
            onClick: onClickHandler(() => {
              if (currentLocation.includes('info')) {
                // Previous onboard products should be removed from cart when returning to the onboard view from info.
                send({ type: 'REMOVE_ONBOARD_PRODUCTS_FROM_CART' });
              } else if (currentLocation.endsWith('catalog')) {
                send({ type: 'ADD_TRIP_TO_CART' });
              }
              send('/catalog/onboard');
            }),
          })}
        >
          {sbPages.onboard?.content.title}
        </Tab>
        <Tab
          {...(allowProceedingToNextPage && {
            asa: Link,
            href: `${match.url}/catalog/info`,
            location: location.pathname,
            onClick: onClickHandler(() => {
              if (currentLocation.endsWith('catalog')) {
                send({ type: 'ADD_TRIP_TO_CART' });
              } else if (currentLocation.includes('onboard')) {
                send({ type: 'ADD_ONBOARD_PRODUCTS_TO_CART' });
              }
              send('/catalog/info');
            }),
          })}
        >
          {sbPages.info?.content.title}
        </Tab>
      </TabBar>
      {current.matches('listing') ? (
        <ListView {...{ context: current.context, send, submitChangeTripSearch, ...footerProps }} />
      ) : null}
      {current.matches('onboard') ? <DetailView {...{ context: current.context, send, ...footerProps }} /> : null}
      {current.matches('info') ? <PassengerInfoView {...{ context: current.context, send, ...footerProps }} /> : null}
    </>
  );
};

export default CatalogView;
