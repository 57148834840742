import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Button from '../../../design-system/components/Button';
import Card from '../../../design-system/components/Card';
import { Subgrid } from '../../../design-system/components/Container';
import Modal from '../../../design-system/components/Modal';
import RichText from '../../../design-system/components/RichText';
import Stepper, { InputProps } from '../../../design-system/components/Stepper';
import { H2, H3, Lead, Price } from '../../../design-system/components/Text';
import { Transition } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { ExtendedProduct, ProductType } from '../../../fsm/types';
import {
  PassengerProductCounts,
  ProductAmount,
  countProducts,
  getCount,
  getProductCounts,
  includedProducts,
  selectableProductCount,
  selectableProductsList,
  setStepperValue,
  sortByProductTypeAndAdultToInfantProductInLimited,
} from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import { TreeData } from '../../../storyblok';
import { formatString } from '../../../utils/formats';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import OnboardDescription from './shared/OnboardDescription';

interface WellnessServicesProps {
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
  readonly onOpen: (sailingCode: string) => void;
  readonly passengers: API.Passengers;
  readonly passengerInfo: API.ExtendedPassengerAndPet[];
}

interface WellnessCardProduct extends TreeData {
  products: API.ExtendedProduct[];
}

interface WellnessCardProps extends Omit<InputProps, 'setValue' | 'value'> {
  readonly service: WellnessCardProduct;
  readonly unit?: string;
  readonly passengers: API.Passengers;
  readonly tariff: API.Tariff;
  readonly setValue: (value: number, serviceVariant: API.ExtendedProduct) => void;
  readonly selectedOnboards: API.ExtendedOnboard[];
  readonly includedWellnessServices: PassengerProductCounts;
  readonly possibleWellnessServiceCounts: API.Passengers;
}

const WELLNESS_SERVICE_ORDER = [API.ProductSubType.SAUNA_AND_GYM, API.ProductSubType.GYM, API.ProductSubType.SAUNA];

const SelectedItem: FunctionComponent<ProductAmount> = ({ code, amount }) => {
  const [sbPackage, ref] = hooks.useStoryblokComponent<HTMLLIElement>({
    path: code || '',
  });

  return (
    <li ref={ref}>
      {amount} × {sbPackage?.content.title || code}
    </li>
  );
};

const WellnessCard: FunctionComponent<WellnessCardProps> = ({
  service,
  unit,
  passengers,
  tariff,
  setValue,
  selectedOnboards,
  possibleWellnessServiceCounts,
  includedWellnessServices,
  ...props
}) => {
  const { formats } = useContext(LanguageContext);
  const description = hooks.useStoryblokRichText(service.content.description);

  /**
   * Filters the steppers based on passenger info and calculates the maximum
   * value for the stepper. If card doesn't have steppers, we won't render the card
   */

  const products = selectableProductsList(service.products, passengers, includedWellnessServices);

  if (!products.length) return null;

  return (
    <Card vertical image={service?.content.image}>
      <H3>{service?.content.title}</H3>
      {description && <RichText>{description}</RichText>}

      {products.map((product) => {
        const chargeInfo = API.maybeChargeInfo(tariff, product);
        const value = getCount(product.code, selectedOnboards) + product.included;

        return (
          <Stepper
            {...props}
            value={value}
            max={product.maxSelectable}
            setValue={(value) => setValue(value - product.included, product)}
            min={product.min}
            key={product.code}
          >
            <Lead as="span">{product.name}</Lead>
            <Price align="left">
              {chargeInfo.mapOrDefault(({ charge }) => formats.currency(charge), '-')}{' '}
              {unit && <Lead as="span">{unit}</Lead>}
            </Price>
          </Stepper>
        );
      })}
    </Card>
  );
};

const WellnessServices: FunctionComponent<WellnessServicesProps> = ({
  sailing,
  add,
  remove,
  onOpen,
  passengers,
  passengerInfo,
}) => {
  const modalState = useDialogState({ animated: false });
  const { formats } = useContext(LanguageContext);

  const sbPorts = hooks.useStoryblokDatasource('ports');
  const [sbWellnessServiceTexts, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.wellness_services',
  });
  const [sbWellnessServicesModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.wellness_services_modal',
  });
  const sbWellnessServices = hooks.useStoryblokComponents('products/onboards/wellness-services');
  const sbWellnessServiceVariants = Object.values(
    hooks.useStoryblokComponents('products/onboards/wellness-service-variants')
  );

  const apiWellnessServices = API.isError(sailing.products) ? [] : sailing.products?.WELLNESS || [];

  const wellnessServicesDescription = hooks.useStoryblokRichText(
    sbWellnessServiceTexts?.content.wellness_services_description
  );

  // Meals are included for passengers in suites and in selected legs
  const accommodations = API.isError(sailing.accommodations) ? [] : sailing.accommodations || [];

  // List of accommodations that contain meals and which meals are included
  const sbAccommodationsWithServices = Object.values(hooks.useStoryblokComponents('products/accommodation_services'))
    .filter(({ content }) => content.wellness_included)
    .map(({ content }) => ({
      productCode: content.product_code,
      productsIncluded: content.wellness_services_included || [],
    }));

  const products = API.isError(sailing.products) ? [] : sailing.products?.WELLNESS || [];

  // List of meals that are included. E.g. if 2 adults are travelling in a suite that contains
  // breakfast and two juniors travel in a cabin that doesn't include meals the result is following
  // ADULT: [S_BR, S_BR],
  // JUNIOR: [],
  // CHILD: [],
  // INFANT: []
  const includedWellnessServices = includedProducts(
    accommodations,
    sbAccommodationsWithServices,
    sbWellnessServiceVariants,
    products,
    passengerInfo
  );

  /**
   * Available Wellness Services merged with product data retrieved from API
   */
  const availableWellnessServices: WellnessCardProduct[] = useMemo(() => {
    return Object.values(sbWellnessServices).map((sbService) => {
      const sbServiceVariants = sbService.content.variants.map((variantUuid: string) =>
        sbWellnessServiceVariants.find((variantContent) => variantContent.uuid === variantUuid)
      );
      return {
        ...sbService,
        products: apiWellnessServices
          .map((apiService) => {
            // If variant is not found from service's variant array, we will not show the variant
            if (!sbServiceVariants.some((variant: TreeData) => variant.slug === apiService.code)) {
              return null;
            }

            const name =
              sbWellnessServiceVariants.find((variantContent) => variantContent.slug === apiService.code)?.content
                .title ?? apiService.name;

            return {
              ...apiService,
              name,
            };
          })
          .filter((val) => Boolean(val)) as ExtendedProduct[],
      };
    });
  }, [sbWellnessServices, apiWellnessServices, sbWellnessServiceVariants]);

  const selectedOnboards: API.ExtendedOnboard[] =
    (API.isError(sailing.onboards) ? null : (sailing.onboards as API.ExtendedOnboard[]))?.filter(
      ({ type }) => type === ProductType.WELLNESS
    ) || [];

  const arrivalPort = (sbPorts && sbPorts[sailing.arrivalPort]) || sailing.arrivalPort;
  const departurePort = (sbPorts && sbPorts[sailing.departurePort]) || sailing.departurePort;
  const totalCharge = calculateProductsTotalPrice(selectedOnboards, sailing.tariff);

  const handleStepperChange = (value: number, product: API.ExtendedProduct) => {
    setStepperValue(product.code, value, selectedOnboards, add, remove);
  };

  const possibleWellnessServiceCounts = selectableProductCount(passengers, includedWellnessServices, products);

  const productCounts = getProductCounts(Object.values(includedWellnessServices).flat());

  countProducts(selectedOnboards, products, productCounts);

  const allSelectedServices = sortByProductTypeAndAdultToInfantProductInLimited(productCounts, WELLNESS_SERVICE_ORDER);

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode);
    }
  }, [modalState.visible]);

  return (
    <Subgrid ref={ref}>
      <H2>{sbWellnessServiceTexts?.content.title}</H2>
      <OnboardDescription>{wellnessServicesDescription}</OnboardDescription>
      {allSelectedServices.length ? (
        <div>
          <H3>{sbWellnessServiceTexts?.content.selected_wellness_services}</H3>
          <ul>
            {allSelectedServices.map((product) => (
              <SelectedItem key={product.code} {...product} />
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
      <DialogDisclosure disclosure as={Button} round {...modalState}>
        {
          sbWellnessServiceTexts?.content[
            selectedOnboards.length > 0 ? 'edit_wellness_services_button' : 'add_wellness_services_button'
          ]
        }
      </DialogDisclosure>
      <Modal
        ref={modalRef}
        state={modalState}
        title={formatString(sbWellnessServicesModal?.content.title, departurePort, arrivalPort)}
        focusOnDialog
        submit={sbWellnessServicesModal?.content.ready_button}
        size="input"
        footer={
          <Transition.Height show={!!totalCharge} as={Price}>
            <Transition.Number value={totalCharge} formatter={formats.currency} />
          </Transition.Height>
        }
      >
        {(modalState.animating || modalState.visible) && (
          <Subgrid>
            {availableWellnessServices?.map((service) => (
              <WellnessCard
                service={service}
                passengers={passengers}
                tariff={sailing.tariff}
                id={`${sailing.sailingCode}-${service.uuid}`}
                key={service.uuid}
                setValue={handleStepperChange}
                selectedOnboards={selectedOnboards}
                includedWellnessServices={includedWellnessServices}
                possibleWellnessServiceCounts={possibleWellnessServiceCounts}
              />
            ))}
          </Subgrid>
        )}
      </Modal>
    </Subgrid>
  );
};

export default WellnessServices;
