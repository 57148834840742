import styled from 'styled-components';
import { typography } from '../../helpers/mixins';

const Group = styled.ul`
  ${typography.p}
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
`;

export default Group;
