import React, { FunctionComponent, useContext } from 'react';
import BookingSummary from '../../../design-system/components/BookingSummary';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { LanguageContext } from '../../../Language.context';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import { sortAdultToInfantOnboardInLimited } from '../../../fsm/utils/productUtils';

interface OnboardsRowsProps {
  readonly onboards: {
    amount: number;
    code: string;
    price?: API.TariffPriceOrError;
    type: string;
    legs: number[] | { leg: number; chargeInfo: API.ChargeInfo }[];
  }[];
  readonly tariff: API.Tariff;
  readonly products?: API.Product[];
  readonly showOnboards?: boolean;
}

interface OnboardSingleRowProps {
  readonly code: string;
  readonly amount: number;
}

const OnboardSingleRow: FunctionComponent<OnboardSingleRowProps> = ({ code, amount }) => {
  const [sbOnboard, innerRef] = hooks.useStoryblokComponent<HTMLLIElement>({
    path: code || '',
  });
  const sbMeals = hooks.useStoryblokDatasource('meal-types');

  return (
    <BookingSummary.Item {...{ innerRef }} count={amount}>
      {sbOnboard?.content.title || sbMeals[code] || code}
    </BookingSummary.Item>
  );
};

const OnboardsRows: FunctionComponent<OnboardsRowsProps> = ({ showOnboards = true, onboards, tariff, products }) => {
  const { formats } = useContext(LanguageContext);

  const [sbLegSummary] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.leg_summary',
  });

  const filter = (onboardType: string, title: string) => ({
    key: onboardType,
    title,
    items: products
      ? sortAdultToInfantOnboardInLimited<
          { amount: number; code: string; price?: API.TariffPriceOrError; type: string },
          API.Product
        >(
          onboards.filter(({ type }) => type === onboardType),
          products
        )
      : onboards.filter(({ type }) => type === onboardType),
  });
  const grouped = [
    filter('FOOD', sbLegSummary?.content.meals_title),
    filter('ELECTRICITY', sbLegSummary?.content.electricity_title),
    filter('WIFI', sbLegSummary?.content.wifis_title),
    filter('PACKAGE', sbLegSummary?.content.packages_title),
    filter('NIGHT', sbLegSummary?.content.nights_title),
    filter('LOUNGE', sbLegSummary?.content.lounge_title),
    filter('WELLNESS', sbLegSummary?.content.wellness_title),
  ];

  return (
    <>
      {showOnboards &&
        grouped.map(
          ({ items, title, key }) =>
            items.length > 0 && (
              <BookingSummary.Row
                {...{ title, key }}
                price={formats.currency(calculateProductsTotalPrice(items, tariff))}
              >
                <BookingSummary.Group>
                  {items.map(({ code, amount }) => (
                    <OnboardSingleRow key={code} {...{ code, amount }} />
                  ))}
                </BookingSummary.Group>
              </BookingSummary.Row>
            )
        )}
    </>
  );
};

export default OnboardsRows;
