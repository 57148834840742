import { Agreement } from '../../FinnlinesB2CBookingAPI';

import { TripType } from '.';
import { ExtendedPassengerAndPet, ExtendedSailing, ExtendedVehicle, SelectedSailing } from './catalog';

export type Trip = {
  type: TripType;
  agreement?: Agreement;
  passengers: ExtendedPassengerAndPet[];
  sailings: {
    index: number;
    sailing: ExtendedSailing & SelectedSailing;
  }[];
  vehicles: ExtendedVehicle[];
};

export enum TripTagTypes {
  OUTGOING = 'OUTGOING',
  RETURN = 'RETURN',
  TWOWAY = 'TWOWAY',
  CRUISE = 'CRUISE',
  LEG = 'LEG',
  NOCABIN = 'NOCABIN',
}
