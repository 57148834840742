import { mix, transparentize } from 'polished';

const finnlines = {
  blue: '#004993',
  aquamarineGreen: '#07B0A0',
  lightBlue: '#82cff5',
  lightGreen: '#95c11e',
  signalOrange: '#ec6607',
};

const basic = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  yellow: '#FFFF00',
};

// see WCAG 2.1 for minimum contrast requirements
// https://www.w3.org/TR/WCAG21/#contrast-minimum

const palette = {
  aquamarineGreen: {
    light: {
      a: mix(0.05, basic.black, finnlines.aquamarineGreen), // contrast with pure white >3:1
      aa: mix(0.25, basic.black, finnlines.aquamarineGreen), // contrast with pure white >4.5:1
    },
    dark: {
      aa: mix(0.5, basic.white, finnlines.aquamarineGreen), // contrast with brand >4.5:1
    },
  },

  lightBlue: {
    a: mix(0.1, basic.white, finnlines.lightBlue), // contrast with copy >3:1
    aa: mix(0.8, basic.white, finnlines.lightBlue), // contrast with copy >4.5:1
  },

  lightGreen: {
    light: {
      a: mix(0.17, basic.black, finnlines.lightGreen), // contrast with pure white >3:1
      aa: mix(0.34, basic.black, finnlines.lightGreen), // contrast with pure white >4.5:1
    },
    dark: {
      aa: mix(0.09, basic.white, finnlines.lightGreen), // contrast with brand >4.5:1
    },
  },

  signalOrange: {
    a: finnlines.signalOrange, // contrast with pure white >3:1
    aa: mix(0.17, basic.black, finnlines.signalOrange), // contrast with pure white >4.5:1
  },

  brand: finnlines.blue,

  error: [
    mix(0.1, basic.white, mix(0.3, finnlines.blue, basic.red)),
    mix(0.9, basic.white, mix(0.3, finnlines.blue, basic.red)),
  ],

  warning: [mix(0.2, basic.red, basic.yellow)],

  grayscale: [
    basic.white, // pure white for background and inverted text
    mix(0.95, basic.white, mix(0.5, basic.black, finnlines.blue)), // alternative grayish white background
    mix(0.9, basic.white, mix(0.5, basic.black, finnlines.blue)), // borders, disabled backgrounds
    mix(0.8, basic.white, mix(0.4, basic.black, finnlines.blue)), // modal overlays, link underline
    mix(0.45, basic.white, mix(0.7, basic.black, finnlines.blue)), // gray for icons and disabled button text
    mix(0.4, basic.white, mix(0.8, basic.black, finnlines.blue)), // darker gray for copy text
    mix(0.28, basic.white, mix(0.88, basic.black, finnlines.blue)), // headings, input values
    basic.black,
  ],

  grayscaleOverlay: [
    transparentize(1, basic.black),
    transparentize(0.95, basic.black),
    transparentize(0.9, mix(0.5, basic.black, finnlines.blue)),
    transparentize(0.8, mix(0.4, basic.black, finnlines.blue)),
    transparentize(0.45, mix(0.7, basic.black, finnlines.blue)),
    transparentize(0.4, mix(0.8, basic.black, finnlines.blue)),
    transparentize(0.28, mix(0.88, basic.black, finnlines.blue)),
    basic.black,
  ],
};

export default {
  brand: finnlines.blue,

  bg: {
    default: palette.grayscale[0],
    alt: palette.grayscale[1],
    invert: {
      default: palette.brand,
      alt: palette.grayscale[7],
      overlay: palette.grayscaleOverlay[3],
    },

    input: {
      default: palette.grayscale[0],
      invert: {
        field: transparentize(1, palette.grayscale[0]),
        inactive: transparentize(0.75, palette.grayscale[0]),
        hover: transparentize(0.5, palette.grayscale[0]),
      },
      radio: {
        active: palette.brand,
      },
      checkbox: {
        active: palette.aquamarineGreen.light.a,
      },
    },

    button: {
      default: palette.aquamarineGreen.light.a,
      warning: palette.error[0],
      disabled: palette.grayscale[2],
      hover: transparentize(0.9, palette.grayscale[7]),
      secondary: {
        default: transparentize(1, mix(0.75, basic.white, palette.aquamarineGreen.dark.aa)),
        icon: transparentize(1, mix(0.75, basic.white, palette.aquamarineGreen.dark.aa)),
        hover: mix(0.75, basic.white, palette.aquamarineGreen.dark.aa),
      },
    },

    overlay: transparentize(0.25, palette.grayscale[3]),
    active: palette.brand,

    datepicker: {
      active: palette.brand,
      hover: transparentize(0.5, palette.lightBlue.aa),
      selected: palette.lightBlue.aa,
      selectedHover: mix(0.025, palette.grayscale[7], palette.lightBlue.aa),
      activeHover: mix(0.1, palette.grayscale[7], palette.brand),
    },

    link: {
      default: palette.grayscale[3],
      invert: palette.grayscale[0],
    },

    departure: [
      mix(0.75, palette.grayscale[0], palette.lightBlue.a),
      mix(0.25, palette.grayscale[0], palette.lightBlue.a),
    ],

    label: {
      default: palette.brand,
      overlay: transparentize(0.2, palette.brand),
    },

    drag: {
      default: palette.grayscale[3],
      active: mix(0.75, basic.white, palette.aquamarineGreen.dark.aa),
    },

    sticky: {
      default: transparentize(0.25, palette.grayscale[0]),
      alt: transparentize(0.25, palette.grayscale[1]),
    },

    tree: {
      active: transparentize(0.9, palette.aquamarineGreen.dark.aa),
    },

    error: {
      default: palette.error[1],
      invert: palette.error[0],
    },

    bookingSummary: transparentize(0.9, palette.brand),
  },

  text: {
    heading: palette.grayscale[6],
    copy: palette.grayscale[5],
    input: palette.grayscale[6],
    invert: palette.grayscale[0],
    error: palette.error[0],
    disabled: palette.grayscale[4],
  },

  link: {
    default: palette.aquamarineGreen.light.aa,
    invert: palette.aquamarineGreen.dark.aa,
  },

  icon: {
    default: palette.grayscale[4],
    disabled: palette.grayscale[3],
    invert: palette.grayscale[0],
  },

  border: {
    default: palette.grayscale[2],
    active: palette.brand,
    section: palette.grayscale[2],
    transparent: transparentize(1, palette.grayscale[2]),
    action: palette.aquamarineGreen.light.a,
    disabled: palette.grayscale[3],
    overlay: palette.grayscaleOverlay[2],
    focus: palette.grayscale[4],
  },

  alert: {
    default: {
      error: palette.error[0],
      warning: palette.warning[0],
      info: palette.brand,
      success: palette.lightGreen.light.aa,
    },
    invert: {
      error: palette.error[0],
      warning: palette.warning[0],
      info: palette.aquamarineGreen.dark.aa,
      success: palette.lightGreen.dark.aa,
    },
  },

  shadow: {
    modal: transparentize(0.9, palette.grayscale[7]),
    menu: transparentize(0.95, palette.grayscale[7]),
    drag: transparentize(0.8, palette.grayscale[7]),
  },
};
