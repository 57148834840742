import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { size } from 'polished';

import { measurement } from '../helpers/vars';

interface SpinnerProps {
  readonly title?: string;
  readonly description?: string;
  readonly small?: boolean;
  readonly large?: boolean;
  readonly inline?: boolean;
}

const radius = 13;
const circumference = Math.PI * 2 * radius;

const frames = {
  spin: keyframes`
    0%   { stroke-dashoffset: -${circumference}; }
    100% { stroke-dashoffset: ${circumference}; }
  `,
  ease: keyframes`
    0%   { stroke-dasharray: ${(circumference / 4) * 3} ${circumference / 4}; }
    100% { stroke-dasharray: ${circumference / 4} ${(circumference / 4) * 3}; }
  `,
};

const SVG = styled.svg<SpinnerProps>`
  ${size('2rem')}
  fill: none;
  stroke: currentColor;
  stroke-dashoffset: -${circumference};
  stroke-width: 3;
  animation: ${frames.spin} 2s linear infinite;
  transform: scaleX(-1);
  margin: auto;

  ${(props) =>
    props.small &&
    css`
      ${size('1.25rem')}
      stroke-width: 4;
    `}

  ${(props) =>
    props.large &&
    css`
      ${size('4rem')}
      stroke-width: 1.5;
    `}

  ${(props) =>
    props.inline &&
    css`
      display: inline;
      margin: ${measurement.space.icon.inline};
      vertical-align: middle;
    `}
`;

const Circle = styled.circle`
  stroke-dasharray: ${(circumference / 4) * 3} ${circumference / 4};
  animation: ${frames.ease} 1s ease-in-out infinite alternate;
`;

const Spinner: React.FunctionComponent<SpinnerProps> = ({ title, description, ...props }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-labelledby="title desc" role="img" {...props}>
    {title && <title id="title">{title}</title>}
    {description && <desc id="desc">{description}</desc>}
    <Circle cx="16" cy="16" r={radius} />
  </SVG>
);

export default Spinner;
