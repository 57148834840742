import React from 'react';
import styled from 'styled-components';

import { P } from '../Text';

export interface ItemProps {
  readonly children?: React.ReactNode;
  readonly time?: string;
}

const Wrapper = styled(P)`
  display: flex;
  align-items: center;
`;

const Time = styled(P)`
  margin-left: auto;
`;

const Item = React.forwardRef<HTMLParagraphElement, ItemProps>(({ children, time }, ref) => (
  <Wrapper ref={ref}>
    {children}
    {time && (
      <Time inline as="span">
        {time}
      </Time>
    )}
  </Wrapper>
));

export default Item;
