import { findCruisePairMaybe } from '../fsm/api/cruise';
import { ChargeInfo, ExtendedSailing, maybeChargeInfo, PriceError, Tariff, TariffPrice, TripType } from '../fsm/types';
import * as API from '../fsm/types';
import { Maybe } from 'purify-ts';

/**
 * @param sailings to calculate price from
 */

type PricesWithTariff = {
  price?: TariffPrice | any;
  tariff: Tariff;
};

type PricesWithAmount = {
  amount: number;
  price?: TariffPrice | any;
};

export const calculateSailingPriceWithoutOnboards = (
  sailing: PricesWithTariff & { onboards?: PricesWithAmount[] | PriceError },
  tariff?: Tariff
): number => maybeChargeInfo(tariff || sailing.tariff, sailing).mapOrDefault((_) => _.charge, 0);

export const calculateSailingTotalPrice = (
  sailing: PricesWithTariff & { onboards?: PricesWithAmount[] | PriceError },
  tariff?: Tariff
): number => {
  const sailingCharge = calculateSailingPriceWithoutOnboards(sailing, tariff);

  const onboardsTotal = Array.isArray(sailing.onboards)
    ? calculateProductsTotalPrice(sailing.onboards, tariff || sailing.tariff)
    : 0;

  return sailingCharge + onboardsTotal;
};

export const calculateTripTotalPrice = (
  sailings: (PricesWithTariff & { onboards?: PricesWithAmount[] | PriceError })[]
): number => {
  return sailings.reduce((total, s) => total + (s ? calculateSailingTotalPrice(s) : 0), 0);
};

/**
 *
 * @param products products to get sum of net charges from
 * @param tariff current tariff
 */
export const calculateProductsTotalPrice = (
  products: ({ price?: TariffPrice | any } | PricesWithAmount)[],
  tariff: Tariff
) =>
  products.reduce(
    (total, product) =>
      maybeChargeInfo(tariff, product).reduce(
        (total, { charge }) => total + ('amount' in product ? product.amount : 1) * charge,
        total
      ),
    0
  );

export const sum = (chargeInfos: ChargeInfo[]): number => {
  return chargeInfos.reduce((total, { charge }) => total + charge, 0);
};

export const getCheapestCruisePrice = (
  tripType: TripType,
  sailings: ExtendedSailing[],
  otherSailings: ExtendedSailing[],
  tariff: API.Tariff
): number | API.Errors => {
  const sailingPricesOrErrors: (number | API.Errors)[] = sailings.map((sailing) =>
    getCruisePrice(tripType, sailing, otherSailings, API.Tariff.SPECIAL)
  );
  const sailingPrices = sailingPricesOrErrors.filter((v): v is number => typeof v === 'number');
  if (sailingPrices.length > 0) {
    return Math.min(...sailingPrices);
  }

  const errors = sailingPricesOrErrors.filter((v) => API.isError(v));
  if (errors.length > 0) {
    return errors[0];
  }

  return API.PriceError.NO_CRUISE_AVAILABLE;
};

export const getCruisePrice = (
  tripType: TripType,
  sailing: ExtendedSailing,
  otherSailings: ExtendedSailing[],
  tariff: API.Tariff
): number | API.Errors => {
  if (API.isError(sailing.price)) {
    return sailing.price;
  }

  const maybe = Maybe.fromNullable(sailing);
  const findCruiseParams = maybe.caseOf({
    Nothing: () => ({} as any),
    Just: (sailing) => ({
      departurePort: sailing.departurePort,
      shipName: sailing.shipName,
      selectedTimes: {
        arrivalDate: sailing.arrivalDate,
        arrivalTime: sailing.arrivalTime,
        departureDate: sailing.departureDate,
        departureTime: sailing.departureTime,
      },
    }),
  });

  let selectedSecondSailing: Maybe<API.ExtendedSailing> = Maybe.fromNullable();
  if (findCruiseParams.selectedTimes) {
    selectedSecondSailing = findCruisePairMaybe(
      findCruiseParams.departurePort,
      findCruiseParams.shipName,
      findCruiseParams.selectedTimes,
      otherSailings,
      tripType
    );
  }

  let secondSailing = selectedSecondSailing.caseOf({
    Nothing: () => ({} as any),
    Just: (otherSailing) => otherSailing,
  });

  if (API.isError(secondSailing.price)) {
    return secondSailing.price;
  }

  let specialPriceSecondSailing = calculateSailingTotalPrice(secondSailing, Tariff.SPECIAL);
  let standardPriceSecondSailing = calculateSailingTotalPrice(secondSailing, Tariff.STANDARD);

  const specialPriceFirstSailing = calculateSailingTotalPrice({ ...sailing, tariff: Tariff.SPECIAL });
  const standardPriceFirstSailing = calculateSailingTotalPrice({ ...sailing, tariff: Tariff.STANDARD });
  const totalSpecialPrice = specialPriceFirstSailing + specialPriceSecondSailing;
  const totalStandardPrice = standardPriceFirstSailing + standardPriceSecondSailing;
  if (tariff === API.Tariff.SPECIAL) {
    return specialPriceFirstSailing > 0 && specialPriceSecondSailing > 0 ? totalSpecialPrice : PriceError.NO_PRICE;
  } else {
    return standardPriceFirstSailing > 0 && standardPriceSecondSailing > 0 ? totalStandardPrice : PriceError.NO_PRICE;
  }
};
