import { assign } from 'xstate';
import { ExtendedPassengerAndPet, isPassengerInput, Passengers, PassengerType, PetType } from '../types';
import { indexToLeg } from '../utils/sailingUtils';
import { CatalogContext } from './catalogMachine';

const PassengerActions = {
  createPassengers: assign<CatalogContext>({
    passengers: (ctx: CatalogContext): ExtendedPassengerAndPet[] =>
      createPassengers(
        ctx.searchParams.passengers,
        ctx.searchParams.petCount,
        ctx.catalogs.map((c) => indexToLeg(c.index))
      ),
    errors: [],
  }),
  addLeg: assign<CatalogContext>({
    passengers: (ctx: CatalogContext, evt: any): ExtendedPassengerAndPet[] => {
      const passengers = [...ctx.passengers];
      const passenger = passengers.find((p) => p.id === evt.data.id);
      if (passenger && !passenger.legs?.includes(evt.data.leg)) passenger.legs?.push(evt.data.leg);
      return passengers;
    },
    errors: [],
  }),
  removeLeg: assign<CatalogContext>({
    passengers: (ctx: CatalogContext, evt: any): ExtendedPassengerAndPet[] => {
      const passengers = [...ctx.passengers];
      const passenger = passengers.find((p) => p.id === evt.data.id);
      if (passenger && passenger.legs?.includes(evt.data.leg))
        passenger.legs.splice(passenger.legs.indexOf(evt.data.leg), 1);
      return passengers;
    },
    errors: [],
  }),
  addInfo: assign<CatalogContext>({
    passengers: (ctx: CatalogContext, { data }: any): ExtendedPassengerAndPet[] => {
      const passenger = ctx.passengers.find(({ id }) => id === data.id);

      if (passenger && isPassengerInput(passenger)) {
        passenger.info = {
          ...passenger.info,
          ...data.info,
          reserverInfo: {
            ...(passenger.info?.reserverInfo || {}),
            ...(data.info?.reserverInfo || {}),
          },
        };
      }

      return ctx.passengers;
    },
    errors: [],
  }),
};

const createPassengers = (passengerOptions: Passengers, petCount: number, legs?: number[]) => {
  const passengers = Object.entries(passengerOptions).reduce((passengers, [type, count]) => {
    if (count > 0) {
      return passengers.concat(
        Array.from({ length: count }).map((_, index) => ({
          id: index + passengers.length + 1,
          type: type as PassengerType,
          reserver:
            (type === PassengerType.ADULT || (passengerOptions.ADULT <= 0 && type === PassengerType.JUNIOR)) &&
            index === 0
              ? true
              : false,
          legs: legs || [],
          info: {
            specialNeeds: '',
            starclub: '',
          },
        }))
      );
    } else {
      return passengers;
    }
  }, [] as ExtendedPassengerAndPet[]);

  return petCount > 0
    ? passengers.concat(
        Array.from({ length: petCount }).map((_, index) => ({
          id: index + passengers.length + 1,
          type: PetType.PET,
          legs: legs || [],
        }))
      )
    : passengers;
};

export default PassengerActions;
