import React, { useContext } from 'react';
import BookingSummary from '../../../design-system/components/BookingSummary';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { PriceError, SailingMeta, ProductError } from '../../../fsm/types';
import { LanguageContext } from '../../../Language.context';
import { TreeData } from '../../../storyblok/types';
import { formatString } from '../../../utils/formats';
import { calculateSailingPriceWithoutOnboards, calculateSailingTotalPrice } from '../../../utils/priceCalculation';
import { typeToSlug } from '../../catalog/listing/Trip/TicketCard';
import MainRows from './MainRows';
import OnboardsRows from './OnboardsRows';
import { sortAdultToInfant } from '../../../fsm/utils/passengerUtils';
import { isOfferCodeValidForTariff } from '../../../fsm/utils/sailingUtils';

export interface SailingSummary {
  departurePort: string;
  departureDate: string;
  departureTime: string;
  arrivalPort: string;
  arrivalDate?: string | null;
  arrivalTime?: string | null;
  shipName?: string | null;
  accommodations?:
    | {
        code: string;
        price?: API.TariffPriceOrError;
        type: string;
      }[]
    | API.AccommodationError;
  offerCode?: string;
  onboards?:
    | {
        amount: number;
        code: string;
        price?: API.TariffPriceOrError;
        type: string;
        legs: number[] | { leg: number; chargeInfo: API.ChargeInfo }[];
      }[]
    | API.PriceError;
  quote?: {
    [tariff: string]: {
      passengers: {
        legs: { leg: number; chargeInfo: API.ChargeInfo }[];
        type: API.PassengerType;
      }[];
      pets: {
        legs: { leg: number; chargeInfo: API.ChargeInfo }[];
        type: API.PetType;
      }[];
      vehicles: {
        legs: { leg: number; chargeInfo: API.ChargeInfo }[];
        type: API.VehicleType;
      }[];
    };
  };
  products?: API.Products | ProductError;
  tariff: API.Tariff;
  meta?: SailingMeta;
}
interface CommonSummaryProps {
  readonly index: number;
  readonly agreement?: API.Agreement;
  readonly offerCode?: string;
  readonly sailing: SailingSummary;
  readonly sbLegSummary?: TreeData | undefined;
  readonly sbTicket?: { [slug: string]: TreeData | undefined };
  readonly tripType?: API.TripType;
  readonly otherSailing?: API.ExtendedSailing;
}

interface LargeSummaryProps extends CommonSummaryProps {
  readonly small?: never;
}

interface SmallSummaryProps extends CommonSummaryProps {
  readonly small: true;
}

type SummaryProps = LargeSummaryProps | SmallSummaryProps;

const filterByLeg = <A, B extends { legs: number[] | { leg: number; chargeInfo: API.ChargeInfo }[]; type: A }>(
  leg: number,
  products: B[]
) =>
  products.filter((_) =>
    _.legs.some(
      (_: number | { leg: number; chargeInfo: API.ChargeInfo }) =>
        (typeof _ === 'object' && _.leg === leg) || (typeof _ === 'number' && _ === leg)
    )
  );

const reduceForLeg = (leg: number, products: { legs: { leg: number; chargeInfo: API.ChargeInfo }[] }[]) =>
  products.reduce(
    (forLeg, product) => forLeg.concat(product.legs.filter((_) => _.leg === leg).map((_) => _.chargeInfo)),
    [] as API.ChargeInfo[]
  );

const getTotalCruisePriceWithoutOnboards = (sailing: SailingSummary, returnSailing: API.ExtendedSailing) => {
  const sailingTotalFirstLeg = calculateSailingPriceWithoutOnboards(sailing);
  // With overnight cruise, extend the selected tariff from the first leg of the trip
  const sailingTotalReturnLeg = calculateSailingPriceWithoutOnboards({
    ...returnSailing,
    tariff: sailing.tariff,
  });
  return sailingTotalFirstLeg && sailingTotalReturnLeg ? sailingTotalFirstLeg + sailingTotalReturnLeg : undefined;
};

const Summary = React.forwardRef<HTMLDivElement, SummaryProps>(
  ({ agreement, index, sailing, small, sbLegSummary, sbTicket, offerCode, tripType, otherSailing }, ref) => {
    const { formats } = useContext(LanguageContext);
    const { accommodations, meta, onboards, quote: quotesByTariff, tariff, products, shipName } = sailing;
    const leg = index + 1;
    const quote = quotesByTariff && quotesByTariff[tariff];

    const passengers = quote ? sortAdultToInfant(filterByLeg(leg, [...quote.passengers, ...quote.pets])) : [];
    const vehicles = quote ? filterByLeg(leg, quote.vehicles) : [];
    const sailingTotal = calculateSailingTotalPrice(sailing);
    const sbErrorCodes = hooks.useStoryblokDatasource('error-codes');

    const getFormattedPrice = (price: number | undefined) => (price ? formats.currency(price) : '–');
    const totalCruisePrice = otherSailing ? getTotalCruisePriceWithoutOnboards(sailing, otherSailing) : undefined;
    const price = totalCruisePrice ? getFormattedPrice(totalCruisePrice) : getFormattedPrice(sailingTotal);
    const onboardsForLeg = Array.isArray(onboards) && !!onboards.length ? filterByLeg(leg, onboards) : undefined;
    const showLegTicketPrice = !(typeof totalCruisePrice === 'number');
    const showOnboards = !(typeof totalCruisePrice === 'number');

    return (
      <BookingSummary.Container ref={ref}>
        {small ? (
          <BookingSummary.Header small otherSailing={otherSailing} tripType={tripType} {...sailing} />
        ) : (
          <BookingSummary.Header
            label={formatString(sbLegSummary?.content.leg_label, index + 1)}
            otherSailing={otherSailing}
            tripType={tripType}
            {...sailing}
          />
        )}
        <BookingSummary.Content>
          <BookingSummary.ListTitle>
            {sbTicket ? sbTicket[typeToSlug(tariff)]?.content.title : ''}
          </BookingSummary.ListTitle>
          <BookingSummary.List>
            {passengers && (
              <MainRows
                {...{
                  passengers,
                  tariff,
                  vehicles,
                  onboards: onboardsForLeg,
                  shipName,
                  showHeader: showLegTicketPrice,
                }}
                {...(Array.isArray(accommodations) && { accommodations })}
                quotes={
                  quote
                    ? [
                        ...reduceForLeg(leg, quote.passengers),
                        ...reduceForLeg(leg, quote.pets),
                        ...reduceForLeg(leg, quote.vehicles),
                      ]
                    : []
                }
              />
            )}
          </BookingSummary.List>
          {offerCode && (
            <BookingSummary.Subtotal
              label={`${
                !isOfferCodeValidForTariff(meta, tariff)
                  ? sbErrorCodes[PriceError.OFFERCODE_NOT_VIABLE]
                  : sbLegSummary?.content.offercode_active
              }: ${offerCode}`}
              price={''}
            />
          )}
          {onboardsForLeg && (
            <BookingSummary.List>
              <OnboardsRows
                {...{
                  onboards: onboardsForLeg,
                  tariff,
                  products: products && Object.values(products),
                  showOnboards: showOnboards,
                }}
              />
            </BookingSummary.List>
          )}

          {agreement && agreement.status === API.AgreementStatus.OK ? (
            <BookingSummary.Subtotal label={sbLegSummary?.content.starclub_discount} price={''} />
          ) : null}
          <BookingSummary.Subtotal label={sbLegSummary?.content.subtotal_label} price={price} />
        </BookingSummary.Content>
      </BookingSummary.Container>
    );
  }
);

export default Summary;
