/**
 * Module for handling discount and rebate related logic.
 */

import { Timetable } from '../types';
import { settings } from '../../settings';

const RETURN_TRIP_DISCOUNT = 1 - settings.returnTripDiscount / 100;

export const applyReturnTripDiscount = (timetables: Timetable[]): Timetable[] => {
  return timetables.map((timetable) => {
    if (!timetable.chargeTotal) {
      return timetable;
    }

    const discountedPrice = timetable.chargeTotal * RETURN_TRIP_DISCOUNT;
    return { ...timetable, chargeTotal: discountedPrice };
  });
};
