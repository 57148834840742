import { parseISO } from 'date-fns';
import I18N from '../../../utils/i18n';
import React, { FunctionComponent, useContext } from 'react';
import styled, { css } from 'styled-components';
import { LanguageContext } from '../../../Language.context';
import { hooks, style } from '../../helpers/mixins';
import { color } from '../../helpers/vars';
import Icon from '../Icon';
import Label from '../Label';
import { H2, H3, P } from '../Text';
import { TripType } from '../../../fsm/types';
import * as API from '../../../fsm/types';

interface TitleProps {
  readonly small?: boolean;
}

interface WrapperProps {
  readonly headerOnly?: boolean;
  readonly variant?: 'default' | 'white';
}

interface HeaderProps extends TitleProps, WrapperProps {
  readonly label?: React.ReactNode;
  readonly departurePort: string;
  readonly departureDate: string;
  readonly departureTime: string;
  readonly arrivalPort: string;
  readonly arrivalDate?: string | null;
  readonly arrivalTime?: string | null;
  readonly shipName?: string | null;
  readonly tripType?: TripType;
  readonly otherSailing?: API.ExtendedSailing;
}

interface LegSectionProps extends TitleProps {
  readonly fromPort: string;
  readonly toPort: string;
  readonly departureLabel: string;
  readonly arrivalLabel: string;
}

interface GetLegParams {
  departurePort: string;
  arrivalPort: string;
  sbPorts: { [name: string]: string };
  departureDate: string;
  departureTime: string | null | undefined;
  arrivalDate: string | null | undefined;
  arrivalTime: string | null | undefined;
}

const Wrapper = styled.header<WrapperProps>`
  ${style.card.content}
  border-color: ${color.bg.bookingSummary};
  overflow: hidden;
  position: relative;

  ${({ headerOnly }) =>
    !headerOnly &&
    css`
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${({ variant }) =>
    variant === 'white'
      ? css`
          background: ${color.bg.default};
        `
      : css`
          background: ${color.bg.bookingSummary};
        `}

  ${Label} {
    position: absolute;
    top: 0;
    left: -0.5rem;
    padding-left: 1.5rem;
  }
`;

const TitleWithMargin = styled(H2)`
  margin-top: 2rem;
`;

const Ship = styled(P)`
  color: ${color.brand};
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 1rem;
`;

const Title: FunctionComponent<TitleProps> = ({ small, ...props }) =>
  small ? <H3 as="h2" {...props} /> : <TitleWithMargin {...props} align="center" />;

const LegSection: FunctionComponent<LegSectionProps> = ({ small, fromPort, toPort, departureLabel, arrivalLabel }) => {
  return (
    <>
      <Title {...{ small }}>
        {fromPort} <Icon name="arrow" inline /> {toPort}
      </Title>
      <P {...(!small && { align: 'center' })} {...{ small }}>
        {departureLabel}
        {arrivalLabel}
      </P>
    </>
  );
};

const findStoryBlockItem = (sbItemPairs: { [name: string]: string }, itemKey: string) =>
  (sbItemPairs && sbItemPairs[itemKey]) || itemKey;

const Header: FunctionComponent<HeaderProps> = ({
  headerOnly,
  label,
  departurePort,
  departureDate,
  departureTime,
  arrivalPort,
  arrivalDate,
  arrivalTime,
  shipName,
  small,
  variant,
  tripType,
  otherSailing,
}) => {
  const { formats } = useContext(LanguageContext);

  const getFormattedDate = (
    formats: {
      currency: (moneyAsCents: number | undefined) => string;
      i18n: I18N;
    },
    date: string
  ) => {
    return formats.i18n.localizeDate(parseISO(date));
  };

  const getLegParams = ({
    departurePort,
    arrivalPort,
    sbPorts,
    departureDate,
    departureTime,
    arrivalDate,
    arrivalTime,
  }: GetLegParams) => ({
    fromPort: findStoryBlockItem(sbPorts, departurePort),
    toPort: findStoryBlockItem(sbPorts, arrivalPort),
    departureLabel: `${getFormattedDate(formats, departureDate)} ${departureTime}`,
    arrivalLabel: `${arrivalDate && ` – ${getFormattedDate(formats, arrivalDate)} ${arrivalTime || ''}`}`,
  });

  const sbPorts = hooks.useStoryblokDatasource('ports');
  const sbShips = hooks.useStoryblokDatasource('ships');

  const firstLegParams = getLegParams({
    departurePort,
    arrivalPort,
    sbPorts,
    departureDate,
    departureTime,
    arrivalDate,
    arrivalTime,
  });

  const ship = shipName ? findStoryBlockItem(sbShips, shipName) : shipName;

  const returnLegParams =
    tripType === TripType.OVERNIGHT_CRUISE && otherSailing
      ? getLegParams({
          departurePort: otherSailing.departurePort,
          arrivalPort: otherSailing.arrivalPort,
          sbPorts,
          departureDate: otherSailing.departureDate,
          departureTime: otherSailing.departureTime,
          arrivalDate: otherSailing.arrivalDate,
          arrivalTime: otherSailing.arrivalTime,
        })
      : undefined;

  return (
    <Wrapper {...{ headerOnly, variant }}>
      {label && <Label>{label}</Label>}
      <LegSection
        small={small}
        fromPort={firstLegParams.fromPort}
        toPort={firstLegParams.toPort}
        departureLabel={firstLegParams.departureLabel}
        arrivalLabel={firstLegParams.arrivalLabel}
      />
      {tripType === TripType.OVERNIGHT_CRUISE && returnLegParams && (
        <LegSection
          small={small}
          fromPort={returnLegParams.fromPort}
          toPort={returnLegParams.toPort}
          departureLabel={returnLegParams.departureLabel}
          arrivalLabel={returnLegParams.arrivalLabel}
        />
      )}
      {!small && ship && (
        <Ship>
          <Icon name="ship" label="ship" large inline inheritColor /> {ship}
        </Ship>
      )}
    </Wrapper>
  );
};

export default Header;
