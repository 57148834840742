import React, { useReducer, useState } from 'react';
import { Cell } from '../../../../design-system/components/Container';
import Button from '../../../../design-system/components/Button';
import { Form, LineIdentity, Port, PortMap, TripType } from '../../../../fsm/types';
import { parseISO } from 'date-fns';
import { formDates } from '../../../search/common';
import { hooks } from '../../../../design-system/helpers/mixins';
import { TripTypeForm } from '../../../search/SearchView';
import {
  ChangeTripButtonWrapper,
  DropdownChangeTripContainer,
} from '../../../../design-system/components/ChangeTripDropdown';
import styled, { css } from 'styled-components';
import { color } from '../../../../design-system/helpers/vars';

interface SailingChangeTripDropdownProps {
  forms: Form[];
  tripType: TripType;
  portMap: PortMap[];
  searchButtonText: string;
  range: number;
  showChangeTripText: string;
  hideChangeTripText: string;
  cruisePorts?: Port[];
  dayCruisePorts?: Port[];
  loadChangeTripSailings: any;
  isPriceLoading: boolean;
  isVehicleSelected: boolean;
}

type FormActions = {
  type: 'EDIT';
  payload: { form: Form; range: number };
};

type DropdownFormState = { forms: Form[] };

const formReducer = ({ forms }: DropdownFormState, action: FormActions): DropdownFormState => {
  switch (action.type) {
    case 'EDIT':
      return {
        forms: forms.map((form) => {
          const selectedDate = parseISO(form.selectedDate);
          const actionSelectedDate = parseISO(action.payload.form.selectedDate);
          return form.index === action.payload.form.index
            ? { ...action.payload.form }
            : form.index > action.payload.form.index
            ? {
                ...form,
                selectedDate: selectedDate < actionSelectedDate ? action.payload.form.selectedDate : form.selectedDate,
                params: {
                  ...form.params,
                  ...(selectedDate < actionSelectedDate
                    ? formDates(actionSelectedDate, action.payload.range)
                    : {
                        startDate: form.params.startDate,
                        endDate: form.params.endDate,
                        numberOfDays: form.params.numberOfDays,
                      }),
                },
              }
            : form;
        }),
      };
    default:
      break;
  }
  return { forms };
};

const extractFormState = (tripType: TripType, forms: Form[]) => {
  return tripType === TripType.ONEWAY ? [forms[0]] : [forms[0], forms[1]];
};

const filterOutPolandPortsIfNoVehicle = (portMap: PortMap[], isVehicleSelected: boolean): PortMap[] => {
  if (!isVehicleSelected) {
    return [...portMap]
      .filter((portMap: PortMap) => portMap.from.code !== Port.PLSWI)
      .map((portMap: PortMap) => ({
        ...portMap,
        to: portMap.to.filter((to: LineIdentity) => to.code !== Port.PLSWI),
      }));
  }
  return portMap;
};

const StyledTripButton = styled(Button)<{ isDropdownOpen: boolean; disabled: boolean }>`
  &:focus {
    ${(props) =>
      !props.isDropdownOpen &&
      css`
        background: ${color.bg.button.secondary.default};
        outline: none;
        box-shadow: none;
      `}
  }

  &:disabled {
    ${(props) =>
      props.disabled &&
      css`
        border-color: ${color.bg.alt};
      `}
  }
`;

export const SailingChangeTripDropdown = ({
  forms,
  tripType,
  portMap,
  loadChangeTripSailings,
  searchButtonText,
  range,
  showChangeTripText,
  hideChangeTripText,
  isPriceLoading,
  isVehicleSelected,
}: SailingChangeTripDropdownProps) => {
  const [newFormState, dispatchForms] = useReducer(formReducer, {
    forms: extractFormState(tripType, forms),
  });
  const [isChangeTripDropdownOpen, setIsChangeTripDropdownOpen] = useState<boolean>(false);

  const toggleIsChangeTripDropdownOpen = () => {
    setIsChangeTripDropdownOpen((prevOpenState: boolean) => !prevOpenState);
  };

  const onChangeTripSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleIsChangeTripDropdownOpen();
    loadChangeTripSailings(newFormState?.forms);
  };

  const updateForm = (form: Form) => {
    dispatchForms({
      type: 'EDIT',
      payload: {
        form,
        range,
      },
    });
  };

  const sbDefaultCruisePorts = hooks.useStoryblokComponent({
    path: 'search.cruise_ports',
  })[0]?.content?.cruise_defaults;

  const sbDefaultDayCruisePorts = hooks.useStoryblokComponent({
    path: 'search.daycruise_ports',
  })[0]?.content?.daycruise_defaults;

  const filteredPortMap: PortMap[] = filterOutPolandPortsIfNoVehicle(portMap, isVehicleSelected);

  return (
    <>
      <ChangeTripButtonWrapper>
        <StyledTripButton
          round
          secondary
          disabled={isPriceLoading}
          isDropdownOpen={isChangeTripDropdownOpen}
          onClick={toggleIsChangeTripDropdownOpen}
        >
          {isChangeTripDropdownOpen ? hideChangeTripText : showChangeTripText}
        </StyledTripButton>
      </ChangeTripButtonWrapper>

      <DropdownChangeTripContainer variant="catalog-search" isChangeTripDropdownOpen={isChangeTripDropdownOpen}>
        <TripTypeForm
          forms={newFormState?.forms}
          tripType={tripType}
          range={range}
          portMap={filteredPortMap}
          updateForm={updateForm}
          cruisePorts={sbDefaultCruisePorts}
          dayCruisePorts={sbDefaultDayCruisePorts}
        />

        <Cell cols={2}>
          <ChangeTripButtonWrapper>
            <Button round onClick={onChangeTripSearch}>
              {searchButtonText}
            </Button>
          </ChangeTripButtonWrapper>
        </Cell>
      </DropdownChangeTripContainer>
    </>
  );
};
