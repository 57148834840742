import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Measure, { ContentRect } from 'react-measure';
import { Spring, animated } from 'react-spring/renderprops';

import Content from './Content';

interface CollapsibleComponentProps {
  collapse: number;
  toggle: boolean;
  collapsible: boolean;
  collapsedHeight: number;
  setCollapsible: (value: boolean) => void;
  setCollapsedHeight: (value: number) => void;
}

interface RefProps {
  ref: (ref: Element | null) => void;
}

const CollapsibleContent = styled(Content)<{ toggleHeight: number }>`
  position: relative;
  overflow: hidden;
`;

const AnimatedContent = animated(CollapsibleContent);

const Collapsible: React.FunctionComponent<CollapsibleComponentProps> = ({
  collapse,
  toggle,
  collapsible,
  setCollapsible,
  collapsedHeight,
  setCollapsedHeight,
  children,
}) => {
  const [childrenArray, setChildrenArray] = useState<React.ReactNodeArray>([]);
  const [measurements, setMeasurements] = useState<ContentRect>({});

  useEffect(() => {
    if (children && collapse) {
      const childrenAsArray = React.Children.toArray(children);

      if (childrenAsArray.length > collapse) {
        setCollapsible(true);
        setChildrenArray(childrenAsArray);
        return;
      }
    }

    setCollapsible(false);
  }, [children, collapse, setCollapsible, setChildrenArray]);

  const measure = (contentRect: ContentRect) => collapsible && setMeasurements(contentRect);

  useEffect(() => {
    if (collapsible) {
      setCollapsedHeight((measurements?.offset?.top || 0) + (measurements?.offset?.height || 0));
    }
  }, [collapsible, measurements, setCollapsedHeight]);

  return collapse && collapsible ? (
    <Spring to={{ height: toggle ? 'auto' : collapsedHeight || 'auto' }}>
      {(style) => (
        <AnimatedContent style={{ ...style }}>
          {childrenArray.map(
            (e, i) =>
              React.isValidElement(e) &&
              (i === collapse - 1 ? (
                <Measure offset onResize={measure} key={i}>
                  {({ measureRef }) => React.cloneElement(e as React.ReactElement<RefProps>, { ref: measureRef })}
                </Measure>
              ) : (
                React.cloneElement(e, { key: i })
              ))
          )}
        </AnimatedContent>
      )}
    </Spring>
  ) : (
    <Content>{children}</Content>
  );
};

export default Collapsible;
