import React from 'react';

import { default as Input, InputProps, ExtendedInputProps } from './Input';
import { default as Labeled, LabeledProps } from './Labeled';

const Stepper: React.FunctionComponent<LabeledProps> = ({ children, innerRef, ...props }) =>
  children ? <Labeled {...{ innerRef, ...props }}>{children}</Labeled> : <Input {...props} />;

export type { InputProps, ExtendedInputProps };
export default Stepper;
