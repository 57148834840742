import React from 'react';
import styled from 'styled-components';

import { color, measurement } from '../../helpers/vars';
import { style } from '../../helpers/mixins';

import Icon, { IconName, SVG } from '../Icon';
import { P, Small } from '../Text';

export interface TagProps {
  readonly count?: number | string;
  readonly label?: string;
  readonly icon?: IconName;
  readonly addition?: string;
}

const Wrapper = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Count = styled(P)`
  margin: 0 0 0 0.25rem;
`;

export const StyledTag = styled(P)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0.25rem;
  border: ${style.border.default};
  border-radius: ${measurement.size.radius.input};
  background: ${color.bg.default};

  & > * {
    margin: 0 0.25rem;
  }

  ${SVG} {
    align-self: center;
    flex: 0 0 auto;
    margin: 0 0 0 -0.125rem;
  }
`;

const Tag: React.FunctionComponent<TagProps> = ({ count, label, icon, addition }) => (
  <Wrapper>
    {count && <Count as="span">{count} ×</Count>}
    <StyledTag as="span" inline>
      {icon && <Icon name={icon} />}
      {label && <span>{label}</span>}
      {addition && (
        <Small as="span" inline>
          {addition}
        </Small>
      )}
    </StyledTag>
  </Wrapper>
);

export default Tag;
