import React from 'react';
import styled, { css } from 'styled-components';
import { size } from 'polished';

import { color, measurement } from '../helpers/vars';
import { animation, style } from '../helpers/mixins';

import { IconDataUri } from './Icon';
import { Lead } from './Text';

interface WrapperProps {
  readonly gridSpan?: number;
  readonly ref?: React.RefObject<HTMLLabelElement>;
}

interface CheckboxProps extends WrapperProps {
  readonly onChange: (val: boolean) => void;
  readonly value: any;
  readonly checked: boolean;
  readonly required?: boolean;
  readonly inline?: boolean;
  readonly id?: string;
  readonly emphasize?: boolean;
}

const Wrapper = styled.label<WrapperProps>`
  position: relative;
  text-align: left;

  ${({ gridSpan }) =>
    gridSpan &&
    css`
      grid-column: span ${gridSpan};
    `}
`;

const Input = styled.input`
  ${style.radio.input}
`;

const Label = styled(Lead)`
  display: flex;
  align-items: center;
  border-radius: ${measurement.size.radius.default};
  padding: ${measurement.space.input.checkbox.wrapper};
  transition: ${animation.transition('box-shadow')};
  position: relative;

  &::before,
  &::after {
    content: '';
    ${size(measurement.size.input.checkbox)}
  }

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${color.bg.input.invert.inactive};
    border-radius: ${measurement.size.radius.input};
    mix-blend-mode: soft-light;
    transition: ${animation.transition('background', 'border')};
    margin-left: ${measurement.space.input.checkbox.wrapper};
  }

  &::before {
    transition: ${animation.transition('background')};
    background-repeat: no-repeat;
    background-size: ${measurement.size.icon.default};
    background-position: center;
    border-radius: ${measurement.size.radius.input};
    display: block;
    margin: ${measurement.space.input.checkbox.input};
  }

  ${Input}:focus + &, &:hover {
    &::before {
      background-color: ${color.bg.input.default};
    }
  }

  ${Input}:checked + & {
    ${style.radio.label.checked}
    border-color: ${color.border.active};

    &::before {
      background-image: url(${IconDataUri('check', color.icon.invert)});
      background-color: ${color.bg.input.checkbox.active};
    }
  }

  ${Input}:invalid + & {
    color: ${color.text.error};

    &::before {
      border: ${style.border.error};
    }
  }

  ${(props: any) => css`
    &::before {
      border: ${style.borderStr(props.theme.border)};
      background-color: ${props.theme.input.background};
      ${props.emphasize ? `border-color: ${color.bg.input.checkbox.active}` : ''}
    }

    ${Input}:focus + & {
      ${props.theme.focus}
    }
  `}
`;

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  onChange,
  children,
  inline,
  gridSpan,
  ref,
  emphasize,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e?.target?.checked || false);

  return (
    <Wrapper {...{ gridSpan, ref }}>
      <Input onChange={handleChange} type="checkbox" {...props} />
      <Label {...{ inline, emphasize }}>{children}</Label>
    </Wrapper>
  );
};

export default Checkbox;
