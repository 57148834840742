import React, { useEffect, useRef, useState } from 'react';
import { useDialogState } from 'reakit/Dialog';
import { Passengers, VehicleType } from '../../fsm/types';
import {
  hasEnoughDriversAsCodes,
  hasJuniorWithVehicle,
  adultsWithoutVehicle,
  passengersWithoutVehicle,
  vehicleTypeRequiresAdultOrJunior,
} from '../../fsm/utils/vehicleUtils';

import { hooks } from '../../design-system/helpers/mixins';

import Alert from '../../design-system/components/Alert';
import InputDisclosure from '../../design-system/components/InputDisclosure';
import TreeSelect, { OptionsType, SelectedListType, SuboptionType } from '../../design-system/components/TreeSelect';
import Modal from '../../design-system/components/Modal';
import { H3, P } from '../../design-system/components/Text';
import RichText from '../../design-system/components/RichText';

interface VehicleSelectProps {
  setVehicles: any;
  initial: SelectedListType;
  passengers: Passengers;
  vehicleRestrictionList: string[] | undefined;
}

type OptionInfoType = (SuboptionType & { uuid: string })[];

const VehicleSelect: React.FunctionComponent<VehicleSelectProps> = ({
  passengers,
  setVehicles,
  initial,
  vehicleRestrictionList,
}) => {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<SelectedListType>(initial ? initial : []);
  const [options, setOptions] = useState<OptionsType>([]);
  const [optionInfo, setOptionInfo] = useState<OptionInfoType>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const selectedCount = selected.length;

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  const sbVehicles = hooks.useStoryblokComponents('products/vehicles');
  const [sbVehicleSelection, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.vehicle_selection',
  });

  const [sbVehiclesTitle] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.vehicles_title',
  });

  const [youngDriver, setYoungDriver] = useState(false);

  const [freeAdults, setFreeAdults] = useState(true);
  const [freePassengers, setFreePassengers] = useState(true);

  useEffect(() => setValue(`${selectedCount}`), [selectedCount, setValue]);

  useEffect(() => {
    setFreeAdults(adultsWithoutVehicle(passengers, selected) > 0);
    setFreePassengers(passengersWithoutVehicle(passengers, selected) > 0);
  }, [passengers, selected]);

  const isSelectable = (productcode: string) =>
    freePassengers && (freeAdults || !vehicleTypeRequiresAdultOrJunior(productcode));

  useEffect(() => {
    setVehicles(selected);
    setYoungDriver(hasJuniorWithVehicle(passengers, selected));
  }, [selected, passengers, setVehicles]);

  useEffect(() => {
    setValue(
      selectedCount > 0
        ? `${selectedCount} ${
            selectedCount === 1
              ? sbSearchForm?.content['vehicle_single'] || ''
              : sbSearchForm?.content['vehicle_plural'] || ''
          }`
        : sbSearchForm?.content['select_vehicles'] || ''
    );
  }, [selectedCount, sbSearchForm]);

  useEffect(() => {
    const vehicleSelection = sbVehicleSelection?.content?.vehicle_groups;

    const getVehicle: (uuid: string) => SuboptionType | undefined = (uuid) =>
      optionInfo.find((v) => v.uuid === uuid) || undefined;

    const includeVehicle = (uuid: string) => {
      return vehicleRestrictionList === undefined || vehicleRestrictionList.includes(uuid);
    };

    if (vehicleSelection && optionInfo.length) {
      setOptions(
        vehicleSelection.reduce((availableVehicles: any, v: any) => {
          if (v?.component === 'vehicle_group') {
            if (v?.vehicles.some((v: any) => includeVehicle(v))) {
              availableVehicles.push({
                name: v.name,
                suboptions: v?.vehicles.filter((v: any) => includeVehicle(v)).map((v: any) => getVehicle(v)),
              });
            }
          } else {
            if (includeVehicle(v.vehicle)) {
              availableVehicles.push(getVehicle(v.vehicle));
            }
          }
          return availableVehicles;
        }, [])
      );
      setSelected(selected.filter((_) => includeVehicle(_.uuid || '')));
    }
  }, [sbVehicleSelection, optionInfo, vehicleRestrictionList]);

  useEffect(
    () =>
      setOptionInfo(
        Object.values(sbVehicles).map(({ content, uuid }) => {
          if (content) {
            if (content?.component === 'vehicle_with_dimensions') {
              return {
                productcode: content?.productcode || '',
                example: content?.example || '',
                height: content?.height || '',
                length: content?.length || '',
                illustration: content?.illustration || undefined,
                name: content?.name || undefined,
                uuid,
              };
            } else if (content?.component === 'vehicle_with_name') {
              return {
                productcode: content?.productcode || '',
                name: content?.name || '',
                uuid,
              };
            }
          }

          return undefined;
        }) as OptionInfoType
      ),
    [sbVehicles]
  );

  const modalState = useDialogState({ animated: false });
  const focusRef = useRef<HTMLElement>();

  const addLabel = sbVehicleSelection?.content?.add_label;
  const selectedLabel = sbVehicleSelection?.content?.selected_label;
  const infoText = hooks.useStoryblokRichText(sbVehicleSelection?.content?.info_text);

  useEffect(() => {
    if (modalState.visible) {
      focusRef?.current?.focus();
    }
  }, [modalState.visible]);

  const addSelected = (productcode: string, uuid: string) => {
    if (hasEnoughDriversAsCodes(productcode as VehicleType, passengers, selected))
      setSelected([...selected, { id: selected.length ? selected[selected.length - 1].id + 1 : 0, productcode, uuid }]);
  };
  const removeSelected = (id: number) => setSelected((selected) => selected.filter((s) => s.id !== id));

  const canAdd = !!passengersWithoutVehicle(passengers, selected);

  const label = sbVehiclesTitle?.content.title;

  return (
    <>
      <InputDisclosure {...{ value, modalState, label }} />
      <Modal
        ref={ref}
        state={modalState}
        title={isSelecting ? addLabel : selectedLabel}
        submit={sbSearchForm?.content['ready_button']}
        size="input"
        {...(youngDriver && {
          footer: (
            <Alert severity="warning">
              <H3>{sbVehicleSelection?.content?.junior_as_driver_title}</H3>
              <P>{sbVehicleSelection?.content?.junior_as_driver_content}</P>
            </Alert>
          ),
        })}
      >
        {(modalState.animating || modalState.visible) && (
          <>
            {!isSelecting && (
              <TreeSelect.SelectedList
                {...{
                  selected: selected.map((v) => ({ ...v, uuid: sbVehicles[v.productcode]?.uuid })),
                  removeSelected,
                  optionInfo,
                  focusRef,
                }}
              />
            )}
            <TreeSelect.Tree
              parentOpen={modalState.visible}
              {...{
                options,
                selectedCount,
                addSelected,
                removeSelected,
                setIsSelecting,
                isSelectable,
                addLabel,
                selectedLabel,
                canAdd,
                ...(isSelecting && { focusRef }),
              }}
            />
          </>
        )}
        <RichText>{infoText}</RichText>
      </Modal>
    </>
  );
};

export default VehicleSelect;
