import React from 'react';

import { H3, Lead } from '../Text';

interface TitleProps {
  readonly addition?: string;
}

const Title: React.FunctionComponent<TitleProps> = ({ children, addition }) => (
  <H3 align="center">
    {children}
    {addition && (
      <Lead as="span" inline>
        , {addition}
      </Lead>
    )}
  </H3>
);

export default Title;
