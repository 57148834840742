import React from 'react';
import styled from 'styled-components';

import Spinner from '../Spinner';

import { Small, Price } from '../Text';

export interface PriceAreaSharedProps {
  readonly price?: string;
  readonly label?: string;
  readonly loading?: boolean;
}

export const StyledPriceArea = styled.p`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const Label = styled(Small)`
  padding-left: 0.5rem;
`;

const PriceArea: React.FunctionComponent<PriceAreaSharedProps> = ({ price, label, loading }) => {
  if (!loading && (label === 'NO_PRICE' || label === 'NO_STANDARD')) {
    return <></>;
  } else
    return (
      <StyledPriceArea>
        {price && (
          <Price inline as="span">
            {price}
          </Price>
        )}
        {label && (
          <Label inline as="span">
            {label}
          </Label>
        )}
        {loading && <Spinner small />}
      </StyledPriceArea>
    );
};

export default PriceArea;
