import React, { FunctionComponent, useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { hooks } from '../design-system/helpers/mixins';
import { usePersistedMachine, useServiceWithHistory } from '../fsm/react';
import { stateMachine } from '../fsm/stateMachine';
import { Form, Trip } from '../fsm/types';
import { LanguageContext } from '../Language.context';
import CatalogView from './catalog/CatalogView';
import PaymentView from './payment/PaymentView';
import SearchView from './search/SearchView';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const locationToStoryblokPageSlug = (path: string): string => {
  if (path.endsWith('/search')) {
    return 'search';
  }
  if (path.endsWith('/catalog')) {
    return 'trip';
  }
  if (path.endsWith('/catalog/onboard')) {
    return 'onboard';
  }
  if (path.endsWith('/catalog/info')) {
    return 'info';
  }
  if (path.endsWith('/payment')) {
    return 'payment';
  }
  if (path.endsWith('/payment/reserve')) {
    return 'reserve-booking';
  }
  if (path.endsWith('/payment/start')) {
    return 'start-payment';
  }
  if (path.endsWith('/payment/verify')) {
    return 'verify-payment';
  }
  if (path.endsWith('/payment/success')) {
    return 'payment-success';
  }

  return '';
};

const Router: FunctionComponent<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const { setCurrency } = useContext(LanguageContext);
  const [current, send, service] = usePersistedMachine(stateMachine);
  const { searchParams } = current.context;

  const [, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    fullSlug: `pages/${locationToStoryblokPageSlug(location.pathname)}`,
  });

  useServiceWithHistory(service, history);

  useEffect(() => {
    searchParams && setCurrency(searchParams.currency);
  }, [setCurrency, searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Wrapper ref={ref}>
      {current.matches('search') ? (
        <SearchView
          {...props}
          savedContext={current.context.searchParams}
          submitSearch={(searchParams) => send({ type: '/catalog', searchParams })}
        />
      ) : null}
      {current.matches('catalog') ? (
        <CatalogView
          {...props}
          moveToSearch={() => send({ type: '/search' })}
          moveToPayment={(trip: Trip) => send({ type: '/payment', trip })}
          submitChangeTripSearch={(newFormsValues: Form[]) => {
            const updatedSearchParams = { ...current.context.searchParams, forms: newFormsValues };
            // this will update the finnlines state machine with the new search form
            send({ type: '/catalog', searchParams: updatedSearchParams });
          }}
          searchParams={current.context.searchParams}
        />
      ) : null}
      {current.matches('payment') ? (
        <PaymentView
          {...props}
          moveToCatalog={() => send({ type: '/catalog', searchParams: current.context.searchParams })}
          moveToSearch={() => send({ type: '/search' })}
          trip={current.context.trip}
        />
      ) : null}
    </Wrapper>
  );
};

export default withRouter(Router);
