import { assign, createMachine, Interpreter } from 'xstate';
import { Tree } from '../../storyblok';
import { GetSearchOptions } from '../api/api';
import { PortMap, SearchParams } from '../types';
import actions from './actions';
import guards from './guards';

export interface SearchContext {
  errorMsg: string[];
  path: string;
  portMap: PortMap[];
  searchParams: SearchParams | undefined;
}

export type SearchEvent =
  | { type: '/search'; data: { popped: boolean } }
  | { type: 'CHECK'; searchParams: SearchParams; sbLegMeta: Tree }
  | { type: 'SEARCH'; searchParams: SearchParams; sbLegMeta: Tree };

export interface SearchStateSchema {
  states: {
    boot: {};
    loadInitial: {};
    idle: {};
  };
}

export type SearchTypestates =
  | {
      value: 'boot';
      context: SearchContext & {
        portMap: [];
        searchParams: undefined;
      };
    }
  | {
      value: 'loadInitial';
      context: SearchContext & {
        searchParams: undefined;
      };
    }
  | {
      value: 'idle';
      context: SearchContext;
    };

export type SearchInterpreter = Interpreter<SearchContext, SearchStateSchema, SearchEvent, SearchTypestates>;

export default createMachine<SearchContext, SearchEvent, SearchTypestates>(
  {
    id: 'search',
    initial: 'boot',
    context: {
      errorMsg: [],
      path: '/search',
      portMap: [],
      searchParams: undefined,
    },
    states: {
      boot: {
        entry: [assign<SearchContext, SearchEvent>({ path: '/search' }), 'updateHistory'],
        always: [{ target: 'loadInitial', cond: 'loadSearchOptionsOnBoot' }, { target: 'idle' }],
      },
      loadInitial: {
        invoke: {
          id: 'GetSearchOptions',
          src: GetSearchOptions,
          onDone: {
            target: 'idle',
            actions: 'loadInitial',
          },
          onError: {
            target: 'idle',
            actions: 'loadInitialError',
          },
        },
      },
      idle: {
        on: {
          CHECK: [
            {
              target: 'idle',
              cond: 'searchParamGuard',
              actions: ['onSearchParamsError'],
            },
            {
              target: 'idle',
              actions: 'onSearchParamsError',
            },
          ],
          SEARCH: [
            {
              target: 'idle',
              cond: 'searchParamGuard',
              actions: ['onSearchParams', 'onSubmitSearch'],
            },
            {
              target: 'idle',
              actions: 'onSearchParamsError',
            },
          ],
        },
      },
    },
    on: {
      '/search': {
        target: 'idle',
      },
    },
  },
  {
    actions,
    guards,
  }
);
