import React from 'react';
import styled from 'styled-components';

import { color, measurement } from '../../helpers/vars';
import { animation, style, typography } from '../../helpers/mixins';

import Input, { ExtendedInputProps } from './Input';

export type LabeledProps = ExtendedInputProps & {
  id: string;
  innerRef?: React.RefObject<HTMLDivElement>;
};

const Label = styled.label`
  ${typography.lead}
  margin-right: ${measurement.space.input.stepper.label};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -${measurement.inset.input.stepper.control};
  padding: ${measurement.inset.input.stepper.control};
  border-radius: ${measurement.size.radius.default};
  transition: ${animation.transition('box-shadow', 'background')};

  & + & {
    margin-top: ${measurement.space.input.stepper.control};
  }

  &:focus,
  &:focus-within {
    background: ${color.bg.alt};
    ${style.focus.subtle}
  }
`;

const Labeled: React.FunctionComponent<LabeledProps> = ({ children, innerRef, ...props }) => (
  <Wrapper ref={innerRef}>
    <Label htmlFor={props.id}>{children}</Label>
    <Input {...props} />
  </Wrapper>
);

export default Labeled;
