import { isAfter, isBefore, parse } from 'date-fns/fp';
import { TripType } from '../fsm/types';
import settings from './data';

const isDaySailing: ({ departureTime }: { departureTime: string }) => boolean = (() => {
  const now = new Date(Date.now());
  const parseTime = parse(now, 'HH:mm');
  const [start, end] = settings.daySailingTimes;
  const isAfterStart = isAfter(parseTime(start));
  const isBeforeEnd = isBefore(parseTime(end));

  return ({ departureTime }: { departureTime: string }) => {
    const departure = parseTime(departureTime);
    return isAfterStart(departure) && isBeforeEnd(departure);
  };
})();

const isDayCruiseTime = ({ departureTime }: { departureTime: string }) => {
  const now = new Date(Date.now());
  const parseTime = parse(now, 'HH:mm');
  const [start, end] = settings.dayCruiseSailingTimes;
  const isAfterStart = isAfter(parseTime(start));
  const isBeforeEnd = isBefore(parseTime(end));

  const departure = parseTime(departureTime);
  return isAfterStart(departure) && isBeforeEnd(departure);
};

const sailingRequiresAccommodation = (
  {
    departurePort,
    arrivalPort,
    departureTime,
  }: {
    departurePort: string;
    arrivalPort: string;
    departureTime: string;
  },
  tripType: TripType,
  passengerHasPet: boolean
): boolean =>
  (!settings.accommodationNotRequired.includes(departurePort) &&
    !settings.accommodationNotRequired.includes(arrivalPort)) ||
  !isDaySailing({ departureTime }) ||
  tripType === TripType.OVERNIGHT_CRUISE ||
  (passengerHasPet &&
    !(
      settings.accommodationNotRequired.includes(departurePort) &&
      settings.accommodationNotRequired.includes(arrivalPort)
    ));

export * from './types';
export { isDaySailing, sailingRequiresAccommodation, settings, isDayCruiseTime };
