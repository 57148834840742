import React from 'react';
import styled, { css } from 'styled-components';

import { font, measurement } from '../helpers/vars';
import { style, typography } from '../helpers/mixins';

interface RichTextProps {
  readonly children: string;
  readonly className?: string;
}

const StyledRichText = styled.div`
  h1,
  h2,
  h3 {
    color: ${(props) => props.theme.text.heading};
    margin: ${measurement.space.text.heading} 0;
  }

  h1 {
    ${typography.h1}
  }

  h2 {
    ${typography.h2}
  }

  h3 {
    ${typography.h3}
  }

  p {
    ${typography.p}
    color: ${(props) => props.theme.text.copy};
    margin: ${measurement.space.text.copy} 0;
  }

  a {
    font: inherit;
    ${style.link.common}
    cursor: pointer;
    font-weight: ${font.weight.strong.default};
    text-decoration: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;

    ${(props) => css`
      ${props.theme.link}
    `}

    &:hover {
      ${style.link.hover}
    }

    &:focus {
      ${style.link.focus}
    }
  }
`;

const RichText: React.FunctionComponent<RichTextProps> = ({ children, className }) => (
  <StyledRichText className={className} dangerouslySetInnerHTML={{ __html: children }} />
);

export default RichText;
