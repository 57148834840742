import { useEffect, useState, useContext } from 'react';
import Joi from '@hapi/joi';
import { PassengerType, VehicleType } from '../../../FinnlinesB2CBookingAPI';
import { FlagIconCode } from 'react-flag-kit';
import { getYear } from 'date-fns';
import { passengerAge } from '../../../fsm/utils/passengerUtils';
import { Language, LanguageContext } from '../../../Language.context';
import { PassengerInfo, ReserverInfo, Language as LanguageType } from '../../../fsm/types';
import logger from '../../../utils/logging';

interface PassedProps {
  readonly label: string;
  readonly htmlFor: string;
  readonly hint?: string;
  readonly upperCase?: boolean;
}

export interface WrapperProps extends PassedProps {
  readonly error?: string;
  readonly select?: boolean;
  readonly gridSpan?: number;
}

interface CommonStateReturn extends WrapperProps {
  readonly value: any;
  readonly onBlur: () => void;
}

export interface InputGroupStateReturn extends CommonStateReturn {
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly mask?: string;
  readonly formatChars?: { [key: string]: string };
}

export interface InputPhoneStateReturn extends CommonStateReturn {
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly masks: MaskList[];
  readonly maskLabel: string;
  readonly maskStr: string;
  readonly maskCountryCode: string;
  readonly maskCode: string;
  readonly onMaskChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  readonly flag: FlagIconCode | null;
}

export interface InputSelectStateReturn extends CommonStateReturn {
  readonly onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface DobField {
  readonly label: string;
  readonly value?: string;
  readonly onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  readonly options: string[];
}

/**
 * Months are localized as object-tuples <monthNumber, monthName>
 * so we need to modify the type declaration a bit.
 */
interface MonthField extends Omit<DobField, 'options'> {
  readonly options: Array<Record<string, string>>;
}

export interface InputDobStateReturn extends WrapperProps {
  readonly day: DobField;
  readonly month: MonthField;
  readonly year: DobField;
}

interface ValidationProps {
  readonly validationSchema?: Joi.AnySchema;
  readonly optionalValidationProps?: PassengerInfo | ReserverInfo | object;
  readonly type?: PassengerType | VehicleType;
  readonly onReady: (key: string, val: string, mask?: string) => void;
  readonly onFail?: (key: string) => void;
  readonly validateOnly?: boolean;
  readonly key: string;
  readonly mask?: string;
  readonly errorMsg?: string;
}

interface ValidationArg extends ValidationProps {
  readonly state: string;
  readonly setError: (val: string | undefined) => void;
}

interface CommonStateSettings extends PassedProps, ValidationProps {
  readonly initial?: string;
  readonly forceValidation?: boolean;
}

interface InputSelectStateSettings extends CommonStateSettings {}

interface InputDobStateSettings extends CommonStateSettings {
  readonly dayLabel: string;
  readonly monthLabel: string;
  readonly yearLabel: string;
  readonly firstDepartureDateTime: Date;
}

interface InputGroupStateSettings extends CommonStateSettings {
  readonly mask?: string;
  readonly formatChars?: { [key: string]: string };
}

interface PhoneMask {
  readonly label: string;
  readonly value: string;
  readonly mask: string;
  readonly flag: FlagIconCode | null;
  readonly countryCallCode: string;
}

interface MaskList {
  readonly label?: string;
  readonly list: PhoneMask[];
}

interface InputPhoneStateSettings extends CommonStateSettings {
  readonly masks: MaskList[];
  readonly initialMask?: string;
  readonly maskLabel: string;
}

export const setInitialValueBasedOnLanguage = (language: Language, initialMask?: string) => {
  switch (language) {
    case LanguageType.DE:
      return 'DE';
    case LanguageType.FI:
      return 'FI';
    case LanguageType.SV:
      return 'SE';
    case LanguageType.PL:
      return 'PL';
    case LanguageType.EN:
    default:
      return initialMask ? initialMask : 'EN';
  }
};

const validation: (arg: ValidationArg) => void = ({
  validationSchema,
  type,
  optionalValidationProps,
  key,
  state,
  setError,
  onFail,
  errorMsg,
  onReady,
  validateOnly,
  mask,
}) => {
  if (validationSchema) {
    const validation = validationSchema.validate({
      [key]: state,
      ...(type ? { type } : {}),
      ...optionalValidationProps,
    });

    if (validation && (validation.error || validation.errors)) {
      logger.info(`Input ${key} error`, validation);
      setError(errorMsg);
      !validateOnly && onFail && onFail(key);
    } else {
      setError(undefined);
      !validateOnly && onReady && onReady(key, state, mask);
    }
  } else {
    setError(undefined);
    !validateOnly && onReady && onReady(key, state, mask);
  }
};

const useCommonState = (initial?: string, forceValidation?: boolean, validationProps?: ValidationProps) => {
  const [error, setError] = useState<string | undefined>();
  const [state, setState] = useState(initial || '');

  useEffect(() => {
    if (validationProps && forceValidation) validation({ validateOnly: true, state, setError, ...validationProps });
  }, [forceValidation, validationProps, state]);

  return { error, setError, state, setState };
};

export const useInputGroupState: (arg: InputGroupStateSettings) => InputGroupStateReturn = ({
  initial,
  label,
  htmlFor,
  mask,
  hint,
  forceValidation,
  upperCase,
  formatChars,
  ...validationProps
}) => {
  const { error, setError, state, setState } = useCommonState(initial, forceValidation, validationProps);
  const [currentValue, setCurrentValue] = useState(state);

  useEffect(() => {
    if (initial) {
      setState(initial);
      setCurrentValue(initial);
    }
  }, [initial, setState]);

  useEffect(() => {
    setState(currentValue);
  }, [currentValue, setState]);

  const restrictPolishCharacters = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Regex to remove polish characters
    const sanitizedValue = e.target.value.replace(/[Ą,Ć,Ę,Ł,Ń,Ó,Ś,Ź,Ż,ą,ć,ę,ł,ń,ó,ś,ź,ż]/gi, '');

    setCurrentValue(upperCase ? sanitizedValue.toUpperCase() : sanitizedValue);
  };

  const onBlur = () =>
    validation({
      mask,
      state,
      setError,
      ...validationProps,
    });

  return {
    label,
    htmlFor,
    value: currentValue,
    onChange: (e) => restrictPolishCharacters(e),
    onBlur,
    error,
    mask,
    hint,
    formatChars,
  };
};

const findMask = (masks: MaskList[], value?: string) =>
  value
    ? masks
        .map(({ list }) => list)
        .flat()
        .find((m) => m.value === value)
    : undefined;

export const useInputPhoneState: (arg: InputPhoneStateSettings) => InputPhoneStateReturn = ({
  initial,
  initialMask,
  maskLabel,
  label,
  htmlFor,
  masks,
  hint,
  forceValidation,
  ...validationProps
}) => {
  const { error, setError, state, setState } = useCommonState(initial, forceValidation, validationProps);
  const { language } = useContext(LanguageContext);

  const [maskCode, setMaskCode] = useState('');

  useEffect(() => {
    initial && setState(initial);
  }, [initial]);

  useEffect(() => {
    if (!!state) {
      initialMask && setMaskCode(initialMask);
    } else {
      initialMask && setMaskCode(setInitialValueBasedOnLanguage(language, initialMask));
    }
  }, [initialMask]);

  const currentMask = findMask(masks, maskCode);

  const onBlur = (e?: any, newMask?: PhoneMask, newState?: string) =>
    validation({
      mask: newMask?.countryCallCode || currentMask?.countryCallCode,
      state: newState || state,
      setError,
      ...validationProps,
    });

  return {
    label,
    htmlFor,
    value: state,
    onChange: (e) => setState(e.target.value),
    onBlur,
    error,
    maskCode,
    maskStr: currentMask?.mask || '',
    maskCountryCode: currentMask?.countryCallCode || '',
    masks,
    flag: currentMask?.flag || null,
    onMaskChange: (e) => {
      const code = e.target.value;
      const newMask = findMask(masks, e.target.value);
      const newState = state.replace(currentMask?.countryCallCode || '', newMask?.countryCallCode || '');
      setState(newState);
      code && setMaskCode(code);
      onBlur(null, newMask, newState);
    },
    hint,
    maskLabel,
  };
};

export const useInputSelectState: (arg: InputSelectStateSettings) => InputSelectStateReturn = ({
  initial,
  label,
  htmlFor,
  hint,
  forceValidation,
  ...validationProps
}) => {
  const { error, setError, state, setState } = useCommonState(initial, forceValidation, validationProps);

  useEffect(() => {
    initial && setState(initial);
  }, [initial, setState]);

  const handleValidation = (state: string) =>
    validation({
      state,
      setError,
      ...validationProps,
    });

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setState(value);
    handleValidation(value);
  };

  const onBlur = () => handleValidation(state);

  return {
    label,
    htmlFor,
    value: state,
    onChange,
    onBlur,
    error,
    hint,
  };
};

const DATE_SEPARATOR = '/';

export const useInputDobState: (arg: InputDobStateSettings) => InputDobStateReturn = ({
  label,
  htmlFor,
  forceValidation,
  dayLabel,
  monthLabel,
  yearLabel,
  initial,
  firstDepartureDateTime,
  ...validationProps
}) => {
  const { error, setError, state, setState } = useCommonState(undefined, forceValidation, validationProps);

  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');

  const [possibleYears, setPossibleYears] = useState<string[]>([]);

  const defaultStartYear = 1900;

  useEffect(() => {
    if (initial) {
      const stripLeadingZero = (value: string) => value.replace(/^[0]+/g, '');

      const [initialDay, initialMonth, initialYear] = initial.split(DATE_SEPARATOR);

      setDay(stripLeadingZero(initialDay));
      setMonth(stripLeadingZero(initialMonth));
      setYear(initialYear);
    }
  }, [initial]);

  useEffect(() => {
    if (!!day && !!month && !!year) {
      const withLeadingZero = (value: string) => (value.length > 1 ? value : '0' + value);
      setState([withLeadingZero(day), withLeadingZero(month), year].join(DATE_SEPARATOR));
    }
  }, [day, month, year]);

  useEffect(() => {
    const handleValidation = () =>
      validation({
        state,
        setError,
        ...validationProps,
      });

    !!state && handleValidation();
  }, [state]);

  useEffect(() => {
    const year = getYear(firstDepartureDateTime);

    const getYears = (type?: PassengerType | VehicleType | undefined) => {
      if (type && type in passengerAge) {
        const { min, max } = passengerAge[type as PassengerType];

        const startYear = max ? year - max - 1 : defaultStartYear;
        const endYear = min ? year - min : year;

        return new Array(endYear - startYear + 1).fill(endYear).map((a, i) => (a - i).toString());
      }

      return [];
    };

    setPossibleYears(getYears(validationProps.type));
  }, [validationProps.type, firstDepartureDateTime]);

  const onChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLSelectElement>) =>
      setter(e.target.value);

  const { formats } = useContext(LanguageContext);

  const days = new Array(31).fill(1).map((a, i) => (i + a).toString());

  const months: Array<Record<string, string>> = new Array(12).fill(1).map((a, i) => {
    const monthNumber = a + i;
    const monthName = formats.i18n.localizeMonth(monthNumber);

    return { monthName, monthNumber: monthNumber.toString() };
  });

  return {
    label,
    htmlFor,
    day: {
      label: dayLabel,
      value: day,
      onChange: onChange(setDay),
      options: days,
    },
    month: {
      label: monthLabel,
      value: month,
      onChange: onChange(setMonth),
      options: months,
    },
    year: {
      label: yearLabel,
      value: year,
      onChange: onChange(setYear),
      options: possibleYears,
    },
    error,
  };
};
