import React from 'react';
import styled from 'styled-components';

import { measurement } from '../../helpers/vars';
import { animation, style } from '../../helpers/mixins';

import { Lead, P } from '../Text';
import Icon from '../Icon';

import { IllustrationName } from './Illustration';

interface OptionCommon {
  readonly uuid: string;
  readonly productcode: string;
  readonly suboptions?: never;
}

interface DimensionOption extends OptionCommon {
  readonly length: string;
  readonly height: string;
  readonly example: string;
  readonly name?: string;
  readonly illustration?: IllustrationName;
}

interface SimpleOption extends OptionCommon {
  readonly length?: never;
  readonly height?: never;
  readonly example?: never;
  readonly name: string;
}

interface OptionBranch {
  readonly example?: string;
  readonly name: string;
}

export interface ItemProps {
  readonly title?: string;
  readonly showDimensionTitle?: boolean;
}

export type SuboptionType = DimensionOption | SimpleOption;

const Wrapper = styled.div`
  text-align: left;
`;

export const Name = styled(Lead)`
  ${style.link.common};
  ${style.link.default};
  ${style.link.subtle};
  transition: ${animation.transition('background')};
`;

const NameItem = styled.span`
  & + & {
    padding: 0 0 0 0.5rem;
  }
`;

const Title = styled(P)`
  margin-bottom: -${measurement.space.text.copy};
`;

const Example = styled(P)`
  margin-top: -${measurement.space.text.copy};
`;

export const Item: React.FunctionComponent<ItemProps & (SuboptionType | OptionBranch)> = ({
  title,
  name,
  showDimensionTitle,
  ...option
}) => (
  <Wrapper>
    {title && <Title>{title}</Title>}
    {'length' in option ? (
      <>
        <Name>
          {showDimensionTitle && name && <NameItem>{name}:</NameItem>}
          <NameItem>
            <Icon name="horizontal" label="Length" inline inheritColor />
            {option.length}
          </NameItem>
          <NameItem>
            <Icon name="vertical" label="Height" inline inheritColor />
            {option.height}
          </NameItem>
        </Name>
        <Example>{option.example}</Example>
      </>
    ) : (
      <>
        <Name>{name}</Name>
        {option.example && <Example>{option.example}</Example>}
      </>
    )}
  </Wrapper>
);

export default Item;
