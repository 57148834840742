import React, { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';
import Measure, { ContentRect } from 'react-measure';
import styled, { css } from 'styled-components';
import { disableBodyScroll } from 'body-scroll-lock';

import { measurement } from '../vars';
import { responsive } from '../mixins';

const ScrollContext = createContext(0);
const ScrollProvider = ScrollContext.Provider;

interface ContainerState {
  readonly start: number;
  readonly end: number;
  readonly windowHeight: number;
  // has been in view (half viewport)
  readonly scrolledToView: boolean;
  // currently in view
  readonly inView: boolean;
}

const initialContainer: ContainerState = {
  start: 0,
  end: 0,
  windowHeight: window.innerHeight,
  scrolledToView: false,
  inView: false,
};

const ContainerContext = createContext<ContainerState>(initialContainer);
const ContainerProvider = ContainerContext.Provider;

interface ScrollProps {
  readonly footer?: boolean;
  readonly full?: boolean;
}

const ScrollContainer = styled.div<ScrollProps & { ref?: any }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin-bottom: env(safe-area-inset-bottom);
  overflow: hidden scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  ${({ footer, full }) =>
    full
      ? css`
          top: 0;
          bottom: 0;
        `
      : css`
          top: ${measurement.size.tabbar.s};
          bottom: ${footer ? measurement.size.footerbar.s : 0};

          ${responsive.fontSize(css`
            top: ${measurement.size.tabbar.l};
            bottom: ${footer ? measurement.size.footerbar.l : 0};
          `)}

          ${responsive.wide(css`
            bottom: ${footer ? measurement.size.footerbar.xl : 0};
          `)}
        `}
`;

const Scroll: FunctionComponent<ScrollProps> = ({ footer = true, full, children }) => {
  const [{ enabled, offset }, setState] = useState({ enabled: true, offset: 0 });
  const ref = React.useRef<HTMLDivElement>();
  const scrollContainer = ref.current;

  useEffect(() => {
    if (enabled && scrollContainer) {
      disableBodyScroll(scrollContainer);
      setState({ offset, enabled: false });
    }
  }, [enabled, scrollContainer]);

  const onScroll = () => scrollContainer && setState({ enabled, offset: scrollContainer.scrollTop });

  return (
    <ScrollProvider value={offset}>
      <ScrollContainer {...{ ref, onScroll, footer, full }}>{children}</ScrollContainer>
    </ScrollProvider>
  );
};

interface ContainerProps {
  readonly id?: string;
  readonly handleScrolledToView?: (scrolledToView: boolean) => void;
}

const Container: FunctionComponent<ContainerProps> = ({ children, id, handleScrolledToView }) => {
  const [container, setContainer] = useState<ContainerState>(initialContainer);
  const scroll = useContext(ScrollContext);

  const onResize: (arg: ContentRect) => void = ({ offset }) => {
    const start = offset?.top || 0;
    const end = start + (offset?.height || 0);
    const height = window.innerHeight;

    setContainer((previous) => ({ ...previous, start, end, height }));
  };

  useEffect(() => {
    const scrolledToView = !!scroll && scroll >= container.start - container.windowHeight / 2;
    const inView = scroll >= container.start && scroll <= container.end;

    setContainer((previous) => ({ ...previous, scrolledToView, inView }));
  }, [scroll, container.start, container.end, container.windowHeight]);

  useEffect(() => {
    handleScrolledToView && handleScrolledToView(container.scrolledToView);
  }, [handleScrolledToView, container.scrolledToView]);

  return (
    <ContainerProvider value={container}>
      <Measure offset {...{ onResize }}>
        {({ measureRef }) => (
          <div style={{ position: 'relative' }} ref={measureRef} {...{ id }}>
            {children}
          </div>
        )}
      </Measure>
    </ContainerProvider>
  );
};

interface StickyProps {
  readonly isSticky: boolean;
  readonly isBottom: boolean;
  readonly height: number;
}

interface ItemProps {
  readonly children: (arg: StickyProps) => React.ReactNode;
}

const Item: FunctionComponent<ItemProps> = ({ children }) => {
  const [{ top, height }, setState] = useState({ top: 0, height: 0 });

  const container = useContext(ContainerContext);
  const scroll = useContext(ScrollContext);

  const isBottom = scroll > container.end - height;
  const isSticky = !!height && container.inView && !isBottom && scroll >= container.start + top;

  const onResize: (arg: ContentRect) => void = ({ offset }) => {
    if (isSticky) {
      setState({ height, top: offset?.top || 0 });
    } else {
      setState({ top, height: offset?.height || 0 });
    }
  };

  return (
    <>
      <Measure offset scroll {...{ onResize }}>
        {({ measureRef }) => (
          <div ref={measureRef}>
            {children({
              isSticky,
              isBottom,
              height,
            })}
          </div>
        )}
      </Measure>
      {(isSticky || isBottom) && <div style={{ position: 'relative', height }} />}
    </>
  );
};

export default {
  Container,
  Item,
  Scroll,
};
