import React, { useEffect, useState } from 'react';
import { DragDropContext, BeforeCapture, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { IconName } from '../../Icon';
import { Subgrid } from '../../Container';

import Group from '../Group';

import PassengerItem from './PassengerItem';
import { settings } from '../../../../settings';

type PassengerType = 'ADULT' | 'JUNIOR' | 'CHILD' | 'INFANT' | 'PET';
type AccommodationType = 'CABIN' | 'BED' | 'CHAIR';

interface Passenger {
  readonly id: number;
  readonly type: PassengerType;
}

type PassengerList = number[];

interface Accommodation {
  readonly id: number;
  readonly type: AccommodationType;
  readonly passengers: PassengerList;
  readonly maxHostedPeople: number;
  readonly maxPets: number;
  readonly maxWithoutBed: number;
}

interface PassengerProps {
  readonly icon?: IconName;
  readonly children?: React.ReactNode;
}

interface PassengerTypeInfo extends Passenger {
  readonly canBeAlone?: boolean;
  readonly isPet?: boolean;
  readonly props: PassengerProps;
}

interface LogicProps {
  readonly passengers: Passenger[];
  readonly accommodations: Accommodation[];
  readonly setAccommodations: (arg: Accommodation[]) => void;
  readonly passengerTypes: {
    [key in PassengerType]: PassengerTypeInfo;
  };
}

export interface PassengerState extends PassengerTypeInfo {}

interface PossibleState {
  readonly id: number;
  readonly accommodations: number[];
  readonly new: AccommodationType[];
}

const Placeholder = styled.span`
  display: none;
  position: absolute;
`;

const Logic: React.FunctionComponent<LogicProps> = ({
  passengers,
  accommodations,
  setAccommodations,
  passengerTypes,
}) => {
  const [passengerInfo, setPassengerInfo] = useState<PassengerState[]>([]);
  const [possibleActions, setPossibleActions] = useState<PossibleState[]>([]);
  const [currentlyPossible, setCurrentlyPossible] = useState<PossibleState | undefined>(undefined);

  useEffect(() => {
    setPassengerInfo(
      passengers.map((passenger) => ({
        ...passenger,
        ...passengerTypes[passenger.type],
      }))
    );
  }, [passengers, passengerTypes]);

  useEffect(() => {
    setPossibleActions(
      passengerInfo.map((passenger) => {
        const accommodation = accommodations.find((accommodation) => accommodation.passengers.includes(passenger.id));

        const canBeMoved =
          accommodation && passenger.canBeAlone
            ? accommodation.passengers.length <= 1
              ? true
              : accommodation.passengers
                  .filter((p) => p !== passenger.id)
                  .map((p) => passengerInfo.find((info) => p === info.id)?.canBeAlone)
                  .find((p) => p === true) || false
            : true;

        const spaceInCabin = (cabin: Accommodation, passenger: PassengerTypeInfo) => {
          const currentPassengerInfo = cabin.passengers.map((p) => passengerInfo.find((info) => p === info.id));

          const { needsBed, withoutBed, pets } = currentPassengerInfo.reduce(
            (acc, p) => ({
              ...acc,
              ...(p?.canBeAlone
                ? { needsBed: acc.needsBed + 1 }
                : p?.isPet
                ? { pets: acc.pets + 1 }
                : { withoutBed: acc.withoutBed + 1 }),
            }),
            { needsBed: 0, withoutBed: 0, pets: 0 }
          );

          return passenger.canBeAlone
            ? needsBed < cabin.maxHostedPeople && needsBed + withoutBed < cabin.maxHostedPeople + cabin.maxWithoutBed
            : passenger.isPet
            ? pets < cabin.maxPets
            : needsBed + withoutBed < cabin.maxHostedPeople + cabin.maxWithoutBed;
        };

        // is there space in the cabin
        const possibleAccommodations =
          accommodation && canBeMoved
            ? accommodations
                .filter((a) => a.type === 'CABIN' && a.id !== accommodation.id && spaceInCabin(a, passenger))
                .map((a) => a.id)
            : [];

        const possibleNew = (
          accommodation && canBeMoved && passenger.canBeAlone
            ? [
                ...(accommodation.type !== 'CABIN' || accommodation.passengers.length > 1 ? ['CABIN'] : []),
                ...(accommodation.type !== 'BED' ? ['BED'] : []),
                ...(accommodation.type !== 'CHAIR' ? ['CHAIR'] : []),
              ]
            : []
        ) as AccommodationType[];

        return {
          id: passenger.id,
          accommodations: possibleAccommodations,
          new: possibleNew,
        };
      })
    );
  }, [accommodations, passengerInfo]);

  const setAndCleanUp = (newAccommodations: Accommodation[]) =>
    setAccommodations(
      newAccommodations
        .filter((a) => a.passengers.length)
        .map((a, i) => ({
          ...a,
          id: i + 1,
        }))
    );

  const moveTo = (passenger: number, source: number, destination: number) =>
    setAndCleanUp(
      accommodations.map((a) =>
        a.id === source || a.id === destination
          ? {
              ...a,
              passengers:
                a.id === source
                  ? a.passengers.filter((p) => p !== passenger)
                  : [...a.passengers, passenger].sort((a, b) => a - b),
            }
          : a
      )
    );

  const moveToNew = (passenger: number, source: number, type: AccommodationType) =>
    setAndCleanUp([
      ...accommodations.map((a) =>
        a.id === source ? { ...a, passengers: a.passengers.filter((p) => p !== passenger) } : a
      ),
      {
        id: accommodations.length,
        type,
        maxHostedPeople: type === 'CABIN' ? 4 : 1,
        maxWithoutBed: type === 'CABIN' ? 2 : 0,
        maxPets: type === 'CABIN' ? settings.maxPetsInCabin : 0,
        passengers: [passenger],
      },
    ]);

  const getPassengerActions = (passengerId: number, accommodationId: number) => {
    const possible = possibleActions.find((info) => passengerId === info.id);

    if (possible) {
      const cabinActions = possible.accommodations.map((to) => ({
        children: `Move to cabin ${to}`,
        onClick: () => moveTo(passengerId, accommodationId, to),
      }));

      const newAccommodationActions = possible.new.map((type) => ({
        children: `Move to a new ${type}`,
        onClick: () => moveToNew(passengerId, accommodationId, type),
      }));

      return [...cabinActions, ...newAccommodationActions];
    }

    return [];
  };

  const dragDropResponders = {
    onBeforeCapture: ({ draggableId }: BeforeCapture) => {
      if (draggableId) {
        setCurrentlyPossible(possibleActions.find((p) => p.id === parseInt(draggableId)));
      }
    },
    onDragEnd: ({ draggableId, source, destination }: DropResult) => {
      if (draggableId && source.droppableId && destination?.droppableId) {
        const passengerId = parseInt(draggableId);
        const sourceId =
          parseInt(source.droppableId) || accommodations.find(({ passengers }) => passengers.includes(passengerId))?.id;
        const destinationId = parseInt(destination.droppableId) || destination.droppableId;

        if (passengerId && sourceId && destinationId) {
          if (typeof destinationId === 'number') {
            moveTo(passengerId, sourceId, destinationId);
          } else {
            moveToNew(passengerId, sourceId, destinationId as AccommodationType);
          }
        }
      }

      setCurrentlyPossible(undefined);
    },
  };

  return (
    <Subgrid>
      <DragDropContext {...dragDropResponders}>
        {accommodations
          .filter((a) => a.type === 'CABIN')
          .map((accommodation) => (
            <Droppable
              droppableId={`${accommodation.id}`}
              key={accommodation.id}
              isDropDisabled={!currentlyPossible?.accommodations.includes(accommodation.id)}
            >
              {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
                <Group
                  placeholder={<Placeholder>{placeholder}</Placeholder>}
                  title={`${accommodation.type} ${accommodation.id}`}
                  innerRef={innerRef}
                  isDropArea={currentlyPossible?.accommodations.includes(accommodation.id)}
                  {...{ isDraggingOver, ...droppableProps }}
                >
                  {accommodation.passengers.map((passengerId, index) => (
                    <PassengerItem
                      key={passengerId}
                      actions={getPassengerActions(passengerId, accommodation.id)}
                      accommodationId={accommodation.id}
                      {...{ index, passengerId, passengerInfo }}
                    />
                  ))}
                </Group>
              )}
            </Droppable>
          ))}
        {(['CABIN', 'BED', 'CHAIR'] as AccommodationType[]).map((type) => {
          const isDropArea = currentlyPossible?.new.includes(type) || false;
          const passengers =
            type !== 'CABIN'
              ? accommodations
                  .filter((a) => a.type === type)
                  .reduce(
                    (acc, a) => [...acc, ...a.passengers.map((p) => ({ accommodationId: a.id, passengerId: p }))],
                    [] as { passengerId: number; accommodationId: number }[]
                  )
              : ([] as { passengerId: number; accommodationId: number }[]);

          return (
            <Droppable key={type} droppableId={type} isDropDisabled={!isDropArea}>
              {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
                <Group
                  unwrapped
                  placeholder={<Placeholder>{placeholder}</Placeholder>}
                  title={`New ${type}`}
                  innerRef={innerRef}
                  {...{ isDraggingOver, isDropArea, ...droppableProps }}
                >
                  {passengers.length
                    ? passengers.map(({ passengerId, accommodationId }, index) => (
                        <PassengerItem
                          unwrapped
                          key={passengerId}
                          actions={getPassengerActions(passengerId, accommodationId)}
                          {...{ index, accommodationId, passengerId, passengerInfo }}
                        />
                      ))
                    : null}
                </Group>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
    </Subgrid>
  );
};

export default Logic;
