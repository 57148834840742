import styled from 'styled-components';

import { Cell } from '../Container';

const Container = styled(Cell)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default Container;
