import React, { useContext, useEffect, useState } from 'react';
import { DateUtils, Modifier, Modifiers } from 'react-day-picker';
import { LanguageContext } from '../../../Language.context';
import Wrapper, { WrapperParentProps } from './Wrapper';

export interface RangeDates {
  readonly from?: Date;
  readonly to?: Date;
}

type DateRange = {
  from?: Date;
  to?: Date;
};

interface DatePickerProps extends WrapperParentProps {
  readonly dates: RangeDates;
  readonly setDates: React.Dispatch<React.SetStateAction<RangeDates>>;
}

const Range: React.FunctionComponent<DatePickerProps> = ({
  dates,
  setDates,
  label,
  submit,
  children,
  title,
  ...props
}) => {
  const [selected, setSelected] = useState([dates.from && dates.from, dates]);
  const [openedFromReturn, setOpenedFromReturn] = useState<boolean>(false);
  const [value, setValue] = useState(['', '']);

  const { localeData } = useContext(LanguageContext);

  useEffect(() => {
    setSelected([dates.from && dates.from, dates]);
  }, [dates]);

  useEffect(() => {
    const localeOptions: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };

    const toLocale = (date: Date) => date.toLocaleDateString(localeData.locale, localeOptions);
    const { from, to } = dates;

    setValue([from ? toLocale(from) : '', to ? toLocale(to) : '']);
  }, [dates, localeData.locale]);

  // Note! This function below is customised version of DateUtils.addDayToRange.
  // See node_modules/react-day-picker/src/DateUtils.js or
  // https://github.com/gpbl/react-day-picker/blob/aeb3e9e8f8aa4dc0cb8ed8400b0a388946e2104e/src/DateUtils.js#L156
  //
  const addDayToRange = (day: Date, range: DateRange): RangeDates => {
    let { from, to } = range;

    if (!from) {
      from = day;
    } else if (DateUtils.isDayBefore(day, from)) {
      from = day;
    } else if (to && DateUtils.isDayAfter(day, to)) {
      to = day;
    } else if (to && DateUtils.isSameDay(day, to)) {
      from = day;
      to = day;
    } else if (DateUtils.isSameDay(day, from)) {
      from = day;
      to = day;
    } else {
      if (openedFromReturn) {
        to = day;
      } else {
        from = day;
      }
    }

    return { from, to };
  };

  const onDayClick = (day: Date, modifiers: any) => {
    if (modifiers.disabled) return;

    const updated = addDayToRange(day, dates);
    // console.log('updated range', updated)
    setDates(updated);
  };

  return (
    <Wrapper
      selectedDays={selected as unknown as Modifier}
      modifiers={dates as Partial<Modifiers>}
      month={openedFromReturn ? dates.to : dates.from}
      onClick={[() => setOpenedFromReturn(false), () => setOpenedFromReturn(true)]}
      {...{ value, label, title, submit, onDayClick }}
      {...props}
    />
  );
};

export default Range;
