import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { Currency } from '../fsm/types';

interface DataLayerEvent {
  name: string;
  id: string;
  category: string;
  price?: string;
  brand?: string;
  variant?: string;
  position?: number;
}

interface GA4DataLayerEvent {
  item_id: string;
  item_name: string;
  currency?: Currency;
  index?: number;
  item_brand?: string;
  item_category: string;
  item_variant?: string;
  price?: string;
  item_list_name?: string;
}

export interface Impression extends DataLayerEvent {
  list?: string;
}

export interface Impressions {
  currency: Currency;
  impressions: Impression[];
}

export interface Product extends DataLayerEvent {
  coupon?: string;
  quantity?: number;
}

export interface GA4Product extends GA4DataLayerEvent {
  coupon?: string;
  quantity?: number;
}

export interface ProductClick {
  actionField?: string;
  currency?: Currency;
  products: Product[];
}

export interface GA4ProductClick {
  currency?: Currency;
  products: GA4Product[];
}

export interface AddToCart {
  actionField?: string;
  currency?: Currency;
  products: Product[];
}

export interface GA4AddToCart {
  actionField?: string;
  currency?: Currency;
  products: GA4Product[];
}

export interface RemoveFromCart {
  actionField?: string;
  currency?: Currency;
  products: Product[];
}

export interface GA4RemoveFromCart {
  actionField?: string;
  currency?: Currency;
  products: GA4Product[];
}

declare global {
  interface Window {
    __gtm_queue: DataLayerArgs[];
  }
}

const gtmId = process.env.REACT_APP_GTM_ID;

window.__gtm_queue = window.__gtm_queue || [];

function addProductEventHandler(eventName: string, propName?: string) {
  return (event: { actionField?: { [key: string]: any }; currency?: Currency; products: Product[] }) => {
    const actionField = event.actionField ? { ...event.actionField } : {};
    const currency = event.currency ? { currencyCode: event.currency } : {};

    window.__gtm_queue.push({
      dataLayer: {
        event: eventName,
        ecommerce: {
          ...currency,
          [propName || eventName]: {
            actionField,
            products: event.products,
          },
        },
      },
    });
  };
}

function addGA4ProductEventHandler(eventName: string) {
  return (event: { products: GA4Product[] }) => {
    window.__gtm_queue.push({
      dataLayer: {
        event: eventName,
        ecommerce: {
          items: event.products,
        },
      },
    });
  };
}

export default {
  addToCart: addProductEventHandler('addToCart', 'add'),
  ga4AddToCart: addGA4ProductEventHandler('add_to_cart'),

  productClick: addProductEventHandler('productClick', 'click'),
  ga4ProductClick: addGA4ProductEventHandler('select_item'),

  removeFromCart: addProductEventHandler('removeFromCart', 'remove'),
  ga4RemoveFromCart: addGA4ProductEventHandler('remove_from_cart'),

  checkout: addProductEventHandler('checkout'),
  beginCheckout: addGA4ProductEventHandler('begin_checkout'),
  addShippingInformation: addGA4ProductEventHandler('add_shipping_info'),
  addPaymentInformation: addGA4ProductEventHandler('add_payment_info'),

  detail: addProductEventHandler('detail'),
  ga4Detail: addGA4ProductEventHandler('view_item'),

  impressions: ({ currency, impressions }: Impressions) => {
    window.__gtm_queue.push({
      dataLayer: {
        event: 'impressions',
        ecommerce: {
          impressions,
          currencyCode: currency,
        },
      },
    });
  },

  ga4Impressions: (products: GA4Product[]) => {
    window.__gtm_queue.push({
      dataLayer: {
        event: 'view_item_list',
        ecommerce: {
          items: products,
        },
      },
    });
  },

  purchase: ({
    currency,
    products,
    referenceHash,
    totalPrice,
  }: {
    currency: Currency;
    products: Product[];
    referenceHash?: string;
    totalPrice: string;
  }) => {
    window.__gtm_queue.push({
      dataLayer: {
        event: 'transaction',
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              id: referenceHash,
              affiliation: 'Online Store',
              revenue: totalPrice,
            },
            products,
          },
        },
      },
    });
  },

  ga4Purchase: ({
    currency,
    products,
    referenceHash,
    totalPrice,
  }: {
    currency: Currency;
    products: GA4Product[];
    referenceHash?: string;
    totalPrice: string;
  }) => {
    window.__gtm_queue.push({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          currency,
          transaction_id: referenceHash,
          affiliation: 'Online Store',
          value: totalPrice,
          items: products,
        },
      },
    });
  },

  clearEcommerce: () => {
    window.__gtm_queue.push({
      dataLayer: {
        ecommerce: null,
      },
    });
  },

  send: () => {
    window.__gtm_queue.splice(0).forEach((event) => gtmId && TagManager.dataLayer(event));
  },
};
