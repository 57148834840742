import { assign } from 'xstate';
import gtm from '../../analytics/gtm';
import { Catalog, ExtendedOnboard, maybeChargeInfo, Onboards } from '../types';
import { onboardProduct, formAnalyticsProductName } from '../utils/analyticsUtils';
import { createDefaultPassengerIndexes, getOnboard } from '../utils/productUtils';
import { indexToLeg, onCatalogSelectedSailing, onCatalogSomeSelectedSailings } from '../utils/sailingUtils';
import { CatalogContext } from './catalogMachine';

const OnboardActions = {
  addOnboard: assign<CatalogContext>({
    catalogs: ({ catalogs, searchParams, passengers }: CatalogContext, evt: any): Catalog[] =>
      onCatalogSomeSelectedSailings(catalogs, evt.legs, (catalog, selected) => {
        const onboardAsProduct = getOnboard(selected, evt.code);
        if (!onboardAsProduct || onboardAsProduct.capacity?.available == null) {
          return catalog;
        }

        const type = onboardAsProduct.type || '';

        // UA
        gtm.clearEcommerce();

        const product = onboardProduct({
          amount: 1,
          chargeInfo: maybeChargeInfo(selected.tariff, onboardAsProduct),
          code: evt.code,
          index: catalog.index,
          type,
          name: formAnalyticsProductName(selected.products, type, evt.code),
          brand: `${selected.departurePort} - ${selected.arrivalPort}`,
        });
        gtm.productClick({
          actionField: { list: 'Onboard' },
          products: [product],
        });

        gtm.send();

        // GA4
        gtm.clearEcommerce();

        gtm.ga4ProductClick({
          products: [
            {
              currency: searchParams.currency,
              item_id: product.id,
              item_name: product.name,
              index: product.position,
              item_brand: product.brand,
              item_category: 'onboard',
              item_variant: product.variant,
              price: product.price,
              quantity: product.quantity,
            },
          ],
        });
        gtm.send();

        if (
          !selected.onboards ||
          (selected.onboards.length <= 0 && onboardAsProduct.capacity.available - (evt.amount || 1) >= 0)
        ) {
          selected.onboards = [
            {
              code: onboardAsProduct.code,
              type: onboardAsProduct.type,
              legs: [indexToLeg(catalog.index)],
              amount: evt.amount || 1,
              passengerIndex: createDefaultPassengerIndexes(onboardAsProduct, evt.amount, passengers),
            },
          ];
        } else if (Array.isArray(selected.onboards)) {
          const i = selected.onboards.findIndex((_) => _.code === evt.code);

          if (i === -1) {
            selected.onboards.push({
              code: onboardAsProduct.code,
              type: onboardAsProduct.type,
              legs: [indexToLeg(catalog.index)],
              amount: evt.amount || 1,
              passengerIndex: createDefaultPassengerIndexes(onboardAsProduct, evt.amount, passengers),
            });
          } else {
            selected.onboards[i] = {
              ...selected.onboards[i],
              amount:
                onboardAsProduct.capacity.available &&
                onboardAsProduct.capacity.available - ((evt.amount || 1) + selected.onboards[i].amount) >= 0
                  ? selected.onboards[i].amount + (evt.amount || 1)
                  : selected.onboards[i].amount,
              passengerIndex: createDefaultPassengerIndexes(
                onboardAsProduct,
                onboardAsProduct.capacity.available &&
                  onboardAsProduct.capacity.available - ((evt.amount || 1) + selected.onboards[i].amount) >= 0
                  ? selected.onboards[i].amount + (evt.amount || 1)
                  : selected.onboards[i].amount,
                passengers
              ),
            };
          }
        }

        return { ...catalog, selected };
      }),
    errors: [],
  }),
  removeOnboard: assign<CatalogContext>({
    catalogs: ({ catalogs }: CatalogContext, evt: any): Catalog[] =>
      onCatalogSomeSelectedSailings(catalogs, evt.legs, (catalog, selected) => {
        if (Array.isArray(selected.onboards)) {
          return {
            ...catalog,
            selected: {
              ...selected,
              onboards: getOnboards(selected.onboards, evt.code, evt.amount),
            },
          };
        } else {
          return catalog;
        }
      }),
    errors: [],
  }),
  removeUserSelectedMeals: assign<CatalogContext>({
    catalogs: ({ catalogs }: CatalogContext, evt: any): Catalog[] => {
      return onCatalogSelectedSailing(catalogs, evt, (catalog, selected) => {
        if (Array.isArray(selected.onboards)) {
          return {
            ...catalog,
            selected: {
              ...selected,
              onboards: selected.onboards.filter((item) => item.type !== Onboards.FOOD), // Rip out meals only
            },
          };
        } else {
          return catalog;
        }
      });
    },
    errors: [],
  }),
};

const getOnboards = (onboards: ExtendedOnboard[], code: string, amount: number): ExtendedOnboard[] => {
  return onboards.reduce(
    (result, onboard) =>
      onboard.code === code
        ? onboard.amount - (amount || 1) > 0
          ? // Match found and decreased amount is still > 0
            [
              ...result,
              {
                ...onboard,
                amount: onboard.amount - (amount || 1),
                passengerIndex:
                  onboard.passengerIndex?.length ?? 0 > 1 ? onboard.passengerIndex?.slice(0, -(amount || 1)) : [],
              },
            ]
          : // Amount is zero, drop the onboard
            result
        : // Different onboard item, keep it
          [...result, onboard],
    [] as ExtendedOnboard[]
  );
};

export default OnboardActions;
