import { Amplify } from 'aws-amplify';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import awsconfig from './aws-exports';
import Router from './components/Router';
import { GlobalStyle, Theme, Center } from './design-system/helpers/components/';
import { LanguageProvider } from './Language.context';
import { StoryblokProvider } from './Storyblok.context';
import styled from 'styled-components';
import Spinner from './design-system/components/Spinner';
import { inspect } from '@xstate/inspect';

const enableDevTools = () => process.env.NODE_ENV !== 'production' && process.env.REACT_APP_XSTATE_DEV_TOOLS === 'true';

enableDevTools() &&
  inspect({
    // options
    // url: 'https://stately.ai/viz?inspect', // (default)
    iframe: false, // open in new window
  });

Amplify.configure(awsconfig);

const MyBookingView = React.lazy(() => import('./components/my-booking/MyBookingView'));
const FullView = styled(Center)`
  height: 100%;
`;

const App = () => {
  return (
    <>
      <Theme.Default>
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            <Route path="/:language(de|en|fi|sv|pl)">
              <LanguageProvider>
                <StoryblokProvider>
                  <React.Suspense
                    fallback={
                      <FullView>
                        <Spinner />
                      </FullView>
                    }
                  >
                    <Switch>
                      {/* MyBooking */}
                      <Route path="/:language/my/booking/:bookingRef">
                        <MyBookingView />
                      </Route>

                      {/* Default route */}
                      <Route>
                        <Router />
                      </Route>
                    </Switch>
                  </React.Suspense>
                </StoryblokProvider>
              </LanguageProvider>
            </Route>
            <Redirect from="/" to={{ pathname: `/en`, search: window.location.search }} />
          </Switch>
        </BrowserRouter>
      </Theme.Default>
    </>
  );
};

export default App;
