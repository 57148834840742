import { Currency, Language } from '../fsm/types';

const currenciesWithRoundedUpFormatting = [Currency.SEK, Currency.EUR, Currency.PLN];

const numberFormatter = (language: Language, currency: Currency): Intl.NumberFormat => {
  // SEK / EUR customers prefer prices without decimals. Other currencies may use the default.
  const fractionDigits = currenciesWithRoundedUpFormatting.includes(currency) ? 0 : 2;

  const options: Intl.NumberFormatOptions = {
    currency,
    style: 'currency',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    localeMatcher: 'lookup',
  };

  switch (language) {
    case Language.DE:
      return new Intl.NumberFormat('de-DE', options);
    case Language.FI:
      return new Intl.NumberFormat('fi-FI', options);
    case Language.SV:
      return new Intl.NumberFormat('sv-SE', options);
    case Language.PL:
      return new Intl.NumberFormat('pl-PL', options);
    case Language.EN:
    default:
      return new Intl.NumberFormat('en-GB', options);
  }
};

export const formatMoney = (language: Language, currency: Currency) => {
  const { format } = numberFormatter(language, currency);
  return (moneyAsCents?: number): string => {
    if (moneyAsCents === undefined || moneyAsCents === null) return ''; // Do not show undefined or null value
    const money = moneyAsCents / 100;
    return format(currenciesWithRoundedUpFormatting.includes(currency) ? Math.ceil(money) : money);
  };
};

export const formatString = (template: string, ...params: React.ReactNodeArray): React.ReactNode => {
  return params.reduce(
    (tpl: React.ReactNodeArray, param, index) =>
      tpl.reduce((nodes: React.ReactNodeArray, node) => {
        if (typeof node === 'string' && node !== '') {
          const result = node.split(new RegExp(`\\{${index}\\}`, 'g'));
          return nodes.concat(
            result.reduce(
              (replaced, splitted, index) =>
                index + 1 === result.length ? replaced.concat(splitted) : replaced.concat(splitted, param),
              [] as React.ReactNodeArray
            )
          );
        }
        return nodes;
      }, []),
    [template]
  );
};
