import { PassengerType, PetType, VehicleType } from '../fsm/types';

export type GroupableType = PassengerType | PetType | VehicleType | string;

export type GroupedCount = {
  [key in GroupableType]?: number;
};

/**
 * @param countable passengers or vehicles to group and count from
 */
export const calculateCountByType = (countable: { type: GroupableType }[]) =>
  countable.reduce((byType, { type }) => ({ ...byType, [type]: (byType[type] || 0) + 1 }), {} as GroupedCount);
