import React from 'react';
import styled, { css } from 'styled-components';

import { color, measurement } from '../../helpers/vars';
import { responsive, style, typography } from '../../helpers/mixins';
import Icon, { SVG, IconName } from '../Icon';

interface WrapperProps {
  readonly secondary?: boolean;
  readonly disabled?: boolean;
  readonly skewLeft?: boolean;
}

interface IconProps {
  readonly icon?: IconName;
}

const Wrapper = styled.button<WrapperProps>`
  ${typography.h3}
  padding: ${measurement.inset.button.finish.s};
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  ${({ skewLeft }) =>
    skewLeft
      ? css`
          margin: ${measurement.space.button.back.xs};
          ${responsive.fontSize(css`
            margin: ${measurement.space.button.back.s};
          `)}
          ${responsive.wide(css`
            margin: ${measurement.space.button.back.xl};
          `)}
        `
      : css`
          margin: ${measurement.space.button.finish.xs};
          ${responsive.fontSize(css`
            margin: ${measurement.space.button.finish.s};
          `)}
          ${responsive.wide(css`
            margin: ${measurement.space.button.finish.xl};
          `)}
        `}

  ${({ secondary, skewLeft }) =>
    secondary
      ? css`
          ${style.label.shape(color.bg.default, skewLeft)}
          color: ${color.link.default};

          &::before {
            border: ${style.border.default};
          }
        `
      : css`
          ${style.label.shape(color.bg.button.default, skewLeft)}
          color: ${color.text.invert};
        `}

  padding: ${measurement.inset.button.finish.s};

  ${responsive.wide(css`
    ${style.label.l}
    padding: ${measurement.inset.button.finish.xl};
  `)}

  ${SVG} {
    color: inherit;
  }

  &[aria-disabled='true'] {
    &::before {
      background-color: #aaa;
      background-blend-mode: overlay;
    }

    color: #eee;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;

    &::before {
      ${style.focus.default}
    }
  }

  &:hover,
  &:focus {
    &::before {
      background-image: ${style.colorAsImage(color.bg.button.hover)};
      background-blend-mode: overlay;
    }
  }
`;

export const FinishWrapper = styled.div`
  display: flex;
`;

export const FinishLink: React.FunctionComponent<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & WrapperProps & IconProps
> = ({ children, icon, ...props }) => (
  <Wrapper as="a" {...props}>
    {children}
    <Icon inline name={icon ? icon : 'right'} />
  </Wrapper>
);

const FinishButton: React.FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement> & WrapperProps & IconProps
> = ({ children, icon, ...props }) => (
  <Wrapper {...props}>
    {icon && icon === 'left' && <Icon inline name={icon} />}
    {children}
    {icon && icon !== 'left' && <Icon inline name={icon} />}
  </Wrapper>
);

export default FinishButton;
