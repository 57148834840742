import React from 'react';
import styled from 'styled-components';

import { color } from '../../helpers/vars';

import Button, { StyledButton } from '../Button';
import { StyledTag, Wrapper } from '../Tag';

export interface TagAreaProps {
  title: string;
  button?: string;
  onClick?: () => void;
  disclosure?: React.ReactNode;
  tags: React.ReactNode[];
}

export const StyledTagArea = styled.section`
  display: flex;
  align-items: flex-start;
  background: ${color.bg.alt};

  ${StyledTag} {
    border-color: ${color.border.transparent};
  }

  ${Wrapper} {
    flex: 1;
  }

  ${StyledButton} {
    margin: -0.5rem;
  }
`;

const TagArea: React.FunctionComponent<TagAreaProps> = ({ title, button, onClick, disclosure, tags }) => (
  <StyledTagArea aria-label={title}>
    <Wrapper>{tags}</Wrapper>
    {button && <Button secondary icon="edit" label={button} {...{ onClick }} />}
    {disclosure}
  </StyledTagArea>
);

export default TagArea;
