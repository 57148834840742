import React from 'react';
import styled from 'styled-components';
import { H1, H2, P, Small } from '../Text';

interface TotalProps {
  readonly innerRef?: React.RefObject<HTMLParagraphElement>;
  readonly label: string;
  readonly price: string;
}

const Label = styled(P)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 0 0;

  span {
    margin-left: 1rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const Addition = styled(Small)`
  margin-top: 0;
`;

export const Subtotal: React.FunctionComponent<TotalProps> = ({ label, price, innerRef }) => (
  <Label ref={innerRef}>
    {label}{' '}
    <H2 inline as="span">
      {price}
    </H2>
  </Label>
);

export const Total: React.FunctionComponent<TotalProps & { readonly addition?: string }> = ({
  label,
  price,
  addition,
  innerRef,
}) => (
  <Wrapper ref={innerRef}>
    <Label>
      {label}{' '}
      <H1 inline as="span">
        {price}
      </H1>
    </Label>

    {addition && <Addition>{addition}</Addition>}
  </Wrapper>
);
