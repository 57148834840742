import React from 'react';
import styled, { css } from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import { math } from 'polished';

import { color, measurement } from '../helpers/vars';
import { responsive } from '../helpers/mixins';
import { Theme, StyledInput } from '../helpers/components';
import { GridKey, SubgridKey } from '../helpers/vars/measurement';

type Variant = 'search' | 'form' | 'departure' | 'text' | 'catalog-search' | undefined;

interface WrapperProps {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly variant?: Variant;
}

interface AlignProps {
  readonly itemAlign?: 'center';
  readonly textAlign?: 'center';
}

const getAlign = ({ itemAlign, textAlign }: AlignProps) => css`
  ${itemAlign &&
  css`
    align-items: ${itemAlign};
  `}

  ${textAlign &&
  css`
    text-align: ${textAlign};
  `}
`;

interface ContentProps extends AlignProps {
  readonly gridType: GridKey;
  readonly grid?: boolean;
}

const Wrap = styled.div<WrapperProps>`
  box-sizing: border-box;
  background: ${({ variant, theme }) => (variant === 'catalog-search' ? '' : theme.bg)};
  padding: ${measurement.inset.container.s};

  ${responsive.fontSize(css`
    padding: ${measurement.inset.container.l};
  `)}

  ${({ variant }) =>
    variant === 'search' &&
    css`
      position: relative;
      background-color: ${color.bg.invert.default};
      background-image: url('data:image/svg+xml;base64,${btoa(SearchBackground)}');
      background-position: center top;
      background-size: 100vw 200vw;
      background-repeat: no-repeat;
      flex: 1;

      ${StyledInput.Wrapper}, ${StyledInput.WrapperButton} {
        & + ${StyledInput.Wrapper}, & + ${StyledInput.WrapperButton} {
          text-align: right;
        }
      }

      ${responsive.wide(css`
        background-size: 100vw 50vw;
      `)}
    `}

  ${({ variant }) =>
    variant === 'catalog-search' &&
    css`
      ${StyledInput.Wrapper}, ${StyledInput.WrapperButton} {
        & + ${StyledInput.Wrapper}, & + ${StyledInput.WrapperButton} {
          text-align: right;
        }
      }
      ${StyledInput.Wrapper}, ${StyledInput.WrapperButton} {
        padding-top: 0.15rem;
      }
    `}
`;

const Content = styled.div<ContentProps>`
  display: grid;
  max-width: ${measurement.grid.mobile.width};
  grid-gap: ${measurement.grid.mobile.gap};
  margin: auto;
  grid-template: auto / minmax(0, 1fr);

  ${({ grid, gridType }) =>
    grid &&
    responsive.wide(css`
      max-width: ${measurement.grid[gridType].width};
      grid-template: auto / ${Array(measurement.grid[gridType].count).fill('minmax(0, 1fr)').join(' ')};
      grid-gap: ${measurement.grid[gridType].gap};
    `)}

  ${(props) => getAlign(props)}
`;

const SearchBackground = ReactDOMServer.renderToString(
  <svg
    width="864"
    height="864"
    fill={color.bg.invert.default}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path fill={color.bg.invert.alt} d="M0 0h864v864H0z" />
    <path fillOpacity=".8" d="M0 0h864v864H0z" />
    <path
      fillOpacity=".4"
      d="M864 171v693H0V212c21 2 42 3 66 3 127 0 229-29 322-56 80-24 155-46 235-46 78 0 138 22 202 45l39 13z"
    />
    <path
      fillOpacity=".6"
      d="M864 363v501H0V358c69-9 122-25 173-39 64-18 123-35 202-35 80 0 154 17 234 36 77 18 158 37 255 43z"
    />
    <path d="M864 506v358H0V465l39-14c64-23 123-44 202-44 80 0 154 22 234 45 94 28 196 57 323 57 23 0 45-1 66-3z" />
  </svg>
);

interface CellProps {
  readonly gridSpan?: number; // For both mobile and desktip viewports
  readonly cols?: number; // Desktop only
  readonly rows?: number;
  readonly header?: boolean;
  readonly footer?: boolean;
  readonly startRow?: boolean;
}

export const Cell = styled.div<CellProps>`
  ${({ gridSpan }) =>
    gridSpan !== undefined &&
    css`
      grid-column: span ${gridSpan};
    `}

  ${({ cols }) =>
    cols !== undefined &&
    css`
      ${responsive.wide(css`
        grid-column: span ${cols};
      `)}
    `}

  ${({ rows }) =>
    rows !== undefined &&
    css`
      ${responsive.grid(css`
        grid-row: span ${rows};
      `)}
    `}

  ${({ header }) =>
    header &&
    css`
      margin-bottom: ${measurement.space.header.s};

      ${responsive.grid(css`
        margin-bottom: ${measurement.space.header.l};
      `)}
    `}

  ${({ footer }) =>
    footer &&
    css`
      margin-top: ${measurement.space.footer.s};

      ${responsive.grid(css`
        margin-top: ${measurement.space.footer.l};
      `)}
    `}

  ${({ startRow }) =>
    startRow &&
    css`
      grid-column-start: 1;
    `}
`;

interface SubgridProps extends AlignProps {
  readonly variant?: SubgridKey;
  readonly as?: any;
  readonly hideMobile?: boolean;
}

export const Subgrid = styled(Cell)<SubgridProps>`
  display: grid;
  grid-gap: ${measurement.grid.mobile.gap};
  position: relative;

  ${({ variant }) => css`
    grid-template: auto / ${Array(measurement.subgrid[variant || 'default'].count)
        .fill('minmax(0, 1fr)')
        .join(' ')};
    grid-gap: ${measurement.subgrid[variant || 'default'].gap};
  `}

  ${({ variant }) =>
    variant === 'spacious' &&
    css`
      margin: ${measurement.subgrid.spacious.gap} 0 ${math(`${measurement.subgrid.spacious.gap} * 1.5`)};

      & > * + * {
        &::before {
          content: '';
          display: block;
          margin: ${measurement.subgrid.spacious.gap} -1rem 0;
          height: 0.25rem;
          background: ${color.bg.alt};

          ${responsive.fontSize(css`
            margin: ${measurement.subgrid.spacious.gap} 0 0;
          `)}
        }
      }
    `}

    ${({ hideMobile }) =>
    hideMobile &&
    css`
      display: none;

      ${responsive.grid(css`
        display: grid;
      `)}
    `}

  ${(props) => getAlign(props)}
`;

export const FlexibleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${measurement.space.flexible.s};

  & > * {
    flex: 1 0 auto;
    box-sizing: border-box;
    padding: ${measurement.space.flexible.s};
  }

  ${responsive.fontSize(css`
    margin: -${measurement.space.flexible.l};

    & > * {
      padding: ${measurement.space.flexible.l};
    }
  `)}
`;

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children, className, variant }) => {
  switch (variant) {
    case 'search':
      return (
        <Theme.Inverted>
          <Wrap {...{ children, className, variant }} />
        </Theme.Inverted>
      );
    default:
      return <Wrap {...{ children, className, variant }} />;
  }
};

const contentProps: (variant: Variant) => ContentProps = (variant) => {
  switch (variant) {
    case 'search':
      return {
        gridType: 'wide',
        grid: true,
      };
    case 'form':
      return {
        gridType: 'form',
        grid: true,
      };
    case 'text':
      return {
        gridType: 'text',
        grid: false,
      };
    case 'departure':
      return {
        gridType: 'default',
        textAlign: 'center',
      };
    case 'catalog-search':
      return {
        gridType: 'form',
        grid: true,
      };
    default:
      return {
        gridType: 'default',
        grid: true,
      };
  }
};

const Container = React.forwardRef<HTMLDivElement, WrapperProps>(({ children, className, variant }, ref) => (
  <Wrapper {...{ className, variant }}>
    <Content {...contentProps(variant)} ref={ref}>
      {children}
    </Content>
  </Wrapper>
));

export default Container;
