import React from 'react';
import styled, { css } from 'styled-components';
import { cover } from 'polished';

import { color, measurement } from '../helpers/vars';
import { style } from '../helpers/mixins';

import Icon, { IconName, SVG } from './Icon';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly as?: any;
  readonly icon?: IconName;
  readonly label?: string;
  readonly forwardRef?: any;
  readonly disclosure?: boolean;

  readonly round?: boolean;
  readonly small?: boolean;
  readonly secondary?: boolean;
  readonly warning?: boolean;
  readonly fullWidth?: boolean;
  readonly align?: 'left' | 'center' | 'right';
}

export const StyledButton = styled.button<ButtonProps>`
  ${style.button.common}
  ${(props) => style.button.size[props.small ? 'small' : 'default']}

  ${(props) => style.button.variant[props.secondary ? 'secondary' : 'default']}
  ${(props) =>
    props.secondary
      ? css`
          ${SVG} {
            color: ${color.icon.default};
          }
        `
      : css`
          ${SVG} {
            color: ${color.icon.invert};
          }
        `}
  ${(props) =>
    props.warning &&
    css`
      background: ${color.bg.button.warning};
    `}

  &:disabled, &[aria-disabled] {
    ${style.button.disabled.common}

    ${SVG} {
      color: ${color.icon.default};
    }

    ${(props) => style.button.disabled[props.secondary ? 'secondary' : 'default']}
    ${(props) =>
      props.secondary &&
      css`
        &.icon-only ${SVG} {
          color: ${color.icon.disabled};
        }
      `}
  }

  &:focus {
    ${(props) => props.theme.focus};
  }

  &.has-text ${SVG} {
    margin-left: -${measurement.space.icon.button};
    margin-right: ${measurement.space.icon.button};
  }

  &.icon-only {
    padding: ${measurement.inset.button.icon};

    & {
      border-radius: ${measurement.size.radius.circle};
    }

    &::before {
      ${cover(measurement.space.button.target)}
      content: '';
    }

    ${(props) => props.secondary && style.button.variant.icon}
  }

  ${(props) =>
    props.round &&
    css`
      border-radius: ${measurement.size.radius.rounded};
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;

      span {
        flex: 1;
      }
    `}

  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`;

const Button: React.FunctionComponent<ButtonProps> = React.forwardRef(
  ({ as, icon, label, children, forwardRef, onClick, disclosure, ...props }, ref) => (
    <StyledButton
      {...{
        icon,
        onClick,
        ...props,
        ...(as && { as }),
        ...(!(onClick || disclosure) && { 'aria-disabled': true }),
        ...((forwardRef || ref) && { ref: forwardRef || ref }),
        ...(icon && { className: !!children ? 'has-text' : 'icon-only' }),
      }}
    >
      {icon && <Icon name={icon} {...{ label }} inline={!!children} />}
      {props.fullWidth ? <span>{children}</span> : children}
    </StyledButton>
  )
);

export default Button;
