import React from 'react';
import styled, { css } from 'styled-components';
import { math } from 'polished';

import { color, measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';
import { Transition } from '../../helpers/components';

import { Lead, Small } from '../Text';

import DropIndicator from './DropIndicator';

interface WrapperProps {
  readonly error?: string;
  readonly innerRef?: any;
  readonly isDropArea?: boolean;
  readonly isDraggingOver?: boolean;
}

interface GroupProps extends WrapperProps {
  readonly title: React.ReactNode;
  readonly placeholder?: React.ReactNode;
  readonly unwrapped?: boolean;
}

const Wrapper = styled.section<WrapperProps>`
  ${style.card.content};
  padding: ${measurement.inset.group.s} 0;
  position: relative;
  transition: ${animation.transition('border', 'background')};

  ${responsive.fontSize(css`
    padding: ${measurement.inset.group.l} 0;
  `)}

  ${(props) =>
    props.error &&
    css`
      border-color: ${color.alert.default.error};
    `}

  ${(props) =>
    props.isDropArea &&
    css`
      border-color: ${color.border.transparent};
    `}

  ${(props) =>
    props.isDraggingOver &&
    css`
      background: ${color.bg.drag.active};
    `}
`;

const Error = styled(Small)`
  color: ${color.text.error};
  position: absolute;
  top: 0;
  right: 1rem;
`;

const Container = styled.ul`
  list-style: none;
  padding: 0;
  margin: -0.25rem;
  display: flex;
  flex-direction: column;
  margin: ${math(`${measurement.inset.group.s} - 0.25rem`)} ${math(`${measurement.inset.group.s} - 0.25rem`)} 0;

  ${responsive.fontSize(css`
    margin: ${math(`${measurement.inset.group.l} - 0.25rem`)} ${math(`${measurement.inset.group.l} - 0.25rem`)} 0;
  `)}
`;

const Title = styled(Lead)<{ isDropArea?: boolean }>`
  transition: ${animation.transition('color')};
  margin: 0 ${measurement.inset.group.s};

  ${responsive.fontSize(css`
    margin: 0 ${measurement.inset.group.l};
  `)}

  ${(props) =>
    props.isDropArea &&
    css`
      color: ${color.link.default};
    `}
`;

const Group: React.FunctionComponent<GroupProps> = ({
  title,
  innerRef,
  error,
  children,
  isDraggingOver,
  isDropArea,
  unwrapped,
  placeholder,
}) =>
  unwrapped ? (
    <section ref={innerRef}>
      <Wrapper as="div" {...{ error, isDropArea, isDraggingOver }}>
        <Title as="h3" {...{ isDropArea }}>
          {title}
        </Title>
        {error && <Error>{error}</Error>}
        <Transition.Opacity show={isDropArea || false} as={DropIndicator} />
      </Wrapper>
      {React.Children.count(children) ? <Container>{children}</Container> : null}
      {placeholder}
    </section>
  ) : (
    <Wrapper ref={innerRef} {...{ error, isDropArea, isDraggingOver }}>
      <Title as="h3" {...{ isDropArea }}>
        {title}
      </Title>
      {React.Children.count(children) ? <Container>{children}</Container> : null}
      {error && <Error>{error}</Error>}
      <Transition.Opacity show={isDropArea || false} as={DropIndicator} />
      {placeholder}
    </Wrapper>
  );

export default Group;
