import styled from 'styled-components';

import { style } from '../../helpers/mixins';

const Content = styled.div`
  ${style.card.content}
  grid-area: content;
  flex: 2;
`;

export default Content;
