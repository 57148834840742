import React, { FunctionComponent, useEffect } from 'react';
import { Subgrid } from '../../design-system/components/Container';
import Select, { useSelectState } from '../../design-system/components/Select';
import { hooks } from '../../design-system/helpers/mixins';
import { LineIdentity, Port, PortMap } from '../../fsm/types';
import { defaultPortsByPort } from '../../fsm/search/rules';
import Button from '../../design-system/components/Button';
import styled from 'styled-components';

type TripPickerProps = {
  portMap: PortMap[];
  setDep: Function;
  setDest: Function;
  dep: Port;
  dest: Port;
  fallbackDep?: Port | undefined;
};

const SwapButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 40px;
  height: 40px;
`;

const TripPicker: FunctionComponent<TripPickerProps> = ({ portMap, setDep, setDest, dep, dest, fallbackDep }) => {
  const depState = useSelectState({ initial: dep ? dep : '' });
  const destState = useSelectState({ initial: dest ? dest : '' });
  const sbPorts = hooks.useStoryblokDatasource('ports');

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  useEffect(() => {
    depState.state && setDep(depState.state);
    destState.state && setDest(destState.state);
  }, [depState.state, destState.state, setDep, setDest]);

  const createLabel = ({ code, name }: { code: string; name: string }): string => {
    return (sbPorts && sbPorts[code]) || name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const createDepChoices = (mapping: PortMap[]) => {
    let depCtrl: { value: string; label: string }[];
    if (mapping.length <= 0) depCtrl = [];
    else {
      depCtrl = mapping.map(({ from }: PortMap) => {
        return {
          value: from.code,
          label: createLabel(from),
        };
      });
      if (!depCtrl.find((o) => o.value === depState.state)) depState.setState(fallbackDep || depCtrl[0].value);
    }
    return depCtrl;
  };

  const createArrChoices = (from: string, mapping: PortMap[]) => {
    let arrCtrl: { value: string; label: string }[];
    if (mapping.length <= 0) arrCtrl = [];
    else {
      const arr = mapping.find((e: PortMap) => e.from.code === from);
      if (!arr) return [{ value: '', label: '' }];
      const to = arr.to;
      arrCtrl = to.map((lineIdentity: LineIdentity) => {
        return {
          value: lineIdentity.code,
          label: createLabel(lineIdentity),
        };
      });
    }
    if (!arrCtrl.find((v) => v.value === destState.state) && arrCtrl[0]) {
      const defaultTo = defaultPortsByPort.find((p) => p.from === from)?.to;
      if (defaultTo && arrCtrl.find((v) => v.value === defaultTo)) {
        destState.setState(defaultTo);
      } else {
        destState.setState(arrCtrl[0].value);
      }
    }
    return arrCtrl;
  };

  const departureProps = {
    ...depState,
    choices: createDepChoices(portMap),
    label: sbSearchForm?.content.from_port || 'From port',
  };
  const destinationProps = {
    ...destState,
    choices: createArrChoices(depState.state, portMap),
    label: sbSearchForm?.content.to_port || 'To port',
  };

  const onSwapButtonClick = () => {
    const tmpDep = depState.state;
    const tmpDest = destState.state;
    depState.setState(tmpDest);
    destState.setState(tmpDep);
  };

  return (
    <Subgrid variant="dense" startRow>
      <Select {...departureProps}>{sbSearchForm?.content.select_from_port}</Select>
      <Select {...destinationProps}>{sbSearchForm?.content.select_to_port}</Select>
      <SwapButtonWrapper>
        <Button icon="swap" onClick={onSwapButtonClick}></Button>
      </SwapButtonWrapper>
    </Subgrid>
  );
};

export default TripPicker;
