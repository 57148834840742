import React, { FunctionComponent, useContext } from 'react';
import BookingSummary from '../../../design-system/components/BookingSummary';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { LanguageContext } from '../../../Language.context';
import { calculateCountByType, GroupableType, GroupedCount } from '../../../utils/countByType';
import { calculateProductsTotalPrice, sum } from '../../../utils/priceCalculation';
import { findAccommodationContent } from '../../../storyblok/helpers';

interface MainRowsProps {
  readonly accommodations?: { code: string; price?: API.TariffPriceOrError; type: string }[];
  readonly passengers: { type: API.PassengerType | API.PetType }[];
  readonly quotes: API.ChargeInfo[];
  readonly vehicles?: { type: API.VehicleType }[];
  readonly tariff: API.Tariff;
  readonly shipName?: string | null;
  readonly showHeader?: boolean;
}

interface PassengerItemProps {
  count: number;
  readonly key: GroupableType | string;
}

const getItemPropsByType = (byType: GroupedCount) => {
  return (Object.keys(byType) as GroupableType[]).map((key) => ({
    count: byType[key],
    key,
  })) as PassengerItemProps[];
};

export const getSurchargeItemPropsFromChargeInfos = (chargeInfos: API.ChargeInfo[]) => {
  const counts = chargeInfos.reduce(
    (acc, chargeInfo) => {
      if (!chargeInfo.taxes) return acc;

      chargeInfo.taxes.forEach((taxInfo) => {
        if (taxInfo.taxcode) {
          const taxcodeLower = taxInfo.taxcode.toLowerCase();
          if (taxcodeLower.includes('pax')) {
            acc.paxCiCount++;
          }
          if (taxcodeLower.includes('veh')) {
            acc.vehCiCount++;
          }
        }
      });
      return acc;
    },
    { paxCiCount: 0, vehCiCount: 0 }
  );
  let surchargeItemProps = [];
  if (counts.paxCiCount > 0) {
    surchargeItemProps.push({ count: counts.paxCiCount, key: 'EU_ETS_SURCHARGE_PAX' });
  }
  if (counts.vehCiCount > 0) {
    surchargeItemProps.push({ count: counts.vehCiCount, key: 'EU_ETS_SURCHARGE_VEH' });
  }
  return surchargeItemProps;
};

const MainRows: FunctionComponent<MainRowsProps> = ({
  accommodations,
  passengers,
  quotes,
  vehicles,
  tariff,
  shipName,
  showHeader,
}) => {
  const { formats } = useContext(LanguageContext);
  const sbPassengers = hooks.useStoryblokComponents('products/passengers');
  const sbVehicles = hooks.useStoryblokComponents('products/vehicles');
  const [sbLegSummary] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'payment.leg_summary',
  });
  const sbSurchages = hooks.useStoryblokComponents('products/surcharges');

  const sbCommon = hooks.useStoryblokDatasource('common-translations');
  const sbVehicleTranslations = hooks.useStoryblokDatasource('vehicles');

  const passengersByType = calculateCountByType(passengers);

  const { [API.PetType.PET]: pets, ...people } = passengersByType;

  const peopleProps = getItemPropsByType(people);
  const petProps = pets ? getItemPropsByType({ [API.PetType.PET]: pets }) : undefined;

  const vehiclesByType = vehicles ? calculateCountByType(vehicles) : null;
  const vehicleProps = vehiclesByType && getItemPropsByType(vehiclesByType);

  const surchargeProps = getSurchargeItemPropsFromChargeInfos(quotes);

  const sbAccommodations = hooks.useStoryblokComponents('products/accommodations');

  const groupedAccommodations = accommodations?.reduce((acc, { code }) => {
    const found = acc.find((a) => a.key === code);
    if (found) {
      found.count = found.count + 1;
    } else {
      acc.push({ count: 1, key: code });
    }
    return acc;
  }, [] as PassengerItemProps[]);

  const propGroups = [
    {
      title: sbLegSummary?.content.main_title,
      props: [
        [...peopleProps, ...(petProps || [])],
        vehicleProps || [],
        groupedAccommodations || [],
        surchargeProps ? surchargeProps : [],
      ],
    },
  ];

  const getLabel = (key: GroupableType) => {
    const info =
      (sbPassengers[key] || sbVehicles[`${key}-${shipName?.toLocaleLowerCase()}`] || sbVehicles[key])?.content ||
      sbSurchages[key];

    return info?.component === 'passenger'
      ? `${info.title}${info.description ? ` ${info.description}` : ''}`
      : info?.component === 'vehicle_with_dimensions'
      ? `${sbVehicleTranslations['VEHICLE']} (${sbCommon['LENGTH']} ${info.length}, ${sbCommon['HEIGHT']} ${info.height})`
      : info?.component === 'vehicle_with_name'
      ? info.name
      : info?.content?.component === 'surcharge'
      ? `${info.content.title}`
      : (shipName &&
          findAccommodationContent(Object.values(sbAccommodations), key, shipName)
            .chainNullable((_) => _.content)
            .chainNullable((_) => _.title)
            .extract()) ||
        key;
  };

  const price = sum(quotes) + calculateProductsTotalPrice(accommodations || [], tariff);

  return (
    <>
      {propGroups.map(
        ({ title, props }, rowIndex) =>
          props &&
          !!props.length && (
            <BookingSummary.Row showHeader={showHeader} price={formats.currency(price)} title={title} key={rowIndex}>
              {props.map((groupedProps, i) => (
                <BookingSummary.Group key={i}>
                  {groupedProps.map(({ key, count }) => (
                    <BookingSummary.Item key={key} {...{ count }}>
                      {getLabel(key)}
                    </BookingSummary.Item>
                  ))}
                </BookingSummary.Group>
              ))}
            </BookingSummary.Row>
          )
      )}
    </>
  );
};

export default MainRows;
