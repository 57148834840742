import styled from 'styled-components';

import { color } from '../../helpers/vars';
import { style } from '../../helpers/mixins';

const Content = styled.div`
  ${style.card.content}
  border-color: ${color.bg.bookingSummary};
  background: ${color.bg.default};
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export default Content;
