import { settings } from '../../../settings';
import * as API from '../../types';

export const groupProducts = ({
  error,
  products,
}: {
  error?: any;
  products?: API.Product[];
}): API.Products | API.ProductError => {
  if (error || !products) {
    return API.ProductError.PRODUCT_QUERY_FAIL;
  }
  const grouped = (products || []).reduce(
    (products, product: API.Product) => ({
      ...products,
      [product.type]: (products[product.type] || []).concat(product),
    }),
    {} as API.Products
  );

  return {
    ...grouped,
    [API.ProductType.ACCOMMODATION]: grouped.ACCOMMODATION
      ? grouped.ACCOMMODATION.sort((acc1, acc2) => {
          const o1 = settings.accommodationOrder.findIndex((code) => code === acc1.code);
          const o2 = settings.accommodationOrder.findIndex((code) => code === acc2.code);
          // Accommodation order in settings is from cheapest to most expensive.
          // Here we want to reserve it as most expensive is shown first in dialogs.
          return -(o1 - o2);
        })
      : undefined,
  };
};
