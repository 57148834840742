import styled, { css } from 'styled-components';

import { font } from '../helpers/vars';
import { style } from '../helpers/mixins';

import { Lead } from './Text';

interface LinkProps {
  readonly subtle?: boolean;
}

const Link = styled.a<LinkProps>`
  font: inherit;
  ${style.link.common}
  cursor: pointer;
  font-weight: ${font.weight.strong.default};
  text-decoration: none;
  border: 0 !important;
  background-color: transparent;
  padding: 0 !important;
  margin: 0;
  place-self: center;

  ${(props) => css`
    ${props.theme.link}
  `}

  ${(props) =>
    props.subtle &&
    css`
      ${style.link.subtle}
    `}

  &:hover {
    ${style.link.hover}
  }

  &:focus {
    ${style.link.focus}
  }

  ${Lead} & {
    font-weight: ${font.weight.strong.lead};
  }
`;

/** @component */
export default Link;
