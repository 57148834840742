import { assign } from 'xstate';
import { Form, PortMap, SearchError } from '../types';
import { validateOfferCode, validateVehiclePort } from './guards';
import { SearchContext, SearchEvent } from './searchMachine';

const actions = {
  loadInitial: assign<SearchContext, SearchEvent>({
    errorMsg: [],
    portMap: (_: SearchContext, evt: any) => {
      return arrangeMap(
        (evt.data as any[]).reduce((portMap, line: any) => {
          const port = portMap.find((e: PortMap) => e.from.code === line.departurePortCode);
          if (!port)
            portMap.push({
              from: {
                name: line.departurePortName,
                code: line.departurePortCode,
              },
              to: [
                {
                  name: line.arrivalPortName,
                  code: line.arrivalPortCode,
                },
              ],
            });
          else {
            port.to.push({
              name: line.arrivalPortName,
              code: line.arrivalPortCode,
            });
          }

          return portMap;
        }, [] as PortMap[])
      );
    },
  }),

  loadInitialError: assign<SearchContext, SearchEvent>({
    errorMsg: () => [SearchError.LOAD_FAIL],
  }),

  onSearchParams: assign<SearchContext, SearchEvent>({
    errorMsg: [],
    searchParams: (_: SearchContext, evt: any) => evt.searchParams,
  }),

  onSearchParamsError: assign<SearchContext, SearchEvent>({
    errorMsg: (_: SearchContext, evt: any): string[] => {
      const errors = [];

      if (!validateOfferCode(evt.searchParams.type, evt.searchParams.offerCode)) {
        errors.push(SearchError.INVALID_OFFER_CODE);
      }

      if (
        evt.searchParams.passengers.ADULT +
          evt.searchParams.passengers.JUNIOR +
          evt.searchParams.passengers.CHILD +
          evt.searchParams.passengers.INFANT <=
        0
      )
        errors.push(SearchError.NOT_ENOUGH_PASSENGERS);

      evt.searchParams.forms.forEach((form: Form) => {
        if (form.params.departurePort === form.params.arrivalPort) {
          errors.push(SearchError.SAME_DEP_DEST);
        } else if (
          !validateVehiclePort(
            evt.searchParams.type,
            form.params.departurePort,
            form.params.arrivalPort,
            form.selectedDate,
            evt.searchParams.vehicles,
            evt.sbLegMeta
          )
        ) {
          errors.push(SearchError.REQUIRE_VEHICLE);
        }
      });
      return errors;
    },
  }),
};

export default actions;

const arrangeMap = (map: PortMap[]): PortMap[] => {
  const sorted = map
    .sort((a, b) => sort(a.from.name, b.from.name))
    .map((m) => ({ ...m, to: m.to.sort((a, b) => sort(a.name, b.name)) }));
  return sorted;
};

const sort = (name1: string, name2: string) => (name1 < name2 ? -1 : name1 > name2 ? 1 : 0);
