import React, { FunctionComponent } from 'react';
import InputMask from 'react-input-mask';
import { FlagIcon } from 'react-flag-kit';

import { StyledInput } from '../../helpers/components';

import { InputPhoneStateReturn } from './useInputGroupState';
import Wrapper, { validation } from './Wrapper';

const correctSelectLength = (countryCode: number) => {
  switch (countryCode) {
    case 2:
      return '67px';
    case 3:
      return '75px';
    case 4:
      return '83px';
    case 5:
      return '92px';
    default:
      return '';
  }
};

const InputPhone: FunctionComponent<React.HTMLProps<HTMLInputElement> & InputPhoneStateReturn> = ({
  htmlFor,
  label,
  as,
  ref,
  error,
  maskCode,
  maskStr,
  maskCountryCode,
  onMaskChange,
  masks,
  maskLabel,
  hint,
  flag,
  gridSpan,
  ...props
}) => (
  <Wrapper {...{ htmlFor, label, error, hint, gridSpan }}>
    <StyledInput.MaskSelect>
      {flag ? <FlagIcon code={flag} size={30} /> : <span>{maskCode}</span>}
      <select
        aria-label={maskLabel}
        onChange={onMaskChange}
        value={maskCode}
        style={{ width: correctSelectLength(maskCountryCode.length) }}
      >
        {masks.map(
          ({ label, list }, i) =>
            list && (
              <optgroup key={i} {...{ label }}>
                {list.map(({ value, label }) => (
                  <option key={value} {...{ value }}>
                    {label}
                  </option>
                ))}
              </optgroup>
            )
        )}
      </select>
    </StyledInput.MaskSelect>
    <InputMask
      mask={maskStr}
      maskChar=""
      formatChars={{
        '#': '[0-9]',
      }}
      {...props}
      alwaysShowMask
    >
      {(inputProps: any) => (
        <StyledInput.Input
          id={htmlFor}
          value={props.value}
          onChange={props.onChange}
          {...inputProps}
          {...validation(error, htmlFor)}
        />
      )}
    </InputMask>
  </Wrapper>
);

export default InputPhone;
