import { StateMachine } from 'xstate';
import loaderMachine from './loader/loaderMachine';

export type ActorMap = {
  [id: string]: StateMachine<any, any, any>;
};

const actors: ActorMap = {
  loader: loaderMachine,
};

export default actors;
