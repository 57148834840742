/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listLinesAvailability = /* GraphQL */ `
  query ListLinesAvailability($query: LinesQuery!) {
    listLinesAvailability(query: $query) {
      ... on Line {
        departurePortCode
        departurePortName
        departurePortDesc
        arrivalPortCode
        arrivalPortName
        arrivalPortDesc
        legCode
        legDesc
        legOrder
        sectionOrder
        sectionDesc
        documentYn
        hobYn
        hobCode
        eventYn
        eventCode
        eventDate
        eventTime
        connections {
          departurePortCode
          departurePortName
          departurePortDesc
          arrivalPortCode
          arrivalPortName
          arrivalPortDesc
          legCode
          legDesc
          documentYn
        }
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const listProductsAvailability = /* GraphQL */ `
  query ListProductsAvailability($query: ProductsQuery!) {
    listProductsAvailability(query: $query) {
      ... on Product {
        code
        type
        subtype
        name
        desc
        maxPeople
        maxPets
        available
        exclusive
        gender
        capacity {
          available
          real
          sellable
          sold
        }
        chargePerUnit
        measureUnit
        limitedTo
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const listSailingsAvailability = /* GraphQL */ `
  query ListSailingsAvailability($query: SailingsQuery!) {
    listSailingsAvailability(query: $query) {
      ... on Sailing {
        sailingCode
        legCode
        shipCode
        shipName
        departureDate
        departureTime
        departurePort
        arrivalDate
        arrivalTime
        arrivalPort
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const listTimeTableAvailability = /* GraphQL */ `
  query ListTimeTableAvailability($query: TimetableQuery!) {
    listTimeTableAvailability(query: $query) {
      ... on Timetable {
        legCode
        sailingCode
        departureDate
        departureTime
        departurePort
        arrivalDate
        arrivalTime
        arrivalPort
        isAvailable
        availabilityReason
        shipCode
        shipName
        moreInfo
        availabilityInfo
        voyageCode
        crossingTime
        chargeTotal
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const quoteAccommodations = /* GraphQL */ `
  query QuoteAccommodations($query: AccommodationQuoteQuery!) {
    quoteAccommodations(query: $query) {
      ... on AccommodationQuote {
        chargeInfo {
          ... on ChargeInfo {
            charge
            discount
            discountDesc
            discountStarClub
            tax
            taxes {
              taxcode
              taxtype
              taxdescription
              taxamount
            }
          }
          ... on ApiError {
            errorCode
            errorMessage
            productCode
          }
        }
        code
        type
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const quoteBooking = /* GraphQL */ `
  query QuoteBooking($query: BookingQuoteQuery!) {
    quoteBooking(query: $query) {
      ... on BookingQuote {
        accommodations {
          code
          type
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          passengers {
            amount
            type
          }
        }
        agreement {
          code
          status
          validationCode
        }
        onboards {
          amount
          type
          code
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
        }
        passengers {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        pets {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        sailings {
          leg {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          departureDate
          departureTime
          departurePortCode
          departurePortName
          departurePortDesc
          arrivalDate
          arrivalTime
          arrivalPortCode
          arrivalPortName
          arrivalPortDesc
          eventCode
          offerCode
          shipCode
          shipName
          shipDesc
          sailingCode
          tariff
        }
        total {
          total
          commission
          tax
        }
        vehicles {
          id
          type
          lengthCm
          heightCm
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          mark
          model
          registrationId
        }
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($query: ReservationUrlInput!) {
    getReservation(query: $query) {
      ... on BookingReservation {
        accommodations {
          code
          type
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          passengers {
            amount
            type
          }
        }
        agreement {
          code
          status
          validationCode
        }
        contactInfo {
          firstName
          lastName
          birthdate
          address
          city
          postCode
          country
          nationality
          email
          countryCallCode
          mobile
          termsOfServiceOk
          privacyPolicyOk
          newsletterOk
        }
        bookingDetail {
          reference
          referenceHash
          status
          date
          time
          expiryDate
        }
        onboards {
          amount
          type
          code
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
        }
        passengers {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        passengerDetails {
          id
          title
          firstName
          lastName
          birthdate
          age
          gender
          nationality
          type
          document {
            type
            number
            nationality
            expiryDate
          }
        }
        pets {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        sailings {
          leg {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          departureDate
          departureTime
          departurePortCode
          departurePortName
          departurePortDesc
          arrivalDate
          arrivalTime
          arrivalPortCode
          arrivalPortName
          arrivalPortDesc
          eventCode
          offerCode
          shipCode
          shipName
          shipDesc
          sailingCode
          tariff
        }
        total {
          total
          commission
          tax
        }
        vehicles {
          id
          type
          lengthCm
          heightCm
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          mark
          model
          registrationId
        }
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const getReservationPdf = /* GraphQL */ `
  query GetReservationPdf($query: ReservationInput!) {
    getReservationPdf(query: $query) {
      ... on ReservationPdf {
        fileName
        base64Data
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const getReservationPdfForUrl = /* GraphQL */ `
  query GetReservationPdfForUrl($query: ReservationUrlInput!) {
    getReservationPdfForUrl(query: $query) {
      ... on ReservationPdf {
        fileName
        base64Data
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
