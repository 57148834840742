import { List } from 'purify-ts/List';
import { Maybe } from 'purify-ts/Maybe';
import { TreeData } from '.';

export const findAccommodationContent = (
  accommodationProducts: TreeData[],
  productCode: string,
  shipName: string
): Maybe<TreeData> =>
  List.find(
    ({ content }) =>
      content.product_code === productCode &&
      content.ship.find((name: string) => name.toLowerCase().replace(/\s+/g, '_') === shipName.toLowerCase()),
    accommodationProducts
  );

export const findAccommodationFullSlug = (
  accommodationProducts: TreeData[],
  productCode: string,
  shipName: string
): string =>
  findAccommodationContent(accommodationProducts, productCode, shipName).mapOrDefault((_) => _.full_slug, '');

export const findAccommodationServicesDescription = (
  accommodationServices: TreeData[],
  productCode: string,
  departurePort: string,
  arrivalPort: string
): Maybe<string> =>
  List.find(
    ({ content }) =>
      content?.product_code === productCode &&
      content?.legs?.find((leg: string) => leg.indexOf(departurePort) !== -1 && leg.indexOf(arrivalPort) !== -1),
    accommodationServices
  ).chainNullable((_) => _.content.description);
