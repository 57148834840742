import React, { FunctionComponent } from 'react';
import InputMask from 'react-input-mask';

import { StyledInput } from '../../helpers/components';

import { InputGroupStateReturn } from './useInputGroupState';
import Wrapper, { validation } from './Wrapper';

const InputGroup: FunctionComponent<React.HTMLProps<HTMLInputElement> & InputGroupStateReturn> = ({
  htmlFor,
  label,
  as,
  ref,
  error,
  mask,
  hint,
  gridSpan,
  ...props
}) => (
  <Wrapper {...{ htmlFor, label, error, hint, gridSpan }}>
    {mask ? (
      <InputMask {...{ mask }} {...props} maskChar="">
        {(inputProps: any) => (
          <StyledInput.Input placeholder={label} id={htmlFor} {...inputProps} {...validation(error, htmlFor)} />
        )}
      </InputMask>
    ) : (
      <StyledInput.Input placeholder={label} id={htmlFor} {...props} {...validation(error, htmlFor)} />
    )}
  </Wrapper>
);

export default InputGroup;
