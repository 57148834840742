const family = {
  robotoSlab: "'Roboto Slab'",
  sourceSans: "'Source Sans Pro'",
};

export default {
  family: {
    ...family,
    heading: `${family.robotoSlab}, serif`,
    copy: `${family.sourceSans}, sans-serif`,
  },

  lineHeight: {
    heading: 1,
    smallHeading: 1.125,
    copy: 1.5,
  },

  size: {
    0: {
      mobile: '.75rem',
      desktop: '.875rem',
    },
    1: {
      mobile: '.875rem',
      desktop: '1rem',
    },
    2: {
      mobile: '1rem',
      desktop: '1.125rem',
    },
    3: {
      mobile: '1.375rem',
      desktop: '2rem',
    },
    4: {
      mobile: '2rem',
      desktop: '3.25rem',
    },
  },

  weight: {
    default: 400,
    heading: 700,
    lead: 400,
    small: 600,

    strong: {
      default: 700,
      lead: 600,
    },
  },
};
