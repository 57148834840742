import * as API from '../../types';

const CACHE_THRESHOLD_MILLIS = 15 * 60 * 1000;

interface CacheData {
  ts: number;
}

export interface SailingData {
  defaultAccommodations?: API.ExtendedAccommodation[] | API.AccommodationError;
  products: API.Products | API.ProductError;
  productPricesFetched: boolean;
}

type Cache = Map<string, CacheData & SailingData>;

const cache: Cache = new Map();

function clear(): void {
  cache.clear();
}

function get(sailingCode: string): SailingData | undefined {
  const entry = cache.get(sailingCode);
  return entry && entry.ts + CACHE_THRESHOLD_MILLIS > Date.now() ? entry : undefined;
}

function getAndUpdate(
  sailingCode: string,
  callback: (data: SailingData) => Promise<SailingData>
): Promise<SailingData> {
  const data = get(sailingCode) || { products: {}, productPricesFetched: false };

  return callback(data).then((updated: SailingData) => {
    const value = { ...updated, ts: Date.now() };
    cache.set(sailingCode, value);

    return value;
  });
}

export default {
  clear,
  get,
  getAndUpdate,
};
