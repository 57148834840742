import { settings } from '../../settings';
import { TreeData } from '../../storyblok';
import { calculateProductsTotalPrice } from '../../utils/priceCalculation';
import * as API from '../types';
import {
  BookingPassenger,
  Catalog,
  PassengerType,
  PriceError,
  ProductError,
  ProductSubType,
  Products,
  SailingMeta,
  TariffPrice,
  TripType,
} from '../types';
import { passengersAsValues } from './passengerUtils';
import {
  getAccommodationProducts,
  getSelected,
  indexToLeg,
  isOfferCodeValidForTariff,
  isReturnWithinMealTimeLimit,
} from './sailingUtils';

export enum MealPackage {
  ADULT = 'AM',
  JUNIOR = 'JM',
  CHILD = 'CM',
  INFANT = 'IM',
}

export interface ExtendedProductWithSelectableCounts extends API.ExtendedProduct {
  maxSelectable: number;
  min: number;
  included: number;
}

export const getOnboardByCode = (sailing: API.SelectedSailing, code: string): API.ExtendedOnboard | undefined =>
  Array.isArray(sailing.onboards) ? sailing.onboards?.find((o) => o.code === code) : undefined;

export const GetOnboardsByType = (sailing: API.SelectedSailing, type: API.ProductType): API.ExtendedOnboard[] =>
  Array.isArray(sailing.onboards) ? sailing.onboards?.filter((o) => o.type === type) : [];

export const getOnboard = (sailing: API.SelectedSailing, code: string): API.ExtendedProduct | undefined => {
  return API.isError(sailing.products)
    ? undefined
    : sailing.products.ELECTRICITY?.find((a) => a.code === code) ||
        sailing.products.FOOD?.find((a) => a.code === code) ||
        sailing.products.PACKAGE?.find((a) => a.code === code) ||
        sailing.products.WIFI?.find((a) => a.code === code) ||
        sailing.products.NIGHT?.find((a) => a.code === code) ||
        sailing.products.WELLNESS?.find((a) => a.code === code) ||
        sailing.products.LOUNGE?.find((a) => a.code === code);
};

export const getCount = (productCode: string, onboards: API.ExtendedOnboard[]) =>
  onboards.find((selected) => selected.code === productCode)?.amount || 0;

export const setStepperValue = (
  productCode: string,
  value: number,
  onboards: API.ExtendedOnboard[],
  add: (code: string, amount?: number) => void,
  remove: (code: string, amount?: number) => void
) => {
  const change = value - getCount(productCode, onboards);

  if (change > 0) {
    add(productCode, change);
  } else if (change < 0) {
    remove(productCode, change * -1);
  }
};

export const sortAdultToInfantProductInLimited = <T extends { limitedTo?: (PassengerType | null)[] | null }>(
  sortable: T[]
): T[] => {
  return [...sortable].sort((p1, p2) =>
    p1.limitedTo && p1.limitedTo[0] && p2.limitedTo && p2.limitedTo[0]
      ? passengersAsValues[p1.limitedTo[0] as PassengerType] > passengersAsValues[p2.limitedTo[0] as PassengerType]
        ? -1
        : passengersAsValues[p1.limitedTo[0] as PassengerType] < passengersAsValues[p2.limitedTo[0] as PassengerType]
        ? 1
        : 0
      : 0
  );
};

export const selectableProductsList = (
  products: API.ExtendedProduct[],
  passengers: API.Passengers,
  includedServices: PassengerProductCounts
) => {
  return sortAdultToInfantProductInLimited(
    products
      .map((product) => {
        const passengerCount = (product.limitedTo ?? []).reduce((acc, passengerType) => {
          if (!passengerType) return acc + 0;
          return acc + passengers[passengerType];
        }, 0);

        if (passengerCount < 1 || !product.capacity.available) return null;
        const passengerType = getPassengerTypeForProduct(product);
        const included = passengerType
          ? includedServices[passengerType].filter((service) => service.code === product.code).length
          : 0;

        const maxSelectable = Math.min(passengerCount, product?.capacity.available || 0);

        return {
          ...product,
          maxSelectable,
          min: 0 + included,
          included,
        };
      })
      .filter((val) => Boolean(val)) as ExtendedProductWithSelectableCounts[]
  );
};

export const countProducts = (
  selectedOnboards: API.ExtendedOnboard[],
  products: API.ExtendedProduct[],
  productCounts: ProductAmount[]
) => {
  selectedOnboards.forEach(({ code, amount }) => {
    const asProduct = products.find((p) => p.code === code);
    const existing = productCounts.find((p) => p.code === asProduct?.code);
    if (existing) {
      existing.amount += amount;
    } else {
      productCounts.push({
        code,
        subtype: asProduct?.subtype,
        amount,
        limitedTo: asProduct?.limitedTo,
      });
    }
  });
};

const divideChargeByTwo = (prices: any) => {
  const updatedPrices = { ...prices };
  for (const type in updatedPrices) {
    updatedPrices[type].charge = updatedPrices[type].charge / 2;
  }
  return updatedPrices;
};

export const addMandatoryMealPackages = (
  passengers: BookingPassenger[],
  products: Products | ProductError,
  onboards:
    | {
        price: TariffPrice | PriceError;
        amount: number;
        code: string;
        legs: number[];
        type: string;
      }[]
    | undefined,
  index: number,
  tripType: API.TripType
) => {
  const combination:
    | PriceError
    | {
        price: TariffPrice | PriceError;
        amount: number;
        code: string;
        legs: number[];
        type: string;
      }[]
    | undefined = [];

  if (!passengers || typeof products !== 'object') {
    return combination;
  }

  const foodItems = products['FOOD'];

  if (!Array.isArray(foodItems)) {
    return combination;
  }

  const mealPackageCodes = Object.values(MealPackage).map((_) => _.toString());
  const mealPackages = products.FOOD?.filter((p) => mealPackageCodes.includes(p.code)) || [];

  mealPackages.forEach((mealPackage) => {
    const { code, limitedTo, type, price } = mealPackage;

    if (
      Array.isArray(limitedTo) &&
      (!onboards || (onboards && !onboards.some((mealPackage) => mealPackage.code === code)))
    ) {
      const count = passengers.reduce((passengerCount, passenger) => {
        return limitedTo.includes(passenger.type) ? ++passengerCount : passengerCount;
      }, 0);
      if (count > 0) {
        const correctPrice =
          tripType === API.TripType.OVERNIGHT_CRUISE ? getMealPackagePriceForOvernightCruise(index, price) : price;
        combination.push({
          price: price ? (correctPrice as TariffPrice) : PriceError.UNSPECIFIED,
          code,
          type,
          amount: count,
          legs: [indexToLeg(index)],
        });
      }
    }
  });

  return combination;
};

const getMealPackagePriceForOvernightCruise = (index: number, price: API.TariffPriceOrError | undefined) => {
  // For overnight cruises the first leg contains price of both legs for meal packages
  return index === 0 && price ? divideChargeByTwo(price) : price;
};

export const isMealForced = (
  catalogs: Catalog[],
  tripType: API.TripType,
  selectedMeta: SailingMeta | undefined,
  offerCode: string | undefined
) => {
  if (
    tripType === TripType.ONEWAY ||
    tripType === TripType.DAYCRUISE ||
    !isForceMealOfferCode(offerCode) ||
    catalogs.some(
      (c) => c.selected?.tariff === undefined || !isOfferCodeValidForTariff(selectedMeta, c.selected?.tariff)
    )
  ) {
    return false;
  }

  return (
    tripType === TripType.OVERNIGHT_CRUISE || (tripType === TripType.RETURN && isReturnWithinMealTimeLimit(catalogs))
  );
};

export const isForceMealOfferCode = (offerCode: string | undefined) =>
  isReturnForceMealOfferCode(offerCode) || isOvernightCruiseForceMealOfferCode(offerCode);

export const isReturnForceMealOfferCode = (offerCode: string | undefined) =>
  offerCode !== undefined && offerCode.includes(settings.returnMealForceOfferCode);

export const isOvernightCruiseForceMealOfferCode = (offerCode: string | undefined) =>
  offerCode !== undefined && offerCode.includes(settings.overnightCruiseMealForceOffercode);

export const sortAdultToInfantOnboardInLimited = <
  T extends { code: string },
  V extends { limitedTo?: (PassengerType | null)[] | null; code: string }
>(
  sortable: T[],
  searchable: V[]
): T[] => {
  return [...sortable].sort((p1, p2) => {
    const p1Limited = searchable.find((s) => s.code === p1.code);
    const p2Limited = searchable.find((s) => s.code === p2.code);

    return p1Limited?.limitedTo && p1Limited.limitedTo[0] && p2Limited?.limitedTo && p2Limited.limitedTo[0]
      ? passengersAsValues[p1Limited.limitedTo[0] as PassengerType] >
        passengersAsValues[p2Limited.limitedTo[0] as PassengerType]
        ? -1
        : passengersAsValues[p1Limited.limitedTo[0] as PassengerType] <
          passengersAsValues[p2Limited.limitedTo[0] as PassengerType]
        ? 1
        : 0
      : 0;
  });
};

export const sortByProductTypeAndAdultToInfantProductInLimited = <
  T extends { limitedTo?: (PassengerType | null)[] | null; subtype?: ProductSubType | null }
>(
  sortable: T[],
  productTypeOrder: API.ProductSubType[]
): T[] => {
  type ProductsByCategory = {
    [key in ProductSubType]?: T[];
  };

  const groupedByCategory = sortable.reduce((acc: ProductsByCategory, curr: T) => {
    const { subtype } = curr;
    if (subtype === undefined || subtype === null) {
      return acc;
    } else if (acc[subtype] === undefined) {
      acc[subtype] = [curr];
    } else {
      acc[subtype]!.push(curr);
    }
    return acc;
  }, {} as ProductsByCategory);

  const sortedByCategory = productTypeOrder
    .map((productType) => {
      return groupedByCategory[productType];
    })
    .filter((val: T[] | undefined) => !!val) as T[][];

  return sortedByCategory.map((val: T[]) => sortAdultToInfantProductInLimited<T>(val)).flat();
};

export const getCruiseAccommodationProducts = (catalogs: API.Catalog[]): API.ExtendedProduct[] => {
  return catalogs
    .map((c) => getSelected(c))
    .map((s) => getAccommodationProducts(s))
    .reduce((result, sailingAccommondation) => {
      return getCommon(result, sailingAccommondation);
    });
};

export const getCommonProducts = (
  products1: API.Products | ProductError,
  products2: API.Products | ProductError
): API.Products => {
  if (API.isError(products1) || API.isError(products2)) {
    return {} as API.Products;
  }
  return {
    ACCOMMODATION: getCommon(products1.ACCOMMODATION, products2.ACCOMMODATION),
    BIKE: getCommon(products1.BIKE, products2.BIKE),
    ELECTRICITY: getCommon(products1.ELECTRICITY, products2.ELECTRICITY),
    FOOD: getCommon(products1.FOOD, products2.FOOD),
    NIGHT: getCommon(products1.NIGHT, products2.NIGHT),
    PACKAGE: getCommon(products1.PACKAGE, products2.PACKAGE),
    PET: getCommon(products1.PET, products2.PET),
    VEHICLE: getCommon(products1.VEHICLE, products2.VEHICLE),
    WIFI: getCommon(products1.WIFI, products2.WIFI),
  };
};

export const getCruiseOutboundProducts = (
  selectedOutboundProducts: API.Products | API.ProductError,
  selectedInboundProducts: API.Products | API.ProductError
): API.Products | API.ProductError => {
  if (typeof selectedOutboundProducts === 'string' || typeof selectedInboundProducts === 'string') {
    return selectedOutboundProducts;
  }
  const mealPackageCodes = Object.values(MealPackage).map((_) => _.toString());
  const outboundMealPacakges = selectedOutboundProducts.FOOD?.filter((p) => mealPackageCodes.includes(p.code)) || [];
  const inboundMealPacakges = selectedOutboundProducts.FOOD?.filter((p) => mealPackageCodes.includes(p.code)) || [];

  const mealPackages = outboundMealPacakges.map((outboundMealPackage) => {
    const inboundMealPackage = inboundMealPacakges.find((p) => p.code === outboundMealPackage.code);
    const price = getCruiseMealPackagePrice(outboundMealPackage, inboundMealPackage);

    return {
      ...outboundMealPackage,
      price,
    };
  });

  const restFoods = (selectedOutboundProducts.FOOD || ([] as API.ExtendedProduct[])).filter(
    (foodProduct) => !mealPackages.map((mp) => mp.code).includes(foodProduct.code)
  );

  return {
    ...selectedOutboundProducts,
    FOOD: [...mealPackages, ...restFoods],
  };
};

export const getReturnOfferCodeOutboundProducts = (
  selectedOutboundProducts: API.Products | API.ProductError,
  selectedInboundProducts: API.Products | API.ProductError
): API.Products | API.ProductError => {
  if (typeof selectedOutboundProducts === 'string' || typeof selectedInboundProducts === 'string') {
    return selectedOutboundProducts;
  }
  const mealPackageCodes = Object.values(MealPackage).map((_) => _.toString());
  const outboundMealPacakges = selectedOutboundProducts.FOOD?.filter((p) => mealPackageCodes.includes(p.code)) || [];
  const inboundMealPacakges = selectedOutboundProducts.FOOD?.filter((p) => mealPackageCodes.includes(p.code)) || [];

  const mealPackages = outboundMealPacakges.map((outboundMealPackage) => {
    const inboundMealPackage = inboundMealPacakges.find((p) => p.code === outboundMealPackage.code);
    const price = getCruiseMealPackagePrice(outboundMealPackage, inboundMealPackage);

    return {
      ...outboundMealPackage,
      price,
    };
  });

  const restFoods = (selectedOutboundProducts.FOOD || ([] as API.ExtendedProduct[])).filter(
    (foodProduct) => !mealPackages.map((mp) => mp.code).includes(foodProduct.code)
  );

  return {
    ...selectedOutboundProducts,
    FOOD: [...mealPackages, ...restFoods],
  };
};

const getCruiseMealPackagePrice = (
  outboundMealPackage: API.ExtendedProduct,
  inboundMealPackage: API.ExtendedProduct | undefined
) => {
  if (inboundMealPackage === undefined) {
    return undefined;
  } else if (typeof outboundMealPackage.price === 'string' || outboundMealPackage.price === undefined) {
    return outboundMealPackage.price;
  } else if (typeof inboundMealPackage.price === 'string' || inboundMealPackage?.price === undefined) {
    return inboundMealPackage?.price;
  } else {
    return {
      SPECIAL: {
        charge: calculateProductsTotalPrice([inboundMealPackage, outboundMealPackage], API.Tariff.SPECIAL),
        discount: 0,
      } as API.ChargeInfo,
      STANDARD: {
        charge: calculateProductsTotalPrice([inboundMealPackage, outboundMealPackage], API.Tariff.STANDARD),
        discount: 0,
      } as API.ChargeInfo,
    };
  }
};

const getCommon = (products1: API.ExtendedProduct[] | undefined, products2: API.ExtendedProduct[] | undefined) => {
  if (products1 === undefined || products2 === undefined) {
    return [] as API.ExtendedProduct[];
  }
  return products1.filter((p) => products2.some((p2) => p2.code === p.code));
};

// All logic here relys on that individual products are always limited to
// one passenger type even though limitedTo is defined as an array
export const getPassengerTypeForProduct = (product: API.ExtendedProduct | undefined): API.PassengerType | undefined => {
  if (product === undefined || product.limitedTo === undefined || product.limitedTo === null) {
    return undefined;
  }
  const { limitedTo } = product;
  return limitedTo.find((p) => p !== null && p !== undefined) as API.PassengerType | undefined;
};

export const passengerProductsObj = () =>
  Object.values(API.PassengerType).reduce(
    (obj, type) => ({ ...obj, [type]: [] as API.ExtendedProduct[] }),
    {}
  ) as PassengerProductCounts;

export interface PassengerProductCounts {
  [API.PassengerType.ADULT]: API.ExtendedProduct[];
  [API.PassengerType.JUNIOR]: API.ExtendedProduct[];
  [API.PassengerType.CHILD]: API.ExtendedProduct[];
  [API.PassengerType.INFANT]: API.ExtendedProduct[];
}

export const includedProducts = (
  accommodations: API.ExtendedAccommodation[],
  accommodationServices: { productCode: string; productsIncluded: string[] }[],
  sbProductTypes: TreeData[],
  products: API.ExtendedProduct[],
  passengerInfo: API.ExtendedPassengerAndPet[]
) => {
  return accommodations.reduce((included, { code, passengers }) => {
    const accommodation = accommodationServices.find((acc) => acc.productCode === code);
    if (accommodation) {
      // accommodation.productsIncluded is a list of uuids
      // => store product codes instead
      const accommodationProducts: (API.ExtendedProduct | undefined)[] = accommodation.productsIncluded.map(
        (mealUuid: string) => {
          const mealType = sbProductTypes.find((sb) => sb.uuid === mealUuid);
          return products.find((p) => p.code === mealType?.name);
        }
      );

      const currentIncluded = (passengers || []).reduce((current, passenger) => {
        const info = passengerInfo.find(({ id }) => id === passenger);

        if (info && info.type !== API.PetType.PET) {
          const includedMeal = accommodationProducts.find((meal) => getPassengerTypeForProduct(meal) === info.type);
          return includedMeal
            ? {
                ...current,
                [info.type]: [...current[info.type], includedMeal],
              }
            : current;
        }
        return current;
      }, passengerProductsObj());

      return Object.keys(included).reduce((currentAdded, type) => {
        const currentType = type as API.PassengerType;
        return {
          ...currentAdded,
          [currentType]: [...included[currentType], ...currentIncluded[currentType]],
        };
      }, passengerProductsObj());
    }
    return included;
  }, passengerProductsObj());
};

export const calculatePossibleProductCount = (
  possibleProductCounts: API.Passengers,
  passengerType: API.PassengerType | undefined,
  selectedProducts: API.ExtendedOnboard[],
  products: API.ExtendedProduct[],
  product?: API.ExtendedProduct
) => {
  if (!product) {
    return 0;
  }

  const selectedProductsAsProducts = selectedProducts.map((mp) => products.find(({ code }) => code === mp.code));
  const selectedProductsOfSameSubType = selectedProductsAsProducts.filter((mp) => mp?.subtype === product.subtype);

  const selectedProductsSameSubTypeAndSamePassengerType = selectedProductsOfSameSubType
    .filter((selectedProduct) => getPassengerTypeForProduct(selectedProduct) === passengerType)
    .filter((selectedProduct) => selectedProduct?.code !== product.code);

  const productCounts = selectedProductsSameSubTypeAndSamePassengerType
    .map((product) => selectedProducts.find((selectedProduct) => selectedProduct.code === product?.code))
    .filter((p) => p !== undefined)
    .map((p) => p?.amount || 0);

  const amountOfSameSubTypeProductsSelected = productCounts.reduce((sum, count) => sum + count, 0);

  const possibleProductCount = passengerType
    ? possibleProductCounts[passengerType]
    : (Object.keys(possibleProductCounts) as API.PassengerType[]).reduce(
        (count, passengerType) => count + possibleProductCounts[passengerType],
        0
      );
  return possibleProductCount - amountOfSameSubTypeProductsSelected;
};

const passengerCountObj = () =>
  Object.values(API.PassengerType).reduce((obj, type) => ({ ...obj, [type]: 0 }), {}) as API.Passengers;

export const selectableProductCount = (
  passengers: API.Passengers,
  includedProducts: PassengerProductCounts,
  products: API.ExtendedProduct[]
) => {
  return Object.keys(passengers).reduce((possible, type) => {
    const currentType = type as API.PassengerType;
    const includedMealsForType = includedProducts[currentType].filter((meal) =>
      products.find(({ code }) => code === meal.code)
    );
    return {
      ...possible,
      [currentType]: passengers[currentType] - includedMealsForType.length,
    };
  }, passengerCountObj());
};

export const getProductCounts = (
  products: API.ExtendedProduct[],
  productCounts: ProductAmount[] = [] as ProductAmount[]
): ProductAmount[] => {
  return products.reduce((amounts: ProductAmount[], product) => {
    const existing = amounts.find((_) => _.code === product.code);
    if (existing) {
      existing.amount++;
    } else {
      amounts.push({
        code: product.code,
        subtype: product.subtype,
        limitedTo: product.limitedTo,
        amount: 1,
      });
    }
    return amounts;
  }, productCounts);
};

export const createDefaultPassengerIndexes = (
  product: API.ExtendedProduct,
  amount: number,
  passengers: API.ExtendedPassengerAndPet[]
) => {
  let passengerIndexes: number[] = [];

  const passengerTypeCounts = new Map<string, API.ExtendedPassengerAndPet[]>();
  passengers.forEach((passenger) => {
    if (!passengerTypeCounts.has(passenger.type)) {
      passengerTypeCounts.set(passenger.type, []);
    }
    const passengersForType = passengerTypeCounts.get(passenger.type);
    passengersForType!.push(passenger);
    passengerTypeCounts.set(passenger.type, passengersForType!);
  });

  const limitedTo = product.limitedTo && product.limitedTo[0] ? product.limitedTo[0].toString() : '';
  if (amount > 0) {
    var matchingPassengers: API.ExtendedPassengerAndPet[] = [];

    if (passengerTypeCounts.has(limitedTo)) {
      matchingPassengers = passengerTypeCounts.get(limitedTo)!.slice(0, amount);
    } else if (limitedTo === '') {
      // if product doesn't have passenger type then assign to passengers in ascending passenger id order
      passengers.sort((a, b) => a.id - b.id);
      matchingPassengers = passengers.slice(0, amount);
    }
    passengerIndexes = matchingPassengers.map((passenger: API.ExtendedPassengerAndPet) => passenger.id);
  }

  return passengerIndexes.length > 0 ? passengerIndexes : [1];
};

export interface ProductAmount {
  code: string;
  subtype: ProductSubType | null | undefined;
  amount: number;
  limitedTo: (API.PassengerType | null)[] | null | undefined;
}
