/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const reserveBooking = /* GraphQL */ `
  mutation ReserveBooking($input: ReserveBooking!) {
    reserveBooking(input: $input) {
      ... on BookingReservation {
        accommodations {
          code
          type
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          passengers {
            amount
            type
          }
        }
        agreement {
          code
          status
          validationCode
        }
        contactInfo {
          firstName
          lastName
          birthdate
          address
          city
          postCode
          country
          nationality
          email
          countryCallCode
          mobile
          termsOfServiceOk
          privacyPolicyOk
          newsletterOk
        }
        bookingDetail {
          reference
          referenceHash
          status
          date
          time
          expiryDate
        }
        onboards {
          amount
          type
          code
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
        }
        passengers {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        passengerDetails {
          id
          title
          firstName
          lastName
          birthdate
          age
          gender
          nationality
          type
          document {
            type
            number
            nationality
            expiryDate
          }
        }
        pets {
          id
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          type
        }
        sailings {
          leg {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          departureDate
          departureTime
          departurePortCode
          departurePortName
          departurePortDesc
          arrivalDate
          arrivalTime
          arrivalPortCode
          arrivalPortName
          arrivalPortDesc
          eventCode
          offerCode
          shipCode
          shipName
          shipDesc
          sailingCode
          tariff
        }
        total {
          total
          commission
          tax
        }
        vehicles {
          id
          type
          lengthCm
          heightCm
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          mark
          model
          registrationId
        }
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const changeReservationVehicles = /* GraphQL */ `
  mutation ChangeReservationVehicles($input: ReservationVehicleChangeInput!) {
    changeReservationVehicles(input: $input) {
      ... on ReservationVehicleChange {
        bookingDetail {
          reference
          referenceHash
          status
          date
          time
          expiryDate
        }
        vehicles {
          id
          type
          lengthCm
          heightCm
          legs {
            leg
            chargeInfo {
              charge
              discount
              discountDesc
              discountStarClub
              tax
              taxes {
                taxcode
                taxtype
                taxdescription
                taxamount
              }
            }
          }
          mark
          model
          registrationId
        }
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const sendReservationEmail = /* GraphQL */ `
  mutation SendReservationEmail($input: ReservationEmailInput!) {
    sendReservationEmail(input: $input) {
      ... on ReservationEmail {
        result
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
    }
  }
`;
export const startPayment = /* GraphQL */ `
  mutation StartPayment($input: PaymentStartInput!) {
    startPayment(input: $input) {
      ... on PaymentStart {
        redirectUrl
        transactionId
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
      ... on PaymentError {
        paymentErrorCode
        paymentErrorMessage
      }
    }
  }
`;
export const verifyPayment = /* GraphQL */ `
  mutation VerifyPayment($input: PaymentVerifyInput!) {
    verifyPayment(input: $input) {
      ... on PaymentVerify {
        bookingDetail {
          reference
          referenceHash
          status
          date
          time
          expiryDate
        }
        transactionId
      }
      ... on ApiError {
        errorCode
        errorMessage
        productCode
      }
      ... on PaymentError {
        paymentErrorCode
        paymentErrorMessage
      }
    }
  }
`;
