import { parseISO } from 'date-fns';
import { List } from 'purify-ts';
import { Just, Maybe } from 'purify-ts/Maybe';
import { ExtendedSailing, TripType } from '../types';
import { findClosest } from '../utils/sailingUtils';
import { settings } from '../../settings';

const sailingIsAfter = (
  sailing: { date: string; time: string },
  compareTo: { date: string; time: string } | Date,
  trip: TripType,
  maxHours?: number
) => {
  const maxMinutes = 709;
  const maxMilliseconds = trip === TripType.DAYCRUISE ? maxMinutes * 60 * 1000 : (maxHours || 0) * 60 * 60 * 1000;

  const compareDate: Date = compareTo instanceof Date ? compareTo : parseISO(compareTo.date + ' ' + compareTo.time);

  const sailingDateStart = parseISO(sailing.date + ' ' + sailing.time).getTime();
  const sailingDateEnd = parseISO(sailing.date + ' ' + sailing.time).getTime() + maxMilliseconds;
  return sailingDateStart <= compareDate.getTime() && compareDate.getTime() <= sailingDateEnd;
};

export const findCruisePairFromSailings = (
  outboundSailings: ExtendedSailing[],
  inboundSailings: ExtendedSailing[],
  outboundInitialSelection: Maybe<ExtendedSailing>,
  trip: TripType
): {
  outboundSailing: Maybe<ExtendedSailing>;
  inboundSailing: Maybe<ExtendedSailing>;
} => {
  return outboundInitialSelection
    .map((selectedSailing) => {
      return findCruisePairMaybe(
        selectedSailing.departurePort,
        selectedSailing.shipName,
        {
          arrivalDate: selectedSailing.arrivalDate,
          arrivalTime: selectedSailing.arrivalTime,
          departureDate: selectedSailing.departureDate,
          departureTime: selectedSailing.departureTime,
        },
        inboundSailings,
        trip
      ).caseOf({
        // Cruise pair was found
        Just: (selectedInbound) => ({
          outboundSailing: outboundInitialSelection,
          inboundSailing: Just(selectedInbound),
        }),
        // inbound sailing not found for selected outbound
        // select next available outbound sailing and try to find a return
        // pair for it
        Nothing: () => {
          const newOutboundSelection = findClosest(outboundSailings, selectedSailing);
          return findCruisePairFromSailings(outboundSailings, inboundSailings, newOutboundSelection, trip);
        },
      });
      // Outbound selection didn't have value, return defaults
    })
    .orDefault({
      outboundSailing: outboundInitialSelection,
      inboundSailing: Maybe.fromNullable(),
    });
};

const areSailingsWithinCruiseTimeLimit = (
  selectedSailing: {
    arrivalDate: string;
    arrivalTime: string;
    departureDate: string;
    departureTime: string;
  },
  compareToSailing: ExtendedSailing,
  trip: TripType,
  selectedSailingIndex?: number,
  maxHours?: number
) => {
  if (selectedSailingIndex && selectedSailingIndex === 1) {
    return sailingIsAfter(
      { date: compareToSailing.arrivalDate, time: compareToSailing.arrivalTime },
      { date: selectedSailing.departureDate, time: selectedSailing.departureTime },
      trip,
      maxHours
    );
  } else {
    return sailingIsAfter(
      { date: selectedSailing.arrivalDate, time: selectedSailing.arrivalTime },
      { date: compareToSailing.departureDate, time: compareToSailing.departureTime },
      trip,
      maxHours
    );
  }
};

const isPossibleShipPair = (
  selectedShipName: string | null | undefined,
  possiblePairShipName: string | null | undefined,
  type: TripType
) => {
  return type === TripType.OVERNIGHT_CRUISE
    ? selectedShipName === possiblePairShipName
    : selectedShipName !== possiblePairShipName;
};

export const findCruisePairMaybe = (
  selectedDeparturePort: string,
  selectedShipName: string | null | undefined,
  selectedTimes: {
    arrivalDate: string;
    arrivalTime: string;
    departureDate: string;
    departureTime: string;
  },
  sailings: ExtendedSailing[],
  trip: TripType,
  selectedSailingIndex?: number
): Maybe<ExtendedSailing> => {
  const maxHours = ['FIHEL', 'DETRV'].includes(selectedDeparturePort)
    ? settings.cruiseFihelDetrvMaxHours
    : settings.cruiseMaxHours;
  return List.find(
    (sailing) =>
      isPossibleShipPair(selectedShipName, sailing.shipName, trip) &&
      areSailingsWithinCruiseTimeLimit(selectedTimes, sailing, trip, selectedSailingIndex, maxHours),
    sailings || []
  );
};

export const getOfferCodeForCruise = (originalOfferCode: string | undefined, offerCodeValid: boolean): string => {
  if (!!originalOfferCode && offerCodeValid) {
    return originalOfferCode;
  }
  return settings.defaultCruiseOfferCode;
};

export const getCruisePairIndex = (selectedSailingIndex: number) => (selectedSailingIndex === 0 ? 1 : 0);

export const getCruisePairLeg = (selectedSailingLeg: number) => (selectedSailingLeg === 1 ? 2 : 1);

export const getSecondSailing = (tripType: TripType, findCruiseParams: any, otherSailings: ExtendedSailing[]) => {
  if (findCruiseParams.selectedTimes) {
    const selectedSailing = findCruisePairMaybe(
      findCruiseParams.departurePort,
      findCruiseParams.shipName,
      findCruiseParams.selectedTimes,
      otherSailings,
      tripType
    );
    return selectedSailing.caseOf({
      Nothing: () => ({} as any),
      Just: (otherSailing) => otherSailing,
    });
  }
  return undefined;
};

export const isCruiseType = (trip: TripType) => trip === TripType.DAYCRUISE || trip === TripType.OVERNIGHT_CRUISE;

export const isDefaultOfferCode = (offerCode: String | undefined) =>
  offerCode === settings.defaultCruiseOfferCode || offerCode === undefined;
