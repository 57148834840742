import React from 'react';

import { StyledInput } from '../../helpers/components';

import Icon from '../Icon';

import { WrapperProps } from './useInputGroupState';

export const validation = (error: string | undefined, htmlFor: string) =>
  error && {
    className: 'invalid',
    'aria-describedby': `${htmlFor}-alert`,
    'aria-invalid': true,
  };

const Wrapper: React.FunctionComponent<WrapperProps> = ({
  htmlFor,
  label,
  error,
  hint,
  children,
  select,
  gridSpan,
}) => (
  <StyledInput.Wrapper {...{ gridSpan }}>
    {children}
    <StyledInput.Label as="label" {...{ htmlFor }}>
      {label}
    </StyledInput.Label>
    {error ? (
      <StyledInput.Error role="alert" id={`${htmlFor}-alert`}>
        {error}
      </StyledInput.Error>
    ) : (
      hint && (
        <StyledInput.Hint role="alert" id={`${htmlFor}-alert`}>
          {hint}
        </StyledInput.Hint>
      )
    )}
    {error ? <Icon name="error" error /> : select && <Icon name="down" />}
  </StyledInput.Wrapper>
);

export default Wrapper;
