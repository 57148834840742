import React, { FunctionComponent, useContext, useEffect } from 'react';
import Stepper, { InputProps } from '../../../design-system/components/Stepper';
import * as API from '../../../fsm/types';
import { Subgrid } from '../../../design-system/components/Container';
import { hooks } from '../../../design-system/helpers/mixins';
import { H2, H3, Lead, Price } from '../../../design-system/components/Text';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Button from '../../../design-system/components/Button';
import Modal from '../../../design-system/components/Modal';
import {
  ProductAmount,
  countProducts,
  getCount,
  getProductCounts,
  includedProducts,
  selectableProductsList,
  setStepperValue,
} from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import OnboardDescription from './shared/OnboardDescription';

interface SelectionProps {
  readonly passengers: API.Passengers;
  readonly passengerInfo: API.ExtendedPassengerAndPet[];
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
  readonly onOpen: (sailingCode: string, passengerTypes: API.PassengerType[]) => void;
}

interface LoungeCardProps extends InputProps {
  readonly product: API.ExtendedProduct;
  readonly tariff: API.Tariff;
  readonly unit?: string;
}

const SelectedItem: FunctionComponent<ProductAmount> = ({ code, amount }) => {
  const [sbLounge, ref] = hooks.useStoryblokComponent<HTMLLIElement>({
    path: code || '',
  });

  return (
    <li ref={ref}>
      {amount} × {sbLounge?.content.title || code}
    </li>
  );
};

const LoungeCard: FunctionComponent<LoungeCardProps> = ({ product, tariff, unit, ...props }) => {
  const [sbLounge, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: product.code || '',
  });

  const { formats } = useContext(LanguageContext);
  const chargeInfo = API.maybeChargeInfo(tariff, product);

  return (
    <Stepper {...props} innerRef={ref}>
      <Lead as="span">{sbLounge?.content.title}</Lead>
      <Price align="left">
        {chargeInfo.mapOrDefault(({ charge }) => formats.currency(charge), '-')} {unit && <Lead as="span">{unit}</Lead>}
      </Price>
    </Stepper>
  );
};

const LoungeSelection: FunctionComponent<SelectionProps> = ({
  passengers,
  passengerInfo,
  sailing,
  add,
  remove,
  onOpen,
}) => {
  const modalState = useDialogState({ animated: false });
  const [sbLoungePackages, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.lounge_packages',
  });
  const [sbLoungePackagesModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.lounge_modal',
  });

  const sbLoungeVariants = Object.values(hooks.useStoryblokComponents('products/onboards/lounge'));
  const loungePackagesDescription = hooks.useStoryblokRichText(sbLoungePackages?.content.lounge_packages_description);

  const accommodations = API.isError(sailing.accommodations) ? [] : sailing.accommodations || [];

  // List of accommodations that contain meals and which meals are included
  const sbAccommodationsWithServices = Object.values(hooks.useStoryblokComponents('products/accommodation_services'))
    .filter(({ content }) => content.lounge_included)
    .map(({ content }) => ({
      productCode: content.product_code,
      productsIncluded: content.lounge_types_included || [],
    }));

  const products = (API.isError(sailing.products) ? null : (sailing.products as API.Products))?.LOUNGE || [];

  const includedLoungeServices = includedProducts(
    accommodations,
    sbAccommodationsWithServices,
    sbLoungeVariants,
    products,
    passengerInfo
  );

  const onboardPackages: API.ExtendedOnboard[] =
    (API.isError(sailing.onboards) ? null : (sailing.onboards as API.ExtendedOnboard[]))?.filter(
      ({ type }) => type === 'LOUNGE'
    ) || [];

  const handleStepperChange = (value: number, product: API.ExtendedProduct) => {
    setStepperValue(product.code, value, onboardPackages, add, remove);
  };

  const passengerTypes = Object.keys(passengers).filter((type) => {
    return passengers[type as API.PassengerType] > 0;
  });

  const selectedOnboards: API.ExtendedOnboard[] =
    (API.isError(sailing.onboards) ? null : (sailing.onboards as API.ExtendedOnboard[]))?.filter(
      ({ type }) => type === API.ProductType.LOUNGE
    ) || [];

  const productCounts = getProductCounts(Object.values(includedLoungeServices).flat());

  countProducts(selectedOnboards, products, productCounts);

  const selectableProducts = selectableProductsList(products, passengers, includedLoungeServices);

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode, passengerTypes as API.PassengerType[]);
    }
  }, [modalState.visible]);

  return !!products.length ? (
    <Subgrid ref={ref}>
      <H2>{sbLoungePackages?.content.title}</H2>
      <OnboardDescription>{loungePackagesDescription}</OnboardDescription>
      {!!productCounts.length && (
        <div>
          <H3>{sbLoungePackages?.content.selected_lounge_packages}</H3>
          <ul>
            {productCounts.map((product) => (
              <SelectedItem key={product.code} {...product} />
            ))}
          </ul>
        </div>
      )}
      <DialogDisclosure disclosure as={Button} round {...modalState}>
        {
          sbLoungePackages?.content[
            onboardPackages.length > 0 ? 'edit_lounge_packages_button' : 'add_lounge_packages_button'
          ]
        }
      </DialogDisclosure>
      <Modal
        ref={modalRef}
        state={modalState}
        title={sbLoungePackagesModal?.content.title}
        focusOnDialog
        submit={sbLoungePackagesModal?.content.ready_button}
        size="input"
      >
        {modalState.visible && (
          <Subgrid>
            {selectableProducts.map((product) => (
              <LoungeCard
                id={`${sailing.sailingCode}-${product.code}`}
                key={product.code}
                product={product}
                tariff={sailing.tariff}
                setValue={(value: number) => handleStepperChange(value - product.included, product)}
                value={getCount(product.code, onboardPackages) + product.included}
                min={product.min}
                max={product.maxSelectable}
                unit={sbLoungePackagesModal?.content.price_unit}
              />
            ))}
          </Subgrid>
        )}
      </Modal>
    </Subgrid>
  ) : (
    <></>
  );
};

export default LoungeSelection;
