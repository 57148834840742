import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { List } from 'purify-ts/List';
import { Maybe, Nothing } from 'purify-ts/Maybe';
import { quoteBooking } from '../../graphql/queries';
import { sailingRequiresAccommodation } from '../../settings';
import logger from '../../utils/logging';
import {
  AccommodationInput,
  ApiError,
  BookingQuote,
  BookingQuoteOrError,
  BookingQuoteQuery,
  BookingSailing,
  Catalog,
  ExtendedAccommodation,
  ExtendedPassengerAndPet,
  ExtendedSailing,
  isApiError,
  isPassengerInput,
  isPetInput,
  PetInput,
  QuoteBookingQuery,
  QuotesByTariff,
  SailingInput,
  SearchParams,
  SelectedSailing,
  Tariff,
  TariffInput,
  Timetable,
  TripType,
  VehicleInput,
} from '../types';
import { getOfferCode, getPreferredSailings, getSelected, indexToLeg } from '../utils/sailingUtils';
import { fetchSailingData, PricingResult } from './api';
import { findCruisePairMaybe, getCruisePairIndex, isCruiseType } from './cruise';
import queue from './queue';
import { hasEnoughPassengerCapacity } from '../utils/catalogUtils';

type StateUpdate = (result: PricingResult) => void;

type RequeryOptions = {
  readonly catalogs: Catalog[];
  readonly sailings: Timetable[];
  readonly searchParams: SearchParams;
  readonly passengers: ExtendedPassengerAndPet[];
  readonly vehicles: VehicleInput[];
  readonly callback?: StateUpdate;
};

type SailingPair = {
  sailing: ExtendedSailing;
  timetable: Timetable;
};

/**
 * When a sailing is sold out, we need to requery the price with the next
 * cheapest accommodation option. Then we patch the old NULL price with the new one.
 */
export async function fetchMissingPrices({
  catalogs,
  sailings,
  passengers,
  searchParams,
  vehicles,
  callback,
}: RequeryOptions): Promise<Timetable[]> {
  const legs = catalogs.map((catalog) => indexToLeg(catalog.index));

  const timetables = await Promise.all(
    catalogs.map((catalog) => {
      const { index } = catalog;
      const otherCatalogs = catalogs.filter((_) => _.index !== index);
      const missingPrices = getSailingsWithMissingPrices(catalog, sailings);
      const preferredSailings = getPreferredSailings(catalog);

      // console.log('fetchMissingPrices, catalog:', index, catalog, searchParams.type)
      // console.log('fetchMissingPrices, missingPrices:', missingPrices.length, missingPrices);
      // console.log(`fetchMissingPrices, missing (${missingPrices.length}) prices:`, missingPrices.map(p => p.sailing.sailingCode), 'on catalog:', catalog);

      // Fetch prices only for initially selected day(s)
      // or departure day has the only one sailing. Trying to get at least one price for price ribbon.
      const selectedDates = searchParams.forms.map((item) => item.selectedDate);
      const sailingsToRefetch = isCruiseType(searchParams.type)
        ? missingPrices // TODO, fetch prices for same ship on return leg
        : missingPrices.filter(
            (ss) =>
              selectedDates.includes(ss.sailing.departureDate) ||
              preferredSailings.find((ps) => ss.sailing.sailingCode === ps.sailingCode)
          );

      // console.log(`fetchMissingPrices, sailingsToRefetch now (${sailingsToRefetch.length} of ${missingPrices.length}):`, sailingsToRefetch.map(p => p.sailing.sailingCode));

      return Promise.all(
        sailingsToRefetch.map(({ sailing, timetable }) => {
          console.log(
            `fetchMissingPrices, re-fetching prices for sailing (cat: ${index}):`,
            sailing?.sailingCode,
            sailing?.shipName
          );
          return queue.add(() =>
            fetchPrice(index, legs, otherCatalogs, passengers, sailing, searchParams, timetable, vehicles, callback)
          );
        })
      );
    })
  );

  // The resulting array is nested from catalog level, so we need to flat it by two levels.
  return timetables.flat(2);
}

const getSailingsWithMissingPrices = (catalog: Catalog, sailings: Timetable[]): SailingPair[] => {
  return catalog.sailings.reduce(
    (pairs, sailing) =>
      List.find(({ sailingCode }) => sailingCode === sailing.sailingCode, sailings).mapOrDefault(
        (timetable) => pairs.concat({ sailing, timetable }),
        pairs
      ),
    [] as SailingPair[]
  );
};

const createSailingsForTripType = (
  searchParamType: TripType,
  sailing: ExtendedSailing,
  otherCatalogs: Catalog[],
  index: number,
  tariff: TariffInput.BOTH,
  offerCode: string | undefined
) => {
  switch (searchParamType) {
    case TripType.OVERNIGHT_CRUISE:
    case TripType.DAYCRUISE:
      return createSailingsForCruise(searchParamType, sailing, otherCatalogs[0].sailings, index, tariff, offerCode);
    default:
      return createSailings(index, otherCatalogs, sailing, tariff, offerCode);
  }
};

const fetchPrice = async (
  index: number,
  legs: number[],
  otherCatalogs: Catalog[],
  passengers: ExtendedPassengerAndPet[],
  sailing: ExtendedSailing,
  searchParams: SearchParams,
  timetable: Timetable,
  vehicles: VehicleInput[],
  callback?: StateUpdate
): Promise<Timetable> => {
  const { offerCode, currency, language, starclub } = searchParams;
  const people = passengers.filter(isPassengerInput);
  const animals = passengers.filter(isPetInput);
  const tariff = TariffInput.BOTH;

  const defaultAccommodations = await fetchDefaultAccommodations(searchParams, passengers, sailing, index);

  const { products } = await fetchSailingData({
    ...searchParams,
    passengers,
    sailing,
    index,
  });

  let sailings: SailingInput[];

  sailings = createSailingsForTripType(searchParams.type, sailing, otherCatalogs, index, tariff, offerCode);

  if (
    sailings.length === 0 ||
    (sailingRequiresAccommodation(sailing, searchParams.type, animals.length > 0) && defaultAccommodations.length === 0) ||
    !hasEnoughPassengerCapacity(products, passengers)
  ) {
    return timetable;
  }

  const baseQuery: BookingQuoteQuery = {
    currency,
    language,
    agreementActive: starclub,
    sailings: sailings,
    passengers: people.map((person) => ({
      legs,
      id: person.id,
      type: person.type,
    })),
    pets: animals.map((animal) => ({
      legs,
      id: animal.id,
      type: animal.type,
    })),
    vehicles: vehicles.map((vehicle) => ({
      legs,
      id: vehicle.id,
      type: vehicle.type,
      heightCm: vehicle.heightCm,
      lengthCm: vehicle.lengthCm,
      make: vehicle.make,
      model: vehicle.model,
      registrationId: vehicle.registrationId,
    })),
    accommodations: [],
    onboards: [],
  };

  const accommodationMandatory = sailingRequiresAccommodation(sailing, searchParams.type, animals.length > 0);

  // Request a new price once without default accommodation
  if (!accommodationMandatory && defaultAccommodations.length === 0) {
    let quotes: QuotesByTariff | null = null;

    try {
      quotes = await fetchQuotes(baseQuery, sailing.sailingCode);
    } catch (error) {
      logger.debug(`No price found for sailing ${timetable.sailingCode} (w/o acc)`);

      return timetable;
    }

    const total = extractPriceFromQuotes(searchParams.type, quotes, indexToLeg(index));

    logger.debug(`Assigning a new price of ${total} for sailing ${timetable.sailingCode} (w/o acc)`);

    const withPrice: Timetable = {
      ...timetable,
      chargeTotal: total,
    };

    if (callback) {
      callback({
        error: Nothing,
        sailings: [withPrice],
      });
    }

    return withPrice;
  }

  let accommodations: AccommodationInput[] = await createAccommodations(
    index,
    otherCatalogs,
    searchParams,
    passengers,
    defaultAccommodations,
    people,
    animals
  );
  if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
    const maybe = Maybe.fromNullable(sailing);
    const findCruiseParams = maybe.caseOf({
      Nothing: () => ({} as any),
      Just: (sailing) => ({
        departurePort: sailing.departurePort,
        shipName: sailing.shipName,
        selectedTimes: {
          arrivalDate: sailing.arrivalDate,
          arrivalTime: sailing.arrivalTime,
          departureDate: sailing.departureDate,
          departureTime: sailing.departureTime,
        },
      }),
    });

    const cruisePair = findCruiseParams.selectedTimes
      ? findCruisePairMaybe(
          findCruiseParams.departurePort,
          findCruiseParams.shipName,
          findCruiseParams.selectedTimes,
          otherCatalogs[0].sailings,
          TripType.OVERNIGHT_CRUISE,
          index
        )
      : (Maybe.fromNullable() as Maybe<ExtendedSailing>);
    if (cruisePair.isJust()) {
      // override previous value on purpose, by default the selected sailing on return catalog is used for accommodation, but
      // in case of cruise it might be before the outbound that we are fetching price for so let's use another function
      // to create accommodations for cruise
      accommodations = await createCruiseAccommodations(
        index,
        cruisePair.extract(),
        searchParams,
        passengers,
        defaultAccommodations,
        people,
        animals
      );
    }
  }

  let quotes: QuotesByTariff | null = null;

  if (!accommodationMandatory || accommodations.length > 0) {
    try {
      quotes = await fetchQuotes({ ...baseQuery, accommodations }, sailing.sailingCode);
    } catch (error) {
      logger.info(`No price found for sailing ${timetable.sailingCode} (w/acc)`);
    }
  }

  const total = quotes ? extractPriceFromQuotes(searchParams.type, quotes, indexToLeg(index)) : 0;

  if (total <= 0) {
    logger.debug(`No price found for sailing ${timetable.sailingCode}, continuing on next one...`);
    return timetable;
  }

  logger.debug(`Assigning a new price of ${total} for sailing ${timetable.sailingCode} (w/acc)`);

  const withPrice: Timetable = {
    ...timetable,
    chargeTotal: total,
  };

  if (callback) {
    callback({
      error: Nothing,
      sailings: [withPrice],
    });
  }

  return withPrice;
};

const createSailings = (
  index: number,
  otherCatalogs: Catalog[],
  sailing: ExtendedSailing,
  tariff: TariffInput,
  offerCode?: string
) => {
  const firstSailing: SailingInput = sailingToSailingInput(sailing, index, tariff, offerCode);

  const restSailings: SailingInput[] = otherCatalogs.map((otherCatalog) => {
    const selected = Maybe.fromNullable(otherCatalog.selected).orDefault({} as SelectedSailing);

    return List.find((_) => _.sailingCode === selected.sailingCode, otherCatalog.sailings).caseOf({
      Nothing: () => ({} as SailingInput),
      Just: (otherSailing) => sailingToSailingInput(otherSailing, otherCatalog.index, tariff, offerCode),
    });
  });

  return [firstSailing, ...restSailings];
};

const createSailingsForCruise = (
  tripType: TripType,
  sailing: ExtendedSailing,
  otherCatalogSailings: ExtendedSailing[],
  selectedSailingIndex: number,
  tariff: TariffInput,
  offerCode: string | undefined
): SailingInput[] => {
  const maybe = Maybe.fromNullable(sailing);
  const findCruiseParams = maybe.caseOf({
    Nothing: () => ({} as any),
    Just: (sailing) => ({
      departurePort: sailing.departurePort,
      shipName: sailing.shipName,
      selectedTimes: {
        arrivalDate: sailing.arrivalDate,
        arrivalTime: sailing.arrivalTime,
        departureDate: sailing.departureDate,
        departureTime: sailing.departureTime,
      },
    }),
  });

  const cruisePair = findCruiseParams.selectedTimes
    ? findCruisePairMaybe(
        findCruiseParams.departurePort,
        findCruiseParams.shipName,
        findCruiseParams.selectedTimes,
        otherCatalogSailings,
        tripType,
        selectedSailingIndex
      )
    : (Maybe.fromNullable() as Maybe<ExtendedSailing>);
  const actualOfferCode = getOfferCode(TripType.OVERNIGHT_CRUISE, offerCode, sailing.meta?.offercodeViable?.SPECIAL);
  const sailingWithMissingPrice = sailingToSailingInput(sailing, selectedSailingIndex, tariff, actualOfferCode);
  return cruisePair.caseOf({
    Nothing: () => [],
    Just: (otherSailing) => {
      const cruisePairSailing = sailingToSailingInput(
        otherSailing,
        getCruisePairIndex(selectedSailingIndex),
        tariff,
        actualOfferCode
      );
      return [sailingWithMissingPrice, cruisePairSailing];
    },
  });
};

export const sailingToSailingInput = (
  sailing: ExtendedSailing,
  sailingIndex: number,
  tariff: TariffInput,
  offerCode?: string
): SailingInput => ({
  leg: indexToLeg(sailingIndex),
  arrivalPortCode: sailing.arrivalPort,
  departureDate: sailing.departureDate,
  departurePortCode: sailing.departurePort,
  departureTime: sailing.departureTime,
  tariff,
  offerCode,
});

const createAccommodations = async (
  index: number,
  otherCatalogs: Catalog[],
  searchParams: SearchParams,
  passengers: ExtendedPassengerAndPet[],
  selectedSailingDefaultAccommodations: ExtendedAccommodation[],
  people: ExtendedPassengerAndPet[],
  animals: PetInput[]
) => {
  const selectedSailingAccommodations: AccommodationInput[] = selectedSailingDefaultAccommodations.map(
    (accommodation) => ({
      legs: [indexToLeg(index)],
      code: accommodation.code,
      type: accommodation.type,
      passengers: (accommodation.passengers || []).filter((id) => people.find((person) => person.id === id)),
      pets: (accommodation.passengers || []).filter((id) => animals.find((animal) => animal.id === id)),
    })
  );

  const restAccommodations: AccommodationInput[] = await otherCatalogs.reduce(
    (promise, otherCatalog) =>
      promise.then(async (accommodations) => {
        const otherSailing = getSelected(otherCatalog).extract();

        const defaultAccommodations = await fetchDefaultAccommodations(searchParams, passengers, otherSailing, index);

        if (defaultAccommodations) {
          return accommodations.concat(
            defaultAccommodations.map((accommodation) => ({
              legs: [indexToLeg(otherCatalog.index)],
              code: accommodation.code,
              type: accommodation.type,
              passengers: (accommodation.passengers || []).filter((id) => people.find((person) => person.id === id)),
              pets: (accommodation.passengers || []).filter((id) => animals.find((animal) => animal.id === id)),
            }))
          );
        }
        return accommodations;
      }),
    Promise.resolve([] as AccommodationInput[])
  );

  return [...selectedSailingAccommodations, ...restAccommodations];
};

const createCruiseAccommodations = async (
  selectedSailingIndex: number,
  cruisePair: ExtendedSailing,
  searchParams: SearchParams,
  passengers: ExtendedPassengerAndPet[],
  selectedLegDefaultAccommodations: ExtendedAccommodation[],
  people: ExtendedPassengerAndPet[],
  animals: PetInput[]
) => {
  const selectedLegAccommodations: AccommodationInput[] = selectedLegDefaultAccommodations.map((accommodation) => ({
    legs: [indexToLeg(selectedSailingIndex)],
    code: accommodation.code,
    type: accommodation.type,
    passengers: (accommodation.passengers || []).filter((id) => people.find((person) => person.id === id)),
    pets: (accommodation.passengers || []).filter((id) => animals.find((animal) => animal.id === id)),
  }));

  const cruisePairDefaultAccommodations = await fetchDefaultAccommodations(
    searchParams,
    passengers,
    cruisePair,
    getCruisePairIndex(selectedSailingIndex)
  );

  const cruisePairAccommodations = cruisePairDefaultAccommodations.map((accommodation) => ({
    legs: [indexToLeg(getCruisePairIndex(selectedSailingIndex))],
    code: accommodation.code,
    type: accommodation.type,
    passengers: (accommodation.passengers || []).filter((id) => people.find((person) => person.id === id)),
    pets: (accommodation.passengers || []).filter((id) => animals.find((animal) => animal.id === id)),
  }));

  return [...selectedLegAccommodations, ...cruisePairAccommodations];
};

const fetchDefaultAccommodations = async (
  searchParams: SearchParams,
  passengers: ExtendedPassengerAndPet[],
  sailing: ExtendedSailing | undefined,
  index: number
): Promise<ExtendedAccommodation[]> => {
  if (!sailing) {
    logger.debug(`No default accommodations found with current search parameters`, searchParams);
    return [];
  }

  const { defaultAccommodations = [] } = await fetchSailingData({
    ...searchParams,
    passengers,
    sailing,
    index,
  });

  if (!Array.isArray(defaultAccommodations)) {
    logger.debug(`No default accommodations found for sailing ${sailing.sailingCode}`);
    return [];
  }

  return defaultAccommodations;
};

const fetchQuotes = async (query: BookingQuoteQuery, sailingCode: string): Promise<QuotesByTariff> => {
  logger.info('-> Fetch quotes:', sailingCode, query);

  const { data, errors = [] } = (await API.graphql(
    graphqlOperation(quoteBooking, { query })
  )) as GraphQLResult<QuoteBookingQuery>;
  if (errors.length) logger.warn('<- Fetch quotes FAIL:', errors, data);
  else logger.info('<- Fetch quotes OK:', sailingCode, 'data:', data?.quoteBooking);

  if (errors.length > 0 || !data || !data.quoteBooking || data.quoteBooking.some(isApiError)) {
    const reasons = ([] as ApiError[]).concat(data ? data.quoteBooking.filter(isApiError) : []);
    if (reasons.length) {
      const message: string = reasons.map((reason: any) => `${reason.errorCode} - ${reason.errorMessage}`)[0];
      logger.info(`Could not fetch prices, ${message}`);
    } else {
      logger.error(`Could not fetch prices. Reason: ${JSON.stringify(reasons)}`);
    }
    throw new Error();
  }

  return getPricesByTariff(data.quoteBooking as BookingQuote[]);
};

export const getPricesByTariff = (quoteBooking: BookingQuote[]): QuotesByTariff => {
  return quoteBooking.reduce((byTariff: QuotesByTariff, quoteOrError: BookingQuoteOrError) => {
    if (isApiError(quoteOrError)) {
      return byTariff;
    }
    const quote: BookingQuote = quoteOrError;
    if (!quote.sailings) {
      return byTariff;
    } else {
      return groupByTariff(byTariff, quote);
    }
  }, {} as QuotesByTariff);
};

const groupByTariff = (byTariff: QuotesByTariff, quote: BookingQuote): QuotesByTariff => {
  const total = quote.total.total || 0;
  const groupedTariffs = (quote.sailings || []).reduce(
    (acc: QuotesByTariff, { tariff, leg, offerCode }: BookingSailing) => {
      // TODO This might need leg charge comparison too. Priority is on prices with offercode if applicable.
      if (tariff === Tariff.SPECIAL) {
        const previousSpecialPrice = acc.SPECIAL?.total.total as number;
        logger.debug(
          'Check tariff:',
          tariff,
          offerCode,
          'total:',
          total,
          'leg.charge:',
          leg.chargeInfo.charge,
          'discount:',
          leg.chargeInfo.discount,
          'previous:',
          previousSpecialPrice
        );
        return previousSpecialPrice < total
          ? acc
          : ({
              ...acc,
              [tariff]: quote,
            } as QuotesByTariff);
      } else if (tariff === Tariff.STANDARD) {
        const previousStandardPrice = acc.STANDARD?.total.total as number;
        logger.debug(
          'Check tariff:',
          tariff,
          offerCode,
          'total:',
          total,
          'leg.charge:',
          leg.chargeInfo.charge,
          'discount:',
          leg.chargeInfo.discount,
          'previous:',
          previousStandardPrice
        );
        return previousStandardPrice < total
          ? acc
          : ({
              ...acc,
              [tariff]: quote,
            } as QuotesByTariff);
      } else {
        return {} as QuotesByTariff;
      }
    },
    byTariff
  );
  return groupedTariffs;
};

const extractPriceFromQuotes = (type: TripType, quotes: QuotesByTariff, leg = 0): number => {
  const specialSailing = quotes.SPECIAL?.sailings.find((s) => s.leg.leg === leg);
  const standardSailing = quotes.STANDARD?.sailings.find((s) => s.leg.leg === leg);
  const special: number = specialSailing?.leg?.chargeInfo?.charge || 0;
  const standard: number = standardSailing?.leg?.chargeInfo?.charge || 0;
  const specialOfferCode = specialSailing?.offerCode;
  const standardOfferCode = standardSailing?.offerCode;

  // Grimaldi backend doesn't support cruise, and thus cruises are implemented there with
  // offer codes. If offercode in quote is empty, it means that cruise is not possible
  if (!isCruiseType(type) || (!!specialOfferCode && !!standardOfferCode)) {
    if (special && standard) {
      return Math.min(standard, special);
    } else if (special) {
      return special;
    } else if (standard) {
      return standard;
    }
  }
  logger.error('No prices (for any tariff) in quotes:', quotes);
  return 0;
};
