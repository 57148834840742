import styled from 'styled-components';

import { color, font } from '../helpers/vars';
import { typography, style } from '../helpers/mixins';

const Label = styled.div`
  ${typography.p};
  font-weight: ${font.weight.strong.lead};
  color: ${color.text.invert};
  padding: 0.25rem 0.75rem;
  width: fit-content;
  ${style.label.shape(color.bg.label.default)}
`;

/** @component */
export default Label;
