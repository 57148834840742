import { parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useState, useContext, useMemo } from 'react';
import DatePicker, { SingleDates } from '../../../design-system/components/DatePicker';
import { hooks } from '../../../design-system/helpers/mixins';
import { Form, isFormEqual, Port, PortMap, TripType } from '../../../fsm/types';
import { usePrevious } from '../../common/usePrevious';
import { createForm, isCruiseDestination, isCruisePort } from '../common';
import TripPicker from '../TripPicker';
import { isCruiseType } from '../../../fsm/api/cruise';
import { defaultDaycruisePortsByLang } from '../../../fsm/search/rules';
import { LanguageContext } from '../../../Language.context';

interface SingleFormProps {
  form: Form;
  returnForm?: Form;
  portMap: PortMap[];
  range: number;
  disableBefore?: string;
  type: TripType;
  cruisePorts?: Port[];
  update: (form: Form) => void;
}

const SingleForm: FunctionComponent<SingleFormProps> = ({
  form,
  returnForm,
  portMap,
  type,
  range,
  disableBefore,
  update,
  cruisePorts,
}) => {
  const [dep, setDep] = useState<Port>(form.params.departurePort || Port.FIHEL);

  const [dest, setDest] = useState<Port>(form.params.arrivalPort);

  const [singleDates, setSingleDates] = useState<SingleDates>(() => ({
    selectedDay: parseISO(form.selectedDate),
  }));

  const prevForm = usePrevious<Form>(form);

  const [disabledDays, setDisabledDays] = useState<{ before: Date }>(() => ({
    before: disableBefore ? parseISO(disableBefore) : parseISO(form.searchedDate),
  }));

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (disableBefore) {
      setDisabledDays({ before: parseISO(disableBefore) });
    }
  }, [disableBefore]);

  useEffect(() => {
    if (prevForm && !isFormEqual(form, prevForm)) {
      setSingleDates({ selectedDay: parseISO(form.selectedDate) });
    }
  }, [form.selectedDate, setSingleDates, form, prevForm]);

  const [sbSearchForm] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'search.sailings_search_form',
  });

  useEffect(() => {
    // console.log('Effect, trip:', type, 'singleDates', singleDates, dep, dest, range, 'returnForm', returnForm);
    const updatedDepForm = createForm(form, dep, dest, singleDates.selectedDay, range);
    if (prevForm && isFormEqual(updatedDepForm, prevForm)) return;
    update(updatedDepForm);
    if (isCruiseType(type) && returnForm) {
      update(createForm(returnForm, dest, dep, singleDates.selectedDay, range, type));
    }
  }, [dep, dest, singleDates.selectedDay, type, range, returnForm, update, form, prevForm]);

  // Shrinks current portMap to match cruise trip options
  const cruisePortMap = useMemo(() => portMap
      .filter((pmItem) => isCruisePort(pmItem, cruisePorts))
      .map((pmItem) => ({
          ...pmItem,
          to: pmItem.to.filter((toPort) => isCruiseDestination(pmItem.from.code, toPort.code, cruisePorts)),
      })), [portMap, cruisePorts]);

  return (
    <>
      <TripPicker
        portMap={isCruiseType(type) ? cruisePortMap : portMap}
        setDep={setDep}
        setDest={setDest}
        dep={dep}
        dest={dest}
        fallbackDep={type === TripType.DAYCRUISE ? defaultDaycruisePortsByLang[language].from || Port.FINLI : undefined }
      />

      <DatePicker.Single
        {...{ dates: singleDates, setDates: setSingleDates, disabledDays }}
        submit={sbSearchForm?.content['ready_button']}
        label={sbSearchForm?.content['departure_date']}
        title={sbSearchForm?.content['select_travel_date']}
      ></DatePicker.Single>
    </>
  );
};

export default SingleForm;
