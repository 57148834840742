import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { format, parse } from 'date-fns';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { LoaderAction, LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import { MyBookingContext } from '../my-booking/myBookingMachine';
import { PaymentContext } from '../payment/paymentMachine';
import { getOfferCode, indexToLeg, isOfferCodeValid } from '../utils/sailingUtils';
import {
  AccommodationInput,
  ApiError,
  BookingReservation,
  ChangeReservationVehiclesMutation,
  ContactInfoInput,
  ErrorCode,
  ExtendedPassenger,
  GetReservationQuery,
  isApiError,
  isPassengerInput,
  isPetInput,
  isReservationActive,
  OnboardInput,
  PassengerDetailInput,
  PetInput,
  ReserveBookingMutation,
  SailingInput,
  SendReservationEmailMutation,
  Tariff,
  TariffInput,
} from '../types';
import {
  createAccommodationInputs,
  createOnboardInputs,
  createPassengerInputs,
  createPetInputs,
  createVehicleInputs,
} from './transform';
import logger from '../../utils/logging';

function formatBirthdate(birthdate?: string): string | undefined {
  return birthdate ? format(parse(birthdate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : undefined;
}

function formatTariff(tariff: Tariff): TariffInput {
  switch (tariff) {
    case Tariff.SPECIAL:
      return TariffInput.SPECIAL;
    case Tariff.STANDARD:
      return TariffInput.STANDARD;
    default:
      throw new Error(`Unknown tariff ${tariff}`);
  }
}

export const fetchReservation = async ({
  type,
  data,
}: LoaderAction<MyBookingContext, LOADER_ACTION_EVENTS>): Promise<any> => {
  const { currency, language, reservationUrl } = data.context;

  try {
    const result = await API.graphql(
      graphqlOperation(queries.getReservation, {
        query: {
          currency,
          reservationUrl,
          language: language.toUpperCase(),
        },
      })
    );

    const { data, errors } = result as GraphQLResult<GetReservationQuery>;

    if (errors || !data || !data.getReservation || isApiError(data.getReservation)) {
      console.error('Failed to load reservation', errors || data);
      return Promise.reject(errors || data?.getReservation);
    } else {
      return { type, reservation: data.getReservation };
    }
  } catch (e) {
    console.error('fetchReservation', e);
    return Promise.reject(e);
  }
};

export const reserveBooking = async (
  ctx: PaymentContext
): Promise<{ redirectUrl: string | undefined; reservation: BookingReservation }> => {
  try {
    const { currency, language, redirectUrl, reservation, trip } = ctx;

    // If user has already made a option reservation,
    // let's not make a new one until it is no longer active.
    // 10 minutes is the buffer we think should be enough for making payment.
    if (reservation && isReservationActive(reservation, 10)) {
      return { redirectUrl, reservation };
    } else if (trip) {
      const accommodations = trip.sailings.reduce(
        (accommodations, { sailing }) =>
          Array.isArray(sailing.accommodations)
            ? accommodations.concat(createAccommodationInputs(sailing.accommodations, trip.passengers))
            : accommodations,
        [] as AccommodationInput[]
      );

      const onboards = trip.sailings.reduce(
        (onboards, { sailing }) =>
          Array.isArray(sailing.onboards) ? onboards.concat(createOnboardInputs(sailing.onboards)) : onboards,
        [] as OnboardInput[]
      );

      const passengers: ExtendedPassenger[] = trip.passengers.filter(isPassengerInput);
      const pets: PetInput[] = trip.passengers.filter(isPetInput);

      const contactInfos = passengers
        .filter((p) => p.info?.reserverInfo)
        .reduce(
          (contactInfos, { info, id }) =>
            info
              ? contactInfos.concat({
                  id,
                  address: info.reserverInfo?.address,
                  birthdate: formatBirthdate(info.birth),
                  city: info.reserverInfo?.city,
                  country: info.reserverInfo?.country,
                  countryCallCode: info.reserverInfo?.countryCallCode,
                  email: info.reserverInfo?.email,
                  firstName: info.firstname,
                  lastName: info.lastname,
                  mobile: info.reserverInfo?.phone?.replace(info.reserverInfo?.countryCallCode || '', ''),
                  nationality: info.nationality,
                  gender: info.gender,
                  postCode: info.reserverInfo?.postalCode,
                  newsletterOk: info.reserverInfo?.newsletterOk, // TODO
                  privacyPolicyOk: info.reserverInfo?.privacyPolicyOk, // TODO
                  termsOfServiceOk: info.reserverInfo?.termsOfServiceOk, // TODO
                } as ContactInfoInput)
              : contactInfos,
          [] as ContactInfoInput[]
        );

      const passengerDetails = passengers.reduce(
        (details, { id, info, type }) =>
          info
            ? details.concat({
                id,
                type,
                birthdate: formatBirthdate(info.birth),
                firstName: info.firstname,
                gender: info.gender,
                lastName: info.lastname,
                nationality: info.nationality,
                specialNeeds: info.specialNeeds,
                starclub: info.starclub,
              } as PassengerDetailInput)
            : details,
        [] as PassengerDetailInput[]
      );

      const passengerInputs = createPassengerInputs(passengers);
      const petInputs = createPetInputs(pets);

      const sailings = trip.sailings.map(
        ({ index, sailing }) =>
          ({
            leg: indexToLeg(index),
            departureDate: sailing.departureDate,
            departureTime: sailing.departureTime,
            departurePortCode: sailing.departurePort,
            arrivalPortCode: sailing.arrivalPort,
            eventCode: undefined,
            offerCode: getOfferCode(
              trip.type,
              sailing.offerCode,
              isOfferCodeValid(sailing.meta, formatTariff(sailing.tariff))
            ),
            tariff: formatTariff(sailing.tariff),
          } as SailingInput)
      );

      const vehicles = createVehicleInputs(trip.vehicles);

      const input = {
        accommodations,
        currency,
        language,
        onboards,
        passengerDetails,
        sailings,
        vehicles,
        contactInfo: contactInfos.length > 0 ? contactInfos[0] : undefined,
        passengers: passengerInputs,
        pets: petInputs,
      };

      logger.info('-> reserveBooking, trip:', trip, 'query', input);

      const result = await API.graphql(
        graphqlOperation(mutations.reserveBooking, {
          input,
        })
      );

      const { data, errors } = result as GraphQLResult<ReserveBookingMutation>;

      logger.info('<- reserveBooking:', errors, data?.reserveBooking);

      if (errors || !data) {
        logger.error('Reservation failed', errors || !data);
        return Promise.reject({
          __typename: 'ApiError',
          errorCode: ErrorCode.SERVER_ERROR,
        } as ApiError);
      } else if (!data.reserveBooking || isApiError(data.reserveBooking)) {
        logger.error('Reservation failed', data);
        return Promise.reject(errors || data?.reserveBooking);
      } else {
        // New reservation created, remove possible old redirect URL.
        return { redirectUrl: undefined, reservation: data.reserveBooking as BookingReservation };
      }
    } else {
      throw new Error('Trip is required for booking reservation.');
    }
  } catch (e) {
    logger.error('reserveBooking', { e });
    return Promise.reject({
      __typename: 'ApiError',
      errorCode: ErrorCode.SERVER_ERROR,
    } as ApiError);
  }
};

export const changeRegistrationVehicles = async (
  ctx: MyBookingContext,
  event: any
): Promise<{ reservation: BookingReservation }> => {
  try {
    const { currency, language, reservation, reservationUrl } = ctx;

    if (reservation) {
      const input = {
        currency,
        language,
        reservationUrl,
        vehicles: Object.values(event.vehicles),
      };

      const result = await API.graphql(
        graphqlOperation(mutations.changeReservationVehicles, {
          input,
        })
      );

      const { data, errors } = result as GraphQLResult<ChangeReservationVehiclesMutation>;

      if (errors || !data) {
        logger.error('Reservation failed (changeRegistrationVehicles)', errors || !data);
        return Promise.reject({
          __typename: 'ApiError',
          errorCode: ErrorCode.SERVER_ERROR,
        } as ApiError);
      } else if (!data.changeReservationVehicles || isApiError(data.changeReservationVehicles)) {
        logger.error('Reservation failed (changeRegistrationVehicles)', data);
        return Promise.reject(errors || data?.changeReservationVehicles);
      } else {
        const { bookingDetail, vehicles } = data.changeReservationVehicles;
        return {
          reservation: {
            ...reservation,
            bookingDetail,
            vehicles,
          } as BookingReservation,
        };
      }
    } else {
      throw new Error('No reservation to update.');
    }
  } catch (e) {
    logger.error('changeRegistrationVehicles', { e });
    return Promise.reject({
      __typename: 'ApiError',
      errorCode: ErrorCode.SERVER_ERROR,
    } as ApiError);
  }
};

export const sendConfirmationEmail = async (ctx: MyBookingContext, event: any): Promise<{ result: string }> => {
  try {
    const { currency, language, reservation, reservationUrl } = ctx;

    if (reservation && event.email) {
      const input = {
        currency,
        language,
        reservationUrl,
        email: event.email,
      };

      const result = await API.graphql(
        graphqlOperation(mutations.sendReservationEmail, {
          input,
        })
      );

      const { data, errors } = result as GraphQLResult<SendReservationEmailMutation>;

      if (errors || !data) {
        console.error('Failed to send email', errors || !data);
        return Promise.reject({
          __typename: 'ApiError',
          errorCode: ErrorCode.SERVER_ERROR,
        } as ApiError);
      } else if (!data.sendReservationEmail || isApiError(data.sendReservationEmail)) {
        console.error('Failed to send email', data);
        return Promise.reject(errors || data?.sendReservationEmail);
      } else {
        const { result } = data.sendReservationEmail;
        return {
          result,
        };
      }
    } else {
      throw new Error('No reservation or valid email address.');
    }
  } catch (e) {
    console.error('sendConfirmationEmail', { e });
    return Promise.reject({
      __typename: 'ApiError',
      errorCode: ErrorCode.SERVER_ERROR,
    } as ApiError);
  }
};
