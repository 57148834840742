import { addDays, differenceInDays, format } from 'date-fns';
import { Form, Port, PortMap, TripType } from '../../fsm/types';

type FormDates = {
  startDate: string;
  endDate: string;
  numberOfDays: string;
};

export const createForm = (
  old: Form,
  departure: Port,
  destination: Port,
  date: Date | undefined,
  range: number,
  tripType?: TripType
): Form => {
  return {
    index: old.index,
    searchedDate: old.searchedDate,
    selectedDate: date ? format(date, 'yyyy-MM-dd') : old.selectedDate,
    params: {
      ...(date ? formDates(date, range, tripType) : old.params),
      departurePort: departure,
      arrivalPort: destination,
    },
  };
};

export const formDates = (date: Date, range: number, tripType?: TripType): FormDates => {
  let startReal: Date = startToEndDate(date, -range);
  let endReal: Date = startToEndDate(date, range);

  if (tripType === TripType.OVERNIGHT_CRUISE) {
    // Create window of return departures for (implicit) cruise selection
    // Max cruise time 36 hours (HEL-TRV) -> look two days ahead
    endReal = startToEndDate(date, range + 2);
  }

  return {
    startDate: format(startReal, 'yyyy-MM-dd'),
    endDate: format(endReal, 'yyyy-MM-dd'),
    /**
     * When calculating days between two dates we need to count the start
     * date as well, hence + 1.
     */
    numberOfDays: (differenceInDays(endReal, startReal) + 1).toString(10),
  };
};

export const startToEndDate = addDays;

// Cruise ports handling (portPair here is similar to e.g. API.Sailing.LegCode)

const splitPorts = (portPair: string) => {
  const ports = portPair.split(/\W/);
  return {
    from: ports[0],
    to: ports[1],
  };
};

export const isCruisePort = (portMap: PortMap, portPairs: Port[] | undefined): boolean => {
  if (!portPairs || !portPairs.length) return false;

  const found = portPairs.find((portPair) => {
    const split = splitPorts(portPair);
    const exists = portMap.to.find((toPort) => toPort.code === split.to && portMap.from.code === split.from);
    return Boolean(exists);
  });
  return Boolean(found);
};

export const isCruiseDestination = (fromPort: Port, toPort: Port, portPairs: Port[] | undefined): boolean => {
  if (!portPairs || !portPairs.length) return false;

  const found = portPairs.find((portPair) => {
    const split = splitPorts(portPair);
    return split.from === fromPort && split.to === toPort;
  });
  return Boolean(found);
};
