import React, { useContext } from 'react';

import SearchActions, { LanguageProps } from '../../design-system/components/SearchActions';

import { Language, LanguageContext } from '../../Language.context';

interface ActionsProps {
  readonly handleLanguageChange: (language: Language) => void;
}

const Actions: React.FunctionComponent<ActionsProps> = ({ handleLanguageChange }) => {
  const { language } = useContext(LanguageContext);

  const languages = [
    {
      label: 'English',
      value: 'EN',
    },
    {
      label: 'suomeksi',
      value: 'FI',
    },
    {
      label: 'auf Deutsch',
      value: 'DE',
    },
    {
      label: 'på svenska',
      value: 'SV',
    },
    {
      label: 'po polsku',
      value: 'PL',
    }
  ] as LanguageProps[];

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => handleLanguageChange(e.target.value as Language);

  return (
    <SearchActions.Container>
      <SearchActions.LanguageSelect label="Select language" {...{ languages, onChange }} value={language} />
    </SearchActions.Container>
  );
};

export default Actions;
