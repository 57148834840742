import React from 'react';
import { DialogDisclosure, useDialogState, DialogStateReturn } from 'reakit/Dialog';
import { CatalogEvent } from '../../../../../fsm/catalog/catalogMachine';
import {
  ExtendedAccommodation,
  ExtendedPassengerAndPet,
  Product,
  Tariff,
  ExtendedSailing,
  SelectedSailing,
  TripType,
} from '../../../../../fsm/types';
import { TreeData } from '../../../../../storyblok';
import * as API from '../../../../../fsm/types';
import { hooks } from '../../../../../design-system/helpers/mixins';

import Button from '../../../../../design-system/components/Button';
import { H2 } from '../../../../../design-system/components/Text';
import Alert from '../../../../../design-system/components/Alert';
import RichText from '../../../../../design-system/components/RichText';

import AccommodationCard from './AccommodationCard';
import PassengerGrouping from './PassengerGrouping';
import { Sender } from 'xstate';
import {
  moveToNew,
  canBeRemovedFrom,
  allCanBeMoved,
  containsPet,
  canAddMoreAccommodations,
  filterToPetCabins,
} from '../../../../../fsm/utils/accommodationUtils';
import { sailingRequiresAccommodation } from '../../../../../settings';
import { indexToLeg } from '../../../../../fsm/utils/sailingUtils';
import { hasPrice } from '../../../../../fsm/utils/catalogUtils';
import { hasPets } from '../../../../../fsm/utils/passengerUtils';

export interface AccommodationSelectProps {
  readonly accommodations: ExtendedAccommodation[];
  readonly accommodationProducts: Product[];
  readonly passengers: ExtendedPassengerAndPet[];
  readonly send: Sender<CatalogEvent>;
  readonly index: number;
  readonly strs?: TreeData;
  readonly sailing: SelectedSailing & ExtendedSailing;
  readonly tariff: Tariff;
  readonly disabled?: boolean;
  readonly tripType: TripType;
}

interface AccommodationAlertProps {
  readonly initialLength: number;
  readonly currentLength: number;
  readonly strs: TreeData;
  readonly modalState: DialogStateReturn;
}

const AccommodationAlert: React.FunctionComponent<AccommodationAlertProps> = ({
  initialLength,
  currentLength,
  strs,
  modalState,
}) => {
  const notMandatory = hooks.useStoryblokRichText(strs?.content.initial_not_mandatory);
  const single = hooks.useStoryblokRichText(strs?.content.initial_single);
  const multiple = hooks.useStoryblokRichText(strs?.content.initial_multiple);

  const severity = initialLength === 0 ? 'info' : initialLength === 1 ? 'success' : 'warning';
  const content = initialLength === 0 ? notMandatory : initialLength === 1 ? single : multiple;

  return (
    <Alert {...{ severity }}>
      <RichText>{content}</RichText>
      {/* <DialogDisclosure secondary small {...modalState} as={Button}>
        {currentLength === 0 ? strs?.content.add_accommodations : strs?.content.edit_accommodations}
      </DialogDisclosure> */}
    </Alert>
  );
};

const AccommodationSelect: React.FunctionComponent<AccommodationSelectProps> = ({
  accommodations,
  accommodationProducts,
  passengers,
  send,
  index,
  strs,
  sailing,
  tariff,
  disabled,
  tripType,
}) => {
  const modalState = useDialogState({ animated: false });
  const [groupingPossible, setGroupingPossible] = React.useState(false);
  const sbPorts = hooks.useStoryblokDatasource('ports');

  const movablePassengers = passengers.filter((p) => {
    const pAccommodation = accommodations.find((a) => a.passengers?.includes(p.id));
    if (pAccommodation?.passengers && pAccommodation.passengers.length === 1) return false;
    return !pAccommodation || canBeRemovedFrom(pAccommodation, p, passengers);
  });

  const newAccProducts = hasPets(movablePassengers) ? filterToPetCabins(accommodationProducts) : accommodationProducts;

  const moveToNewAcc = moveToNew(indexToLeg(index), accommodations, newAccProducts, send);

  const removeAccommodation = (id: number) => {
    const accommodation = accommodations.find((a) => a.id === id);

    if (accommodation) {
      if (!sailingRequiresAccommodation(sailing, tripType, containsPet(accommodation, passengers))) {
        send({
          type: 'REMOVE_ACCOMMODATION',
          data: { accommodationId: accommodation.id, leg: indexToLeg(index) },
        });
      } else {
        const allToRemove = allCanBeMoved(accommodation, accommodations, passengers, accommodationProducts);
        if (allToRemove) {
          allToRemove.forEach((toRemove) => {
            send({
              type: 'ADD_PASSENGER_TO_ACCOMMODATION',
              data: {
                passengerId: toRemove.id,
                leg: indexToLeg(index),
                accommodationId: toRemove.to,
              },
            });
          });
        }
      }
    }
  };

  const showAddAccommodations =
    movablePassengers[0] &&
    sailing.accommodations &&
    canAddMoreAccommodations(passengers, accommodations) &&
    hasPrice(sailing.price);

  // Sort by id in order to maintain accommodation adding order
  const sortedAccommodationList = accommodations.sort(
    (acc1: ExtendedAccommodation, acc2: ExtendedAccommodation): number => acc1.id - acc2.id
  );

  return (
    <>
      <H2>{strs?.content.title}</H2>

      {strs && groupingPossible && sailing.meta && !!sailing.meta.initialAccommodations && (
        <AccommodationAlert
          initialLength={sailing.meta.initialAccommodations || 0}
          currentLength={accommodations.length}
          {...{ strs, modalState }}
        />
      )}

      {sortedAccommodationList.map((accommodation: ExtendedAccommodation) => {
        if (accommodation.code) {
          return (
            <AccommodationCard
              {...{ accommodation, send, tariff, groupingPossible }}
              arrivalPort={sbPorts[sailing.arrivalPort]}
              choices={accommodationProducts}
              departurePort={sbPorts[sailing.departurePort]}
              key={accommodation.id}
              leg={indexToLeg(index)}
              sailingCode={sailing.sailingCode}
              shipName={sailing.shipName || ''}
              passengers={
                accommodation.passengers
                  ?.map((p) => passengers.find((info) => p === info.id))
                  .filter((p) => p) as ExtendedPassengerAndPet[]
              }
              removeAccommodation={
                (!sailingRequiresAccommodation(sailing, tripType, containsPet(accommodation, passengers))) ||
                allCanBeMoved(accommodation, accommodations, passengers, accommodationProducts)
                  ? removeAccommodation
                  : undefined
              }
              tagsDisclosure={
                <DialogDisclosure secondary small {...modalState} as={Button}>
                  {strs?.content.edit_passengers}
                </DialogDisclosure>
              }
              disabled={disabled}
              movablePassengers={movablePassengers || []}
            />
          );
        }
        return null;
      })}

      {showAddAccommodations && (
        <Button
          secondary
          small
          fullWidth
          icon="plus"
          onClick={() => {
            const movable = movablePassengers[0];
            if (movable) {
              const current = accommodations.find((a) => a.passengers?.includes(movable.id));
              moveToNewAcc(movable.id, current?.id || 'NO_ACCOMMODATION', API.ProductSubType.CABIN);
            }
          }}
        >
          {strs?.content.add_accommodations}
        </Button>
      )}

      <PassengerGrouping
        state={modalState}
        leg={indexToLeg(index)}
        {...{
          send,
          sailing,
          accommodations,
          accommodationProducts,
          passengers,
          setGroupingPossible,
          tripType,
        }}
      />
    </>
  );
};

export default AccommodationSelect;
