import { assign } from 'xstate';
import { ActionEvents, LoaderContext, LoaderEvent, LoaderEventData } from './loaderMachine';

const actions = {
  queue: assign<LoaderContext, LoaderEvent>({
    evtQueue: (ctx, evt) => [...ctx.evtQueue, { evt: evt as ActionEvents, timestamp: Date.now(), done: false }],
  }),

  setEventDone: assign<LoaderContext, LoaderEvent>({
    evtQueue: (ctx) => ctx.evtQueue.map((e, i) => ({ ...e, done: i === 0 })),
  }),

  cleanQueue: assign<LoaderContext, LoaderEvent>({
    evtQueue: (ctx) =>
      ctx.evtQueue
        .filter(
          ({ done, evt, timestamp }) =>
            !done &&
            timestamp >=
              Math.max.apply(
                Math,
                ctx.evtQueue.map((o) => (o.evt.type === evt.type ? o.timestamp : -1))
              )
        )
        .reduce(
          (a, b) =>
            a.findIndex((a2) => a2.evt.type === b.evt.type && a2.timestamp === b.timestamp) < 0 ? a.concat(b) : a,
          [] as LoaderEventData[]
        ),
  }),
};

export default actions;
