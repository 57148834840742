import React, { FunctionComponent, useContext } from 'react';
import { Sender } from 'xstate';
import { Subgrid } from '../../../../design-system/components/Container';
import Spinner from '../../../../design-system/components/Spinner';
import Stepper, { InputProps } from '../../../../design-system/components/Stepper';
import { H2, Lead, Price, Small } from '../../../../design-system/components/Text';
import { Transition } from '../../../../design-system/helpers/components';
import { hooks } from '../../../../design-system/helpers/mixins';
import { CatalogEvent } from '../../../../fsm/catalog/catalogMachine';
import * as API from '../../../../fsm/types';
import { getCount, setStepperValue } from '../../../../fsm/utils/productUtils';
import { indexToLeg } from '../../../../fsm/utils/sailingUtils';
import { LanguageContext } from '../../../../Language.context';

export interface ElectricitySelectionProps {
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly electricityProducts: API.ExtendedProduct[];
  readonly send: Sender<CatalogEvent>;
  readonly index: number;
  readonly electricityVehicles: API.ExtendedVehicle[];
}

interface ElectricityRowProps extends InputProps {
  readonly product: API.ExtendedProduct;
  readonly tariff: API.Tariff;
  readonly unit?: string;
}

const ElectricityRow: FunctionComponent<ElectricityRowProps> = ({ product, tariff, unit, ...props }) => {
  const { formats } = useContext(LanguageContext);
  const [sbPackage, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: product.code || '',
  });
  const description = sbPackage?.content.description;
  const charge = API.maybeChargeInfo(tariff, product).map(({ charge }) => formats.currency(charge));

  return (
    <Stepper innerRef={ref} {...props}>
      <Lead as="span">{sbPackage?.content.title || product.desc}</Lead>
      <br />
      <Price inline as="span">
        <Transition.Toggle
          toggle={charge.isJust()}
          as="span"
          whenFalse={<Spinner small inline />}
          whenTrue={charge.extract()}
        />
      </Price>
      {description && (
        <>
          <br />
          <Small as="span">{description}</Small>
        </>
      )}
    </Stepper>
  );
};

const ElectricitySelection: FunctionComponent<ElectricitySelectionProps> = ({
  electricityProducts,
  sailing,
  send,
  index,
  electricityVehicles,
}) => {
  const [sbElectricity, sbElectricityRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'trip.electricity',
  });

  const add = (code: string, amount?: number) => {
    send({ type: 'ADD_ONBOARD', legs: [indexToLeg(index)], code, amount });
  };

  const remove = (code: string, amount?: number) => {
    send({ type: 'REMOVE_ONBOARD', legs: [indexToLeg(index)], code, amount });
  };

  const onboards = (API.isError(sailing.onboards) ? [] : sailing.onboards || []).filter(
    ({ type }) => type === 'ELECTRICITY'
  );

  return (
    <Subgrid ref={sbElectricityRef}>
      <H2>{sbElectricity?.content.title}</H2>
      {electricityProducts.map((product) => (
        <ElectricityRow
          key={product.code}
          product={product}
          tariff={sailing.tariff}
          setValue={(value: number) => setStepperValue(product.code, value, onboards, add, remove)}
          value={getCount(product.code, onboards)}
          min={0}
          max={electricityVehicles.length}
          id={`${sailing.sailingCode}-${product.code}`}
        />
      ))}
    </Subgrid>
  );
};

export default ElectricitySelection;
