import { color } from '../vars';

import style from './style';

export enum Theme {
  DEFAULT,
  ALTERNATIVE,
  INVERTED,
}

const commonTheme = {
  text: {
    copy: color.text.copy,
    heading: color.text.heading,
    price: color.brand,
    input: color.text.input,
  },
  focus: style.focus.default,
  input: {
    background: color.bg.input.default,
  },
  icon: color.icon.default,
  link: style.link.default,
  logo: color.brand,

  error: {
    focus: style.focus.error.default,
  },

  alert: {
    ...color.alert.default,
  },
};

export default {
  [Theme.DEFAULT]: {
    key: Theme.DEFAULT,

    ...commonTheme,

    input: {
      ...commonTheme.input,
      hover: style.input.hover.default,
    },

    bg: color.bg.default,
    border: color.border.default,
  },
  [Theme.ALTERNATIVE]: {
    key: Theme.ALTERNATIVE,

    ...commonTheme,

    input: {
      ...commonTheme.input,
      hover: style.input.hover.alternative,
    },

    bg: color.bg.alt,
    border: color.border.transparent,
  },
  [Theme.INVERTED]: {
    key: Theme.INVERTED,

    text: {
      copy: color.text.invert,
      heading: color.text.invert,
      price: color.text.invert,
      input: color.text.invert,
    },
    focus: style.focus.invert,
    input: {
      background: color.bg.input.invert.field,
      hover: style.input.hover.inverted,
    },
    icon: color.icon.invert,
    link: style.link.invert,
    logo: color.text.invert,

    bg: color.bg.invert,
    border: color.border.transparent,

    error: {
      focus: style.focus.error.invert,
    },

    alert: {
      ...color.alert.invert,
    },
  },
};
