import { RefObject, useContext, useEffect, useMemo, useRef } from 'react';
import { Richtext } from 'storyblok-js-client';
import { Language, LanguageContext } from '../../../Language.context';
import { Tree, TreeData } from '../../../storyblok';
import { StoryblokContext } from '../../../Storyblok.context';

function addClass(el: HTMLElement, className: string) {
  if (el.classList) {
    el.classList.add(className);
  } else if (!new RegExp(`\\b${className}\\b`).test(el.className)) {
    el.className += ` ${className}`;
  }
}

function localizedFullSlug(language: Language, fullSlug: string): string {
  const lowerCaseLang = language.toLowerCase();
  return language === 'EN' || fullSlug.startsWith(`${lowerCaseLang}/`) ? fullSlug : `${lowerCaseLang}/${fullSlug}`;
}

function findComponent(tree: Tree | undefined, path: string[]): TreeData | undefined {
  if (tree) {
    const [slug, ...rest] = path;
    const parent = Object.values(tree).find((_) => _.slug === slug);
    const comp = rest.reduce((comp, slug) => (comp ? comp.content[slug] : null), parent);

    if (comp) {
      return comp;
    }
    // Uncomment if you want to track missing Storyblok components.
    //
    // if (process.env.NODE_ENV !== 'production') {
    //   console.warn(`No Storyblok component found for ${slug}`);
    // }
  }
}

function findComponentBy(
  tree: Tree | undefined,
  language: Language,
  {
    fullSlug,
    slug,
    uuid,
  }: {
    fullSlug?: string;
    slug?: string;
    uuid?: string;
  }
): TreeData | undefined {
  if (tree) {
    const values = Object.values(tree);
    const comp = fullSlug
      ? values.find((_) => _.full_slug === localizedFullSlug(language, fullSlug))
      : slug
      ? values.find((_) => _.slug === slug)
      : uuid
      ? values.find((_) => _.uuid === uuid)
      : undefined;

    if (comp) {
      return comp;
    }
    // Uncomment if you want to track missing Storyblok components.
    //
    // if (process.env.NODE_ENV !== 'production') {
    //   console.warn(`No Storyblok component found for ${fullSlug || slug || uuid}`);
    // }
  }
}

function filterComponents(tree: Tree, language: Language, slugPrefix: string): { [slug: string]: TreeData } {
  return Object.values(tree).reduce((matching, branch) => {
    return branch.full_slug.startsWith(localizedFullSlug(language, slugPrefix))
      ? Object.assign(matching, { [branch.slug]: branch })
      : matching;
  }, {});
}

function useStoryblokComponent<T extends HTMLElement>(
  searchParam: { fullSlug?: string; path?: string; slug?: string; uuid?: string },
  options?: { editElement?: boolean }
): [TreeData | undefined, RefObject<T>] {
  const { language } = useContext(LanguageContext);
  const { editElement, tree } = useContext(StoryblokContext);
  const comp = searchParam.path
    ? findComponent(tree, searchParam.path.split('.'))
    : findComponentBy(tree, language, searchParam);

  // In case editor is present.
  const ref = useRef<T>(null);

  useEffect(() => {
    // Not editing content.
    if (!comp || !comp.content._editable || window.location === window.parent.location) {
      return;
    }

    if (ref.current) {
      const options = JSON.parse(comp.content._editable?.replace(/^<!--#storyblok#/, '').replace(/-->$/, '') || '{}');

      if (ref.current instanceof Object && typeof ref.current.setAttribute === 'function') {
        ref.current.setAttribute('data-blok-c', JSON.stringify(options));
        ref.current.setAttribute('data-blok-uid', `${options.id}-${options.uid}`);

        addClass(ref.current, 'storyblok__outline');
      } else {
        throw new TypeError(
          'It seems that you are using a DOM text-node inside the SbEditable wrapper. Please wrap it with an HTML DOM element.'
        );
      }
    }

    if (editElement && options?.editElement) {
      editElement(ref);
    }
  }, [editElement, comp, options, ref]);

  return [comp, ref];
}

function useStoryblokComponents(slugPrefix: string): { [slug: string]: TreeData } {
  const { language } = useContext(LanguageContext);
  const { tree } = useContext(StoryblokContext);

  return useMemo(() => (tree ? filterComponents(tree, language, slugPrefix) : {}), [language, slugPrefix, tree]);
}

function useStoryblokDatasource(slug: string): { [name: string]: string } {
  const { datasources } = useContext(StoryblokContext);
  return datasources ? datasources[slug] : {};
}

function useStoryblokRichText(data: Richtext): string {
  const { client } = useContext(StoryblokContext);
  return client && data && data.content ? client.richTextResolver.render(data) : '';
}

export default {
  useStoryblokComponent,
  useStoryblokComponents,
  useStoryblokDatasource,
  useStoryblokRichText,
};
