import { List } from 'purify-ts/List';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Sender } from 'xstate';
import Spinner from '../../../design-system/components/Spinner';
import { Center, Sticky, Transition } from '../../../design-system/helpers/components';
import { CatalogContext, CatalogEvent } from '../../../fsm/catalog/catalogMachine';
import * as API from '../../../fsm/types';
import { Form, TripType } from '../../../fsm/types';
import { getCruiseAccommodationProducts } from '../../../fsm/utils/productUtils';
import {
  getSelected,
  getSelectedSailings,
  getProducts,
  getAccommodationProducts,
  indexToLeg,
} from '../../../fsm/utils/sailingUtils';
import CatalogFooter, { CatalogFooterPassedProps } from '../CatalogFooter';
import TripCatalog from './Trip/TripCatalog';

type CatalogProps = {
  context: CatalogContext;
  submitChangeTripSearch: (newFormsValues: Form[]) => void;
  send: Sender<CatalogEvent>;
};

const catalogId = (index: number) => `catalog-${index}`;

const ListView: FunctionComponent<CatalogProps & CatalogFooterPassedProps> = ({
  context,
  submitChangeTripSearch,
  send,
  ...footerProps
}) => {
  const select = useCallback(
    (index: number, sailingCode: string, loading?: boolean) => {
      send({ type: 'SELECT', data: { index, sailingCode, loading } });
    },
    [send]
  );

  const setTicketType = useCallback(
    (leg: number, newTariff: API.Tariff, oldTariff: API.Tariff) => {
      send({ type: 'TARIFF', data: { leg, newTariff, oldTariff } });
    },
    [send]
  );

  const loadMoreSailings = useCallback(
    (index: number, direction: number) => {
      //send('LOAD_MORE_SAILINGS', { index, direction });
    },
    //[send]
    []
  );

  const loadChangeTripSailings = useCallback(
    (forms: Form[]) => {
      submitChangeTripSearch(forms);
      send({ type: 'LOAD_CHANGE_TRIP_SAILINGS', data: { forms } });
    },
    [send]
  );

  const { passengers, vehicles, catalogs, agreement, searchParams } = context;
  const [lastSectionSeen, setLastSectionSeen] = useState(0);
  const allSectionsSeen =
    searchParams.type === TripType.OVERNIGHT_CRUISE || context.catalogs.length <= lastSectionSeen + 1;

  const buildTripCatalog = (catalogs: API.Catalog[]) => {
    const tripType = searchParams.type;
    const isOvernightCruise = tripType === TripType.OVERNIGHT_CRUISE;

    const buildCatalog = (catalog: API.Catalog) => {
      const { index, sailings } = catalog;
      const selected = getSelected(catalog);
      const products = getProducts(selected);
      const accommodationProducts: API.ExtendedProduct[] = isOvernightCruise
        ? getCruiseAccommodationProducts(catalogs)
        : getAccommodationProducts(selected);

      const previous = List.find((_) => _.index === catalog.index - 1, catalogs).chain(getSelected);

      const handleScrolledToView = (isVisible: boolean) => {
        if (isVisible && index > lastSectionSeen) {
          setLastSectionSeen(index);
        }
      };

      return (
        <Sticky.Container key={`${index}`} {...{ handleScrolledToView }} id={catalogId(catalog.index)}>
          <TripCatalog
            {...{ agreement, catalog, index, sailings, select, send, setTicketType, catalogs }}
            disableBefore={previous.extract()}
            loadMore={loadMoreSailings}
            loadChangeTripSailings={loadChangeTripSailings}
            form={searchParams.forms[index]}
            accommodations={selected
              .chainNullable((_) => _.accommodations)
              .mapOrDefault((accommodations) => (Array.isArray(accommodations) ? accommodations : []), [])}
            tariff={selected.caseOf({ Nothing: () => API.Tariff.SPECIAL, Just: (price) => price.tariff })}
            passengers={passengers.filter((p) => p.legs?.includes(indexToLeg(index)))}
            vehicles={vehicles.filter((v) => v.legs?.includes(indexToLeg(index)))}
            accommodationProducts={accommodationProducts}
            electricityProducts={products.chainNullable((_) => _.ELECTRICITY).orDefault([])}
            offerCode={searchParams.offerCode}
            tripType={tripType}
            context={context}
          />
        </Sticky.Container>
      );
    };

    return isOvernightCruise ? buildCatalog(catalogs[0]) : catalogs.map((catalog) => buildCatalog(catalog));
  };

  return (
    <>
      <Transition.Toggle
        toggle={catalogs.every((c) => c.sailings)}
        as={Sticky.Scroll}
        whenFalse={
          <Center>
            <Spinner />
          </Center>
        }
        whenTrue={buildTripCatalog(catalogs)}
      />
      <CatalogFooter
        {...footerProps}
        summary={{
          agreement,
          sailings: getSelectedSailings(catalogs),
          offerCode: searchParams.offerCode,
        }}
        toggle={allSectionsSeen}
        disabled={!context.productPricesLoaded || !context.ticketPricesLoaded}
        moreId={catalogId(lastSectionSeen + 1)}
      />
    </>
  );
};

export default ListView;
