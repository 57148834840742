import { List } from 'purify-ts/List';
import { Maybe } from 'purify-ts/Maybe';
import { assign, send } from 'xstate';
import { choose, log } from 'xstate/lib/actions';
import { sailingRequiresAccommodation } from '../../settings';
import logger from '../../utils/logging';
import { PricingResult } from '../api';
import { findCruisePairFromSailings, isCruiseType, isDefaultOfferCode } from '../api/cruise';
import { LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import {
  AccommodationError,
  Agreement,
  BookingQuote,
  Catalog,
  CatalogErrors,
  ChargeInfo,
  DEFAULT_TARIFF,
  ExtendedAccommodation,
  ExtendedSailing,
  Form,
  isPassengerInput,
  PriceError,
  ProductError,
  Products,
  QuotesByTariff,
  Sailing,
  SailingError,
  SearchParams,
  SelectedSailing,
  Tariff,
  TariffPrice,
  TripType,
} from '../types';
import {
  accommodationsMatch,
  chooseByAccommodations,
  convertQuotesByTariffToTariffPrice,
  createInitialSailingChargeInfo,
  getPriceOrError,
  isOfferCodeViableForTariff,
  mapTariffPriceToObjectById,
  priceError,
  timetableVehicleError,
  vehicleError,
} from '../utils/catalogUtils';
import { hasPets } from '../utils/passengerUtils';
import { addMandatoryMealPackages, getCruiseOutboundProducts, isMealForced } from '../utils/productUtils';
import {
  createTariffPrice,
  findClosest,
  findFirstWithoutErrors,
  getSailingsFilter,
  getSelected,
  indexToLeg,
  onCatalogSelectedSailing,
  onCatalogSelectedSailings,
} from '../utils/sailingUtils';
import accommodationActions, { createDefaultAccommodations } from './accommodationActions';
import analyticsActions from './analyticsActions';
import { CatalogContext, CatalogEvent } from './catalogMachine';
import { validateInfo } from './guards';
import onboardActions from './onboardActions';
import passengerActions from './passengerActions';
import vehicleActions from './vehicleActions';

const loaderId = 'loader';

const actions = {
  onSearchStart: assign<CatalogContext, CatalogEvent>({
    searchParams: (ctx: CatalogContext, evt: any): SearchParams => evt.data || ctx.searchParams,
    catalogs: (ctx: CatalogContext, evt: any): Catalog[] => {
      return (evt.data || ctx.searchParams).forms.map(({ index }: Form) => ({
        index,
        sailings: null,
        selected: null,
      }));
    },
    errors: [],
  }),
  search: send<CatalogContext, CatalogEvent>(
    (context) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_CATALOGS,
        data: { context },
      };
    },
    {
      to: loaderId,
    }
  ),
  onLoadChangeTripSailings: assign<CatalogContext, CatalogEvent>({
    searchParams: (ctx: CatalogContext, evt: any): SearchParams => {
      const updatedSearchParams = { ...ctx.searchParams, forms: ctx.formsOnChangeTrip || [] };
      return {
        ...updatedSearchParams,
      };
    },
    catalogs: (ctx: CatalogContext, evt: any): Catalog[] => {
      return (evt.data || ctx.searchParams).forms.map(({ index }: Form) => ({
        index,
        sailings: null,
        selected: null,
      }));
    },
    errors: [],
  }),
  onSearchDone: assign<CatalogContext, CatalogEvent>(({ searchParams }: CatalogContext, evt: any): CatalogContext => {
    logger.debug('onSearchDone, event:', evt, 'searchParams:', searchParams);

    const { errors } = evt;
    if (errors) {
      // Handle AWS lambda based errors e.g.
      // errorType: "Lambda:IllegalArgument"
      // message: "software.amazon.awssdk.services.lambda.model.TooManyRequestsException: Rate Exceeded. (Service: Lambda, Status Code: 429..."

      const words = ['TooManyRequestsException', 'Timeout'];
      const error: SailingError =
        errors.length && new RegExp(words.join('|'), 'i').test(errors[0].message)
          ? SailingError.UNSPECIFIED
          : SailingError.NO_AVAIL_DEPARTURES_FOR_LEG;

      return {
        catalogs: searchParams.forms.map((form) => ({
          index: form.index,
          sailings: [] as ExtendedSailing[],
          error,
        })),
      } as CatalogContext;
    }

    let outboundSelected: Maybe<ExtendedSailing>;

    return {
      catalogs: evt.result
        ? evt.result.reduce((catalogs: Catalog[], { index, sailings }: { index: number; sailings: Sailing[] }) => {
            console.log('onSearchDone, reduce cat', index, sailings);
            if (!sailings) {
              return catalogs.concat({
                index,
                sailings: [],
                error: SailingError.NO_AVAIL_DEPARTURES_FOR_LEG,
              });
            }

            const newSailings = sailings.map((sailing) => ({
              meta: {
                loading: true,
              },
              ...sailing,
            })) as ExtendedSailing[];

            const newSelection = newSailings.find((s) => s.departureDate === searchParams.forms[index].selectedDate);

            const sailingsFilter = getSailingsFilter(searchParams);

            let maybeSelected: Maybe<ExtendedSailing>;

            // TODO cruise pair finding is done twice which is a bit stupid but
            // Investigate how inbound sailing cound be stores already on first finding
            if (isCruiseType(searchParams.type)) {
              const outboundFilteredSailings = evt.result[0].sailings.filter(sailingsFilter);
              const inboundFilteredSailings = evt.result[1].sailings.filter(sailingsFilter);

              if (index === 0) {
                const result = findCruisePairFromSailings(
                  outboundFilteredSailings,
                  inboundFilteredSailings,
                  findFirstWithoutErrors(newSailings, newSelection?.sailingCode),
                  searchParams.type
                );
                maybeSelected = result.outboundSailing;
              } else {
                const result = findCruisePairFromSailings(
                  outboundFilteredSailings,
                  inboundFilteredSailings,
                  outboundSelected,
                  searchParams.type
                );
                maybeSelected = result.inboundSailing;
              }
            } else {
              maybeSelected =
                index === 0
                  ? findFirstWithoutErrors(newSailings, newSelection?.sailingCode)
                  : findClosest(newSailings, catalogs[index - 1], newSelection?.sailingCode);
            }

            const selected = maybeSelected
              .map(({ sailingCode }) => ({
                sailingCode,
                offerCode: searchParams.offerCode,
                products: {},
                tariff: DEFAULT_TARIFF,
              }))
              .extract();

            if (index === 0) {
              outboundSelected = maybeSelected;
            }

            return catalogs.concat({
              index,
              // Merge each sailing with defaults.
              sailings: newSailings.filter(sailingsFilter),
              selected,
            });
          }, [] as Catalog[])
        : searchParams.forms.map((f) => ({ index: f.index, sailings: [] })),
      errors: evt.errors,
    } as CatalogContext;
  }),

  loadInitialProducts: send<CatalogContext, CatalogEvent>(
    (context) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_CATALOG_PRODUCTS,
        data: { context },
      };
    },
    {
      to: loaderId,
    }
  ),

  onLoadInitialProducts: assign<CatalogContext, CatalogEvent>({
    catalogs: ({ catalogs, vehicles, passengers, searchParams }: CatalogContext, evt: any): Catalog[] => {
      const {
        products,
        sailingCode,
      }: {
        products: Products | ProductError;
        sailingCode: string;
      } = evt.result;

      return catalogs.map((catalog) => ({
        ...catalog,
        sailings: catalog.sailings.map((sailing, index) => {
          if (sailing.sailingCode !== sailingCode) {
            return sailing;
          }
          if (sailingRequiresAccommodation(sailing, searchParams.type, hasPets(passengers))) {
            // force accommodation check
            const defaultAccommodations = createDefaultAccommodations(
              sailing,
              passengers,
              products,
              index,
              searchParams.type
            );
            if (typeof defaultAccommodations === 'string') {
              return {
                ...sailing,
                meta: {
                  loading: true,
                },
                vehicleError: vehicleError(vehicles, products, indexToLeg(catalog.index)),
                accommodationError: defaultAccommodations as AccommodationError,
              };
            }
          }
          return {
            ...sailing,
            meta: {
              loading: true,
            },
            vehicleError: vehicleError(vehicles, products, indexToLeg(catalog.index)),
          };
        }),
      }));
    },
    errors: [],
  }),

  loadInitialPricing: send<CatalogContext, CatalogEvent>(
    (context) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_CATALOG_PRICES,
        data: { context },
      };
    },
    {
      to: loaderId,
    }
  ),

  onLoadTimetables: assign<CatalogContext, CatalogEvent>({
    catalogs: (ctx: CatalogContext, event: any): Catalog[] => {
      const {
        result: { sailings, error },
      }: {
        result: PricingResult;
      } = event;

      if (error.isJust()) {
        return ctx.catalogs.map((catalog) => ({
          ...catalog,
          sailings: catalog.sailings.map((sailing) => ({
            ...sailing,
            price: PriceError.NO_PRICE,
            meta: {
              ...sailing.meta,
              loading: true,
            },
          })),
        }));
      }

      return ctx.catalogs.map((catalog) => ({
        ...catalog,
        sailings: catalog.sailings.map((catalogSailing) =>
          List.find((sailing) => sailing.sailingCode === catalogSailing.sailingCode, sailings).caseOf({
            Nothing: () => catalogSailing,
            Just: (currentSailing) => {
              const price: TariffPrice = createInitialSailingChargeInfo(currentSailing);

              return {
                ...catalogSailing,
                price,
                meta: {
                  ...catalogSailing.meta,
                  initialCheapestPrice: price,
                  loading: true,
                },
              };
            },
          })
        ),
      }));
    },
  }),

  onLoadInitialPricing: assign<CatalogContext, CatalogEvent>({
    catalogs: (ctx: CatalogContext, evt: any): Catalog[] => {
      const {
        result: { sailings, offerCodeMeta, fetchingPricesDone },
      }: {
        result: PricingResult;
      } = evt;

      return ctx.catalogs.map((catalog) => ({
        ...catalog,
        fetchingPricesDone: fetchingPricesDone || undefined,
        sailings: catalog.sailings.map((catalogSailing) => {
          return List.find((sailing) => sailing.sailingCode === catalogSailing.sailingCode, sailings).caseOf({
            Nothing: () => {
              // Reloading price should not be enabled if timetable returns offercode error
              // for default cruise offercode
              if (isCruiseType(ctx.searchParams.type)) {
                const sailingOfferCodeMeta = offerCodeMeta?.find(
                  (ocm) => ocm.sailingCode === catalogSailing.sailingCode
                );
                if (isDefaultOfferCode(ctx.searchParams.offerCode) && !sailingOfferCodeMeta?.offerCodeViable.SPECIAL) {
                  return {
                    ...catalogSailing,
                    price: PriceError.NO_CRUISE_AVAILABLE as unknown as TariffPrice,
                    meta: {
                      ...catalogSailing.meta,
                      loading: false,
                    },
                  };
                }
              }
              return {
                ...catalogSailing,
                price: PriceError.NO_PRICE_RELOAD_PRICE as unknown as TariffPrice, // TODO, pass initial error code here if exists
                meta: {
                  ...catalogSailing.meta,
                  loading: false,
                },
              };
            },
            Just: (currentSailing) => {
              const price = createInitialSailingChargeInfo(currentSailing);
              const sailingOfferCodeMeta =
                offerCodeMeta && offerCodeMeta.find((ocMeta) => ocMeta.sailingCode === catalogSailing.sailingCode);

                            return {
                ...catalogSailing,
                price,
                meta: {
                  ...catalogSailing.meta,
                  loading: false,
                  initialCheapestPrice: price,
                  offercodeViable: sailingOfferCodeMeta?.offerCodeViable,
                },
                vehicleError: timetableVehicleError(currentSailing),
              };
            },
          });
        }),
      }));
    },
    errors: [],
  }),

  loadProductPrices: send<CatalogContext, CatalogEvent>(
    (context) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_PRODUCT_PRICES,
        data: { context },
      };
    },
    {
      to: loaderId,
    }
  ),

  onLoadProductPrices: assign<CatalogContext, CatalogEvent>({
    catalogs: (ctx: CatalogContext, event: any): Catalog[] => {
      const {
        result: { index, products, sailingCode },
      }: {
        result: {
          index: number;
          products: Products | ProductError;
          sailingCode: string;
        };
      } = event;

      const updatedProducts =
        ctx.searchParams.type === TripType.OVERNIGHT_CRUISE &&
        ctx.catalogs[0].selected &&
        ctx.catalogs[1]?.selected &&
        index === 0
          ? getCruiseOutboundProducts(products, ctx.catalogs[1].selected.products)
          : products;

      return ctx.catalogs.map((catalog) => {
        return catalog.index === index && catalog.selected && catalog.selected.sailingCode === sailingCode
          ? {
              ...catalog,
              selected: {
                ...catalog.selected,
                products: updatedProducts,
              },
            }
          : catalog;
      });
    },
  }),

  loadSelectedPricing: choose<CatalogContext, CatalogEvent>([
    {
      //
      // Prevent possible backend issues by not fetching prices
      // unless any catalogs have selected sailing present.
      //
      cond: ({ catalogs }: CatalogContext) =>
        catalogs.map((catalog) => getSelected(catalog)).some((selected) => selected.isJust()),
      actions: [
        assign<CatalogContext, CatalogEvent>({
          catalogs: ({ catalogs, searchParams }) =>
            onCatalogSelectedSailings(catalogs, (catalog, selected) => ({
              ...catalog,
              sailings: catalog.sailings.map((sailing) =>
                sailing.sailingCode === selected.sailingCode
                  ? { ...sailing, meta: { ...sailing.meta, loading: true } }
                  : sailing
              ),
              selected: {
                ...selected,
                meta: {
                  ...selected.meta,
                  loading: true,
                },
              },
            })),
        }),
        'onTicketPricesRemoved',
        send<CatalogContext, CatalogEvent>(
          (context) => {
            return {
              type: LOADER_ACTION_EVENTS.RELOAD_SELECTED,
              data: { context },
            };
          },
          {
            to: loaderId,
          }
        ),
      ],
    },
    {
      actions:
        process.env.NODE_ENV === 'production'
          ? []
          : [log((_, event) => ({ message: 'Skip loadSelectedPricing', event }))],
    },
  ]),

  onLoadSelectedPricing: assign<CatalogContext, CatalogEvent>({
    catalogs: ({ catalogs, searchParams }: CatalogContext, evt: any) => {
      const {
        result,
        error,
      }: {
        result: QuotesByTariff;
        error: any;
      } = evt;

      console.log('onLoadSelectedPricing', result, 'error', error);

      return catalogs.map((catalog: Catalog, catalogIndex): Catalog => {
        if (error || !result) {
          return Maybe.fromNullable(catalog.selected).mapOrDefault(
            (selected) => ({
              ...catalog,
              selected: {
                ...selected,
                meta: {
                  // ...selected.meta, // TODO: Fix metadata loss if needed
                  loading: false,
                },
                price: error.sailingIndex === catalogIndex ? getPriceOrError(selected.price, error) : selected.price,
              },
              sailings: catalog.sailings.map(
                (sailing: ExtendedSailing): ExtendedSailing =>
                  sailing.sailingCode === selected.sailingCode
                    ? {
                        ...sailing,
                        meta: {
                          ...sailing.meta,
                          loading: false,
                        },
                        price:
                          error.sailingIndex === catalogIndex ? getPriceOrError(sailing.price, error) : sailing.price,
                      }
                    : sailing
              ),
            }),
            catalog
          );
        }

        // No errors, process successful response

        return Object.keys(result).reduce(
          (catalog, tariff) =>
            getSelected(catalog)
              .map(({ accommodations, offerCode, onboards, price, products, sailingCode, ...rest }) => {
                const newQuote = result[tariff as Tariff] as BookingQuote;
                if (newQuote) {
                  const chargeInfo = List.find((_) => _.sailingCode === sailingCode, newQuote.sailings)
                    .map(({ leg }) => createTariffPrice(price, tariff as Tariff, leg.chargeInfo as ChargeInfo))
                    .extract();

                  const newAccommodations = newQuote.accommodations.reduce((result, current) => {
                    // Accommodations are not identified so the separate multiples
                    // of same type we "randomly" assign prices to each of them.
                    const availablePrices = current.legs.filter((l) => l.leg === indexToLeg(catalog.index));

                    return Array.isArray(accommodations)
                      ? result.concat(
                          accommodations
                            .filter(({ code }) => code === current.code)
                            .reduce((newacc, accommodation) => {
                              //already priced
                              if (result.find((a) => a.id === accommodation.id)) return newacc;
                              const chosen = availablePrices.shift();
                              const oldPrice =
                                accommodation.price && typeof accommodation.price !== 'string'
                                  ? accommodation.price
                                  : undefined;
                              const newPrice = createTariffPrice(
                                accommodation.price,
                                tariff as Tariff,
                                chosen?.chargeInfo as ChargeInfo
                              );
                              newacc.push({
                                ...accommodation,
                                price:
                                  oldPrice || newPrice
                                    ? ({
                                        ...(oldPrice || {}),
                                        ...(newPrice || {}),
                                      } as TariffPrice)
                                    : PriceError.UNSPECIFIED,
                              });
                              return newacc;
                            }, [] as ExtendedAccommodation[])
                        )
                      : result;
                  }, [] as ExtendedAccommodation[]);

                  // Makíng sure new prices are assigned to correct accommodations.
                  // In some cases selected sailing may change before price query response arrives.
                  // In this case we should not update prices, if accomodations lists are different.
                  const updatedAccommodations = accommodationsMatch(accommodations, newAccommodations, sailingCode)
                    ? newAccommodations
                    : accommodations;

                  let updatedOnboards = Array.isArray(onboards)
                    ? onboards.map((onboard) => {
                        const onboardPrice = List.find(({ code }) => code === onboard.code, newQuote.onboards)
                          .chain(({ legs }) => List.find(({ leg }) => leg === indexToLeg(catalog.index), legs))
                          .map(({ chargeInfo }) => chargeInfo)
                          .extract();

                        const oldPrice = onboard.price && typeof onboard.price !== 'string' ? onboard.price : undefined;
                        const newPrice = createTariffPrice(onboard.price, tariff as Tariff, onboardPrice);

                        return {
                          ...onboard,
                          price:
                            oldPrice || newPrice
                              ? ({ ...(oldPrice || {}), ...(newPrice || {}) } as TariffPrice)
                              : PriceError.UNSPECIFIED,
                        };
                      })
                    : onboards;

                  const selectedMeta = {
                    ...rest.meta,
                    loading: false,
                    offercodeViable: {
                      STANDARD: isOfferCodeViableForTariff(
                        result.STANDARD,
                        { ...rest, sailingCode },
                        Tariff.STANDARD,
                        offerCode
                      ),
                      SPECIAL: isOfferCodeViableForTariff(
                        result.SPECIAL,
                        { ...rest, sailingCode },
                        Tariff.SPECIAL,
                        offerCode
                      ),
                    },
                  };
                  const mealForced = isMealForced(catalogs, searchParams.type, selectedMeta, offerCode);

                  const forcedMeals = mealForced
                    ? addMandatoryMealPackages(
                        newQuote.passengers,
                        products,
                        updatedOnboards as {
                          price: TariffPrice | PriceError;
                          amount: number;
                          code: string;
                          legs: number[];
                          type: string;
                        }[],
                        catalog.index,
                        searchParams.type
                      )
                    : updatedOnboards;

                  if (mealForced && forcedMeals && forcedMeals.length > 0) {
                    updatedOnboards = forcedMeals;
                  }

                  // Update selected sailing in both catalog.sailings and catalog.selected
                  return {
                    ...catalog,
                    sailings: catalog.sailings.map((sailing) =>
                      sailing.sailingCode === sailingCode
                        ? {
                            ...sailing,
                            meta: {
                              ...sailing.meta,
                              loading: false,
                              offercodeViable: {
                                STANDARD: isOfferCodeViableForTariff(
                                  result.STANDARD,
                                  sailing,
                                  Tariff.STANDARD,
                                  offerCode
                                ),
                                SPECIAL: isOfferCodeViableForTariff(result.SPECIAL, sailing, Tariff.SPECIAL, offerCode),
                              },
                            },
                            price: chargeInfo || sailing.price,
                          }
                        : sailing
                    ),
                    selected: {
                      ...rest,
                      offerCode,
                      products,
                      sailingCode,
                      meta: selectedMeta,
                      price: chargeInfo || PriceError.UNSPECIFIED,
                      quote: {
                        ...(rest.quote || ({} as QuotesByTariff)),
                        [tariff as Tariff]: {
                          ...newQuote,
                          // Remove/replace unneeded props to reduce footprint.
                          accommodations: [],
                          agreement: null,
                          onboards: [],
                          sailings: [],
                        } as BookingQuote,
                      },
                      accommodations: updatedAccommodations,
                      onboards: updatedOnboards,
                    },
                  };
                } else {
                  return catalog;
                }
              })
              .orDefault(catalog),
          catalog
        );
      });
    },
    passengers: (ctx: CatalogContext, evt: any) => {
      const {
        result,
        error,
      }: {
        result: QuotesByTariff;
        error: any;
      } = evt;

      if (error || !result) {
        return priceError(ctx.passengers);
      }

      return Object.keys(result).reduce((passengers, tariff) => {
        const quote = result[tariff as Tariff] as BookingQuote;

        return quote
          ? passengers.map((passengerOrPet) => {
              if (isPassengerInput(passengerOrPet)) {
                return mapTariffPriceToObjectById(quote.passengers, passengerOrPet, tariff as Tariff);
              } else {
                return mapTariffPriceToObjectById(quote.pets, passengerOrPet, tariff as Tariff);
              }
            })
          : passengers;
      }, ctx.passengers);
    },
    vehicles: (ctx: CatalogContext, evt: any) => {
      const {
        result,
        error,
      }: {
        result: QuotesByTariff;
        error: any;
      } = evt;

      if (error || !result) {
        return priceError(ctx.vehicles);
      }

      return Object.keys(result).reduce((vehicles, tariff) => {
        const quote = result[tariff as Tariff] as BookingQuote;

        return quote
          ? vehicles.map((vehicle) => mapTariffPriceToObjectById(quote.vehicles, vehicle, tariff as Tariff))
          : vehicles;
      }, ctx.vehicles);
    },
    agreement: (ctx: CatalogContext, evt: any) => {
      const {
        result,
      }: {
        result: QuotesByTariff;
      } = evt;
      return (
        result &&
        getSelected(ctx.catalogs[0])
          .chainNullable(({ tariff }: { tariff: Tariff }) => result[tariff])
          .chainNullable((_) => _.agreement as Agreement)
          .extract()
      );
    },
    errors: [],
  }),

  onProductPricesLoaded: assign<CatalogContext, CatalogEvent>({
    productPricesLoaded: true,
  }),

  onTicketPricesLoaded: assign<CatalogContext, CatalogEvent>({
    ticketPricesLoaded: true,
  }),

  onProductPricesRemoved: assign<CatalogContext, CatalogEvent>({
    productPricesLoaded: false,
  }),

  onTicketPricesRemoved: assign<CatalogContext, CatalogEvent>({
    ticketPricesLoaded: false,
  }),

  // --- obsolete, no need to set loading for all departures ---
  // setLoading: assign<CatalogContext, CatalogEvent>({
  //   catalogs: ({ catalogs }: CatalogContext, { data }: any): Catalog[] => {
  //     console.log('setLoading', data)
  //     return catalogs.map((catalog) => {
  //       if (catalog.index === data.index) {
  //         const sailing = List.find((_) => _.sailingCode === data.sailingCode, catalog.sailings);

  //         return {
  //           ...catalog,
  //           sailings: setLoadingOnSameDepartureDate(sailing, catalog.sailings, true),
  //         };
  //       }

  //       return catalog;
  //     })
  //   }
  // }),

  updateSelected: assign<CatalogContext, CatalogEvent>({
    catalogs: ({ catalogs }: CatalogContext, { data }: any): Catalog[] => {
      return catalogs.map((catalog) =>
        List.find(
          (_) => _.index === catalog.index,
          data.results as {
            index: number;
            selected?: ExtendedSailing & SelectedSailing;
            previous?: ExtendedSailing & SelectedSailing;
          }[]
        ).mapOrDefault(
          ({ selected, previous, index }) => {
            const existingAccommodations = catalog.selected?.accommodations;
            const accommodations = selected?.accommodations;
            const useCatalog = chooseByAccommodations(existingAccommodations, accommodations);
            logger.debug(
              'updateSelected, existingAccommodations:',
              existingAccommodations,
              'received accommodations:',
              accommodations,
              'useCatalog:',
              useCatalog
            );

            // change loading flag only if it is set on the previously selected sailing and only to false
            let catalogSailings = catalog.sailings;
            if (catalog.index === index) {
              catalogSailings = catalog.sailings.map((sailing: ExtendedSailing): ExtendedSailing => {
                if (previous?.sailingCode === sailing.sailingCode) {
                  return {
                    ...sailing,
                    meta: {
                      ...sailing.meta,
                      loading:
                        previous.meta?.loading !== undefined && !previous.meta?.loading
                          ? previous.meta?.loading
                          : sailing.meta?.loading || false,
                    },
                  };
                }
                return sailing;
              });
            }

            return {
              ...catalog,
              sailings: catalogSailings,
              selected:
                selected?.sailingCode === catalog.selected?.sailingCode && useCatalog ? catalog.selected : selected,
              // --- obsolete, no need to set loading for all departures ---
              // sailings:
              //   //
              //   // Prices are loaded after catalogs
              //   // so we keep loading anims active.
              //   //
              //   data.type === 'DONE_CATALOG_PRODUCTS'
              //     ? catalog.sailings
              //     : setLoadingOnSameDepartureDate(
              //         Maybe.fromNullable(selected),
              //         catalog.sailings,
              //         false
              //     ),
            };
          },
          // else (no changes)
          catalog
        )
      );
    },
  }),

  /**
   * Launch price query for sailing(s) without prices.
   * Pass event to the loader machine, which will then call the http-API.
   */
  reloadMissingPrices: send<CatalogContext, CatalogEvent>(
    (context: CatalogContext, event: CatalogEvent) => {
      return {
        type: LOADER_ACTION_EVENTS.RELOAD_MISSING,
        data: { context, event },
      };
    },
    {
      to: loaderId,
    }
  ),

  /**
   * Set loading flag on for certain sailings only.
   * SailingCodes are listed in event data.
   * Handling both cases/events, single code or array with codes.
   */
  setSpinnerOn: assign<CatalogContext, CatalogEvent>({
    catalogs: (ctx: CatalogContext, event: any): Catalog[] => {
      const { sailingCode, sailingCodes } = event.data;
      const sailingCodeArray = Array.isArray(sailingCodes) ? sailingCodes : [];
      if (sailingCode) sailingCodeArray.push(sailingCode);
      logger.info('setSpinnerOn for sailingCode(s):', sailingCodeArray, 'event:', event.type);

      const updatedCatalogs = ctx.catalogs.map((catalog: Catalog): Catalog => {
        const catalogSailings = catalog.sailings.map((sailing: ExtendedSailing): ExtendedSailing => {
          if (sailingCodeArray.includes(sailing.sailingCode)) {
            return {
              ...sailing,
              // price: undefined, // Reset price error if any (not needed for now)
              meta: {
                ...sailing.meta,
                loading: true,
              },
            };
          }
          return sailing;
        }); // end of sailings map

        return {
          ...catalog,
          sailings: catalogSailings,
        };
      }); // end of catalogs map
      return updatedCatalogs;
    },
  }),

  /**
   * Handle price query response for sailing.
   * The 'assign' -action must be pure function, no async methods here.
   * We are expecting new data/prices on event data.
   * Update catalog only if new prices for sailing has arrived.
   */
  onReloadMissingPrices: assign<CatalogContext, CatalogEvent>({
    catalogs: (ctx: CatalogContext, event: any): Catalog[] => {
      const { catalogs, searchParams, passengers, vehicles } = ctx;
      const { type, result } = event;

      logger.debug('onReloadMissingPrices action, context:', ctx);
      logger.debug('onReloadMissingPrices action, event:', type, event);

      if (!result) return catalogs;

      const catalogIndex: number = result.index;
      const sailingCodeToUpdate: string = result.sailingCode;
      const newPricesOrError: QuotesByTariff = result.prices;

      logger.debug('onReloadMissingPrices action, catalogIndex:', catalogIndex, 'catalogs:', catalogs);
      logger.debug('onReloadMissingPrices action, searchParams:', searchParams);
      logger.debug('onReloadMissingPrices action, passengers:', passengers);
      logger.debug('onReloadMissingPrices action, vehicles:', vehicles);
      logger.debug('onReloadMissingPrices action, newPricesOrError:', newPricesOrError);

      // Update catalog if new prices are received
      return catalogs.map((catalog: Catalog): Catalog => {
        // Pick matching catalog
        const catalogToUpdate = catalog.index === catalogIndex && catalog;
        logger.debug('onReloadMissingPrices action, catalogToUpdate', catalogToUpdate);

        if (catalogToUpdate) {
          const { sailings } = catalogToUpdate;
          logger.debug('onReloadMissingPrices action, sailings:', sailings);

          // Find sailings to update
          const updatedSailings = sailings.map((catalogSailing: ExtendedSailing): ExtendedSailing => {
            if (catalogSailing.sailingCode !== sailingCodeToUpdate) {
              return catalogSailing;
            }
            // Update prices
            const price =
              typeof newPricesOrError === 'string' // Check if error code here
                ? newPricesOrError
                : convertQuotesByTariffToTariffPrice(newPricesOrError, sailingCodeToUpdate);

            const updatedSailing: ExtendedSailing = {
              ...catalogSailing,
              price,
              meta: {
                ...catalogSailing.meta,
                loading: false,
              },
            };
            return updatedSailing;
          });

          return {
            ...catalog,
            sailings: updatedSailings,
          };
        }

        return catalog; // Nothing to update
      });
    },
  }),

  onInfoError: assign<CatalogContext>({
    errors: (ctx): CatalogErrors => {
      const error = validateInfo(ctx);
      return error ? [...ctx.errors, ...error] : ctx.errors;
    },
  }),

  setTariff: assign<CatalogContext, CatalogEvent>({
    catalogs: ({ catalogs, searchParams }, { data }: any): Catalog[] => {
      if (isCruiseType(searchParams.type)) {
        return onCatalogSelectedSailings(catalogs, (catalog, selected) => ({
          ...catalog,
          selected: {
            ...selected,
            tariff: data.newTariff,
          },
        }));
      }
      return onCatalogSelectedSailing(catalogs, data, (catalog, selected) => ({
        ...catalog,
        selected: {
          ...selected,
          tariff: data.newTariff,
        },
      }));
    },
  }),

  ...accommodationActions,
  ...analyticsActions,
  ...onboardActions,
  ...passengerActions,
  ...vehicleActions,
};

export default actions;
