import Joi from '@hapi/joi';
import parseISO from 'date-fns/esm/parseISO';
import { List } from 'purify-ts/List';
import {
  CatalogError,
  InfoError,
  isPassengerInput,
  PassengerCountry,
  PassengerGender,
  PassengerType,
  VehicleType,
} from '../types';
import { getAccommodations, getAccommodationsAsProducts, inGenderSpecific } from '../utils/accommodationUtils';
import { validateAge } from '../utils/passengerUtils';
import { getSelectedSailings } from '../utils/sailingUtils';
import { CatalogContext } from './catalogMachine';

const guards = {
  loadSearchResultsOnBoot: (ctx: CatalogContext): boolean => {
    return ctx.catalogs.length === 0;
  },

  infoGuard: (ctx: CatalogContext): boolean => !validateInfo(ctx),
};

const countryCallCodeRegex = /^\+[0-9]{1,4}\s?$/;
const starclubRegex = /^[3]{3}/;
const phoneRegex = /^\+(?:[0-9]\s?){6,18}[0-9]$/;
const postalRegex = /^[A-Z0-9\s]*$/;
const numbersOnlyRegex = /^[0-9]+$/;

export const joiAgeValidation = (type: PassengerType, firstDepartureDateTime: Date) => (value: any) => {
  if (validateAge(type, firstDepartureDateTime, value)) {
    return value;
  } else {
    throw new Error(`Passenger is not ${type}`);
  }
};

export const passengerInfoSchema = (firstDepartureDateTime: Date): Joi.ObjectSchema<any> =>
  Joi.object({
    type: Joi.string()
      .allow(...Object.keys(PassengerType))
      .required(),
    firstname: Joi.string().min(2).max(18),
    lastname: Joi.string().min(2).max(30),
    birth: Joi.when('type', {
      is: PassengerType.ADULT,
      then: Joi.custom(joiAgeValidation(PassengerType.ADULT, firstDepartureDateTime)),
    })
      .when('type', {
        is: PassengerType.JUNIOR,
        then: Joi.custom(joiAgeValidation(PassengerType.JUNIOR, firstDepartureDateTime)),
      })
      .when('type', {
        is: PassengerType.CHILD,
        then: Joi.custom(joiAgeValidation(PassengerType.CHILD, firstDepartureDateTime)),
      })
      .when('type', {
        is: PassengerType.INFANT,
        then: Joi.custom(joiAgeValidation(PassengerType.INFANT, firstDepartureDateTime)),
      }),
    requireGender: Joi.array().allow(PassengerGender.FEMALE, PassengerGender.MALE, PassengerGender.UNSPECIFIED),
    gender: Joi.when('requireGender', {
      is: Joi.array().items(PassengerGender.UNSPECIFIED),
      then: Joi.valid(PassengerGender.FEMALE, PassengerGender.MALE),
    })
      .when('requireGender', {
        is: Joi.array().items(Joi.string().only().allow(PassengerGender.FEMALE)),
        then: Joi.valid(PassengerGender.FEMALE),
      })
      .when('requireGender', {
        is: Joi.array().items(Joi.string().only().allow(PassengerGender.MALE)),
        then: Joi.valid(PassengerGender.MALE),
      })
      .when('requireGender', {
        is: Joi.array().items(
          Joi.string().only().allow(PassengerGender.MALE).required(),
          Joi.string().only().allow(PassengerGender.FEMALE).required()
        ),
        then: Joi.forbidden(),
      }),

    nationality: Joi.string(),
    requireReserver: Joi.boolean().optional(),
    //3 first numbers are 3's, length 15
    starclub: Joi.when('requireReserver', {
      is: true,
      then: Joi.string().min(15).max(15).regex(starclubRegex),
      otherwise: Joi.string().min(15).max(15).regex(starclubRegex).allow(''),
    }),

    reserverInfo: Joi.any(),
    specialNeeds: Joi.string().allow(''),
  });

const joiPostalCodeValidation = (length: number, regex: RegExp = numbersOnlyRegex) =>
  Joi.string().length(length).regex(regex);

export const reserverInfoSchema = Joi.object({
  type: Joi.string().allow(PassengerType.ADULT, PassengerType.JUNIOR),
  address: Joi.string().min(2).max(60),
  postalCode: Joi.when('country', {
    is: PassengerCountry.FI,
    then: joiPostalCodeValidation(5),
  })
    .when('country', {
      is: PassengerCountry.SE,
      then: joiPostalCodeValidation(5),
    })
    .when('country', {
      is: PassengerCountry.DE,
      then: joiPostalCodeValidation(5),
    })
    .when('country', {
      is: PassengerCountry.RU,
      then: joiPostalCodeValidation(6),
      otherwise: Joi.string().min(3).max(14).regex(postalRegex),
    }),
  city: Joi.string().min(1).max(30),
  country: Joi.string().min(1),
  countryCallCode: Joi.string().regex(countryCallCodeRegex),
  phone: Joi.string().regex(phoneRegex),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .max(70),
  termsOfServiceOk: Joi.boolean().optional(),
  privacyPolicyOk: Joi.boolean().optional(),
  newsletterOk: Joi.boolean().optional(),
});

export const vehicleInfoSchema = Joi.object({
  id: Joi.any(),
  legs: Joi.any(),
  type: Joi.string().optional(),
  registrationId: Joi.string().min(2).max(15),
  make: Joi.string().optional(),
  model: Joi.string().optional(),
  lengthCm: Joi.number().optional(),
  heightCm: Joi.number().optional(),
  assignedDriver: Joi.number().optional(),
});

const createInfoError = (field: string, id: number): CatalogError => ({
  field,
  id,
  source: 'info',
  msg: InfoError.FINAL_CHECK,
});

export const validateInfo = (ctx: CatalogContext): CatalogError[] | null => {
  const selectedSailings = getSelectedSailings(ctx.catalogs);
  const selectedAccommodations = getAccommodations(selectedSailings);
  const accommodationProducts = getAccommodationsAsProducts(selectedSailings);
  const firstDepartureDateTime = List.head(selectedSailings).mapOrDefault(
    ({ sailing }) => parseISO(`${sailing.departureDate} ${sailing.departureTime}`),
    new Date()
  );

  const validatePassenger: CatalogError[] = ctx.passengers.reduce((result, passengerOrPet) => {
    if (isPassengerInput(passengerOrPet)) {
      const inSpecific = inGenderSpecific(passengerOrPet, selectedAccommodations, accommodationProducts);

      const infoErrors = passengerInfoSchema(firstDepartureDateTime)
        .validate(
          {
            ...passengerOrPet.info,
            type: passengerOrPet.type,
            requireGender: inSpecific,
            requireReserver: ctx.searchParams.starclub ? passengerOrPet.reserver : false,
          },
          { abortEarly: false, presence: 'required' }
        )
        .error?.details.map((d) => createInfoError(d.context?.key || d.path + '', passengerOrPet.id));

      const reserverErrors =
        passengerOrPet.info?.reserverInfo && Object.keys(passengerOrPet.info.reserverInfo).length > 0
          ? reserverInfoSchema
              .validate(
                { ...passengerOrPet.info?.reserverInfo, type: passengerOrPet.type },
                {
                  abortEarly: false,
                  presence: 'required',
                }
              )
              .error?.details.map((d) => createInfoError(d.context?.key || d.path + '', passengerOrPet.id))
          : undefined;

      if (infoErrors) result.push(...infoErrors);
      if (reserverErrors) result.push(...reserverErrors);
    }
    return result;
  }, [] as CatalogError[]);

  const validateVehicle = ctx.vehicles.reduce((result, { price, ...vehicle }) => {
    if (vehicle.type !== VehicleType.BCY) {
      const errors = vehicleInfoSchema
        .validate(vehicle, { abortEarly: false, presence: 'required' })
        .error?.details.map((d) => createInfoError(d.context?.key || d.path + '', vehicle.id));
      if (errors) result.push(...errors);
    }

    return result;
  }, [] as CatalogError[]);

  const errors = [...validatePassenger, ...validateVehicle];

  return !ctx.passengers.some((p) => isPassengerInput(p) && p.info?.reserverInfo) || errors.length > 0 ? errors : null;
};

export default guards;
