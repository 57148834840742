import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Toolbar, ToolbarItem, useToolbarState } from 'reakit/Toolbar';
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog';
import Measure, { ContentRect } from 'react-measure';
import { Spring } from 'react-spring/renderprops';

import { measurement } from '../../helpers/vars';
import { animation, responsive, style } from '../../helpers/mixins';

import Button, { StyledButton } from '../Button';

import Item, { SuboptionType } from './Item';
import Option, { Title } from './Option';
import Branch from './Branch';
import Illustration from './Illustration';
import Icon from '../Icon';
import { Small } from '../Text';
import RichText from '../RichText';

export interface OptionType {
  readonly name: string;
  readonly suboptions: SuboptionType[];
}

export type OptionsType = (OptionType | SuboptionType)[];

interface TreeSelectProps {
  readonly options: OptionsType;
  readonly selectedCount: number;
  readonly addSelected: (productcode: string, uuid: string) => void;
  readonly addLabel: string;
  readonly selectedLabel: string;
  readonly setIsSelecting?: (isSelecting: boolean) => void;
  readonly parentOpen?: boolean;
  readonly focusRef?: any;
  readonly isSelectable?: (productcode: string) => boolean;
  readonly infoText?: string;
  readonly canAdd: boolean;
}

const Wrapper = styled.div<{ visible: boolean }>`
  overflow: hidden;
  margin: -${measurement.inset.modal.s} -${measurement.inset.modal.s} 0;
  transition: ${animation.transition('margin')};

  ${responsive.fontSize(css`
    margin: -${measurement.inset.modal.l} -${measurement.inset.modal.l} 0;
  `)}

  ${(props) =>
    !props.visible &&
    css`
      margin-top: 0;

      ${responsive.fontSize(css`
        margin-top: 0;
      `)}
    `}
`;

const Container = styled(Toolbar)`
  display: flex;
  flex-direction: column;
  padding-right: 100%;
  position: relative;
  width: 100%;
`;

const StyledDisclosure = styled.div<{ open?: boolean }>`
  ${StyledButton} {
    border: ${style.border.default};
    border-radius: ${measurement.size.radius.default};
    margin: auto;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    transition: ${animation.transition('line-height', 'padding', 'border', 'opacity')};

    ${(props) =>
      props.open &&
      css`
        line-height: 0;
        padding: 0;
        border: 0;
        height: 0;
        opacity: 0;
      `}
  }
`;

const StyledDialog = styled(Dialog)`
  &:focus {
    box-shadow: none;
    outline: ${style.border.focus};
    outline-offset: -${measurement.size.border.strong};
  }
`;

const InfoContainer = styled.div`
  padding: 0 ${measurement.inset.modal.s};
`;

const Tree: React.FunctionComponent<TreeSelectProps> = ({
  addLabel,
  selectedLabel,
  options,
  selectedCount,
  addSelected,
  setIsSelecting,
  parentOpen,
  focusRef,
  isSelectable,
  infoText,
  canAdd,
}) => {
  const toolbar = useToolbarState();
  const dialog = useDialogState({ modal: false, animated: true });
  const openDialog = dialog.show;

  const [containerHeight, setContainerHeight] = useState(0);
  const [treeHeight, setTreeHeight] = useState(0);
  const [branchHeight, setBranchHeight] = useState(0);

  useEffect(() => (!selectedCount ? openDialog() : undefined), [selectedCount, openDialog, parentOpen]);

  useEffect(() => setIsSelecting && setIsSelecting(dialog.visible), [setIsSelecting, dialog.visible]);

  useEffect(() => setContainerHeight(branchHeight || treeHeight), [setContainerHeight, branchHeight, treeHeight]);

  const measure: (arg: ContentRect) => void = ({ offset }) => setTreeHeight(offset?.height || 0);

  const handleSelection = (productcode: string, uuid: string) => {
    dialog.hide();
    addSelected(productcode, uuid);
  };

  return (
    <StyledDisclosure open={dialog.visible}>
      {canAdd && (
        <DialogDisclosure as={Button} secondary small icon="plus" {...dialog} disabled={dialog.visible}>
          {addLabel}
        </DialogDisclosure>
      )}
      <StyledDialog {...dialog} aria-label={addLabel} hideOnClickOutside={false}>
        <Spring
          to={{
            height: dialog.visible ? containerHeight : 0,
            opacity: dialog.visible ? 1 : 0,
          }}
          onRest={dialog.stopAnimation}
        >
          {(style) => (
            <Wrapper {...{ style }} visible={dialog.visible}>
              <Measure offset onResize={measure}>
                {({ measureRef }) => (
                  <Spring
                    to={{
                      marginLeft: branchHeight ? '-100%' : '0%',
                    }}
                  >
                    {(style) => (
                      <Container {...toolbar} aria-label={addLabel} ref={measureRef} {...{ style }}>
                        {!!selectedCount && (
                          <Title onClick={dialog.hide}>
                            <Icon name="left" label="back" />
                            <Small as="h2">{selectedLabel}</Small>
                          </Title>
                        )}
                        {options.map((option, i) =>
                          option.suboptions ? (
                            <ToolbarItem
                              key={i}
                              as={Branch}
                              {...option}
                              {...toolbar}
                              {...{ handleSelection, setBranchHeight, isSelectable }}
                              {...(focusRef && i === 0 && { ref: focusRef })}
                            />
                          ) : (
                            <ToolbarItem
                              key={i}
                              as={Option}
                              {...toolbar}
                              {...(focusRef && i === 0 && { ref: focusRef })}
                              onClick={() =>
                                isSelectable &&
                                isSelectable(option.productcode) &&
                                handleSelection(option.productcode, option.uuid)
                              }
                              {...(isSelectable && !isSelectable(option.productcode) && { 'aria-disabled': true })}
                            >
                              <Item {...option} showDimensionTitle={true} />
                              {'illustration' in option && option.illustration && (
                                <Illustration name={option.illustration} />
                              )}
                            </ToolbarItem>
                          )
                        )}
                        {infoText && (
                          <InfoContainer>
                            <RichText>{infoText}</RichText>
                          </InfoContainer>
                        )}
                      </Container>
                    )}
                  </Spring>
                )}
              </Measure>
            </Wrapper>
          )}
        </Spring>
      </StyledDialog>
    </StyledDisclosure>
  );
};

export default Tree;
