import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import { EitherAsync } from 'purify-ts/EitherAsync';
import { List } from 'purify-ts/List';
import Observable from 'zen-observable-ts';

type Response = GraphQLResult<object> | Observable<object>;
type GraphQLError = Pick<GraphQLResult, 'errors'>;

interface Throwable {
  readonly message: string;
}

class QueryError extends Error {}

export const query = <T>(operation: string, query: any): EitherAsync<QueryError, T> =>
  EitherAsync(async () => {
    let response: Response;

    try {
      response = await API.graphql(graphqlOperation(operation, { query }));
    } catch (e) {
      const { errors = [] } = e as GraphQLError;
      throw new QueryError(extractReason(errors));
    }

    return validateResponse<T>(response);
  });

const validateResponse = <T>(response: Response): T => {
  const { data, errors = [] } = response as GraphQLResult<T>;

  if (errors.length > 0) {
    throw new QueryError(extractReason(errors));
  }

  if (!data || Object.keys(data).length === 0) {
    throw new QueryError('GraphQL query returned an empty response');
  }

  return data;
};

const extractReason = (errors: Throwable[]): string =>
  List.head(errors)
    .map((error) => error.message)
    .orDefault('GraphQL query encountered an unknown error');
