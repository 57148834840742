import { assign, createMachine, Interpreter, spawn, DoneInvokeEvent, State, SpawnedActorRef } from 'xstate';
import { Language } from '../../Language.context';
import { startPayment, verifyPayment } from '../api/payment';
import { reserveBooking } from '../api/reservation';
import loaderMachine, {
  LOADED_CONFIRMATION_PDF,
  LoaderInterpreter,
  LoaderContext,
  LoaderEvent,
} from '../loader/loaderMachine';
import * as API from '../types';
import { ExtendedPassenger, isPassengerInput } from '../types';
import actions from './actions';

export interface PaymentContext {
  currency: API.Currency;
  error: any | undefined;
  language: Language;
  loaderRef:
    | LoaderInterpreter
    | undefined
    | SpawnedActorRef<LoaderEvent, State<LoaderContext, LoaderEvent, any, { value: any; context: LoaderContext }>>;
  path: string;
  redirectUrl: string | 'used' | undefined;
  reservation: API.BookingReservation | undefined;
  transactionId: string | undefined;
  trip: API.Trip | undefined;
  reserver: ExtendedPassenger | undefined;
  verificationRetried: number;
}

export interface PaymentStateSchema {
  states: {
    confirmBooking: {};
    reserveBooking: {};
    startPayment: {};
    verifyPayment: {};
    success: {
      states: {
        idle: {};
        load: {};
      };
    };
  };
}

export type PaymentTypestates =
  | {
      value: 'confirmBooking' | 'reserveBooking';
      context: PaymentContext & {
        redirectUrl: undefined;
        reservation: undefined;
        transactionId: undefined;
      };
    }
  | {
      value: 'startPayment';
      context: PaymentContext & {
        redirectUrl: string | 'used';
        reservation: API.BookingReservation;
        transactionId: undefined;
      };
    }
  | {
      value: 'verifyPayment' | 'success' | 'success.idle' | 'success.load';
      context: PaymentContext & {
        redirectUrl: 'used';
        reservation: API.BookingReservation;
        transactionId: string;
        verificationRetried: number;
      };
    };

export type PaymentEvent =
  | { type: '/payment'; error?: any }
  | { type: '/payment/reserve' }
  | { type: '/payment/start' }
  | { type: '/payment/verify' }
  | { type: '/payment/success' }
  | { type: 'PAY' }
  | { type: 'PAID' }
  | { type: 'REDIRECTED' }
  | { type: 'VERIFY'; transactionId: string }
  | { type: 'RE_VERIFY'; transactionId: string; verificationRetried: number }
  | { type: 'DOWNLOAD_PDF' }
  | LOADED_CONFIRMATION_PDF
  | { type: 'SET_CONSENT'; dataConsent: boolean };

export type PaymentInterpreter = Interpreter<PaymentContext, PaymentStateSchema, PaymentEvent, PaymentTypestates>;

export const loaderId = 'loader';
export default createMachine<PaymentContext, PaymentEvent, PaymentTypestates>(
  {
    id: 'payment',
    initial: 'confirmBooking',
    context: {
      currency: API.Currency.EUR,
      error: undefined,
      language: API.Language.EN,
      loaderRef: undefined,
      path: '/payment',
      redirectUrl: undefined,
      reservation: undefined,
      transactionId: undefined,
      trip: undefined,
      reserver: undefined,
      verificationRetried: 0,
    },
    states: {
      confirmBooking: {
        id: 'confirmBooking',
        entry: [
          assign<PaymentContext, PaymentEvent>({ path: '/payment', redirectUrl: undefined }),
          'updateHistory',
          'gtmCheckoutOrderSummary',
        ],
        on: {
          PAY: [
            {
              cond: (ctx) => {
                return (
                  (ctx.trip?.passengers.find((p) => isPassengerInput(p) && p.info?.reserverInfo) as ExtendedPassenger)
                    ?.info?.reserverInfo?.termsOfServiceOk || false
                );
              },
              target: 'reserveBooking',
            },
            {
              actions: 'onConsentError',
            },
          ],
          PAID: [
            {
              target: 'success',
            },
          ],
          SET_CONSENT: [
            {
              actions: ['updateHistory', 'setConsent'],
            },
          ],
          RE_VERIFY: [
            {
              target: 'verifyPayment',
              actions: [
                assign<PaymentContext, { transactionId: string; verificationRetried: number; type: 'RE_VERIFY' }>(
                  (ctx, event) => ({
                    verificationRetried: event.verificationRetried,
                  })
                ),
              ],
            },
          ],
        },
      },
      reserveBooking: {
        id: 'reserveBooking',
        entry: [assign<PaymentContext, PaymentEvent>({ path: '/payment/reserve' }), 'updateHistory'],
        invoke: {
          id: 'reserveBooking',
          src: reserveBooking,
          onDone: {
            target: 'startPayment',
            actions: assign((_, event: any) => ({ ...event.data })),
          },
          onError: {
            target: 'confirmBooking',
            actions: 'extractError',
          },
        },
      },
      startPayment: {
        id: 'startPayment',
        entry: ['updateHistory', 'gtmCheckoutPayment'],
        invoke: {
          id: 'startPayment',
          src: startPayment,
          onDone: {
            actions: [
              assign<PaymentContext, DoneInvokeEvent<any>>((_, event: any) => ({
                path: '/payment/start',
                redirectUrl: event.data.redirectUrl,
              })),
              'updateHistory',
            ],
          },
          onError: {
            target: 'confirmBooking',
            actions: 'extractError',
          },
        },
        on: {
          REDIRECTED: {
            actions: [assign<PaymentContext, { type: 'REDIRECTED' }>({ redirectUrl: 'used' }), 'updateHistory'],
          },
          VERIFY: {
            target: 'verifyPayment',
            actions: [
              assign<PaymentContext, { transactionId: string; type: 'VERIFY' }>((_, event) => ({
                transactionId: event.transactionId,
              })),
              'updateHistory',
            ],
          },
        },
      },
      verifyPayment: {
        id: 'verifyPayment',
        entry: [assign<PaymentContext, PaymentEvent>({ path: '/payment/verify' }), 'updateHistory'],
        invoke: {
          id: 'verifyPayment',
          src: verifyPayment,
          onDone: {
            target: 'success',
            actions: ['gtmPurchase'],
          },
          onError: {
            target: 'confirmBooking',
            actions: 'extractError',
          },
        },
      },
      success: {
        id: 'success',
        initial: 'idle',
        states: {
          idle: {
            entry: [
              assign<PaymentContext, PaymentEvent>({
                loaderRef: (context) => context.loaderRef || spawn(loaderMachine, loaderId),
                path: '/payment/success',
              }),
              'updateHistory',
            ],
            on: {
              DOWNLOAD_PDF: {
                actions: ['loadConfirmationPdf'],
                target: 'load',
              },
            },
          },
          load: {
            entry: ['updateHistory'],
            on: {
              LOADED_CONFIRMATION_PDF: {
                target: 'idle',
              },
            },
          },
        },
      },
    },
    on: {
      '/payment': {
        target: 'confirmBooking',
        actions: [
          assign<PaymentContext, { error?: any; type: '/payment' }>((ctx: PaymentContext, event) => ({
            error: event.error || ctx.error,
          })),
        ],
      },
      '/payment/reserve': {
        target: 'reserveBooking',
      },
      '/payment/start': {
        target: 'startPayment',
      },
      '/payment/verify': {
        target: 'verifyPayment',
      },
      '/payment/success': {
        target: 'success',
      },
    },
  },
  { actions }
);
