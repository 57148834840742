import { format, parseISO } from 'date-fns';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import Alert from '../../../design-system/components/Alert';
import Button from '../../../design-system/components/Button';
import Card from '../../../design-system/components/Card';
import { FlexibleRow, Subgrid } from '../../../design-system/components/Container';
import Modal from '../../../design-system/components/Modal';
import Stepper from '../../../design-system/components/Stepper';
import Tag, { Wrapper } from '../../../design-system/components/Tag';
import { H2, H3, Lead, P, Price, Small } from '../../../design-system/components/Text';
import TimeTable, { ItemProps } from '../../../design-system/components/TimeTable';
import { Transition } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import * as API from '../../../fsm/types';
import { allPassengersInCabins } from '../../../fsm/utils/accommodationUtils';
import {
  calculatePossibleProductCount,
  getCount,
  getPassengerTypeForProduct,
  includedProducts,
  MealPackage,
  PassengerProductCounts,
  selectableProductCount,
  setStepperValue,
  sortByProductTypeAndAdultToInfantProductInLimited,
} from '../../../fsm/utils/productUtils';
import { LanguageContext } from '../../../Language.context';
import { TreeData } from '../../../storyblok';
import { formatString } from '../../../utils/formats';
import { calculateProductsTotalPrice } from '../../../utils/priceCalculation';
import OnboardDescription from './shared/OnboardDescription';

export enum MealSelectionType {
  PACKAGE,
  INDIVIDUAL_MEAL,
  ALA_CARTE,
}

interface StateSetProps {
  readonly add: (code: string, amount?: number) => void;
  readonly remove: (code: string, amount?: number) => void;
}

interface MealSelectionProps extends StateSetProps {
  readonly passengers: API.Passengers;
  readonly sailing: API.ExtendedSailing & API.SelectedSailing;
  readonly passengerInfo: API.ExtendedPassengerAndPet[];
  readonly onOpen: (sailingCode: string, passengerTypes: API.PassengerType[]) => void;
  readonly freeMeals?: boolean;
  readonly mealSelectionType: MealSelectionType;
  readonly disableModify: boolean;
}

interface MealRowProps extends StateSetProps {
  readonly sbMeal?: TreeData;
  readonly products: API.ExtendedProduct[];
  readonly selectedMealProducts: API.ExtendedOnboard[];
  readonly passengers: API.Passengers;
  readonly possibleMealCounts: API.Passengers;
  readonly includedMeals: PassengerProductCounts;
  readonly tariff: API.Tariff;
  readonly modalStrs?: TreeData;
  readonly id: string;
  readonly infantFreeOfCharge: boolean;
}

interface MealItemProps extends ItemProps {
  readonly uuid?: string;
}

const MEAL_ORDER = [
  API.ProductSubType.MEAL,
  API.ProductSubType.BREAKFAST,
  API.ProductSubType.PREMIUM_BREAKFAST,
  API.ProductSubType.LOUNGE_BREAKFAST,
  API.ProductSubType.BRUNCH,
  API.ProductSubType.LUNCH,
  API.ProductSubType.DINNER,
  API.ProductSubType.NIGHTSNACK,
];

const isOfMealType = (
  { code }: API.ExtendedProduct | API.ExtendedOnboard,
  mealSelectionType: MealSelectionType,
  sbMealPackages: TreeData | undefined,
  sbMealTypes: TreeData[]
): boolean => {
  if (!sbMealPackages || !sbMealTypes) {
    return false;
  }

  const mealProducts = [] as string[];
  switch (mealSelectionType) {
    case MealSelectionType.PACKAGE: {
      mealProducts.push(...sbMealPackages.content.meal_package_products);
      break;
    }
    case MealSelectionType.INDIVIDUAL_MEAL: {
      mealProducts.push(...sbMealPackages.content.individual_meal_products);
      break;
    }
    case MealSelectionType.ALA_CARTE: {
      mealProducts.push(...sbMealPackages.content.ala_carte_products);
      break;
    }
  }
  const meals = mealProducts.map((mp) => sbMealTypes.find((mealType) => mealType.uuid === mp)).map((_) => _?.name);
  return meals.includes(code);
};

const infantFreeOfCharge = (products: API.ExtendedProduct[], mealSelectionType: MealSelectionType) =>
  !products.some((p) => p.code === MealPackage.INFANT) && mealSelectionType === MealSelectionType.PACKAGE;

const getPassengerForMeal = (code: string, products: API.ExtendedProduct[]) => {
  const meal = products.find((p) => p.code === code);
  return meal ? getPassengerTypeForProduct(meal) : undefined;
};

const getModalOpeningButtonText = (
  sbMealPackages: TreeData | undefined,
  selectedMealProducts: API.ExtendedOnboard[],
  mealSelectionType: MealSelectionType
) => {
  if (mealSelectionType === MealSelectionType.PACKAGE) {
    return !selectedMealProducts.length
      ? sbMealPackages?.content.add_meal_packages_button
      : sbMealPackages?.content.edit_meal_packages_button;
  } else if (mealSelectionType === MealSelectionType.ALA_CARTE) {
    return !selectedMealProducts.length
      ? sbMealPackages?.content.add_ala_carte_meals_button
      : sbMealPackages?.content.edit_ala_carte_meals_button;
  } else {
    return !selectedMealProducts.length
      ? sbMealPackages?.content.add_individual_meals_button
      : sbMealPackages?.content.edit_individual_meals_button;
  }
};

const MealItem: FunctionComponent<MealItemProps> = ({ uuid, ...itemProps }) => {
  const [sbMeal, ref] = hooks.useStoryblokComponent<HTMLParagraphElement>({ uuid });
  return (
    <TimeTable.EventItem ref={ref} variant="meal" {...itemProps}>
      {sbMeal?.content.title}
    </TimeTable.EventItem>
  );
};

const MealRow: FunctionComponent<MealRowProps> = ({
  sbMeal,
  products,
  selectedMealProducts,
  passengers,
  possibleMealCounts,
  includedMeals,
  tariff,
  id,
  add,
  remove,
  modalStrs,
  infantFreeOfCharge,
}) => {
  const { formats } = useContext(LanguageContext);

  // e.g. S_BR
  const mealPackageType = sbMeal?.name;
  // e.g. Breakfast, adult
  const mealPackageName = sbMeal?.content.title;

  if (mealPackageType) {
    const asProduct = products.find(({ code }) => code === mealPackageType);
    const passengerType = getPassengerTypeForProduct(asProduct);

    // When passengerType is undefined, the meal type is not restricted to passenger type
    // e.g. Micke's a la carte
    if ((passengerType && passengers[passengerType] > 0) || passengerType === undefined) {
      const charge = API.maybeChargeInfo(tariff, asProduct).mapOrDefault((_) => _.charge, undefined);
      const chargeMissingMessage = hooks.useStoryblokDatasource('onboard-error-codes').MEAL_CHARGE_NOT_FOUND;

      const possibleMealCount = calculatePossibleProductCount(
        possibleMealCounts,
        passengerType,
        selectedMealProducts,
        products,
        asProduct
      );
      const max = Math.min(possibleMealCount, asProduct?.capacity.available || 0);
      const included = passengerType
        ? includedMeals[passengerType].filter((meal) => meal.code === mealPackageType).length
        : 0;

      const freeOfCharge = passengerType === API.PassengerType.INFANT && infantFreeOfCharge;

      const stepper = freeOfCharge
        ? {
            placeholder: modalStrs?.content.free_of_charge,
          }
        : {
            setValue: (value: number) =>
              setStepperValue(mealPackageType, value - included, selectedMealProducts, add, remove),
            value: getCount(mealPackageType, selectedMealProducts) + included,
            min: 0 + included,
            max: max + included,
          };

      return (
        <Stepper id={id} {...stepper}>
          <Lead as="span">{mealPackageName}</Lead>
          <br />
          <Small as="span">
            {freeOfCharge ? (
              modalStrs?.content.free_of_charge_long
            ) : charge !== undefined ? (
              <>
                {!!included && (
                  <>
                    {formatString(modalStrs?.content.included, included)}
                    <br />
                  </>
                )}
                {formatString(
                  modalStrs?.content.price_online,
                  <Price inline small as="span" key="online-price">
                    {formats.currency(charge)}
                  </Price>
                )}
              </>
            ) : (
              <Small>{chargeMissingMessage}</Small>
            )}
          </Small>
        </Stepper>
      );
    }
  }

  return <></>;
};

const MealSelection: FunctionComponent<MealSelectionProps> = ({
  passengers,
  passengerInfo,
  sailing,
  freeMeals,
  add,
  remove,
  onOpen,
  mealSelectionType,
  disableModify,
}) => {
  const { formats } = useContext(LanguageContext);

  const sbPorts = hooks.useStoryblokDatasource('ports');

  const sbMealTypes = Object.values(hooks.useStoryblokComponents('products/onboards/meal-types'));
  const sbMealTypesArray = sbMealTypes.map((meal) => meal.name);
  const sbMealTypesObject = sbMealTypes.reduce(
    (obj, meal) => Object.assign(obj, { [meal.name]: meal.content.title }),
    {}
  ) as { [name: string]: string };

  const [sbMealPackages, ref] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.meal_packages',
  });

  const [sbMealPackagesModal, modalRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'onboard.meal_packages_modal',
  });

  const individualMealsDescription = hooks.useStoryblokRichText(sbMealPackages?.content.individual_meals_description);
  const alaCarteDescription = hooks.useStoryblokRichText(sbMealPackages?.content.ala_carte_description);

  const sbSailingTimes = Object.values(hooks.useStoryblokComponents('components/sailing-times')).map(
    ({ content, uuid }) => ({ uuid, start: content.start_time, end: content.end_time })
  );

  const arrivalPort = (sbPorts && sbPorts[sailing.arrivalPort]) || sailing.arrivalPort;
  const departurePort = (sbPorts && sbPorts[sailing.departurePort]) || sailing.departurePort;

  // Meals are included for passengers in suites and in selected legs
  const accommodations = API.isError(sailing.accommodations) ? [] : sailing.accommodations || [];

  // List of accommodations that contain meals and which meals are included
  const sbAccommodationsWithMeals = Object.values(hooks.useStoryblokComponents('products/accommodation_services'))
    .filter(({ content }) => content.meals_included)
    .map(({ content }) => ({
      productCode: content.product_code,
      productsIncluded: content.meal_types_included || [],
    }));

  const products = API.isError(sailing.products) ? [] : sailing.products?.FOOD || [];

  // List of meals that are included. E.g. if 2 adults are travelling in a suite that contains
  // breakfast and two juniors travel in a cabin that doesn't include meals the result is following
  // ADULT: [S_BR, S_BR],
  // JUNIOR: [],
  // CHILD: [],
  // INFANT: []
  const includedMeals = includedProducts(
    accommodations,
    sbAccommodationsWithMeals,
    sbMealTypes,
    products,
    passengerInfo
  );

  const allMealsIncluded =
    freeMeals ||
    (accommodations.length > 0 &&
      accommodations.every(({ code }) => sbAccommodationsWithMeals.find((acc) => acc.productCode === code)) &&
      allPassengersInCabins(accommodations, passengerInfo));

  const someMealsIncluded = allMealsIncluded || Object.values(includedMeals).some((value) => value.length > 0);

  // List of meals that user has explicitly selected
  const selectedMealProducts = (API.isError(sailing.onboards) ? [] : sailing.onboards || [])
    .filter(({ type }) => type === 'FOOD')
    .filter((p) => isOfMealType(p, mealSelectionType, sbMealPackages, sbMealTypes));

  // which meals are available on the selected leg
  const shownMealProducts: API.ExtendedProduct[] = products.filter((p) =>
    isOfMealType(p, mealSelectionType, sbMealPackages, sbMealTypes)
  );

  // How many meals user can explicitly select, e.g. if two adults are travelling
  // and one of them has free meals, then one adult meal can be selected
  const possibleMealCounts = freeMeals
    ? passengers
    : selectableProductCount(passengers, includedMeals, shownMealProducts);

  // products available on sailing based on GrimaldiData and selected passengers
  // i.e. if no juniors are travelling then junior products are filtered out
  const possibleMealProducts = shownMealProducts.filter((meal) => {
    const passengerType = getPassengerTypeForProduct(meal);
    return passengerType ? passengers[passengerType] > 0 : true;
  });

  const possibleSbMealTypes = sortByProductTypeAndAdultToInfantProductInLimited<API.Product>(
    possibleMealProducts,
    MEAL_ORDER
  ).reduce((mealtTypes, product) => {
    const meal = sbMealTypes.find(({ name }) => name === product.code);
    if (meal) {
      return [...mealtTypes, meal];
    }
    return mealtTypes;
  }, [] as TreeData[]);

  const modalState = useDialogState({ animated: false });

  // Special case for Cruise:
  // Selected meal products are onboards products that contain correct price per leg.
  // The dialog should however show the prices of product for both legs in case of meal
  // package and that price is stored in shownMealProducts. For all other cases the
  // price in shownMealProducts and selectedMealProducts is the same
  const productsWithPrices = selectedMealProducts.map((onboardProduct) => {
    const mealProduct = shownMealProducts.find((mealProduct) => mealProduct.code === onboardProduct.code);
    return {
      ...onboardProduct,
      price: mealProduct !== undefined ? mealProduct.price : onboardProduct.price,
    };
  });
  const totalCharge = calculateProductsTotalPrice(productsWithPrices, sailing.tariff);

  const selectedMeals = sbMealTypesArray.reduce((selected, type) => {
    const onboardForType = selectedMealProducts.find(({ code }) => type === code)?.amount || 0;

    const passengerType = getPassengerForMeal(type, possibleMealProducts);
    if (passengerType) {
      const freeForType = includedMeals[passengerType].filter((meal) => meal.code === type).length;
      return { ...selected, [type]: { amount: freeForType + onboardForType, type: passengerType } };
    }
    return { ...selected, [type]: { amount: onboardForType, type: undefined } };
  }, {}) as { [key: string]: { amount: number; type: API.PassengerType | undefined } };

  const allSelectedMeals = Object.values(selectedMeals).some(
    ({ amount, type }) => !!amount && !!type && amount === passengers[type]
  );
  const someSelectedMeals = allSelectedMeals || Object.values(selectedMeals).some(({ amount }) => amount > 0);

  const passengerTypes = Object.keys(possibleMealCounts).filter((type) => {
    return possibleMealCounts[type as API.PassengerType] > 0;
  });

  useEffect(() => {
    if (modalState.visible) {
      onOpen(sailing.sailingCode, passengerTypes as API.PassengerType[]);
    }
  }, [modalState.visible]);

  // TODO card image and modal image for individual meals might be defined in story blok
  // in the future, currently their shown only for meal packages
  let cardImage;
  let modalImage;
  let description;
  let bottomRow;

  // Show which meals are included in meal package
  if (mealSelectionType === MealSelectionType.PACKAGE) {
    const sbMealPackageContents = hooks.useStoryblokComponents('products/onboards/meal-package-contents');

    const mealPackagesForLeg = Object.values(sbMealPackageContents).filter(
      ({ content }) => content.leg === `${sailing.departurePort}-${sailing.arrivalPort}`
    );

    const departureTime = parseInt(sailing.departureTime.replace(/:/g, ''));

    const mealPackageForSailing = mealPackagesForLeg.find(({ content }) => {
      if (content.sailing_time) {
        const times = sbSailingTimes.find((time) => time.uuid === content.sailing_time);
        if (times) {
          const start = parseInt(times.start);
          const end = parseInt(times.end);

          if (end > start) {
            return departureTime > start && departureTime < end;
          } else {
            return departureTime > start || departureTime < end;
          }
        }
      }
      return true;
    });

    const [sbPackageForSailing, packageSailingRef] = hooks.useStoryblokComponent<HTMLDivElement | HTMLParagraphElement>(
      {
        uuid: mealPackageForSailing?.uuid,
      }
    );

    const timeTable: {
      children: React.ReactNode;
      addition: string;
      items: { time?: string; children?: React.ReactNode; uuid?: string; isMeal?: boolean }[];
    }[] = [
      {
        children: sbMealPackages?.content.departure_day,
        addition: format(parseISO(sailing.departureDate), 'iii d. MMM'),
        items: [
          {
            time: sailing.departureTime,
            children: formatString(sbMealPackages?.content.ship_departs_from, departurePort),
          },
        ].concat(
          (sbPackageForSailing?.content.food_items_available_departure || []).map((uuid: string) => ({
            uuid,
            isMeal: true,
          }))
        ),
      },
      {
        children: sbMealPackages?.content.arrival_day,
        addition: format(parseISO(sailing.arrivalDate), 'iii d. MMM'),
        items: (sbPackageForSailing?.content.food_items_available_arrival || [])
          .map((uuid: string) => ({
            uuid,
            isMeal: true,
          }))
          .concat([
            {
              time: sailing.arrivalTime,
              children: formatString(sbMealPackages?.content.ship_arrives_in, arrivalPort),
            },
          ]),
      },
    ];

    cardImage = sbPackageForSailing?.content.image;
    modalImage = sbPackageForSailing?.content.image.image;

    description = <P ref={packageSailingRef}>{sbPackageForSailing?.content.description}</P>;
    bottomRow = (
      <FlexibleRow ref={packageSailingRef}>
        {timeTable.map(({ items }, i) => (
          <TimeTable.Wrapper key={i}>
            {items.map(({ isMeal, ...itemProps }, j) => (isMeal ? <MealItem key={j} {...itemProps} /> : ''))}
          </TimeTable.Wrapper>
        ))}
      </FlexibleRow>
    );
  }

  let title = '';
  let mainPageDescription = <></>;
  if (mealSelectionType === MealSelectionType.PACKAGE) {
    title = sbMealPackages?.content.meal_packages_title;
  } else if (mealSelectionType === MealSelectionType.INDIVIDUAL_MEAL) {
    title = sbMealPackages?.content.individual_meals_title;
    mainPageDescription = <OnboardDescription>{individualMealsDescription}</OnboardDescription>;
  } else {
    title = sbMealPackages?.content.ala_carte_meals_title;
    mainPageDescription = <OnboardDescription>{alaCarteDescription}</OnboardDescription>;
  }

  return freeMeals || !!shownMealProducts.length ? (
    <Subgrid ref={ref}>
      <H2>{title}</H2>
      {mealSelectionType === MealSelectionType.PACKAGE && (allMealsIncluded || someMealsIncluded) && (
        <Alert severity="success">
          <H3>
            {allMealsIncluded
              ? sbMealPackages?.content.meals_included_all
              : sbMealPackages?.content.meals_included_some}
          </H3>
          <p>
            {freeMeals ? sbMealPackages?.content.meals_included_route : sbMealPackages?.content.meals_included_suite}
            {!allMealsIncluded && ` ${sbMealPackages?.content.meals_included_add_additional}`}
          </p>
        </Alert>
      )}
      {!allMealsIncluded && someSelectedMeals && (
        <Card image={cardImage}>
          <H3>{formatString(sbMealPackagesModal?.content.title, departurePort, arrivalPort)}</H3>
          <Lead>{sbMealPackages?.content.selected_meals}</Lead>
          <Wrapper>
            {Object.keys(selectedMeals).map(
              (key) =>
                selectedMeals[key].amount > 0 && (
                  <Tag key={key} count={selectedMeals[key].amount} label={sbMealTypesObject[key] || key} />
                )
            )}
          </Wrapper>
          {passengers.INFANT > 0 && infantFreeOfCharge(shownMealProducts, mealSelectionType) && (
            <Small>{sbMealPackages?.content.infants_free}</Small>
          )}
        </Card>
      )}
      {(!allMealsIncluded || mealSelectionType !== MealSelectionType.PACKAGE) && !disableModify && (
        <>
          {mainPageDescription}
          <DialogDisclosure disclosure as={Button} round {...modalState}>
            {getModalOpeningButtonText(sbMealPackages, selectedMealProducts, mealSelectionType)}
          </DialogDisclosure>
          <Modal
            ref={modalRef}
            state={modalState}
            title={formatString(sbMealPackagesModal?.content.title, departurePort, arrivalPort)}
            focusOnDialog
            image={modalImage}
            submit={sbMealPackagesModal?.content.ready_button}
            size="input"
            footer={
              <Transition.Height show={!!totalCharge} as={Price}>
                <Transition.Number value={totalCharge} formatter={formats.currency} />
              </Transition.Height>
            }
          >
            {(modalState.animating || modalState.visible) && (
              <>
                {description}
                {possibleSbMealTypes !== undefined &&
                  possibleSbMealTypes.map((sbMeal: TreeData, i: number) => (
                    <MealRow
                      key={i}
                      id={`${sailing.sailingCode}-${sbMeal.name}`}
                      modalStrs={sbMealPackagesModal}
                      {...{
                        sbMeal,
                        products: shownMealProducts,
                        selectedMealProducts,
                        passengers,
                        possibleMealCounts,
                        includedMeals,
                        remove,
                        add,
                        tariff: sailing.tariff,
                        infantFreeOfCharge: infantFreeOfCharge(shownMealProducts, mealSelectionType),
                        accommodationsWithMeals: sbAccommodationsWithMeals,
                      }}
                    />
                  ))}
              </>
            )}
          </Modal>
        </>
      )}
      {bottomRow}
    </Subgrid>
  ) : (
    <></>
  );
};

export default MealSelection;
