// This is console.log -wrapper.
//
// The purpose is to dynamically activate console logging
// for debugging purposes, also in production env.
// It will fetch the logging level from url params and
// will activate more detailed logging for application.
// By default no logging is done, except error and warn logs.

const searchParams = new URLSearchParams(window.location.search);
const level = searchParams.get('log') || 'warn'; // default level

const logger = {
  debug: (...args: Array<any>) => {
    return ['debug'].includes(level) && console.log('debug:', ...args);
  },
  info: (...args: Array<any>) => {
    return ['debug', 'info'].includes(level) && console.info('info:', ...args);
  },
  warn: (...args: Array<any>) => {
    return ['debug', 'info', 'warn'].includes(level) && console.warn('warn:', ...args);
  },
  error: (...args: Array<any>) => {
    return console.error('error:', ...args);
  },
};

// In order to test levels, uncomment lines below.
// logger.debug('Debug log', 1)
// logger.info('Info log', 2)
// logger.warn('Warn log', 3)
// logger.error('Error log', 4)

export default logger;
