import * as API from '../../FinnlinesB2CBookingAPI';
import { Form, SearchParams } from './search';

export const isFormEqual = (f1: Form, f2: Form) =>
  f1.index === f2.index &&
  f1.searchedDate === f2.searchedDate &&
  f1.selectedDate === f2.selectedDate &&
  f1.params.arrivalPort === f2.params.arrivalPort &&
  f1.params.departurePort === f2.params.departurePort &&
  f1.params.endDate === f2.params.endDate &&
  f1.params.numberOfDays === f2.params.numberOfDays &&
  f1.params.startDate === f2.params.startDate;

export const isSearchEqual = (s1: SearchParams, s2: SearchParams): boolean => {
  return (
    s1.currency === s2.currency &&
    s1.language === s2.language &&
    s1.starclub === s2.starclub &&
    s1.offerCode === s2.offerCode &&
    s1.searchedAt === s2.searchedAt &&
    s1.type === s2.type &&
    s1.petCount === s2.petCount &&
    Object.keys(s1.passengers).every(
      (passengerType) =>
        s1.passengers[passengerType as API.PassengerType] === s2.passengers[passengerType as API.PassengerType]
    ) &&
    s1.vehicles.every((v1) => s2.vehicles.find((v2) => v2.id === v1.id && v2.productcode === v1.productcode)) &&
    s1.forms.length === s2.forms.length &&
    s1.forms.every((f1) => {
      const f2 = s2.forms.find((form) => form.index === f1.index);
      return f2 && isFormEqual(f1, f2);
    })
  );
};
