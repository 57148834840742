import styled from 'styled-components';

import { StyledTag } from './Tag';

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -0.25rem;

  ${StyledTag} {
    margin: 0.25rem;
  }
`;

export default Wrapper;
