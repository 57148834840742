import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import { size } from 'polished';
import { VisuallyHidden } from 'reakit/VisuallyHidden';

import { color, measurement } from '../helpers/vars';
import { StyledInput } from '../helpers/components';

export type IconName =
  | 'empty'
  | 'plus'
  | 'minus'
  | 'times'
  | 'left'
  | 'right'
  | 'up'
  | 'down'
  | 'horizontal'
  | 'vertical'
  | 'check'
  | 'arrow'
  | 'edit'
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'alert'
  | 'adult'
  | 'junior'
  | 'child'
  | 'infant'
  | 'pet'
  | 'ship'
  | 'meal'
  | 'remove'
  | 'dnd'
  | 'swap';

const path: { [key in IconName]: React.ReactElement } = {
  empty: <></>,
  plus: <path stroke="currentColor" d="M6 12h12m-6-6v12" />,
  minus: <path stroke="currentColor" d="M6 12h12" />,
  times: <path stroke="currentColor" d="M7 7l10 10m-10-0l10 -10" />,
  left: <path stroke="currentColor" d="M10 7l-5 5l5 5" />,
  right: <path stroke="currentColor" d="M14 7l5 5l-5 5" />,
  up: <path stroke="currentColor" d="M7 14l5 -5l5 5" />,
  down: <path stroke="currentColor" d="M7 10l5 5l5 -5" />,
  horizontal: <path stroke="currentColor" d="M7 9l-3 3l3 3m-3 -3h16m-3 -3l3 3l-3 3" />,
  vertical: <path stroke="currentColor" d="M9 7l3 -3l3 3m-3 -3v16m-3 -3l3 3l3 -3" />,
  check: <path stroke="currentColor" d="M6 13l4 4l7 -9" />,
  arrow: <path stroke="currentColor" d="M4 12h16m-3 -3l3 3l-3 3" />,
  edit: (
    <path stroke="currentColor" d="M17.7 4.7a1 1 0 00-1.4 0L5 16v3h3L19.3 7.7a1 1 0 000-1.4l-1.6-1.6zM14.5 6.5l3 3" />
  ),
  error: (
    <path
      stroke="currentColor"
      d="M9.25 9.25l5.5 5.5M14.75 9.25l-5.5 5.5M8.207 3.793a1 1 0 01.707-.293h6.172a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707v6.172a1 1 0 01-.293.707l-4.414 4.414a1 1 0 01-.707.293H8.914a1 1 0 01-.707-.293l-4.414-4.414a1 1 0 01-.293-.707V8.914a1 1 0 01.293-.707l4.414-4.414z"
    />
  ),
  warning: (
    <>
      <path
        stroke="currentColor"
        d="M2.83 19.01l8.3-14.94a1 1 0 011.74 0l8.3 14.94a1 1 0 01-.87 1.49H3.7a1 1 0 01-.87-1.49z"
      />
      <path
        fill="currentColor"
        d="M10.67 9.65a1 1 0 011-1.15h.67a1 1 0 01.99 1.15l-.84 5.55c-.08.57-.9.57-.98 0l-.84-5.55zM12 18.5a1 1 0 100-2 1 1 0 000 2z"
      />
    </>
  ),
  success: (
    <>
      <path stroke="currentColor" d="M10.58 15.57l5.67-5.82M7.75 12.66l2.83 2.91" />
      <circle cx="12" cy="12" r="9" stroke="currentColor" />
    </>
  ),
  info: (
    <>
      <path stroke="currentColor" d="M11 11h1.5v5" />
      <circle cx="12" cy="12" r="9" stroke="currentColor" />
      <circle cx="12.25" cy="8.25" r=".25" stroke="currentColor" fill="currentColor" />
    </>
  ),
  alert: (
    <path
      fill="currentColor"
      d="M10 4.12A1 1 0 0111 3h1.73a1 1 0 011 1.12l-1.37 10.91c-.07.58-.92.58-.99 0l-1.36-10.9zM11.88 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    />
  ),

  adult: (
    <path
      fill="currentColor"
      d="M11.25 2.7a2.25 2.25 0 101.5 4.24 2.25 2.25 0 00-1.5-4.24zM13.26 7.45c1.12.07 2.24.55 2.67 1.68.42 1.13 1.07 5.37 1.07 5.37a.88.88 0 01-1.72.3l-.65-3.27v10.35a.93.93 0 01-.94.94.94.94 0 01-.94-.94v-6.13c0-.41-.25-.8-.75-.8-.46 0-.75.39-.75.8v6.13a.94.94 0 01-1.87 0V11.53l-.65 3.28A.87.87 0 117 14.5s.65-4.24 1.08-5.37C8.5 8 9.63 7.52 10.74 7.45h2.52z"
    />
  ),
  junior: (
    <path
      fill="currentColor"
      d="M10.87 8.62h2.26l.14.01c1.11.07 2.12.7 2.66 1.68l1.92 3.48a.91.91 0 01-1.6.9l-1.62-2.82v9.32a.94.94 0 01-1.88 0V16.5a.75.75 0 00-1.5 0v4.69a.94.94 0 11-1.87 0V11.8l-1.64 2.87a.91.91 0 01-1.6-.89l1.9-3.47a3.23 3.23 0 012.83-1.7zM14.34 5.76a2.34 2.34 0 11-4.68 0 2.34 2.34 0 014.68 0z"
    />
  ),
  child: (
    <path
      fill="currentColor"
      d="M12 10.12a2.52 2.52 0 100-5.05 2.52 2.52 0 000 5.05z M6 7.39c-.23.34-.2.79.08 1.09l3.38 3.69.02 8.8a.94.94 0 101.87 0v-3.6a.65.65 0 111.3 0v3.6a.94.94 0 101.87 0v-8.8l3.37-3.7a.88.88 0 00-1.3-1.2l-2.51 2.69a2 2 0 01-1.46.63h-1.25a2 2 0 01-1.45-.63L7.38 7.27A.89.89 0 006 7.4z"
    />
  ),
  infant: (
    <path
      fill="currentColor"
      d="M12 8.64a2.42 2.42 0 100-4.84 2.42 2.42 0 000 4.84zM11.1 9.25c-.98 0-1.9.38-2.6 1.07l-2 2.01a.83.83 0 001.17 1.18l1.9-1.91v1.58h4.85V11.6l1.91 1.9a.83.83 0 001.18-1.17l-2.02-2.01a3.65 3.65 0 00-2.58-1.07h-1.82zM9.58 14.1l1.51 1.5-.55.56a1 1 0 00-.05 1.36l.68.8a.87.87 0 01-1.3 1.16l-1.29-1.4a2.2 2.2 0 01.07-3.06l.93-.93zM12.9 15.6l1.52-1.5.93.92c.84.84.87 2.18.07 3.05l-1.3 1.4a.87.87 0 01-1.3-1.14l.7-.8a1 1 0 00-.06-1.37l-.55-.55z"
    />
  ),
  pet: (
    <path
      fill="currentColor"
      d="M7.62 12a1.46 1.46 0 100-2.92 1.46 1.46 0 000 2.92zM10.25 9.67a1.46 1.46 0 100-2.92 1.46 1.46 0 000 2.92zM13.75 9.67a1.46 1.46 0 100-2.92 1.46 1.46 0 000 2.92zM16.38 12a1.46 1.46 0 100-2.92 1.46 1.46 0 000 2.92zM15.12 13.67l-1.45-1.7a2.48 2.48 0 00-1.02-.77 1.18 1.18 0 00-.2-.05c-.14-.03-.3-.03-.45-.03-.15 0-.3 0-.46.03l-.2.06c-.4.14-.74.45-1.01.77l-1.45 1.7c-.76.76-1.7 1.6-1.53 2.79.17.6.6 1.18 1.36 1.35.43.09 1.78-.25 3.23-.25h.1c1.45 0 2.81.33 3.24.25a1.84 1.84 0 001.36-1.35c.18-1.2-.76-2.04-1.53-2.8z"
    />
  ),
  ship: (
    <>
      <path fill="currentColor" d="M6.52 8v1.962h1.42L7.585 8H6.521zM2 16.256h16.705l2.001-2.822H2.32L2 16.256z" />
      <path
        stroke="currentColor"
        d="M6.52 9.962h-.83v1.648H2.527l-.155 1.37-.052.454m4.2-3.472V8h1.065l.355 1.962m-1.42 0h1.42m0 0h.772v1.648h1.855V9.27h6.82l2.314 2.34H22l-.971 1.37-.323.454m0 0l-2 2.822H2l.32-2.822m18.386 0H2.32"
      />
    </>
  ),
  meal: (
    <>
      <path
        stroke="currentColor"
        d="M6.18 3v4.43c0 .45.3.85.74.97l1.76.47m0 0V3m0 5.87l1.76-.47a1 1 0 00.74-.97V3m-2.5 5.87V21"
      />
      <path stroke="currentColor" d="M17.82 12.04h-1.45a1 1 0 01-1-1.1l.62-6.2a1 1 0 01.41-.72L17.82 3v9.04zm0 0V21" />
    </>
  ),
  remove: (
    <>
      <path stroke="currentColor" d="M17 7v12H7V7m10 0h1.5M17 7h-2M7 7H5.5M7 7h2m0 0l1-2.5h4L15 7M9 7h6" />
      <path stroke="currentColor" d="M10.5 10v6M13.5 10v6" />
    </>
  ),
  dnd: (
    <>
      <circle cx="14" cy="16" r="1" fill="currentColor" />
      <circle cx="10" cy="8" r="1" fill="currentColor" />
      <circle cx="10" cy="12" r="1" fill="currentColor" />
      <circle cx="10" cy="16" r="1" fill="currentColor" />
      <circle cx="14" cy="8" r="1" fill="currentColor" />
      <circle cx="14" cy="12" r="1" fill="currentColor" />
    </>
  ),
  swap: (
    <>
      <path strokeLinecap="round" strokeWidth="1.5" stroke="currentColor" d="m12.50173,14.7301l-10,0" />
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="currentColor"
        d="m6.50173,18.7301l-4,-4l4,-4"
      />
      <path strokeLinecap="round" strokeWidth="1.5" stroke="currentColor" d="m11.50173,10.7301l10,0" />
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="currentColor"
        d="m17.50173,14.7301l4,-4l-4,-4"
      />
    </>
  ),
};

export const iconNames = Object.keys(path);

interface SVGProps {
  readonly inline?: boolean;
  readonly small?: boolean;
  readonly large?: boolean;
  readonly inheritColor?: boolean;
  readonly error?: boolean;
}

interface IconProps extends SVGProps {
  readonly name: IconName;
  readonly label?: string;
}

export const SVG = styled.svg<SVGProps>`
  fill: none;
  stroke: none;
  stroke-width: 1.75;
  stroke-linecap: round;
  stroke-linejoin: round;
  display: block;
  ${size(measurement.size.icon.default)}

  ${(props) =>
    props.inheritColor
      ? css`
          color: inherit;
        `
      : css`
          color: ${props.theme.icon};
        `}

  ${(props) =>
    props.inline &&
    css`
      display: inline;
      margin: ${measurement.space.icon.inline};
      vertical-align: middle;
    `}

  ${(props) =>
    props.small &&
    css`
      stroke-width: 2;
      ${size(measurement.size.icon.small)}
    `}

  ${(props) =>
    props.large &&
    css`
      stroke-width: 1;
      ${size(measurement.size.icon.large)}
    `}

  ${StyledInput.Wrapper} & {
    position: absolute;
    top: 50%;
    right: ${measurement.space.input.icon};
    transform: translateY(-50%);
    z-index: 1;
  }

  ${StyledInput.InnerSelect} & {
    top: 0;
    right: 0;
    transform: translate(-25%, 12.5%);
    z-index: 0;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${color.alert.default.error};
    `}
`;

const Icon: React.FunctionComponent<IconProps> = ({ name, label, ...props }) => (
  <>
    <SVG viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props} aria-hidden="true">
      {path[name]}
    </SVG>
    {label && <VisuallyHidden>{label}</VisuallyHidden>}
  </>
);

export const IconDataUri = (name: IconName, strokeColor = color.icon.default, strokeWidth = 1.75) =>
  `'data:image/svg+xml;base64,${btoa(
    ReactDOMServer.renderToString(
      <SVG
        style={{ color: strokeColor }}
        fill="none"
        stroke="none"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {path[name]}
      </SVG>
    )
  )}'`;

export default Icon;
