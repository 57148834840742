import * as lodash from 'lodash';
import { List } from 'purify-ts/List';
import { Maybe } from 'purify-ts/Maybe';
import { assign, send } from 'xstate';
import gtm from '../../analytics/gtm';
import { sailingRequiresAccommodation } from '../../settings';
import logger from '../../utils/logging';
import { LOADER_ACTION_EVENTS } from '../loader/loaderMachine';
import * as API from '../types';
import {
  ApiError,
  ChargeInfoOrError,
  ExtendedAccommodation,
  isPassengerInput,
  PriceError,
  SailingMeta,
  TripType,
} from '../types';
import {
  accommodationsAvailable,
  cabinsAvailable,
  canFitAdultOrJunior,
  canFitChild,
  canFitInfant,
  canFitPet,
  checkAccommodationAvailableInSailing,
  createAccommodationId,
  filterToPetCabins,
  getChildren,
  getInfants,
  getPets,
  pickUntilFoundCheapest,
  removeEmptyAccommodations,
} from '../utils/accommodationUtils';
import { accommodationProduct, formAnalyticsProductName, productToGa4Product } from '../utils/analyticsUtils';
import { arrangeByType, getPassengersAndPetsForLeg, hasChildOrJunior, hasPets } from '../utils/passengerUtils';
import { getCommonProducts } from '../utils/productUtils';
import {
  createTariffPrice,
  indexToLeg,
  onCatalogSelectedSailing,
  onCatalogSelectedSailings,
} from '../utils/sailingUtils';
import { CatalogContext, CatalogEvent } from './catalogMachine';

const loaderId = 'loader';

const findAccommodationAvailability = (
  existing: API.ExtendedAccommodation[] | API.AccommodationError | undefined,
  products: API.Products,
  code: string
): Maybe<API.ExtendedProduct> =>
  Maybe.fromNullable(products.ACCOMMODATION)
    .chain((accommodationProducts) => List.find((_) => _.code === code, accommodationProducts))
    .filter(
      ({ code }) =>
        !!products.ACCOMMODATION &&
        checkAccommodationAvailableInSailing(
          products.ACCOMMODATION,
          typeof existing !== 'string' ? existing || [] : [],
          code
        )
    );

const addOrReplaceAccommodation = ({ catalogs, searchParams }: CatalogContext, { data, type }: any): API.Catalog[] => {
  logger.debug('addOrReplaceAccommodation', type, data, catalogs);

  const callback = (catalog: API.Catalog, selected: API.ExtendedSailing & API.SelectedSailing) => {
    let accommodations = lodash.cloneDeep(selected.accommodations);
    const products = API.isError(selected.products) ? {} : selected.products;

    return findAccommodationAvailability(accommodations, products, data.code)
      .map(({ code, subtype, price }) => {
        const accommodation: API.ExtendedAccommodation = {
          code,
          id: type === 'REPLACE_ACCOMMODATION' ? data.accommodationId : createAccommodationId(accommodations),
          type: subtype || '',
          legs: [indexToLeg(catalog.index)],
          passengers: data.passengers || [],
          price,
        };

        //UA
        gtm.clearEcommerce();
        const product = accommodationProduct({
          code,
          amount: 1,
          chargeInfo: API.maybeChargeInfo(selected.tariff, accommodation),
          index: catalog.index,
          type: accommodation.type,
          name: formAnalyticsProductName(products, API.ProductType.ACCOMMODATION, code),
          brand: `${selected.departurePort} - ${selected.arrivalPort}`,
        });
        gtm.productClick({
          actionField: { list: 'Accommodations' },
          products: [product],
        });

        gtm.send();

        //GA4
        const ga4Product = {
          ...productToGa4Product(product, searchParams.currency),
          item_list_name: 'accommodations',
        };
        gtm.clearEcommerce();
        gtm.ga4ProductClick({
          products: [ga4Product],
        });
        gtm.send();

        if (accommodations && Array.isArray(accommodations)) {
          const acclist = accommodations as ExtendedAccommodation[];
          if (type === 'REPLACE_ACCOMMODATION') {
            List.findIndex((_) => _.id === data.accommodationId, accommodations)
              .map((index) => acclist.splice(index, 1, accommodation))
              .orDefaultLazy(() => console.error(`Could not find accommodation for`, { data }) as any);
          } else if (data.addToIndex >= 0) {
            accommodations.splice(data.addToIndex, 0, accommodation);
          } else {
            accommodations.push(accommodation);
          }
        } else {
          accommodations = [accommodation];
        }

        const newAccommodations =
          accommodations && Array.isArray(accommodations)
            ? accommodations.map((acc) => {
                const filtered =
                  acc.id === accommodation.id
                    ? acc
                    : {
                        ...acc,
                        passengers: acc.passengers?.filter((p) => !data.passengers.includes(p)),
                      };
                return filtered;
              })
            : accommodations;

        return {
          ...catalog,
          selected: {
            ...selected,
            accommodations: removeEmptyAccommodations(newAccommodations),
          },
        } as API.Catalog;
      })
      .orDefault(catalog);
  };

  if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
    return onCatalogSelectedSailings(catalogs, callback);
  }
  return onCatalogSelectedSailing(catalogs, data, callback);
};

const AccommodationActions = {
  addAccommodation: assign<CatalogContext>({
    catalogs: addOrReplaceAccommodation,
    errors: [],
  }),

  removeAccommodation: assign<CatalogContext>({
    catalogs: ({ catalogs }: CatalogContext, { data }: any): API.Catalog[] => {
      return onCatalogSelectedSailing(catalogs, data, (catalog, selected) => {
        const accommodations = lodash.cloneDeep(selected.accommodations);
        if (Array.isArray(accommodations)) {
          List.findIndex(({ id }) => id === data.accommodationId, accommodations).map((index) =>
            accommodations.splice(index, 1)
          );
        }

        return {
          ...catalog,
          selected: {
            ...selected,
            accommodations,
          },
        };
      });
    },
    errors: [],
  }),

  replaceAccommodation: assign<CatalogContext>({
    catalogs: addOrReplaceAccommodation,
    errors: [],
  }),

  addPassengerToAccommodation: assign<CatalogContext>({
    catalogs: ({ catalogs, searchParams }: CatalogContext, { data }: any): API.Catalog[] => {
      const callback = (catalog: API.Catalog, selected: API.ExtendedSailing & API.SelectedSailing) => {
        const accommodations = lodash.cloneDeep(selected.accommodations);
        if (Array.isArray(accommodations)) {
          accommodations.forEach((accommodation) => {
            accommodation.passengers = accommodation.passengers?.filter((id: number) => id !== data.passengerId);
          });

          List.find(({ id }) => id === data.accommodationId, accommodations).map((accommodation) =>
            accommodation.passengers?.includes(data.passengerId)
              ? null
              : accommodation.passengers?.push(data.passengerId)
          );

          return {
            ...catalog,
            selected: {
              ...selected,
              accommodations: removeEmptyAccommodations(accommodations),
            },
          };
        }

        return { ...catalog, selected };
      };
      if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
        return onCatalogSelectedSailings(catalogs, callback);
      }
      return onCatalogSelectedSailing(catalogs, data, callback);
    },
    errors: [],
  }),

  removePassengerFromAccommodation: assign<CatalogContext>({
    catalogs: ({ catalogs, searchParams }: CatalogContext, { data }: any): API.Catalog[] => {
      const callback = (catalog: API.Catalog, selected: API.ExtendedSailing & API.SelectedSailing) => {
        const accommodations = lodash.cloneDeep(selected.accommodations);
        if (Array.isArray(accommodations)) {
          List.find(({ id }) => id === data.accommodationId, accommodations).map((accommodation) => {
            accommodation.passengers = accommodation.passengers?.filter((id: number) => id !== data.passengerId);
            return accommodation;
          });

          return {
            ...catalog,
            selected: {
              ...selected,
              accommodations: removeEmptyAccommodations(accommodations),
            },
          };
        }

        return { ...catalog, selected };
      };
      if (searchParams.type === TripType.OVERNIGHT_CRUISE) {
        return onCatalogSelectedSailings(catalogs, callback);
      }
      return onCatalogSelectedSailing(catalogs, data, callback);
    },
    errors: [],
  }),

  loadAccommodationPrices: send<CatalogContext, CatalogEvent>(
    (context, { data }: any) => {
      return {
        type: LOADER_ACTION_EVENTS.LOAD_ACCOMMODATION_PRICES,
        data: { context, ...data },
      };
    },
    {
      to: loaderId,
    }
  ),

  onLoadAccommodationPrices: assign<CatalogContext, CatalogEvent>({
    catalogs: ({ catalogs }: CatalogContext, { result }: any) => {
      return onCatalogSelectedSailing(catalogs, result, (catalog, selected) => {
        if (typeof selected.products !== 'string' && Array.isArray(selected.products[API.ProductType.ACCOMMODATION])) {
          selected.products[API.ProductType.ACCOMMODATION]?.forEach((product) =>
            List.find(
              ({ code, type }: { code: string; type: string }) => product.code === code && product.subtype === type,
              result.quotes as API.AccommodationQuote[]
            )
              .chainNullable((_) => _.chargeInfo)
              .map((chargeInfo: ChargeInfoOrError) => {
                if (API.isApiError(chargeInfo)) {
                  logger.warn(
                    'No accommodation price received:',
                    selected.sailingCode,
                    product.name,
                    (chargeInfo as ApiError).errorCode
                  );
                  product.price = PriceError.NO_PRICE;
                } else {
                  product.price = createTariffPrice(product.price, selected.tariff, chargeInfo);
                }
                return chargeInfo;
              })
          );
        }

        return catalog;
      });
    },
  }),
};

export const createDefaultAccommodationsForCruise = (
  sailing: {
    departurePort: string;
    arrivalPort: string;
    departureTime: string;
    sailingCode: string;
  },
  passengers: API.ExtendedPassengerAndPet[],
  outbboundProducts: API.Products | API.ProductError,
  inboundProducts: API.Products | API.ProductError,
  sailingIndex: number,
  tripType: TripType
): API.ExtendedAccommodation[] | API.AccommodationError => {
  const commonProducts = getCommonProducts(outbboundProducts, inboundProducts);

  return createDefaultAccommodations(sailing, passengers, commonProducts, sailingIndex, tripType);
};

/*
1. Lemmikin kanssa täytyy kuitenkin aina olla lemmikkihytti, maxHostedPets (yleensä 2)
2. Finnlinkin päivälähdöillä 0600-1800 (kaikki Naantalin, Långnäsin ja Kapellskärin väliset lähdöt) Ei hyttiä
3. Hytitys halvimmasta kalliimpaan
  - Aikuiset ja juniorit vie vuodepaikan aina.
  - Kaksi lasta voi matkustaa ilman vuodepaikkaa, kolmas vaatii vuodepaikan
  - Esim. neljän hengen hytissä voi olla 1 aikuinen ja 5 lasta, joista 2 ei vie vuodepaikkaa

kysymyksiä:
  - Voiko yksi henkilö ottaa 4 hlö hengen hytin
  - Lasketaanko juniori aikuiseksi, eli juniorin kanssa voi olla lapsia?
*/

export const createDefaultAccommodations = (
  sailing: {
    departurePort: string;
    arrivalPort: string;
    departureTime: string;
    sailingCode: string;
    meta?: SailingMeta;
  },
  passengers: API.ExtendedPassengerAndPet[],
  products: API.Products | API.ProductError,
  sailingIndex: number,
  tripType: TripType
): API.ExtendedAccommodation[] | API.AccommodationError => {
  // Wrapper (initially) for debugging purposes

  const accommodations = (API.isError(products) ? {} : products).ACCOMMODATION;

  if (!accommodations || accommodations.length <= 0) {
    logger.debug(
      'createDefaultAccommodations, no accommodations found in products',
      sailing.sailingCode,
      'products:',
      products
    );
    return API.AccommodationError.NOT_AVAIL_ACCOMMODATIONS;
  }

  logger.debug(
    'createDefaultAccommodations',
    sailing.sailingCode,
    'sailingIndex:',
    sailingIndex,
    'sailing:',
    sailing,
    'passengers:',
    passengers,
    'products:',
    products
  );
  let result = doCreateDefaultAccommodations(sailing, passengers, accommodations, sailingIndex, false, tripType);
  if (result === API.AccommodationError.NOT_AVAIL_ACCOMMODATIONS) {
    // Do second round with CHAIRS
    result = doCreateDefaultAccommodations(sailing, passengers, accommodations, sailingIndex, true, tripType);
  }
  logger.debug('createDefaultAccommodations', sailing.sailingCode, 'result:', result);

  return result;
};

const doCreateDefaultAccommodations = (
  sailing: {
    departurePort: string;
    arrivalPort: string;
    departureTime: string;
    sailingCode: string;
    meta?: SailingMeta;
  },
  passengers: API.ExtendedPassengerAndPet[],
  accommodations: API.ExtendedProduct[],
  sailingIndex: number,
  includeChairs: boolean,
  tripType: TripType
): API.ExtendedAccommodation[] | API.AccommodationError => {
  const defaults: API.ExtendedAccommodation[] = [];
  const passengersAndPetsInLeg = getPassengersAndPetsForLeg(passengers, indexToLeg(sailingIndex));
  const usablePassengersAndPets = [...passengersAndPetsInLeg];

  if (!sailingRequiresAccommodation(sailing, tripType, hasPets(passengersAndPetsInLeg))) {
    return defaults;
  }

  let requireSize = 1;

  while (usablePassengersAndPets.length > 0) {
    requireSize = usablePassengersAndPets.filter(isPassengerInput).length;
    let requireSubtypes = includeChairs ? ['CABIN', 'CHAIR'] : ['CABIN'];
    let cabinsOnly = false;
    let filteredAccommodations = [...accommodations];

    if (getChildren(usablePassengersAndPets).length > 0 || getInfants(usablePassengersAndPets).length > 0) {
      cabinsOnly = true;
      if (!cabinsAvailable(filteredAccommodations, defaults)) {
        return API.AccommodationError.NO_AVAIL_CABINS;
      }
    }

    if (hasPets(usablePassengersAndPets)) {
      filteredAccommodations = filterToPetCabins(accommodations);
      if (!accommodationsAvailable(filteredAccommodations)) {
        return API.AccommodationError.NO_AVAIL_PET_CABINS;
      }
    }

    const pick = pickUntilFoundCheapest(filteredAccommodations, defaults, requireSize, requireSubtypes, cabinsOnly);

    if (!pick) {
      return API.AccommodationError.NOT_AVAIL_ACCOMMODATIONS;
    }

    defaults.push({
      id: createAccommodationId(defaults),
      code: pick.code,
      type: pick.subtype || '',
      legs: [indexToLeg(sailingIndex)],
      passengers: [],
    });

    if (getChildren(usablePassengersAndPets).length > 0 || getInfants(usablePassengersAndPets).length > 0) {
      const adultIndex = usablePassengersAndPets.findIndex((p) => p.type === API.PassengerType.ADULT);
      //console.log('Did find initial adult', adultIndex);
      if (adultIndex < 0) {
        return API.AccommodationError.CHILD_NO_SUPERVISOR;
      }
      const initialAdult = usablePassengersAndPets.splice(adultIndex, 1);
      defaults[defaults.length - 1].passengers?.push(initialAdult[0].id);
    } else if (hasPets(usablePassengersAndPets)) {
      const adultOrJuniorIndex = usablePassengersAndPets.findIndex(
        (p) => p.type === API.PassengerType.ADULT || p.type === API.PassengerType.JUNIOR
      );
      //console.log('Did find initial adult', adultIndex);
      if (adultOrJuniorIndex < 0) {
        return API.AccommodationError.PETS_NO_SUPERVISOR;
      }
      const initialAdult = usablePassengersAndPets.splice(adultOrJuniorIndex, 1);
      defaults[defaults.length - 1].passengers?.push(initialAdult[0].id);
    }

    //fill with children
    // console.log(
    //   'should fill with children',
    //   [...usablePassengers],
    //   canFitChild(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   getChildren(usablePassengers).length
    // );
    while (
      canFitChild(defaults[defaults.length - 1], passengersAndPetsInLeg, accommodations) &&
      getChildren(usablePassengersAndPets).length > 0
    ) {
      //console.log('filling with children');
      const childIndex = usablePassengersAndPets.findIndex((p) => p.type === API.PassengerType.CHILD);
      if (childIndex < 0) break; //no children to fill with

      const child = usablePassengersAndPets.splice(childIndex, 1);
      defaults[defaults.length - 1].passengers?.push(child[0].id);
    }
    // console.log(
    //   'After filling with children',
    //   [...defaults],
    //   canFitChild(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   [...usablePassengers],
    //   getChildren(usablePassengers).length
    // );

    //fill with infant
    // console.log(
    //   'should fill with infant',
    //   canFitInfant(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   getChildren(usablePassengers).length > 0
    // );
    while (
      canFitInfant(defaults[defaults.length - 1], passengersAndPetsInLeg, accommodations) &&
      getInfants(usablePassengersAndPets).length > 0
    ) {
      //console.log('filling with infants');
      const childIndex = usablePassengersAndPets.findIndex((p) => p.type === API.PassengerType.INFANT);
      if (childIndex < 0) break; //no children to fill with

      const child = usablePassengersAndPets.splice(childIndex, 1);
      defaults[defaults.length - 1].passengers?.push(child[0].id);
    }
    // console.log(
    //   'After filling with infants',
    //   [...defaults],
    //   canFitInfant(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   [...usablePassengers],
    //   getInfants(usablePassengers).length
    // );
    //fill with pets
    // console.log(
    //   'should fill with pets',
    //   canFitPet(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   hasPets(usablePassengers)
    // );

    while (
      canFitPet(defaults[defaults.length - 1], passengersAndPetsInLeg, accommodations) &&
      hasPets(usablePassengersAndPets)
    ) {
      const petIndex = usablePassengersAndPets.findIndex(API.isPetInput);
      if (petIndex < 0) break; //no children to fill with

      const pet = usablePassengersAndPets.splice(petIndex, 1);
      defaults[defaults.length - 1].passengers?.push(pet[0].id);
    }

    //fill with rest
    // console.log(
    //   'Fill with rest',
    //   canFitAdultOrJunior(defaults[defaults.length - 1], passengersInLeg, accommodations),
    //   usablePassengers.length > 0
    // );
    while (
      !hasPets(usablePassengersAndPets) &&
      !hasChildOrJunior(usablePassengersAndPets) &&
      canFitAdultOrJunior(defaults[defaults.length - 1], passengersAndPetsInLeg, accommodations) &&
      usablePassengersAndPets.length > 0
    ) {
      //console.log('filling with rest');
      const index = usablePassengersAndPets.findIndex(
        ({ type }) => type === API.PassengerType.ADULT || type === API.PassengerType.JUNIOR
      );
      if (index < 0) {
        return API.AccommodationError.UNSPECIFIED;
      }
      const rest = usablePassengersAndPets.splice(index, 1);
      defaults[defaults.length - 1].passengers?.push(rest[0].id);
    }
  }

  return usablePassengersAndPets.length > 0
    ? API.AccommodationError.NO_SPACE
    : defaults.map((def) => ({
        ...def,
        passengers: def.passengers ? arrangeByType(def.passengers, passengers) : undefined,
      }));
};

export default AccommodationActions;
