import React, { FunctionComponent } from 'react';
import { RadioStateReturn } from 'reakit/Radio';
import RadioCard from '../../../design-system/components/RadioCard';
import { H3, P } from '../../../design-system/components/Text';
import { hooks } from '../../../design-system/helpers/mixins';

export interface PaymentCardProps {
  readonly paymentType: RadioStateReturn;
  readonly uuid: string;
}

const PaymentCard: FunctionComponent<PaymentCardProps> = ({ paymentType, uuid }) => {
  const [sbPayment, ref] = hooks.useStoryblokComponent<HTMLLabelElement>({ uuid });
  const slug = sbPayment?.slug;

  return (
    <>
      <RadioCard state={paymentType} value={slug} ref={ref}>
        <H3>{sbPayment?.content['title']}</H3>
        <P>{sbPayment?.content['description']}</P>
      </RadioCard>
    </>
  );
};

export default PaymentCard;
